import { Injectable } from "@angular/core";
import { UiExtensionService } from "../general/ui/ui-extension";
import { ILeplaceTreasure } from "../../classes/def/places/leplace";
import { MarkerUtils } from "./marker-utils";
import { IPlaceMarkerContent, IPlaceMarkerEdit } from "../../classes/def/map/map-data";
import { ILeplaceObjectContainer } from "../../classes/def/core/objects";
import { IGroupMember } from "../../classes/def/mp/groups";
import { EMarkerTypes, EMarkerScope, EMarkerPriority } from "../../classes/def/map/markers";
import { ETreasureType } from "../../classes/def/items/treasures";
import { EMarkerIcons } from "../../classes/def/app/icons";
import { GameUtils } from "../../classes/utils/game-utils";
import { UserDataService } from "../data/user";
import { ESettingsCode } from "../../classes/def/app/settings";
import { LatLng } from "@ionic-native/google-maps/ngx";


@Injectable({
    providedIn: 'root'
})
export class MarkerUtilsService {

    maxEditsPerItem: number = 100;
    keepPrevCoords: boolean = false;

    constructor(
        public uiext: UiExtensionService
    ) {
        console.log("marker utils service created");
    }

    /**
    * attach a marker to this specific item
    * @param treasure 
    * @param layer 
    * @param worldMapMode 
    * @param debug 
    */
    attachPlaceMarkerToTreasure(treasure: ILeplaceTreasure, layer: string, worldMapMode: boolean, debug: boolean,
        callback: (item: ILeplaceTreasure) => any, reversePriority: boolean, editorMode: boolean) {
        let crateMarkersContent1: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromTreasure(treasure, treasure.spec ? treasure.spec.markerType : EMarkerTypes.canvasFrame);
        let locked: boolean = crateMarkersContent1.locked;

        if (treasure.lockedForUser != null) {
            locked = treasure.lockedForUser;
        }

        if (treasure.owned) {
            crateMarkersContent1.canEdit = true;
            if (editorMode) {
                crateMarkersContent1.drag = true;
            }
            crateMarkersContent1.animate = true;
        }

        MarkerUtils.setTreasureMarkerZindex(crateMarkersContent1, treasure, reversePriority);

        crateMarkersContent1.layer = layer;
        crateMarkersContent1.icon = treasure.spec ? treasure.spec.photoUrl : null;

        if (treasure.photoMarker && treasure.photoUrl) {
            crateMarkersContent1.icon = treasure.photoUrl;
            MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, EMarkerScope.place);
            crateMarkersContent1.label = null;
        } else {
            MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, !locked ? EMarkerScope.item : EMarkerScope.auxItem);
        }

        crateMarkersContent1.uid = treasure.uid;

        // include all places (not only registered)
        if (treasure.place && treasure.place.place) {
            switch (treasure.type) {
                case ETreasureType.treasure:
                    // show place names above treasures
                    crateMarkersContent1.label = treasure.place.place.name;
                    // crateMarkersContent1.label = MarkerUtils.attachMarkerId(crateMarkersContent1.label, treasure);
                    // crateMarkersContent1.heading = SettingsManagerService.settings.app.settings.showMarkerIds.value ? "LP" + treasure.id : null;
                    MarkerUtils.setColorRegistered(crateMarkersContent1);
                    break;
                default:
                    MarkerUtils.setColorRegistered(crateMarkersContent1);
                    break;
            }
        }

        MarkerUtils.setColorDefaultInit(crateMarkersContent1);

        if (worldMapMode != null) {
            treasure.isWorldMap = worldMapMode;
        }
        if (debug != null) {
            treasure.isDebug = debug;
        }
        if (!callback) {
            crateMarkersContent1.callback = () => {
                let crateOpenInfo = MarkerUtils.getCrateOpenInfo(treasure, treasure.isWorldMap, treasure.isDebug);
                this.uiext.showAlertNoAction(crateOpenInfo.title, crateOpenInfo.sub);
            };
        } else {
            crateMarkersContent1.callback = () => {
                callback(treasure);
            };
        }

        // if (crateMarkersContent1.drag) {
        crateMarkersContent1.dragCallback = (lat: number, lng: number) => {

            let newEdit: IPlaceMarkerEdit = {
                placeMarkerUid: treasure.placeMarker.uid,
                prevCoords: new LatLng(treasure.lat, treasure.lng),
                timestamp: new Date(),
                index: 0
            };

            if (!treasure.placeMarker.edits) {
                treasure.placeMarker.edits = [];
            }

            if (!treasure.placeMarker.editIndex) {
                treasure.placeMarker.editIndex = 0;
            }
            newEdit.index = treasure.placeMarker.editIndex;
            treasure.placeMarker.editIndex += 1;

            console.log("new edit: ", newEdit);

            if (treasure.placeMarker.edits.length < this.maxEditsPerItem) {
                treasure.placeMarker.edits.push(newEdit);
            } else {
                if (this.keepPrevCoords) {
                    treasure.placeMarker.edits[treasure.placeMarker.edits.length - 1].timestamp = newEdit.timestamp;
                } else {
                    treasure.placeMarker.edits[treasure.placeMarker.edits.length - 1] = newEdit;
                }
            }

            treasure.placeMarker.location.lat = lat;
            treasure.placeMarker.location.lng = lng;
            // if (item.location && item.location.location) {
            //     item.location.location.merged.lat = lat;
            //     item.location.location.merged.lng = lng;
            // }
            treasure.lat = lat;
            treasure.lng = lng;

            console.log("drag event registered");
        };
        // }

        if (treasure.isLocal) {
            crateMarkersContent1.lockSync = true;
        } else {
            crateMarkersContent1.lockSync = false;
        }

        crateMarkersContent1.treasure = Object.assign({}, treasure);

        // do not deep copy as it will break references when updating map markers (and they will get replaced)

        // crateMarkersContent1.treasure = DeepCopy.deepcopy(treasure);

        crateMarkersContent1.lockedForUser = treasure.lockedForUser;

        // console.log("treasure: " + treasure.id + " locked for user: " + treasure.lockedForUser);
        // console.log(treasure);

        // crateMarkersContent1.treasure = DeepCopy.deepcopy(treasure);
        // console.log("attach place marker: " + treasure.id);

        treasure.placeMarker = crateMarkersContent1;
        return crateMarkersContent1;
    }




    /**
    * attach a marker to this specific item
    * @param item 
    * @param layer 
    * @param worldMapMode 
    * @param debug 
    */
    getARObjectPlaceMarker(item: ILeplaceObjectContainer, layer: string) {
        let crateMarkersContent1: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromARObject(item);
        MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, EMarkerScope.item);
        crateMarkersContent1.layer = layer;
        crateMarkersContent1.icon = item.object.marker;
        crateMarkersContent1.uid = GameUtils.getDefaultObjectUid(item.object.id, item.object.genericCode);
        return crateMarkersContent1;
    }


    /**
     * format mp user marker
     * check show user marker flag
     * @param member
     * @param layer
     */
    getGroupMemberPlaceMarker(member: IGroupMember, layer: string) {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataFromGroupMember(member);
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.layer = layer;
        if (member.user && UserDataService.checkFlag(member.user.settings, ESettingsCode.showUserMarkerMp) === 1) {
            gMarkerContent.icon = member.user.photoUrl;
        } else {
            gMarkerContent.icon = EMarkerIcons.mpUser;
        }
        gMarkerContent.uid = "/MP/USER/" + member.userId;
        return gMarkerContent;
    }


    /**
     * format user marker
     * @param layer 
     * @param username 
     * @param userIcon 
     */
    getUserPlaceMarker(layer: string, username: string, userIcon: string) {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataForUser(username);
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.layer = layer;
        gMarkerContent.icon = userIcon;
        gMarkerContent.zindex = EMarkerPriority.user;
        gMarkerContent.uid = "/GENERAL/USER";
        return gMarkerContent;
    }

    getDronePlaceMarker(layer: string, username: string, droneicon: string) {
        let gMarkerContent: IPlaceMarkerContent = MarkerUtils.getBaseMarkerDataForUser(username);
        gMarkerContent.mode = EMarkerTypes.canvasPlain;
        MarkerUtils.setMarkerDisplayOptions(gMarkerContent, EMarkerScope.item);
        gMarkerContent.radius = 80;
        gMarkerContent.layer = layer;
        gMarkerContent.icon = droneicon;
        gMarkerContent.zindex = EMarkerPriority.drone;
        gMarkerContent.uid = "/GENERAL/USER-DRONE";
        return gMarkerContent;
    }

}




