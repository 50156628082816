import { Component, OnInit, OnDestroy } from '@angular/core';
import { IButtonOptions, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { ITutorial } from 'src/app/classes/def/app/tutorials';
import { EAppIcons } from 'src/app/classes/def/app/icons';

export interface IDescriptionFrameParams {
  description: string;
  loaderCode: number;
  fromCache?: boolean;
  mode: number;
  title: string;
  photoUrl: string;
  buttons?: IButtonOptions[];
  customButton?: IButtonOptions;
  customButtonFn?: any;
}

export enum EDescriptionViewStyle {
  plain = 0,
  withOk = 1,
  withOkDismiss = 2,
  withCustomButton = 3,
  withCustomButtonArray = 4
}

@Component({
  selector: 'modal-description-frame',
  templateUrl: './description-frame.component.html',
  styleUrls: ['./description-frame.component.scss'],
})
export class DescriptionFrameViewComponent implements OnInit, OnDestroy {
  description: SafeHtml = "";
  title: string = "";
  selectAll: boolean = false;
  mode: number = EDescriptionViewStyle.plain;
  modes = EDescriptionViewStyle;

  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";

  loaderCode: number = null;

  loading: boolean = false;

  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IDescriptionFrameParams = null;
  noButtons: boolean = true;

  appIcons = EAppIcons;

  np: INavParams = null;

  customButton: IButtonOptions = {
    name: null,
    code: null,
    icon: EAppIcons.film,
    customIcon: true,
    class: null,
    size: null,
    disabled: false,
    enabled: true,
    highlight: null,
    callback: null
  };

  buttons: IButtonOptions[] = null;
  fromCache: boolean = true;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IDescriptionFrameParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      if (params.description) {
        this.description = this.sanitizer.bypassSecurityTrustHtml(params.description);
      }

      if (params.customButton) {
        this.customButton = params.customButton;
      }

      if (params.buttons) {
        this.buttons = params.buttons;
      }

      this.loaderCode = params.loaderCode;
      // this.descriptionLines = this.formatText(this.description);
      this.title = params.title;
      this.mode = params.mode;
      this.photoUrl = params.photoUrl;

      if (params.fromCache != null) {
        this.fromCache = params.fromCache;
      }
    }

    this.getClass(this.mode);

    if (this.loaderCode != null) {
      this.load(this.loaderCode, this.fromCache);
    }
    // console.log(this.wrapperClass);
    // console.log(this.innerClass);
  }

  ngOnDestroy() {

  }


  /**
   * load remote resource (description)
   * @param code 
   */
  load(code: number, fromCache: boolean) {
    this.loading = true;
    this.resources.getInfoData(code, fromCache).then((resp: ITutorial) => {
      this.loading = false;
      this.description = resp.description;
      this.photoUrl = resp.photoUrl;
    }).catch((err: Error) => {
      this.loading = false;
      this.description = "Description not found";
      this.analytics.dispatchError(err, "description-frame");
    });
  }

  getClass(mode: number) {
    this.noButtons = false;
    switch (mode) {
      case EDescriptionViewStyle.withOk:
      case EDescriptionViewStyle.withCustomButton:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border";
        this.buttonBar = "theme-border-top";
        break;
      case EDescriptionViewStyle.withOkDismiss:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border";
        this.buttonBar = "theme-border-top";
        break;
      case EDescriptionViewStyle.plain:
      default:
        // this.wrapperClass = "theme-border-top";
        this.wrapperClass = "";
        this.innerClass = "padding-s";
        this.buttonBar = "";
        this.noButtons = true;
        break;
    }
  }

  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    if (this.params.customButtonFn) {
      // special action
      this.params.customButtonFn();
    } else {
      // dismiss with ok
      this.dismiss(EAlertButtonCodes.ok);
    }
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }

  customButtonTap(button: IButtonOptions) {
    if (button.callback) {
      button.callback();
    } else {
      this.dismiss(button.code);
    }
  }
}
