
import { GeneralCache } from "../app/general-cache";
import { EStoryUnlockType } from "../def/business/story";
import { IStory, IStoryUnlock } from "../def/core/story";

export interface IQRFrameParams {
    description: string;
    title: string;
    qs: IQRCodeSlide[];
}

export interface IQRCodeSlide {
    heading: string;
    qrCode: string;
    plainText: boolean;
    withAction: boolean;
    actionLabel: string;
    withEmbed?: boolean;
    callback: (params: any) => Promise<IQRFrameCallbackData>;
}

export interface IQRFrameCallbackData {
    qrCode: string;
    dismiss: boolean;
}

export class StoryQRUtils {

    static getQRSlides(story: IStory, handleQRSliderCallback: (buttonCode: number, mode: number, unlockCodeCrt: string) => any) {
        let storyUnlocks: IStoryUnlock[] = story.storyUnlocks;
        let qrSlides: IQRCodeSlide[] = [];

        let su: IStoryUnlock;

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.short);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "Unlock Code",
                qrCode: su.unlockCode,
                plainText: true,
                withAction: GeneralCache.canBeTester,
                actionLabel: "Reset (admin)",
                withEmbed: false,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.short, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.searchOnlyShort);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "Unlock Code (search)",
                qrCode: su.unlockCode,
                plainText: true,
                withAction: GeneralCache.canBeTester,
                actionLabel: "Reset (admin)",
                withEmbed: false,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.searchOnlyShort, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.redeemShort);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "Unlock Code (redeem)",
                qrCode: su.unlockCode,
                plainText: true,
                withAction: GeneralCache.canBeTester,
                actionLabel: "Reset (admin)",
                withEmbed: false,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.redeemShort, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.branded);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "QR Code / Branded",
                qrCode: su.unlockCode,
                plainText: false,
                withAction: true,
                actionLabel: "Reset",
                withEmbed: true,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.branded, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.partner);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "QR Code / Partner",
                qrCode: su.unlockCode,
                plainText: false,
                withAction: true,
                actionLabel: "Reset",
                withEmbed: true,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.partner, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.searchOnly);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "QR Code (search)",
                qrCode: su.unlockCode,
                plainText: false,
                withAction: true,
                actionLabel: "Reset",
                withEmbed: true,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.searchOnly, unlockCode); }
            });
        }

        su = storyUnlocks.find(su => su.type === EStoryUnlockType.redeem);

        if (su != null) {
            let unlockCode: string = su.unlockCode;
            qrSlides.push({
                heading: "QR Code (redeem)",
                qrCode: su.unlockCode,
                plainText: false,
                withAction: true,
                actionLabel: "Reset",
                withEmbed: true,
                callback: (buttonCode: number) => { return handleQRSliderCallback(buttonCode, EStoryUnlockType.redeem, unlockCode); }
            });
        }

        return qrSlides;
    }
}