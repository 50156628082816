
import { IStory } from "../../classes/def/core/story";
import { LocationUtils } from "../location/location-utils";


export class StoryDataHelpers {
  constructor(
  ) { }

  /**
   * get story by story id
   */
  static _getStoryById(stories: IStory[], storyId: number) {
    console.log("get story by id: " + storyId);
    let story: IStory = null;
    for (let i = 0; i < stories.length; i++) {
      if (stories[i].id === storyId) {
        story = stories[i];
        break;
      }
    }
    return story;
  }

  /**
   * get story index by story id
   * @param stories 
   * @param storyId 
   * @param islocal 
   */
  static _getStoryIndexFromStoryId(stories: IStory[], storyId: number, islocal: boolean) {
    console.log("get story by id: " + storyId + ", islocal: " + islocal);
    let index: number = 0;
    for (let i = 0; i < stories.length; i++) {
      // check if islocal is false/undefined/null or if it is true
      if ((stories[i].id === storyId) && (islocal === stories[i].islocal || (islocal === false && stories[i].islocal === undefined))) {
        // console.log(this.stories[i].id + " match");
        index = i;
        break;
      }
    }
    return index;
  }


  /**
   * load places from db first
   * @param story 
   */
  static _formatStoryFromBackend(story: IStory) {
    // story = LocationUtils.formatStoryLocMulti(story);
    story.locs = [];
    for (let i = 0; i < story.locations.length; i++) {
      story.locs.push(LocationUtils.createLocationFromDB(story.locations[i]));
    }

    // console.log("format story from backend: ", story);
    return story;
  }

}
