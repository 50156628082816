import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ITabInterface } from 'src/app/app-utils';

@Injectable({
    providedIn: 'root'
})
export class NavUtilsService {

    transparentBgObservable: BehaviorSubject<boolean>;
    transparentBg: boolean = false;

    menuStateObservable: BehaviorSubject<boolean>;
    menuState: boolean = false;

    roundedStateObservable: BehaviorSubject<boolean>;
    roundedState: boolean = false;

    navChangedObservable: BehaviorSubject<boolean>;


    constructor(
    ) {
        this.transparentBgObservable = new BehaviorSubject(null);
        this.menuStateObservable = new BehaviorSubject(null);
        this.roundedStateObservable = new BehaviorSubject(null);
        this.navChangedObservable = new BehaviorSubject(null);
    }


    // spec

    /**
     * check enabled transparent bg based on the current view
     * @param p 
     */
    setOpenView(p: ITabInterface) {
        if (p) {
            if (p.transparent) {
                this.setTransparentBg(true);
            } else {
                this.setTransparentBg(false);
            }
        } else {
            this.setTransparentBg(false);
        }
    }

    // set

    setTransparentBg(enabled: boolean) {
        this.transparentBg = enabled;
        this.transparentBgObservable.next(enabled);
    }


    setMenuState(state: boolean) {
        this.menuState = state;
        this.menuStateObservable.next(state);
    }

    setRoundedTabMenuState(state: boolean) {
        this.roundedState = state;
        this.roundedStateObservable.next(state);
    }

    registerNavChanged() {
        this.navChangedObservable.next(true);
        this.navChangedObservable.next(null);
    }


    // get


    getTransparentBgObservable() {
        return this.transparentBgObservable;
    }

    getMenuStateObservable() {
        return this.menuStateObservable;
    }

    getRoundedTabStateObservable() {
        return this.roundedStateObservable;
    }

    getNavChangedObservable() {
        return this.navChangedObservable;
    }

}
