import { ILeplaceReg } from "./google";
import { IStory, IStoryLocation } from "../core/story";
import { IPlaceMarkerContent } from "../map/map-data";
import { IActivity } from "../core/activity";
import { ILocationContainer, IBackendLocation } from "./backend-location";
import { LatLng } from "@ionic-native/google-maps";
import { EMarkerTypes } from "../map/markers";
import { EARModes } from "../ar/core";
import { IGameItem } from '../items/game-item';
import { ICustomParamCategory } from "../core/custom-param";
import { IBeacon } from "../../utils/beacons";


export interface ILeplaceWrapper {
    place: ILeplaceReg;
    id: number;
    exists: boolean;
    treasures: ILeplaceTreasure[];
}


export interface IRegisteredPlaceCheck {
    googleId: string;
    id: number;
}


export interface ILeplaceTreasureAction {
    code: number;
    treasure: ILeplaceTreasure;
}


export interface ILeplaceTreasureDynamic {
    inRange: boolean;
    distanceFromUser: number;
    index: number;
    /**
    * set this flag from map for the specific use case e.g. storyline mode does not allow loading stories from map
    */
    showMarker: boolean;
    /**
    * the original index when it was loaded in the cache
    * because some items will be removed when collecting (treasures)
    */
    cacheIndex: number;
    /**
     * world edit registered
     */
    updated: boolean;
}

export interface ILeplaceTreasureAux {
    public: number,
    customParamCategory: ICustomParamCategory,
    placeClass?: number[],
    floating?: boolean
}

/**
 * min specs required by DMS
 */
export class ILeplaceTreasureMin {
    /* check origin */
    viewId?: number;
    id: number;
    uid: string;
    /**
     * treasure, story, arena
     */
    type: number;
    lat: number;
    lng: number;
    radius: number;
    // for treasure type
    coins: number;

    disabled: number;
    specificType: number;


    valid: boolean;
    /**
    * the index of the item within a place
    */
    index: number;
    /**
     * provider location id
     * starting point
     */
    locationId: number;
    /**
     * fixed objects
     */
    locked: number;

    spec: ITreasureSpec;
    /**
     * the story id that was assigned to this treasure (if the treasure is of type S)
     */
    story?: IStory;

    /**
    * the item is locked 
    * e.g.
    * the player level is too low to unlock the feature
    * the item has been collected in the meantime by another player (event)
    */
    lockedForUser: boolean;
    /**
     * check if the marker is owned by the same user e.g. custom content
     */
    owned: boolean;
    storyCategoryCode: number;

    level?: number;
    isLocal?: boolean;

    /**
    * show photo marker for e.g. user generated treasures
    */
    photoMarker: boolean;
    /**
     * override specs
     */
    photoUrl: string;

    creatorId: number;

    timestamp?: string;

    aux?: ILeplaceTreasureAux;

    /**
     * copy constructor for unextend
     * not used (extensibility reasons), use delete unwanted prop instead
     * @param data 
     */
    constructor(data: ILeplaceTreasure) {
        this.viewId = data.viewId;
        this.disabled = data.disabled;
        this.id = data.id;
        this.uid = data.uid;
        this.type = data.type;
        this.lat = data.lat;
        this.lng = data.lng;
        this.radius = data.radius;
        this.coins = data.coins;
        this.valid = data.valid;
        this.index = data.index;
        this.locationId = data.locationId;
        this.locked = data.locked;
        this.spec = data.spec;
        this.story = data.story;
        this.lockedForUser = data.lockedForUser;
        this.owned = data.owned;
        this.storyCategoryCode = data.storyCategoryCode;
        this.level = data.level;
        this.isLocal = data.isLocal;
        this.photoMarker = data.photoMarker;
        this.photoUrl = data.photoUrl;
        this.aux = data.aux;
        this.creatorId = data.creatorId;
    }
}


export class ILeplaceTreasure extends ILeplaceTreasureMin {
    // other params used by the app
    dynamic: ILeplaceTreasureDynamic;

    /**
     * the place on the map as loaded via google
     */
    place: ILeplaceReg;
    /**
     * the place from the db (e.g. loaded from external source)
     * used for e.g. event map, where the location is loaded directly from the db via treasure.location_id
     */
    location?: ITreasureLocationExtended;

    storyLocation?: IStoryLocation;

    /**
     * the activity that can be associated to a challenge
     */
    activity?: IActivity;

    placeMarker?: IPlaceMarkerContent;
    isWorldMap?: boolean;
    isDebug?: boolean;

    /**
     * show popup when collected
     * (dynamic set by the app depending on the context)
     */
    notifyCollectPopup?: boolean;
    treasureContent?: ITreasureCustomContent;
    beacon?: IBeacon;

    items: IGameItem[];

    /**
     * collected was initiated by AR (tap)
     */
    ontap?: boolean;

    source?: any;
}


export interface ITreasureCustomContent {
    id?: number;
    description: string;
    photoUpload?: string;
    photoUrl?: string;
    withPhoto?: boolean;
    beacon?: IBeacon
}


export interface ITreasureCustomContentUpdate {
    id: number;
    lat: number;
    lng: number;
    name?: string;
    description: string;
    photo?: string;
    locked?: number;
    locationId?: number;
    beacon?: IBeacon;
}

export interface ITreasureSpec {
    id: number;
    code: number;
    delegateCode: number;
    name: string;
    icon: string;
    photoUrl: string;
    radius: number;
    ratio: number;
    dispName: string;
    dispNameMap?: string;
    dispNameAux?: string;
    description: string;
    markerType: number;
    /**
     * AR object display mode
     * 1 = object with material, full specs
     * 2 = object without material, with color
     * 3 = object without material, default color
     */
    arMode: number;
    /**
     * AR object url
     */
    obj: string;
    /**
     * AR object material url
     */
    mtl: string;
    /**
     * AR object texture url
     */
    texture: string;
    color: string;
    animate: number;
    /**
     * define if the item is world map only (e.g. treasure, challenge, arena, story)
     */
    worldMap: number;
    /**
     * altitude, from user level, centimeters
     */
    altitude: number;
    customContent: number;
}

export enum ETreasureMode {
    worldMap = 1,
    collectible = 2
}

export enum ETreasureLockedMode {
    regular = 0,
    fixedScenePrivate = 1,
    fixedScenePublic = 2
}



export interface ITreasureLocationExtended {
    location: ILocationContainer;
    // registered business
    registered: boolean;
}


/**
 * converge all the representations into one single internal (in app) representation
 * covering only the features of interest for main functionality
 */
export interface IPlaceDataInternalStandard {
    // the id in the leplace db
    registeredId: number;
    googleId: string;
    name: string;
    registered: boolean;
    registeredBusiness: boolean;
}

export interface IPlaceDataInternalStandardContainer {
    availablePlace: boolean;
    availableLocationExt: boolean;
    /**
     * check available specs regardless of their source
     */
    availableSpecs: boolean;
    placeData: IPlaceDataInternalStandard;
}

export class TreasureUtils {

    static getChallengeName(item: ILeplaceTreasure, map: boolean) {
        if (!item.activity) {
            return item.spec.dispName;
        }
        let dispName: string = item.spec.dispName;

        if (map && item.spec.dispNameMap) {
            dispName = item.spec.dispNameMap;
        }

        return dispName;
    }

    static getPlace(item: ILeplaceTreasure): IPlaceDataInternalStandardContainer {
        let placeData: IPlaceDataInternalStandard = {
            registeredId: null,
            registeredBusiness: false,
            googleId: null,
            name: null,
            registered: false
        };

        let res: IPlaceDataInternalStandardContainer = {
            availablePlace: false,
            availableLocationExt: false,
            availableSpecs: false,
            placeData: placeData
        };

        if (!item) {
            return null;
        }
        if (!item.place) {
            if (item.location) {
                placeData.name = item.location.location.merged.name;
                placeData.googleId = item.location.location.merged.googleId;
                placeData.registered = item.location.location.merged.registered;
                placeData.registeredId = item.location.location.merged.id;
                // this is actually useful in the app
                placeData.registeredBusiness = item.location.location.merged.registered;
                res.availableLocationExt = true;
                res.availableSpecs = true;
            }
        } else {
            placeData.name = item.place.place.name;
            placeData.googleId = item.place.place.googleId;
            res.availablePlace = true;
            res.availableSpecs = true;
        }

        return res;
    }

    static getDefaultTreasureDynamic() {
        let d: ILeplaceTreasureDynamic = {
            inRange: false,
            showMarker: false,
            distanceFromUser: 0,
            index: 0,
            cacheIndex: null,
            updated: false
        };
        return d;
    }

    static checkRegisteredTreasurePlace(item: ILeplaceTreasure) {
        if (item.place) {
            return item.place.registeredBusiness;
        }
        if (item.location) {
            return item.location.registered;
        }
        return false;
    }

    static getLocalTreasureDataFromUserContent(location: LatLng, type: number, specificType: number, _nearbyLocationUid: string, content: ITreasureCustomContent, index: number) {
        let treasure: ILeplaceTreasure = {
            lat: location.lat,
            lng: location.lng,
            id: null,
            type: type,
            uid: "/LOCAL/" + type + "/" + index,
            radius: null,
            coins: null,
            dynamic: TreasureUtils.getDefaultTreasureDynamic(),
            valid: true,
            location: null,
            locationId: null,
            locked: ETreasureLockedMode.fixedScenePublic,
            index: index,
            place: null,
            creatorId: null,
            spec: {
                id: null,
                code: specificType,
                delegateCode: type,
                photoUrl: content.photoUrl,
                icon: content.photoUrl,
                radius: null,
                dispName: null,
                name: null,
                ratio: 0,
                description: content.description,
                markerType: EMarkerTypes.canvasFrame,
                arMode: EARModes.extGenerate,
                obj: null,
                mtl: null,
                texture: null,
                color: null,
                animate: null,
                worldMap: 1,
                altitude: null,
                customContent: 1
            },
            items: null,
            treasureContent: content,
            lockedForUser: false,
            owned: true,
            storyCategoryCode: null,
            photoMarker: true,
            photoUrl: content.photoUrl,
            isLocal: true,
            disabled: 0,
            specificType: null
        };

        treasure.dynamic.inRange = true;
        treasure.dynamic.distanceFromUser = 0;
        treasure.dynamic.showMarker = true;
        return treasure;
    }
}
