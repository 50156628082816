import { Injectable } from "@angular/core";
import { IPaginationContainer } from "../../../classes/def/views/pagination";
import { SettingsManagerService } from "../settings-manager";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { UiExtensionService } from "./ui-extension";
import { INavParams } from "../../../classes/def/nav-params/general";
import { IAmountNavParams } from "../../../classes/def/nav-params/inventory";
import { AmountInputViewComponent } from 'src/app/modals/generic/modals/amount-input/amount-input.component';


/**
 * handles a single instance of pagination
 * must be reinitialized when e.g. returning to home view from details view
 */
@Injectable({
    providedIn: 'root'
})
export class PaginationHandlerService {

    paginationInit: IPaginationContainer = {
        page: 0,
        pages: 1,
        pageDisp: 1,
        hideLeft: true,
        hideRight: false
    };

    pagination: IPaginationContainer = {
        page: 0,
        pages: 1,
        pageDisp: 1,
        hideLeft: true,
        hideRight: false
    };

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        private settingsProvider: SettingsManagerService,
        private uiext: UiExtensionService
    ) {
        console.log("pagination handler service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    init(pagination: IPaginationContainer) {
        if (pagination) {
            this.pagination = pagination;
        } else {
            this.pagination = Object.assign({}, this.paginationInit);
        }
        return this.pagination;
    }

    setBlinkEnabled(enable: boolean) {
        this.pagination.blinkEnabled = enable;
        this.pagination.blinkLeft = false;
        this.pagination.blinkRight = false;
        this.pagination.hasBlinked = false;
    }


    /**
     * set pagination buttons enable/hide
     */
    setPaginationControls() {
        let page: number = this.pagination.page;

        if (page <= 0) {
            this.pagination.hideLeft = true;
        } else {
            this.pagination.hideLeft = false;
            if (this.pagination.blinkEnabled) {
                this.pagination.blinkLeft = true;
                this.pagination.hasBlinked = true;
            }
        }

        if (page >= this.pagination.pages - 1) {
            this.pagination.hideRight = true;
        } else {
            this.pagination.hideRight = false;
            if (this.pagination.blinkEnabled) {
                this.pagination.blinkRight = true;
                this.pagination.hasBlinked = true;
            }
        }

        this.pagination.pageDisp = this.pagination.page;
        this.pagination.pageDisp++;
    }

    /**
     * load prev/next page
     * set pagination controls
     * @param delta increment
     */
    setPaginationPage(delta: number) {
        let page = this.pagination.page;
        page += delta;
        if (page > this.pagination.pages - 1) {
            page = this.pagination.pages - 1;
        }
        if (page < 0) {
            page = 0;
        }
        this.pagination.page = page;
        this.setPaginationControls();
    }

    isLastPageSelected() {
        return this.pagination.page >= (this.pagination.pages - 1);
    }

    /**
     * open pagination selector wheel
     * callback on select page action
     * @param callback 
     */
    openPaginateSelector(callback: (page: number) => any) {
        let params: IAmountNavParams = {
            title: "Select page",
            description: null,
            min: 1,
            max: this.pagination.pages,
            current: this.pagination.page + 1
        };

        let navParams: INavParams = {
            view: {
                fullScreen: false,
                transparent: false,
                large: false,
                addToStack: false,
                frame: false
            },
            params: params
        };

        this.uiext.showCustomModal(null, AmountInputViewComponent, navParams).then((selectedPage: number) => {
            if (selectedPage != null) {
                this.pagination.page = selectedPage - 1;
                this.setPaginationControls();
                callback(selectedPage - 1);
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }
}
