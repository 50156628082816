export enum EGetStoryClientsMode {
    players = 1,
    teams = 2,
    iap = 3,
    unlocks = 101,
    redeems = 102,
    partnerUnlocks = 103,
    manualBookings = 104,
    interceptedBookings = 105,
    trackedQR = 201
}

export interface IStoryUnlockCode {
    storyId?: number,
    unlockCode?: string,
    enabled?: number
}

export enum EStoryUnlock {
    none = 0,
    required = 1
}


export enum EStoryUnlockScope {
    leplace = 1,
    partner = 2
}


export enum EStoryUnlockType {
    branded = 1,
    partner = 2,
    short = 3,
    redeem = 4,
    redeemShort = 5,
    searchOnly = 6,
    searchOnlyShort = 7
}

export enum EStoryReleaseFlag {
    released = 0,
    devOnly = 1,
    unlisted = 2
}
