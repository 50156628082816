import { IPageResponse } from '../requests/general';
import { IGameItemCategoryWithItems } from './game-item';

export enum EInventorySync {
    items = "items",
    coins = "coins"
}

export interface IUserStatsCacheElement {
    /**
     * the actual local cache
     */
    cache: any;
    /**
     * flag that keeps track of inventory actions
     * if the inventory was not modified by an update request
     * then do not load the inventory again on load request
     * result: optimize data and performance
     */
    inSync: boolean;
    enable: boolean;
}


export interface IInventoryPageCacheElement {
    /**
     * the actual local cache
     */
    cache: IPageResponse[];
    /**
     * flag that keeps track of inventory actions
     * if the inventory was not modified by an update request
     * then do not load the inventory again on load request
     * result: optimize data and performance
     */
    inSync: boolean;
    enable: boolean;
}

export interface IInventoryCombinedPageCacheElement {
    /**
     * the actual local cache
     */
    cache: IPageResponse[];
    /**
     * flag that keeps track of inventory actions
     * if the inventory was not modified by an update request
     * then do not load the inventory again on load request
     * result: optimize data and performance
     */
    inSync: boolean;
    enable: boolean;
}


export interface IUserStatsCache {
    // [key: string]: IUserStatsCacheElement
    items: IInventoryPageCacheElement;
    itemCategories: IInventoryCombinedPageCacheElement;
    categories: IUserStatsCacheElement;
    publicCategories: IUserStatsCacheElement;
    publicCategoriesStory: IUserStatsCacheElement;
    coins: IUserStatsCacheElement;
}
