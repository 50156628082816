

import { Injectable } from "@angular/core";
import { StorageOpsService } from '../general/data/storage-ops';
import { ELocalAppDataKeys, IAppFlagsElement } from 'src/app/classes/def/app/storage-flags';
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { NavParams } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

export interface INavParamsEntry {
    // the id of the resource that is passed between views
    id: number;
    params: any;
}

export interface INavParamsInfo {
    hasParams: boolean;
    params: any;
}

@Injectable({
    providedIn: 'root'
})
export class NavParamsService {

    navParamsStore: INavParamsEntry[] = [];
    platformFlags: IPlatformFlags = {} as IPlatformFlags;

    paramsLoaded: BehaviorSubject<boolean>;
    paramsLoadedSub: Subscription;

    constructor(
        public storageOps: StorageOpsService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("nav params service created");

        this.paramsLoaded = new BehaviorSubject(null);

        this.checkReload();

    }

    checkReload() {
        console.log("check reload");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                console.log("nav params platform flags loaded");
                this.platformFlags = SettingsManagerService.settings.platformFlags;
                if (this.platformFlags.WEB) {
                    this.reload();
                } else {
                    console.log("snapshot reload only available for browser dev");
                    this.paramsLoaded.next(false);
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    checkParamsLoaded(): Promise<boolean> {
        console.log("check params loaded");
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.paramsLoadedSub = ResourceManager.clearSub(this.paramsLoadedSub);
            this.paramsLoadedSub = this.paramsLoaded.subscribe((loaded: boolean) => {
                if (loaded != null) {
                    resolve(loaded);
                }
            }, (err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    reload() {
        console.log("request reload nav params store");
        this.storageOps.getLocalDataKey(ELocalAppDataKeys.navParams).then((np) => {
            if (np) {
                console.log("reload nav params store: ", np);
                this.navParamsStore = np;
                this.paramsLoaded.next(true);
            } else {
                this.paramsLoaded.next(false);
            }
        }).catch((err: Error) => {
            console.error(err);
            this.paramsLoaded.next(false);
        });
    }

    snapshot() {
        if (this.platformFlags.WEB) {
            console.log("snapshot nav params");
            let flag: IAppFlagsElement = {
                flag: ELocalAppDataKeys.navParams,
                value: this.navParamsStore
            };
            this.storageOps.setStorageFlagNoAction(flag);
        } else {
            console.log("snapshot only available for browser dev");
        }
    }

    clearAll() {
        this.navParamsStore = [];
    }

    clear(id: number){
        let index: number = this.navParamsStore.findIndex(e => e.id === id);
    
        if (index !== -1) {
            console.log("nav clear set #" + id);
            this.navParamsStore.splice(index, 1); 
        } else {
            console.log("nav clear set/not init #" + id);
        }       
    }

    set(id: number, params: any) {
        let np: INavParamsEntry = this.navParamsStore.find(e => e.id === id);
        if (!np) {
            np = {
                id: id,
                params: params
            };
            this.navParamsStore.push(np);
        } else {
            np.params = params;
        }
        console.log("nav params set #" + id + ": ", params);
    }

    get(id: number) {
        let np: INavParamsEntry = this.navParamsStore.find(e => e.id === id);
        console.log("nav params get #" + id + ": ", np ? np.params : null);
        if (!np) {
            return null;
        } else {
            return np.params;
        }
    }

    /**
     * get nav params info
     * wrapper function
     * @param id 
     */
    getInfo(id: number): INavParamsInfo {
        let res: INavParamsInfo = {
            hasParams: false,
            params: null
        };

        let npar = this.get(id);
        let hasParams: boolean = ParamHandler.checkParams(npar);
        res.hasParams = hasParams;
        res.params = npar;
        return res;
    }

    /**
     * get nav params
     * for regular view or modal
     * either from params store (page) or ionic nav params (modal)
     * @param id 
     * @param navParams 
     */
    getCombined(id: number, navParams?: NavParams, modalParams?: INavParams): INavParamsInfo {
        let res: INavParamsInfo = this.getInfo(id);

        if (navParams) {
            // if (!res.hasParams) {
            let hasParamsModal: boolean = ParamHandler.checkParams(navParams);
            if (hasParamsModal) {
                res.params = navParams;
                res.hasParams = true;
            }
            // }
        }

        if (modalParams) {
            // if (!res.hasParams) {
            let hasParamsModal: boolean = ParamHandler.checkParams(modalParams);
            if (hasParamsModal) {
                res.params = modalParams;
                res.hasParams = true;
            }

            console.log("original nav params: ", modalParams);
            // }
        }

        return res;
    }
}


