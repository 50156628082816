



import { Injectable } from '@angular/core';
import { ApiDef } from '../../../classes/app/api';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { AuthRequestService } from '../auth-request/auth-request';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HTTPErrorHandlerService } from './error-handler';
import { AuthUtils } from '../auth-request/utils';


@Injectable({
    providedIn: 'root'
})
export class GenericDataService {
    serverUrl: string = ApiDef.mainServerURL;

    constructor(
        public authentication: AuthRequestService,
        public http: HttpClient,
        public errorHandler: HTTPErrorHandlerService
    ) {
        console.log("generic data service created");
    }


    /**
     * do a http request with JWT auth
     * return raw response
     * @param url 
     * @param data 
     */
    genericPostStandard(url: string, data: any, customApiUrl?: string): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpPostSecureUserId(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * do a http request with JWT auth
     * return raw response
     * @param url 
     * @param data 
     */
    genericGetStandard(url: string, data: any, customApiUrl?: string): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpGetSecureUserId(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic request 
     * return data
     * @param url 
     * @param data 
     * @param customApiUrl 
     */
    genericGetStandardWData(url: string, data: any, customApiUrl?: string): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericGetStandard(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    genericGetNoAuthWData(url: string, data: any, strict: boolean = true): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericGetNoAuth(url, data, strict).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    genericPostNoAuthWData(url: string, data: any, strict: boolean = true): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericPostNoAuth(url, data, strict).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic request
     * return data
     * @param url 
     * @param data 
     * @param customApiUrl 
     */
    genericPostStandardWData(url: string, data: any, customApiUrl?: string): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericPostStandard(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * do a http request with JWT auth
     * check for message and reject if status is false with the specified message
     * @param url 
     * @param data 
     * @param strict 
     */
    genericPost(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpPostSecureUserId(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * do a http request with JWT auth
     * check for message and reject if status is false with the specified message
     * @param url 
     * @param data 
     * @param strict 
     */
    genericGet(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpGetSecureUserId(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    genericGetExt(url: string, data: any): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let headers = AuthUtils.getHeaders();

            if (data) {
                headers.params = data;
            }

            this.http.get(url, headers).subscribe((response: any) => {
                resolve(response);
            }, (err: HttpErrorResponse) => {
                reject(new Error(this.errorHandler.handleHttpError(err)));
            });
        });
        return promise;
    }


    /**
    * generic post no auth
    * use with caution
    * e.g. only for GDPR popup before signup
    */
    genericPostNoAuth(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.genericPostNoAuth(url, data).then((response: IGenericResponse) => {         
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic get no auth
     * use with caution
     * e.g. only for GDPR popup before signup
     */
    genericGetNoAuth(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.genericGetNoAuth(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
