import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { IAmountNavParams } from 'src/app/classes/def/nav-params/inventory';
import { EAppIcons } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'modal-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss'],
})
export class AmountInputViewComponent implements OnInit, OnDestroy {
  title: string = "";
  selectAll: boolean = false;
  description: string;
  amount: number = 1;
  max: number = Number.MAX_SAFE_INTEGER;
  min: number = 1;
  vs: IViewSpecs = ViewSpecs.getDefault();

  appIcons = EAppIcons;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IAmountNavParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.title = params.title;
      this.description = params.description;
      if (params.max != null) {
        this.max = params.max;
      }
      if (params.min != null) {
        this.min = params.min;
      }
      if (params.current != null) {
        this.amount = params.current;
      }
    }
  }

  ngOnDestroy() {

  }


  decreaseAmount() {
    if (this.amount > this.min) {
      this.amount -= 1;
    }
  }

  increaseAmount() {
    let crt: any = this.amount;
    crt = parseInt(crt, 10);
    this.amount = crt + 1;
    if (this.amount > this.max) {
      this.amount = this.max;
    }
  }

  dismiss(amount: number) {
    setTimeout(() => {
      this.modalCtrl.dismiss(amount).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  cancel() {
    this.dismiss(null);
  }

  ok() {
    console.log("submit amount: ", this.amount);
    this.dismiss(this.amount);
  }
}
