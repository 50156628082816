import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IDBModelPayment } from 'src/app/classes/def/business/payment';


@Injectable({
    providedIn: 'root'
})
export class PaymentStripeDataService {
    clientToken: string;
    apiKey: string;
    productCache: any = {};
    isLive: boolean;
    productListCache: IDBModelPayment[] = null;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService
    ) {
    }

    /**
    * create payment checkout page
    * handle everything
    */
    createPaymentCheckoutWizard(customerEmail: string, storyId: number, connectUrl: string) {
        return this.generic.genericPostNoAuthWData("/business/payment/stripe/create-payment-checkout-session", {
           customerEmail: customerEmail,
           storyId: storyId,
           connectUrl: connectUrl
        });
    }
}
