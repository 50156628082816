
import { IActivity } from "../core/activity";
import { LatLng } from "@ionic-native/google-maps/ngx";
import { IActivityLocalStats } from "../core/activity-stats";
import { ICustomParamForActivity } from "../core/custom-param";
import { IQuaternions } from 'src/app/services/utils/geometry-utils';


export interface IARSpecialActivity {
    code: number;
    customParams?: ICustomParamForActivity[];
}

export enum EARSpecialActivity {
    screenshotAR = 1,
    find = 0
}

export enum EViewLinkCodes {
    exit = 0,
    exitAck = 1
}

export enum EARModesText {
    selfGenerate = "AR Explore",
    selfGenerateIndoor = "AR Explore 2",
    extGenerate = "AR Treasure Hunt"
}

export interface IARMode {
    object: string;
    mode: number;
    activityStats?: IActivityLocalStats;
    activity?: IActivity;
    modeText?: string;
    initialLocation?: LatLng;
}

export enum EHeadingCalibrationSources {
    GPS = 0,
    compass = 1
}

export enum EARModes {
    selfGenerate = 1,
    selfGenerateIndoor = 2,
    extGenerate = 3
}

export enum EARGenModes {
    testHeading = 0,
    compassRelative = 1,
    compassAbsolute = 2,
    gpsHeading = 3
}

export enum EARCalibrationModes {
    /**
     * delta compensation
     */
    precise = 0,
    /**
     * slow PID
     */
    smooth = 1
}

export interface IARHeadingData {
    timestamp: number;
    /**
     * calibrated heading
     */
    heading: number;
    // calibrated heading w/ gimbal lock fix
    headingGimbalFix: number;
    /**
     * raw heading from gyro
     */
    rawHeading: number;
    /**
     * compass provides the calibration reference
     */
    compassHeading: number;
    reversed: boolean;
    raw: IRawHeadingData;
    updateChart: boolean;
    fps: number;
}

export interface IRawHeadingData {
    alpha: number;
    beta: number;
    gamma: number;
    quaternions: IQuaternions;
}

export interface IARMotionData {
    altitude: {
        acceleration: number,
        prevAcceleration: number,
        speed: number,
        prevSpeed: number,
        relativeAltitude: number
    };
}

export interface IARStreamingData {
    timestamp: number;
    gyroHeading: number;
    compassHeading: number;
    calibratedHeading: number;
    lat: number;
    lng: number;
    dx: number;
    dy: number;
}

export interface IARUpdatePOV {
    lat: number;
    lng: number;
    x: number;
    y: number;
}

export interface IAREnableParams {
    enableScale: boolean;
    calibrationMode: number;
    calibrationInterval: number;
    filterConstant: number;
    hq: boolean;
    calibrationZone: number;
    heightAdjust: number;
    pauseDisplayLoopEnabled: boolean;
    FOV: number;
    webMode: boolean;
    ios: boolean;
}





