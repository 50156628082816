<div>
  <ion-row class="filters">
    <ion-col class="col-with-arrow" (click)="slidePrev()" no-padding size="1">
      <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
    </ion-col>
    <ion-col size="10" #sliderContainer>
      <!-- (swipe)="swipeEvent($event)" -->
      <!-- #sliderRef -->
      <ion-slides *ngIf="configInit" (ionSlideDidChange)="slideChanged()" [options]="sliderOptions">
        <ion-slide (click)="filterData(item)" *ngFor="let item of navBarItems">
          <ion-button class="action-button-tabs scrollitem"
            [ngClass]="isSelected(item) ? 'button-color-alternate' : 'button-color-primary'"
            (click)="selectCategoryOnClick(item)" icon-end>
            <span class="button-font-size-xxs uppercase bold">{{item.name}}</span>
            <icon-wrapper *ngIf="item.icon" slot="start" [icon]="item.icon" [custom]="item.customIcon"
              class="slot-icon-padding" customClass="leplace-icon-fixed-s leplace-icon-s">
            </icon-wrapper>
          </ion-button>
        </ion-slide>
      </ion-slides>
    </ion-col>
    <ion-col class="col-with-arrow" (click)="slideNext()" no-padding size="1">
      <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline">
      </icon-wrapper>
    </ion-col>
  </ion-row>
</div>