import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icon-span',
  templateUrl: './icon-span.component.html',
  styleUrls: ['./icon-span.component.scss']
})
export class IconSpanComponent implements OnInit {

  @Input()
  icon: string;

  @Input()
  text: string;

  @Input()
  plain: boolean;

  overlay: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.plain) {
      this.overlay = false;
    }
  }

}
