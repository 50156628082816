<ion-app [ngClass]="global.shiftView ? 'shift-view' : ''">
  <button id="speechButtonHidden" style="display:none"></button>
  <!-- <ion-split-pane [when]="splitPane.getSplitPane()" #menuContainer id="menuContainer"
    [ngClass]="!transparentBg ? 'ion-split-pane-bg' : ''"> -->
  <div [ngClass]="!transparentBg ? 'ion-split-pane-bg' : ''" class="height-100" #menuContainer id="menuContainer">

    <ion-router-outlet id="content" main></ion-router-outlet>

    <div *ngIf="showTabs">
      <!-- <leplace-hud-button icon="menu" (onClick)="toggleMenu()"></leplace-hud-button> -->
    </div>
    <ion-tabs [ngClass]="(isWeb && webFrameEnabled) ? 'web-content' : ''">
      <ion-tab-bar slot="bottom"
        [ngClass]="roundedTabMenu ? 'tab-bar-semi tab-bar-rounded tab-bar-overlap' : 'tab-bar tab-bar-extend'"
        *ngIf="showTabs">
        <div *ngFor="let tab of tabs">
          <ion-tab-button *ngIf="tab.enabled" class="tab-button" (click)="changeTab(tab)">
            <ion-icon [ios]="tab.icon" [md]="tab.icon"
              [ngClass]="isActiveTab(tab) ? 'icon-color-primary-high':'icon-color-primary-low'">
            </ion-icon>
            <ion-label class="button-font-size-xs"
              [ngClass]="isActiveTab(tab) ? 'text-color-primary-high':'text-color-primary-low'">
              {{tab.name}}</ion-label>
          </ion-tab-button>
        </div>

      </ion-tab-bar>
    </ion-tabs>

    <!-- </ion-split-pane> -->
  </div>
</ion-app>

<!-- <div class="fb-customerchat" page_id="397813247355479"></div> -->
