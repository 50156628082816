

import { Injectable } from '@angular/core';
import { IActionLayers } from '../../../classes/def/core/objects';
import { SettingsManagerService } from '../../general/settings-manager';
import { ETreasureType } from '../../../classes/def/items/treasures';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { ITreasureSpec, ETreasureMode } from '../../../classes/def/places/leplace';
import { LocationMonitorService } from '../../map/location-monitor';
import { Platform } from '@ionic/angular';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { Messages } from 'src/app/classes/def/app/messages';
import { VirtualPositionService } from './virtual-position';

@Injectable({
    providedIn: 'root'
})
export class GeoObjectsService {

    layers: IActionLayers = {
        crates: {
            name: "crates",
            code: ETreasureType.treasure,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        stories: {
            name: "stories",
            code: ETreasureType.story,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        challenges: {
            name: "challenges",
            code: ETreasureType.challenge,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        arenas: {
            name: "meeting places",
            code: ETreasureType.arena,
            enabled: true,
            type: ETreasureMode.worldMap
        }
    };

    layersInitialized: boolean = false;

    constructor(
        public settingsProvider: SettingsManagerService,
        public platform: Platform,
        public resources: ResourcesCoreDataService,
        public locationMonitor: LocationMonitorService,
        public virtualPosition: VirtualPositionService,
        public uiext: UiExtensionService
    ) {
        console.log("geo objects service created");
    }

    /**
     * get visible layers for AR objects
     * request treasure specs from the server
     * fallback to local specs
     * resolve only
     * @param reset use this when switching from storyline to world map (to restore the treasures on the map)
     */
    getShowLayersResolve(reset: boolean): Promise<IActionLayers> {
        let promise: Promise<IActionLayers> = new Promise((resolve) => {
            if (this.layersInitialized && !reset) {
                resolve(this.layers);
                return;
            }

            // if (this.layersInitialized) {
            // this one triggered the duplicate bug!
            // if (reset) {
            //     this.setShowLayersAll(this.layers, true);
            // }

            this.resources.getTreasureSpecsGeneric().then((data: ITreasureSpec[]) => {
                if (data) {
                    this.layers = {};
                    for (let spec of data) {
                        this.layers[spec.name] = {
                            name: spec.dispName,
                            code: spec.code,
                            enabled: true,
                            photoUrl: spec.photoUrl,
                            type: spec.worldMap === 1 ? ETreasureMode.worldMap : ETreasureMode.collectible
                        };
                    }
                    this.layersInitialized = true;
                    resolve(this.layers);
                } else {
                    this.uiext.showAlertNoAction(Messages.msg.layersNotInitialized.after.msg, Messages.msg.layersNotInitialized.after.sub);
                    resolve(this.layers);
                }
            }).catch((err: Error) => {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.layersNotInitialized.after.msg, Messages.msg.layersNotInitialized.after.sub);
                resolve(this.layers);
            });
        });
        return promise;
    }

}




