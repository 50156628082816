import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { ILocationPhotoResult, LocationUtilsDef } from './location-utils-def';
import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../general/settings-manager';
import { PlacesDataService } from '../data/places';


@Injectable({
    providedIn: 'root'
})
export class LocationUtilsGooglePlus {

    isCordova: boolean = false;

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public settingsProvider: SettingsManagerService,
        public placeData: PlacesDataService
    ) {
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                console.log("platform flags loaded");
                this.platform = SettingsManagerService.settings.platformFlags;
                this.isCordova = !this.platform.WEB;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    getGoogleRedirectPhotoUrl(placeId: string, url: string): Promise<ILocationPhotoResult> {
        let promise: Promise<ILocationPhotoResult> = new Promise((resolve, reject) => {
            let res: ILocationPhotoResult = LocationUtilsDef.getLocationPhotoResultContainer();
            if (!url) {
                resolve(res);
                return;
            }
            console.log("get google redirect from: ", url);

            this.placeData.getGooglePhotoUrl(placeId).then((redirectUrl: string) => {
                res.photoUrl = redirectUrl;
                res.photoFound = true;
                res.photoLoadedFinal = true;
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });

            // LocationUtilsGoogle.getGoogleRedirectPhotoUrlResolve(url).then((data: ILocationPhotoResult) => {
            //     resolve(data);
            // }).catch((err: Error) => {
            //     reject(err);
            // });

        });
        return promise;
    }
}

