export enum ETrackedEvents {
    story = "leplace_story",
    generic = "leplace_app_event",
    start = "leplace_app_start",
    error = "leplace_error",
    registered = "leplace_onboard",
    spendCoins = "leplace_spend_coins",
    levelUp = "leplace_level_up",
    leplaceEngaged2x = "leplace_engaged_2x",
    leplaceEngaged3x = "leplace_engaged_3x",
    leplaceEngaged5x = "leplace_engaged_5x",
    leplaceEngaged10x = "leplace_engaged_10x",
    firstMapOpen = "leplace_first_map_open",
    firstAROpen = "leplace_first_ar_open",
    startChallenge = "leplace_start_challenge",
    completeChallenge = "leplace_complete_challenge"
}