
import { ECategoryCodes } from "../def/core/category";
import { EFeatureColor } from "../def/app/theme";

export interface IGenericFlags {
    [key: string]: boolean;
}

export enum EKeyCodes {
    w = 119,
    s = 115,
    a = 97,
    d = 100,
    t = 116,
    p = 112
}

export class GeneralUtils {
    static getColorBase(category: number) {
        let color: string;
        console.log("get color for ", category);
        switch (category) {
            case ECategoryCodes.sport:
                color = EFeatureColor.sport;
                break;
            case ECategoryCodes.explore:
                color = EFeatureColor.explore;
                break;
            case ECategoryCodes.casual:
                color = EFeatureColor.casual;
                break;
            default:
                color = null;
                break;
        }
        return color;
    }

    static addColorAlpha(color: string, alpha: number) {
        if (color) {
            let rgb = color.substring(4, color.length - 1)
                .replace(/ /g, '')
                .split(',');
            let rgba = "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + alpha + ")";
            return rgba;
        }
        return null;

    }

    static getPropName(obj, propVal) {
        let keys = Object.keys(obj);
        let propName = "";
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (obj[key] === propVal) {
                propName = key;
                break;
            }
        }
        return ">" + propName;
    }



    static resetFlags(flags: IGenericFlags, value: boolean) {
        let keys: string[] = Object.keys(flags);
        for (let i = 0; i < keys.length; i++) {
            flags[keys[i]] = value;
        }
    }

    /**
     * get unique values from array
     * @param a 
     */
    static getUniqueValues(a: any[]) {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        let unique = a.filter(onlyUnique);
        return unique;
    }


    /**
     * 1st, 2nd, 3rd, nth
     * @param val 
     */
    static getOrdinalString(val: number) {
        let ord: string = "";
        if (val > 3 && val < 21) {
            ord = 'th';
        } else {
            switch (val % 10) {
                case 1:
                    ord = "st";
                    break;
                case 2:
                    ord = "nd";
                    break;
                case 3:
                    ord = "rd";
                    break;
                default:
                    ord = "th";
                    break;
            }
        }
        return "" + val + ord;
    }


    /**
     * check arrays equal
     * size, elements
     * only for primary types
     * does not modify the original array
     * @param a1 
     * @param a2 
     */
    static arrayEquals(a1: any[], a2: any[]) {
        let b1 = a1.slice(0).sort();
        let b2 = a2.slice(0).sort();
        return b1.length === b2.length && (b1.every((value, index) => value === b2[index]));
    }

}
