<ion-row>
  <ion-col size="2">
    <img [src]="e.photoUrl" class="img-xxs" />
  </ion-col>
  <ion-col size="10">
    <div [innerHTML]="descriptionHtml" class="info-text-m description-frame text-color-primary"></div>
  </ion-col>
</ion-row>
<ion-row *ngIf="!fixed">
  <ion-col size="12">
    <expander [expanded]="expanded" (expand)="toggleExpanded()"></expander>
  </ion-col>
</ion-row>