import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { IPhotoFrameNavParams, PhotoFrameViewComponent } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';

@Directive({
    selector: '[interactiveImageWrapper]'
})
export class InteractiveImageWrapperDirective implements AfterViewInit {
    constructor(
        private elementRef: ElementRef,
        private uiext: UiExtensionService
    ) { }

    viewPhoto(url: string) {
        let pfparams: IPhotoFrameNavParams = {
            photoUrl: url,
            title: "Preview",
            sharePhoto: false,
            isDataUrl: false,
            uploadPhoto: false,
            uploadPhotoFn: null,
            expanded: true
        };
        this.uiext.showCustomModal(null, PhotoFrameViewComponent, {
            view: {
                fullScreen: false,
                transparent: false,
                large: false,
                addToStack: true,
                frame: false
            },
            params: pfparams
        }).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    ngAfterViewInit() {
        const imgElement = this.elementRef.nativeElement.querySelector('img');
        const selectedElement = this.elementRef.nativeElement;
        if (imgElement) {
            const wrapperDiv = document.createElement('div');
            wrapperDiv.classList.add('wrap-img-container');

            // const parentElement = selectedElement.parentNode;

            // console.log(selectedElement);
            // console.log(imgElement);

            // Clone the image element
            const clonedImageElement = imgElement.cloneNode(true);

            // Append the cloned image element to the wrapper div
            wrapperDiv.appendChild(clonedImageElement);

            // Create the zoom button
            const zoomButton = document.createElement('button');
            zoomButton.classList.add('wrap-img-button');
            // zoomButton.textContent = 'Zoom';

            // Add the click event listener to the zoom button
            zoomButton.addEventListener('click', (event) => {
                //   clonedImageElement.style.transform = 'scale(2)';
                console.log("on zoom button click");
                event.stopPropagation(); // detect button click separately from clicking on the wrapper itself
                this.viewPhoto(imgElement.src);
            });

            // Append the zoom button to the wrapper div
            wrapperDiv.appendChild(zoomButton);

            // console.log(wrapperDiv);

            // Replace the original image element with the wrapper div
            selectedElement.replaceChild(wrapperDiv, imgElement);
        }
    }
}
