
export interface ILocationPhotoResult {
    photoUrl: string;
    /** check if the final url was retrieved */
    photoLoadedFinal: boolean;
    photoFound: boolean;
}

export interface IPlacePhotoExt extends google.maps.places.PlacePhoto {
    // height: number;
    // html_attributions: string[];
    // width: number;
    // getUrl(opts: PhotoOptions): string;
    raw_reference?: {
        fife_url: string
    };
}

export interface IGetPhotoOptions {
    noPlaceholder: boolean;
    /** should photo be redirected through google before showing */
    redirect: boolean;
    /** should the photo run through local cache before showing */
    cacheDisk: boolean;
    /** use the generic photo defined via type (handled locally/ext) */
    useGeneric: boolean;
}

export interface ISelectPhotoOptions {
    hidden: boolean;
}


export interface ILocationUtilsOptions {
    useTempUrlGoogle: boolean;
}

export enum EGoogleMapsRequestStatus {
    ok = 1,
    quotaExceeded = 2,
    error = 3,
    scanEnergyDepleted = 4
}


export class LocationUtilsDef {
    static getLocationPhotoResultContainer(): ILocationPhotoResult {
        let res: ILocationPhotoResult = {
            photoUrl: null,
            photoLoadedFinal: false,
            photoFound: false
        };
        return res;
    }
}
