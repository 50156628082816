import { ILeplaceTreasure, ITreasureSpec, ETreasureLockedMode } from "../../../classes/def/places/leplace";
import { ILeplaceObject, EObjectModes } from "../../../classes/def/core/objects";
import { ETreasureType } from "../../../classes/def/items/treasures";
import { EMarkerIcons } from "../../../classes/def/app/icons";
import { AppConstants } from "../../../classes/app/constants";

export class ItemScannerUtils {

    /**
     * convert crate to AR object specs
     */
    static getCrateObjectSpecs(crate: ILeplaceTreasure) {
        if(!(crate && crate.spec)){
            console.error("no crate spec at: ", crate);
            return null;
        }
        let spec: ILeplaceObject = {
            id: 0,
            code: crate.spec.code,
            genericCode: crate.spec.delegateCode ? crate.spec.delegateCode : crate.spec.code,
            uid: crate.uid,
            name: crate.spec.name,
            mode: crate.spec.arMode,
            obj: crate.spec.obj,
            mtl: crate.spec.mtl,
            photo: crate.spec.texture,
            marker: crate.spec.photoUrl,
            color: crate.spec.color,
            animate: crate.spec.animate,
            show: true,
            showMinimap: true,
            locked: crate.locked,
            altitude: crate.spec.altitude
        };
        return spec;
    }

    static getCrateObjectSpecsFromTreasureSpecs(spec: ITreasureSpec) {
        let objspec: ILeplaceObject = {
            id: 0,
            code: spec.code,
            genericCode: spec.delegateCode ? spec.delegateCode : spec.code,
            uid: "LOCAL/",
            name: spec.name,
            mode: spec.arMode,
            obj: spec.obj,
            mtl: spec.mtl,
            photo: spec.texture,
            marker: spec.photoUrl,
            color: spec.color,
            animate: spec.animate,
            show: true,
            showMinimap: true,
            locked: ETreasureLockedMode.regular,
            altitude: spec.altitude
        };
        return objspec;
    }

    /**
     * get default object specs for map/AR display
     * @param objectCode 
     */
    static getLocalObjectSpecs(objectCode: number) {
        let spec: ILeplaceObject = {
            id: 0,
            code: objectCode,
            genericCode: objectCode,
            uid: "LOCAL/",
            name: "object",
            mode: EObjectModes.objectWithColor,
            obj: null,
            mtl: null,
            photo: null,
            marker: null,
            color: "0xff7400",
            animate: 0,
            show: true,
            showMinimap: true,
            locked: ETreasureLockedMode.regular,
            altitude: AppConstants.gameConfig.defaultObjectAltitude
        };

        switch (objectCode) {
            case ETreasureType.exploreObject:
                spec.name = "coin";
                spec.mode = EObjectModes.objectWithColor;
                spec.obj = "./objects/coin.obj";
                spec.mtl = "./objects/coin.mtl";
                spec.marker = EMarkerIcons.coin;
                spec.color = "0xff7400";
                spec.animate = 1;
                break;
            case ETreasureType.treasure:
                spec.name = "crate";
                // spec.mode = EObjectModes.fullSpec;
                spec.mode = EObjectModes.objectWithColor;
                spec.obj = "./objects/crate.obj";
                spec.mtl = "./objects/crate.mtl";
                spec.photo = "./objects/crate.jpg";
                spec.marker = EMarkerIcons.treasure;
                spec.color = "0xff7400";
                spec.animate = 1;
                break;
            default:
                break;
        }
        return spec;
    }

    static checkActivityFormatParams(item: ILeplaceTreasure) {
        if (!item) {
            return false;
        }
        if (!item.activity) {
            return true;
        }
        if (!item.activity.params) {
            console.log("activity params undefined at: ", item);
            return false;
        }
        return true;
    }

}

