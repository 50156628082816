

export interface IPlatformFlags {
  WEB: boolean;
  MOBILE: boolean;
  MOBILE_LIVERELOAD: boolean;
  ANDROID: boolean;
  IOS: boolean;
  ELECTRON: boolean;
}

export enum EPlatformDef {
  WEB = 1,
  MOBILE = 2,
  MOBILE_LIVERELOAD = 3,
  ANDROID = 4,
  IOS = 5,
  ELECTRON = 6
}
