import { BehaviorSubject } from "rxjs";
import { LatLng } from "@ionic-native/google-maps/ngx";
import { Injectable } from "@angular/core";
import { IGeolocationResult } from "../../classes/def/map/map-data";
import { TimeoutService } from "../app/modules/timeout";
import { IAverageHeading } from "../../classes/def/core/move-monitor";
import { AnalyticsService } from "../general/apis/analytics";
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { StorageOpsService } from '../general/data/storage-ops';
import { IAppFlagsElement, ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { UserDataService } from '../data/user';

@Injectable({
    providedIn: 'root'
})
export class LocationMonitorService {

    observables = {
        unifiedLocation: null,
        locationTimeout: null
    };

    currentLocation: LatLng;
    manualOverride: boolean = false;

    averageHeading: IAverageHeading = {
        sum: 0,
        crt: 0,
        counter: 0,
        samples: 10,
        minSpeed: 1,
        ready: false
    };

    constructor(
        public timeoutMonitor: TimeoutService,
        public analytics: AnalyticsService,
        public storageOps: StorageOpsService,
        public userData: UserDataService
    ) {
        console.log("location monitor service created");
        this.observables = ResourceManager.initBsubObj(this.observables);
    }

    /**
     * get cached location
     */
    getCachedLocation(): LatLng {
        return this.currentLocation;
    }

    /**
     * subscribe to the internal location changes (GPS)
     */
    getUnifiedLocationObservable(): BehaviorSubject<IGeolocationResult> {
        return this.observables.unifiedLocation;
    }

    /**
     * real location timeout (GPS)
     */
    getLocationTimeoutObservable(): BehaviorSubject<number> {
        return this.observables.locationTimeout;
    }

    /**
     * real location timeout (GPS)
     */
    setLocationTimeoutObservable(event: number) {
        this.observables.locationTimeout.next(event);
    }

    resetUnifiedLocationObservable() {
        this.observables.unifiedLocation.next(null);
    }


    keyOverrideLocation(key: number) {
        let delta = 0.0001;
        let move = true;
        let loc = this.currentLocation;

        if (loc) {
            switch (key) {
                case 119:
                    // w
                    loc.lat += delta;
                    break;
                case 115:
                    // s
                    loc.lat -= delta;
                    break;
                case 97:
                    // a
                    loc.lng -= delta;
                    break;
                case 100:
                    // d
                    loc.lng += delta;
                    break;
                default:
                    move = false;
                    break;
            }
            if (move) {
                // disable follow
                this.manualLocationOverrideLock(loc);
            }
        }
    }

    /**
     * switches to manual location override
     * e.g. move with keys
     * the internal location is disabled!
     */
    manualLocationOverrideLock(coords: LatLng) {
        if (coords !== null) {
            // console.log("location override: ", coords);
            this.setLocationCache(coords);
            let fakeData: IGeolocationResult = {
                coords,
                timestamp: 0,
                elapsed: 0,
                heading: 0,
                accuracy: 100,
                speed: 1
            };
            this.observables.unifiedLocation.next(fakeData);
            this.manualOverride = true;
        }
    }

    manualLocationRelease() {
        console.log("manual location lock released");
        this.manualOverride = false;
    }

    /**
     * set cached location
     * reset location fix timer
     * @param coords 
     */
    setLocationCache(coords: LatLng) {
        if (coords !== null) {
            this.currentLocation = coords;
        }
    }

    /**
     * get average heading for e.g. placing the coins in the direction of travel
     */
    getAverageHeading() {
        return this.averageHeading;
    }


    /**
     * set location from location manager, external event
     * calculate distance and speed if requested
     * @param data 
     */
    setLocationFromUnified(data: IGeolocationResult) {
        if (this.manualOverride) {
            return;
        }

        if (data && data.coords && (data.coords.lat != null) && (data.coords.lng != null)) {
            // check sent coords to server to extract user country
            if (!GeneralCache.locationChecked) {
                GeneralCache.locationChecked = true;
                let flag: IAppFlagsElement = {
                    flag: ELocalAppDataKeys.locationChecked,
                    value: true
                };

                let setCountry = () => {
                    this.userData.setCountry(data.coords.lat, data.coords.lng).then(() => {
                        this.storageOps.setStorageFlagNoAction(flag);
                    }).catch((err: Error) => {
                        console.error(err);
                        // set country update failed, unlock retry on next location update
                        GeneralCache.locationChecked = false;
                    });
                };

                this.storageOps.getLocalDataKey(flag.flag).then((val: boolean) => {
                    if (!val) {
                        setCountry();
                    } else {
                        // flag was previously set (country was previously set)
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    setCountry();
                });
            }
        }

        // console.log("set location from unified: ", data);
        if (this.observables.unifiedLocation !== null) {
            this.observables.unifiedLocation.next(data);
        }
    }
}
