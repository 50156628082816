<ion-header>
  <!-- <leplace-toolbar [menu]="false" [leftButtonDisabled]="false" [leftButton]="appIconsStandard.exit" [leftIcon]="true"
    (leftClick)="back()" [title]="title" rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()"
    [loading]="!loaded">
  </leplace-toolbar> -->

  <leplace-toolbar [menu]="false" [leftButtonDisabled]="false" [leftButton]="appIconsStandard.home" [leftIcon]="true"
    (leftClick)="back()" [title]="title" [loading]="!loaded">
  </leplace-toolbar>

  <!-- <ion-searchbar class="custom-searchbar" color="primary" ngDefaultControl [(ngModel)]="queryText" (ionInput)="findStory()" placeholder="Search">
        </ion-searchbar> -->
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <!-- <ion-grid class="full-w no-padding">
      <ion-row>
        <ion-col size="4" class="no-padding">
          <ion-button class="action-button-fill button-color-primary" (click)="searchByName()">
            <icon-wrapper slot="icon-only" [icon]="appIconsStandard.search"></icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="4" class="no-padding">
          <ion-button class="action-button-fill button-color-primary" (click)="doRefresh(0)">
            <icon-wrapper slot="icon-only" [icon]="appIconsStandard.refresh"></icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="4" class="no-padding">
          <ion-button class="action-button-fill button-color-primary" (click)="openSortFilterModal()">
            <icon-wrapper slot="icon-only" [icon]="appIcons.sortFilter" [custom]="true"></icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid> -->


    <ion-grid class="full-w no-padding padding-bottom-s" *ngIf="showCitySelector">
      <ion-row class="ion-align-items-center" tappable (click)="openLocationSelector()">
        <ion-col size="10">
          <span class="info-text-s fab-display-top bold text-color-primary">{{selectedDisp}}</span>
        </ion-col>
        <ion-col size="2" class="text-right">
          <ion-button class="action-button-xs button-color-primary">
            <icon-wrapper slot="icon-only" [icon]="appIcons.city" [custom]="true"></icon-wrapper>
          </ion-button>
          <!-- <blink-button [icon]="appIcons.city" [customIcon]="true" [set]="false" theme="primary"
            customBaseClass="action-button-xs" [noBorder]="false">
          </blink-button> -->
        </ion-col>
      </ion-row>
    </ion-grid>


    <div class="margin-top-s scroll-type-2" #scrollContent>
      <div *ngIf="emptyResult" class="padding-s center-text">
        <span class="center-text info-text-m bold text-color-primary">No stories found</span>
      </div>
      <ion-list [@showState]="showState" class="list-padding">
        <ion-item class="list-item list-item-card-padding transparent-bg"
          *ngFor="let storyWithProgress of storiesWithProgress" #slidingItem [hidden]="storyWithProgress.hide">
          <story-info [data]="storyWithProgress" class="icon-color-primary full-w" tappable
            [showCategory]="globalCategory" [large]="storyWithProgress.large"
            (click)="goToStoryDetail(storyWithProgress.story)" [reload]="reloadItems"></story-info>
        </ion-item>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s theme-border-top padding-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="doRefresh($event)"></leplace-paginator>
    </div>
  </div>

</ion-content>