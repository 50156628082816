import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IStoryExtended, IStoryExtendedGroup, IStoriesPageResponse, IStory, EStoryListMode, IStoryUnlock } from 'src/app/classes/def/core/story';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IStoryListNavParams, IStoryListReturnParams } from 'src/app/classes/def/nav-params/story';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages, ETutorialEntries } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions, IPopoverInputs } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BusinessDataService } from 'src/app/services/data/business';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MiscDataService } from 'src/app/services/data/misc';
import { CanvasLoaderService } from 'src/app/services/utils/canvas-loader';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { StoryUtils } from 'src/app/classes/utils/story-utils';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { IPageResponse } from 'src/app/classes/def/requests/general';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { PlacesDataService } from 'src/app/services/data/places';
import { IBusinessDataExt } from 'src/app/classes/def/business/business';
import { StoriesLoaderService } from 'src/app/services/app/modules/stories/loader';
import { ESettingsType } from 'src/app/classes/def/app/settings';
import { EOrderBySort, EOrderStoriesBy, ICustomFilterOption, ICustomFiltersContainer } from 'src/app/classes/def/app/filters';
import { CustomFiltersViewComponent } from 'src/app/modals/generic/modals/custom-filters/custom-filters.component';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { MarketplaceContentPage } from '../content/content.page';
import { IMarketplaceBrowseNavParams, IMarketplaceDetailNavParams } from 'src/app/classes/def/nav-params/marketplace';
import { MarketplaceDataService } from 'src/app/services/data/marketplace';
import { EStoryUnlockType } from 'src/app/classes/def/business/story';
import { ECityCodes, ECountryCodes, IDBModelCity, IDBModelCountry, IGetCountriesWithDetailsAndNearby } from 'src/app/classes/def/world/world';
import { ILocationSelectorParams, LocationSelectorViewComponent } from 'src/app/modals/app/modals/location-selector/location-selector.component';
import { WorldDataService } from 'src/app/services/data/world';
import { ILatLng } from '@ionic-native/google-maps/ngx';

@Component({
  selector: 'app-marketplace-browse',
  templateUrl: './browse.page.html',
  styleUrls: ['./browse.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MarketplaceBrowsePage implements OnInit, OnDestroy {
  @ViewChild('scrollContent', { static: false }) scrollContent: ElementRef;

  public title: string = "Marketplace";
  public storiesWithProgress: IStoryExtended[];
  public groupedStoriesWithProgress: IStoryExtendedGroup[];

  info: string = "";
  useStoryColors: boolean = false;
  queryText = '';
  loaded: boolean = false;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  showState: string = "inactive";
  timeout = null;
  params: IMarketplaceBrowseNavParams;
  privateStories: boolean = false;
  emptyResult: boolean = false;
  reload: boolean = true;
  theme: string = "theme-light theme-light-bg";
  animate: boolean = true;
  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;
  localStories: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  selectedDisp: string;


  selectedDispDefault: string = "select a city >";
  selectedCity: IDBModelCity;
  selectedCityId: number;
  localData: IGetCountriesWithDetailsAndNearby = null;

  coords: ILatLng = null;
  showCitySelector: boolean = false;

  globalCategory: boolean = true;
  reloadItems: boolean = false;
  // selected location id
  providerId: number = null;
  categoryCode: number = null;

  scrollLoadingInProgress: boolean = false;

  initLoaded: boolean = false;
  navBarItems: INavBarItem[] = [];
  categoryTabsGroupCount: number = 2;
  selectedCategoryCode: number = EStoryListMode.published;
  orderBy: number = GeneralCache.orderBy.stories;
  sort: number = GeneralCache.sort.stories;
  filter: ICustomFilterOption[] = GeneralCache.filter.stories;

  hasDrafts: boolean = false;
  loadMoreEnabled: boolean = true;

  constructor(
    public marketplaceDataProvider: MarketplaceDataService,
    public settingsProvider: SettingsManagerService,
    public router: Router,
    public zone: NgZone,
    public plt: Platform,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public locationManager: LocationManagerService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public businessDataProvider: BusinessDataService,
    public resourcesProvider: ResourcesCoreDataService,
    public miscProvider: MiscDataService,
    public canvasLoader: CanvasLoaderService,
    public modalCtrl: ModalController,
    public popupFeatures: PopupFeaturesService,
    public tutorials: TutorialsService,
    public paginationHandler: PaginationHandlerService,
    public route: ActivatedRoute,
    public nps: NavParamsService,
    public placesData: PlacesDataService,
    public storiesLoader: StoriesLoaderService,
    public worldService: WorldDataService
  ) {

  }

  loadGlobalFilters() {
    this.orderBy = GeneralCache.orderBy.stories;
    this.sort = GeneralCache.sort.stories;
    this.filter = DeepCopy.deepcopy(GeneralCache.filter.stories);
  }

  saveGlobalFilters() {
    GeneralCache.orderBy.stories = this.orderBy;
    GeneralCache.sort.stories = this.sort;
    GeneralCache.filter.stories = DeepCopy.deepcopy(this.filter);
  }

  ngOnInit() {
    this.analytics.trackView("browse");
    this.resetFilters();
    this.paginationHandler.init(this.pagination);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        // this.theme = Constants.theme["aubergine"].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.title = "Stories";
    this.storiesWithProgress = [];
    console.log("activated route: ", this.route.snapshot.queryParams);

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.marketplaceBrowse, null, this.np);
      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      console.log(npar);

      if (hasParams) {
        let np: INavParams = npar;
        console.log(np);
        this.params = np.params;
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        this.localStories = false;
        this.reload = this.params?.reload;
        this.globalCategory = true;

        if (!this.reload || this.isModal) {
          this.animate = false;
          setTimeout(() => {
            this.showState = 'active';
          }, 250);
        }
      }

      PromiseUtils.wrapNoAction(this.initCitySelector(), true);

      this.initUI();
      this.refresh(0, this.reload, null, false).then(() => {

      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "browse");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });

      this.plt.ready().then(() => {
        this.backButton.replace(() => {
          this.back();
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  async initCitySelector() {
    try {
      let coords: ILatLng = await this.locationManager.getCurrentLocationWrapper(true, true, true);
      console.log("story list current location: ", coords);
      this.coords = coords;
      this.localData = await this.worldService.getCountriesWithDetailsAndNearby(this.coords ? this.coords.lat : null, this.coords ? this.coords.lng : null, true);
      this.formatGlobal();
      this.selectGlobal();
      this.showCitySelector = true;
    } catch (err) {
      console.error(err);
    }
  }

  formatGlobal() {
    let global: IDBModelCountry = this.localData.list.find(c => c.id === ECountryCodes.global);
    // this.localData.nearby = null;
    if (global != null) {
      let globalCity = global.cities.find(city => city.id === ECityCodes.global);
      global.cities = [globalCity];
    }
  }

  initUI() {
    this.navBarItems = [
      {
        name: "Draft",
        value: EStoryListMode.draft
        // icon: EAppIcons.edit,
        // customIcon: true
      },
      {
        name: "Published",
        value: EStoryListMode.published
        // icon: EAppIcons.live,
        // customIcon: true
      }
    ];

    setTimeout(() => {
      this.initLoaded = true;
    }, 1);
  }

  resetFilters() {
    this.loadGlobalFilters();
  }


  ngOnDestroy() {
    GeneralCache.storyListSelectedCategory = this.selectedCategoryCode;
    this.clearWatch();
    this.saveGlobalFilters();
    console.log("destroy");
  }

  back() {
    // this.showState = "inactive";
    // this.timeout = setTimeout(() => {
    //   this.navCtrl.setRoot(StoryCategoryListPage).then(() => {
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });
    // }, Constants.ANIMATION_TIME);

    if (this.isModal) {
      this.modalDismiss(null);
    } else {
      let navParams: INavParams = {
        view: null,
        params: null
      };
      this.nps.set(ENavParamsResources.home, navParams);
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss(params: IStoryListReturnParams) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  scrollTop() {
    this.scrollContent.nativeElement.scrollTop = 0;
  }

  doRefresh(dir: number) {
    this.loadMoreEnabled = true;
    this.refresh(dir, true, null, false).then(() => {
      this.scrollTop();
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "browse");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      search: {
        name: "Search",
        code: 2,
        icon: EAppIconsStandard.search,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 0:
          this.doRefresh(0);
          break;
        case 1:
          this.onHelpClick();
          break;
        case 2:
          this.searchByName();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onHelpClick() {
    let params: IDescriptionFrameParams = {
      title: "Storyline Tutorial",
      description: null,
      mode: EDescriptionViewStyle.plain,
      photoUrl: null,
      loaderCode: ETutorialEntries.storylineCreatorMode
    };
    this.tutorials.showTutorialNoAction(null, null, null, params, true);
  }

  /**
   * reload stories from the server
   * go to next/prev page
   * @param dir
   * @param reload
   * @param searchName
   */
  refresh(dir: number, reload: boolean, searchName: string, append: boolean) {
    // refresh stories
    this.paginationHandler.setPaginationPage(dir);
    return this.refreshLoadPage(this.pagination.page, reload, searchName, append);
  }


  async loadStoriesMode(page: number, _reload: boolean, searchName: string) {
    let categoryCode = this.categoryCode;
    let pageResponse: IPageResponse;
    let storyData: IStoriesPageResponse;

    let releaseFilter: number[] = this.filter.map(ut => {
      if (ut.checked) {
        return ut.code;
      } else {
        return null;
      }
    }).filter(ut => ut !== null);

    if (searchName == null) {
      storyData = await this.marketplaceDataProvider.listStories(null, categoryCode, this.providerId, this.selectedCityId, releaseFilter, this.orderBy, this.sort, page);
    } else {
      storyData = await this.marketplaceDataProvider.searchStoryByName(null, categoryCode, this.providerId, null, searchName);
    }

    pageResponse = {
      page: storyData.page,
      pages: storyData.pages,
      aux: null,
      data: storyData.stories
    };
    return pageResponse;
  }

  /**
   * refresh load page using specified page number
   * @param page 
   * @param reload 
   * @param searchName 
   */
  refreshLoadPage(page: number, reload: boolean, searchName: string, append: boolean) {
    let promise = new Promise(async (resolve, reject) => {
      this.loaded = false;
      this.emptyResult = false;
      let pageResponse: IPageResponse;
      try {
        pageResponse = await this.loadStoriesMode(page, reload, searchName);
        if (pageResponse.pages == null) {
          pageResponse.pages = 1;
        }
        if (pageResponse.page == null) {
          pageResponse.page = 0;
        }
        this.pagination.page = pageResponse.page;
        this.pagination.pages = pageResponse.pages;
        this.showStories(pageResponse.data, append);
        this.loaded = true;
        this.paginationHandler.setPaginationControls();
        if (this.animate) {
          setTimeout(() => {
            this.showState = 'active';
          }, 250);
        }
        resolve(true);
      } catch (err) {
        console.error(err);
        this.loaded = true;
        reject(err);
      }
    });
    return promise;
  }

  refreshLoadPageNoAction(page: number, reload: boolean, searchName: string) {
    this.refreshLoadPage(page, reload, searchName, false).then(() => {

    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "browse");
    });
  }

  /**
   * search by name in the leaderboard and retrieve the containing page
   */
  searchByName() {
    let searchName: string = "";
    let popoverInputs: IPopoverInputs = {
      name: {
        // the name is the actual tag here
        name: "name",
        value: searchName,
        placeholder: "story name",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Find story", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      // console.log(data);
      switch (code) {
        case 0:
          break;
        case 1:
          searchName = data.name;
          this.resetFilters();
          this.loadMoreEnabled = false;
          // console.log(searchName);
          PromiseUtils.wrapNoAction(this.refresh(0, true, searchName, false), true);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  public showStories(stories: IStory[], append: boolean): void {
    console.log("load stories: ", stories);
    this.hasDrafts = false;
    if (stories && stories.length > 0) {

      if (!append) {
        this.storiesWithProgress = [];
        this.groupedStoriesWithProgress = [];
      }

      let storiesFound: IStory[] = stories.filter(story => story.found);
      if (storiesFound && storiesFound.length > 0) {
        stories = storiesFound;
      }

      for (let story of stories) {
        let levels: boolean[] = [];
        if (!story.islocal) {
          for (let i = 1; i <= 5; i++) {
            if (i <= story.level) {
              levels.push(true);
            } else {
              levels.push(false);
            }
          }
        }
        let storyWithProgress: IStoryExtended = {
          story: story,
          storyProgress: 0,
          // photoUrl: this.getStoryPhotoUrl(story)
          photoUrl: null,
          color: null,
          // color: this.useStoryColors ? GetDefaults.addColorAlpha(GetDefaults.getColorBase(story.categoryCode), 0.2) : null,
          levels: levels,
          hide: false,
          show: true,
          large: false,
          modeIcon: null,
          modeText: null
        };

        if ((story.priceCoins || story.itemIapCode || story.locked) && story.regionType) {
          storyWithProgress.large = true;
        }

        storyWithProgress.modeIcon = StoryUtils.getModeIcon(story);

        if (story.isDraft) {
          this.hasDrafts = true;
        }
        this.storiesWithProgress.push(storyWithProgress);

      }

      console.log("stories with progress: ", this.storiesWithProgress);

      let storyGroup: IStoryExtendedGroup = {
        stories: this.storiesWithProgress,
        show: true,
        hide: false,
        categoryCode: this.categoryCode,
        categoryName: null
      };
      this.groupedStoriesWithProgress.push(storyGroup);
    } else {
      this.storiesWithProgress = [];
      this.groupedStoriesWithProgress = [];
      this.emptyResult = true;
    }

    this.info = "showing " + stories.length + "/" + stories.length + " stories";
  }

  clearWatch() {
    ResourceManager.clearTimeout(this.timeout);
    this.timeout = null;
  }

  /**
   * go to story detail on story select (tapped)
   * @param story 
   */
  goToStoryDetail(story: IStory): void {
    if (!story) {
      return;
    }
    if (this.isModal) {
      this.returnStoryData(story);
    } else {
      this.enterStory(story);
    }
  }

  async returnStoryData(story: IStory) {
    this.storiesLoader.loadStoryPlacesCore(story.id, story.isDraft, null, null).then((bplaces: IBusinessDataExt[]) => {
      let returnParams: IStoryListReturnParams = {
        locations: bplaces,
        storySelected: story.name,
        showQR: false,
        unlockCode: null
      };
      this.modalDismiss(returnParams);
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.storyNotAvailable.after.msg, Messages.msg.storyNotAvailable.after.sub);
    });
  }

  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.doRefresh(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.doRefresh(-1);
            }
            break;
        }
      }
    }
  }

  /**
   * finally enter selected story
   * @param story 
   */
  enterStory(story: IStory) {
    // get search token
    let token: string = null;
    if (story.storyUnlocks != null) {
      let su: IStoryUnlock = story.storyUnlocks.find(su => su.type === EStoryUnlockType.searchOnly);
      if (su != null) {
        token = su.unlockCode;
      } else {
        su = story.storyUnlocks.find(su => su.type === EStoryUnlockType.searchOnlyShort);
        if (su != null) {
          token = su.unlockCode;
        }
      }
    }
    let params: IMarketplaceDetailNavParams = {
      storyId: story.id,
      fromMarket: true,
      reload: true
    };

    if (token != null) {
      params.token = token;
    }

    this.timeout = setTimeout(() => {
      let promiseView: Promise<any>;
      if (this.animate) {
        this.showState = "inactive";
      }
      if (this.isModal) {
        promiseView = this.uiext.showCustomModal(null, MarketplaceContentPage, {
          view: {
            fullScreen: true,
            transparent: false,
            large: true,
            addToStack: true,
            frame: true
          },
          storyId: story.id,
          token: token,
          params: params
        });
      } else {
        let navParams: INavParams = {
          view: null,
          storyId: story.id,
          token: token,
          params: params
        };

        this.nps.set(ENavParamsResources.marketplaceContent, navParams);
        let extras: NavigationExtras = {
          replaceUrl: true,
          queryParams: {
            storyId: story.id,
            token: token
          }
        };
        promiseView = this.router.navigate([ERouteDef.storyline], extras);
      }
      promiseView.then((res: IStoryListNavParams) => {
        if (res && res.showQR) {
          let retParams: IStoryListReturnParams = {
            showQR: true,
            unlockCode: null,
            storySelected: story.name,
            locations: null
          };
          this.modalDismiss(retParams);
        }
      }).catch((err: Error) => {
        this.uiext.showAlertNoAction(Messages.msg.storyNotAvailable.after.msg, Messages.msg.storyNotAvailable.after.sub);
        console.error(err);
      });
    }, this.animate ? AppConstants.animationTime : 0);
  }

  /**
   * load more stories from the server
   * based on the categories selected
   * or all stories if no category filter is used
   */
  showMore() {
    // console.log("show more");
  }

  switchPage(dir: number, append: boolean) {
    return new Promise((resolve, reject) => {
      this.refresh(dir, true, null, append).then((res) => {
        resolve(res);
      }).catch((err: Error) => {
        reject(err);
      });
    });
  }

  /**
  * load next page, append content
  * @param delta 
  */
  addPage() {
    console.log("infinite scroll fired");
    if (this.scrollLoadingInProgress) {
      console.warn("scroll in progress");
      return;
    }
    if (this.isLastPageSelected()) {
      console.warn("last page loaded");
      return;
    }
    this.scrollLoadingInProgress = true;

    this.switchPage(1, true).then(() => {
      this.scrollLoadingInProgress = false;
    }).catch((err: Error) => {
      console.error(err);
      this.scrollLoadingInProgress = false;
    });
  }

  isLastPageSelected() {
    return this.paginationHandler.isLastPageSelected();
  }

  buildSortModal() {
    let params: ICustomFiltersContainer = {
      title: "Sort",
      filters: [
        {
          type: ESettingsType.radio,
          label: "Order by",
          selected: this.orderBy,
          options: [{
            name: "Default",
            code: EOrderStoriesBy.default,
            checked: false
          }, {
            name: "Name",
            code: EOrderStoriesBy.name,
            checked: false
          }, {
            name: "Date registered",
            code: EOrderStoriesBy.dateRegistered,
            checked: false
          },
          {
            name: "Date edited",
            code: EOrderStoriesBy.dateEdited,
            checked: false
          },
            // {
            //   name: "Country",
            //   code: EOrderPlacesBy.country,
            //   checked: false
            // }
          ]
        }, {
          type: ESettingsType.radio,
          label: "Sort order",
          selected: this.sort,
          options: [{
            name: "Ascending",
            code: EOrderBySort.ascending,
            checked: false
          }, {
            name: "Descending",
            code: EOrderBySort.descending,
            checked: false
          }]
        }
      ]
    };
    return params;
  }

  buildFilterModal() {
    let params: ICustomFiltersContainer = {
      title: "Filter",
      filters: [
        {
          type: ESettingsType.checkbox,
          label: "Status",
          options: this.filter
        }
      ]
    };
    return params;
  }

  openSortModal() {
    let params: ICustomFiltersContainer = this.buildSortModal();
    this.uiext.showCustomModal(null, CustomFiltersViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: ICustomFiltersContainer) => {
      if (res != null && res.changed) {
        // reset pagination handler
        this.paginationHandler.init(this.pagination);
        this.orderBy = res.filters[0].selected;
        this.sort = res.filters[1].selected;
        this.doRefresh(0);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "browse");
      // reset pagination handler
      this.paginationHandler.init(this.pagination);
    });
  }

  openFilterModal() {
    let params: ICustomFiltersContainer = this.buildFilterModal();
    this.uiext.showCustomModal(null, CustomFiltersViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: ICustomFiltersContainer) => {
      if (res != null && res.changed) {
        // reset pagination handler
        this.paginationHandler.init(this.pagination);
        this.filter = res.filters[0].options;
        this.doRefresh(0);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "browse");
      // reset pagination handler
      this.paginationHandler.init(this.pagination);
    });
  }

  openSortFilterModal() {
    let params: ICustomFiltersContainer = this.buildSortModal();
    let paramsFilter: ICustomFiltersContainer = this.buildFilterModal();
    params.filters = params.filters.concat(paramsFilter.filters);
    params.title = "Sort & Filter";
    this.uiext.showCustomModal(null, CustomFiltersViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: ICustomFiltersContainer) => {
      if (res != null && res.changed) {
        // reset pagination handler
        this.paginationHandler.init(this.pagination);
        this.orderBy = res.filters[0].selected;
        this.sort = res.filters[1].selected;
        this.filter = res.filters[2].options;
        this.doRefresh(0);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "browse");
      // reset pagination handler
      this.paginationHandler.init(this.pagination);
    });
  }

  openLocationSelector() {
    let params: ILocationSelectorParams = {
      title: "Local stories",
      specs: this.localData,
      includeGlobal: true
    };
    this.uiext.showCustomModal(null, LocationSelectorViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((city: IDBModelCity) => {
      if (city != null) {
        switch (city.id) {
          case ECityCodes.global:
            this.selectGlobal();
            this.pagination.page = 0;
            PromiseUtils.wrapNoAction(this.refresh(0, true, null, false), true);
            break;
          default:
            this.selectCity(city);
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  selectGlobal() {
    this.selectedCity = null;
    this.selectedCityId = null;
    this.selectedDisp = this.selectedDispDefault;
  }

  /**
   * select city and load stories
   * @param city 
   */
  selectCity(city: IDBModelCity) {
    console.log("select city: ", city);
    console.log("prev selected: ", this.selectedCity);
    let loadingRequired: boolean = this.dispSelectedCity(city);
    console.log("loading required: ", loadingRequired);
    if (loadingRequired) {
      this.pagination.page = 0;
      PromiseUtils.wrapNoAction(this.refresh(0, true, null, false), true);
    }
  }

  /**
   * show selected city info
   * check new selection
   * @param city 
   */
  dispSelectedCity(city: IDBModelCity): boolean {
    let newSelection: boolean = true;
    console.log("disp selected city: ", city);
    if (!city) {
      // select nearby
      if (!this.localData) {
        return newSelection;
      }
      newSelection = this.selectedCityId != null ? (this.localData.nearby.cities[0].id !== this.selectedCityId) : true;
      this.selectedCity = this.localData.nearby.cities[0];
      this.selectedCityId = this.selectedCity.id;
      this.selectedDisp = "" + this.selectedCity.name + ", " + this.localData.nearby.name + "";
      return newSelection;
    }
    newSelection = this.selectedCity != null ? (city.id !== this.selectedCityId) : true;
    this.selectedCity = city;
    this.selectedCityId = city.id;
    this.selectedDisp = "" + this.selectedCity.name + ", " + city.country?.name + "";
    return newSelection;
  }

}
