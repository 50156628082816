
import { IGoogleProfileData } from '../../../classes/def/social/profile';
import { Injectable } from '@angular/core';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { ApiDef } from '../../../classes/app/api';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../settings-manager';

declare var firebase;

import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { Subscription } from 'rxjs';
import { UiExtensionService } from '../ui/ui-extension';
import { Injector } from '@angular/core';
import { NgZone } from '@angular/core';


export enum ESocialLoginProviders {
    native = 1,
    angularSocialLogin = 2,
    angularFirebase = 3 // disabled
}

@Injectable({
    providedIn: 'root'
})
export class SocialAuthWrapperService {
    serverUrl: string;
    platformFlags: IPlatformFlags = {} as IPlatformFlags;
    platformObs;

    // config: any;
    // socialAuthService: SocialAuthService;

    /**
     * social login provider
     */
    provider: number = ESocialLoginProviders.native;

    bgModeWatchSub: Subscription;
    googleLoginTimeout;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public googlePlus: GooglePlus,
        public settingsProvider: SettingsManagerService,
        public uiext: UiExtensionService,
        public injector: Injector,
        public ngZone: NgZone
    ) {
        console.log("social auth service created");

        this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
                if (GeneralCache.isWeb) {
                    this.provider = ESocialLoginProviders.angularSocialLogin;
                } else {
                    if (GeneralCache.os === EOS.ios) {
                        // this.provider = ESocialLoginProviders.native;
                    } else {
                        // this.provider = ESocialLoginProviders.angularSocialLogin;
                    }
                }
                if (!this.platform.ELECTRON) {
                    this.init();
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    init() {
        console.log("social auth config init provider: ", this.provider);
        switch (this.provider) {
            case ESocialLoginProviders.angularSocialLogin:

                // const googleLoginOptions: any = {
                //     scope: 'profile email'
                // }; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

                // this.config = {
                //     providers: [
                //         {
                //             id: GoogleLoginProvider.PROVIDER_ID,
                //             provider: new GoogleLoginProvider(ApiDef.googleWebClientId, googleLoginOptions)
                //             // provider: new GoogleLoginProvider("806950131803-raplh77kl0t6cojm5e4gvb47ccp5vod4.apps.googleusercontent.com", googleLoginOptions)
                //         }
                //     ]
                // };

                // this.socialAuthService = new SocialAuthService(this.config, this.ngZone, this.injector);

                break;
            case ESocialLoginProviders.angularFirebase:

                // TODO: Replace the following with your app's Firebase project configuration
                // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field

                // Android only
                const firebaseConfig = {
                    apiKey: "AIzaSyBNE0A_941elrAAE4m4QHDikRYlYyURrtI",
                    authDomain: "leplace-store.firebaseapp.com",
                    projectId: "leplace-store",
                    storageBucket: "leplace-store.appspot.com",
                    messagingSenderId: "930150187502",
                    appId: "1:930150187502:web:781583dad7afb312424eff",
                    measurementId: "G-9JJ0C2MZXL"
                };

                // Initialize Firebase
                firebase.initializeApp(firebaseConfig);
                break;
            default:
                break;
        }
    }


    /**
     * handle (now masked) error/crash via timeout after app resume
     * init when app is switched to background (google login opened)
     * start timeout when app is resumed from background (google login closed, may not resolve)
     * @param onResumedTimeout callback
     */
    watchGoogleSignInStatus() {
        // this.backgroundModeWatch.initBgmWatchTriggerOnResumed(EBGMWatchId.socialAuth, "Connecting to Google account..", onResumedTimeout, 10000);
    }

    /**
     * resolve, clear timeout
     */
    clearWatchGoogleSignInStatusResolve(): Promise<boolean> {
        // return this.backgroundModeWatch.clearBgmWatchTrigger(EBGMWatchId.socialAuth, true);
        return Promise.resolve(true);
    }

    /**
     * get google account info 
     */
    signInWithGoogle(): Promise<IGoogleProfileData> {
        let promise: Promise<IGoogleProfileData> = new Promise((resolve, reject) => {
            if (this.platformFlags.IOS) {
                console.log("using reversed client id for iOS");
            }

            console.log("sign in with google client id: ", ApiDef.googleWebClientId);

            switch (this.provider) {
                case ESocialLoginProviders.native:
                    this.watchGoogleSignInStatus();
                    this.googlePlus.login({
                        // scopes: '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
                        scopes: 'profile email',
                        webClientId: ApiDef.googleWebClientId,

                        // webClientId: '232174753512-648og5lgspprcrcm915v0p0i5ge6rhur.apps.googleusercontent.com', 
                        // optional clientId of your Web application from Credentials settings of your project - 
                        // On Android, this MUST be included to get an idToken. On iOS, it is not required.
                        offline: true,
                        safeMode: true,
                        // crashTest: true
                        crashTest: false
                    }).then(async (user) => {
                        // console.log(user);
                        let googleData: IGoogleProfileData = {
                            name: user.displayName,
                            email: user.email,
                            // picture: user.imageUrl,
                            picture: null,
                            token: user.idToken,
                            id: user.userId
                        };
                        await this.clearWatchGoogleSignInStatusResolve();
                        resolve(googleData);
                    }).catch(async (err: Error) => {
                        await this.clearWatchGoogleSignInStatusResolve();
                        reject(err);
                    });
                    break;
                case ESocialLoginProviders.angularSocialLogin:
                    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
                    //     // console.log(user);
                    //     let googleData: IGoogleProfileData = {
                    //         name: user.name,
                    //         email: user.email,
                    //         // picture: user.imageUrl,
                    //         picture: null,
                    //         token: user.idToken,
                    //         id: user.id
                    //     };
                    //     resolve(googleData);
                    // }).catch((err: Error) => {
                    //     console.error(err);
                    //     reject(err);
                    // });
                    reject(new Error("Google Sign-In custom method not available in this version"));
                    break;
                case ESocialLoginProviders.angularFirebase:
                    var provider = new firebase.auth.GoogleAuthProvider();
                    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                    // To apply the default browser preference instead of explicitly setting it.
                    firebase.auth().useDeviceLanguage();
                    // firebase.auth().languageCode = 'pt';

                    // provider.setCustomParameters({
                    //     'login_hint': 'user@example.com'
                    // });

                    // firebase.auth.UserCredential
                    firebase.auth().signInWithPopup(provider).then((user: any) => {
                        console.log(user);
                        let credential: any = user.credential;

                        let googleData: IGoogleProfileData = {
                            name: user.user.displayName,
                            email: user.user.email,
                            // picture: user.imageUrl,
                            picture: null,
                            token: credential ? credential.accessToken : "firebase",
                            id: "firebase"
                        };
                        resolve(googleData);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                    break;
                default:
                    reject(new Error("unknown provider"));
                    break;
            }
        });
        return promise;
    }


    googleLogout() {
        let promise = new Promise((resolve, reject) => {
            if (this.platform.ELECTRON) {
                resolve(false);
                return;
            }
            this.googlePlus.logout().then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}
