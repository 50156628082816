import { NgModule } from '@angular/core';
import { MarketplaceBrowsePageModule } from './marketplace/browse/browse.module';
import { MarketplaceContentPageModule } from './marketplace/content/content.module';

@NgModule({
  declarations: [

  ],
  imports: [
    MarketplaceBrowsePageModule,
    MarketplaceContentPageModule,
  ],
  exports: [

  ]
})
export class PagesLinksModule { }
