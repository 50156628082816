import { BehaviorSubject } from 'rxjs';


export class WaitUtils {

    /**
     * check flag target value
     * wait until flag meets target value (subscribe to observable)
     * @param flag 
     * @param flagObs 
     */
    static waitFlagResolve(flag: any, flagObs: BehaviorSubject<any>, targetList: any[], timeout: number): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let t = null;

            // check if flag is not set
            if (targetList.indexOf(flag) !== -1) {
                resolve(true);
                return;
            }

            let onCheck = () => {
                if (sub != null) {
                    sub.unsubscribe();
                    sub = null;
                }
                if (t != null) {
                    clearTimeout(t);
                }
            };

            // else wait until flag meets target value
            let sub = flagObs.subscribe((set: any) => {
                if (targetList.indexOf(set) !== -1) {
                    onCheck();
                    resolve(true);
                }
            }, (err: Error) => {
                console.error(err);
                resolve(false);
            });

            if (timeout != null) {
                t = setTimeout(() => {
                    t = null;
                    onCheck();
                    resolve(false);
                }, timeout);
            }
        });
        return promise;
    }


    /**
     * check flag is not set
     * wait until flag is not set (subscribe to observable)
     * @param flag 
     * @param flagObs 
     */
    static waitFlagResetResolve(flag: boolean, flagObs: BehaviorSubject<boolean>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {

            // check if flag is not set
            if (!flag) {
                resolve(true);
                return;
            }

            // else wait until flag is reset
            let sub = flagObs.subscribe((set: boolean) => {
                if (set === false) {
                    if (sub != null) {
                        sub.unsubscribe();
                    }
                    resolve(true);
                }
            }, (err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * wait until flag matches condition
     * @param flagObs 
     */
    static waitObsTimeout(flagObs: BehaviorSubject<any>, flag: any, timeout: number): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let t = null;

            let onCheck = () => {
                if (sub != null) {
                    sub.unsubscribe();
                    sub = null;
                }
                if (t != null) {
                    clearTimeout(t);
                }
            };

            let sub = flagObs.subscribe((set: any) => {
                if (set === flag) {
                    onCheck();
                    resolve(true);
                }
            }, (err: Error) => {
                console.error(err);
                onCheck();
                resolve(false);
            });

            if (timeout != null) {
                t = setTimeout(() => {
                    t = null;
                    onCheck();
                    resolve(false);
                }, timeout);
            }
        });
        return promise;
    }



    /**
     * wait until flag matches condition
     * resolve value returned
     * @param flagObs 
     */
    static waitObsAnyTimeout(flagObs: BehaviorSubject<any>, flag: any[], timeout: number): Promise<any> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let t = null;

            let onCheck = () => {
                if (sub != null) {
                    sub.unsubscribe();
                    sub = null;
                }
                if (t != null) {
                    clearTimeout(t);
                }
            };

            let sub = flagObs.subscribe((set: any) => {
                if (flag.indexOf(set) !== -1) {
                    onCheck();
                    resolve(set);
                }
            }, (err: Error) => {
                console.error(err);
                onCheck();
                resolve(null);
            });

            if (timeout != null) {
                t = setTimeout(() => {
                    t = null;
                    onCheck();
                    resolve(null);
                }, timeout);
            }
        });
        return promise;
    }
}