import { IGameItem, GameItemDef, EItemCategoryCodes, IGameItemOptions, EItemIapCodes } from 'src/app/classes/def/items/game-item';
import { Component, OnInit, OnDestroy, Input, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { BehaviorSubject, timer } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { TimeUtils } from 'src/app/classes/general/time';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { GameItemOptionsViewComponent } from 'src/app/modals/app/modals/game-item-options/game-item-options.component';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';


export interface IDynamicParamGameItem {
  data: IGameItem;
}

@Component({
  selector: 'game-item',
  templateUrl: './game-item.component.html',
  styleUrls: ['./game-item.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        transition: "background-color 250ms linear"
      })),
      state('active', style({
        transition: "background-color 250ms linear"
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class GameItemComponent implements OnInit, OnChanges, OnDestroy {
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  highlight: boolean = false;
  priceCoins: string = "";
  priceIap: string = "";
  photoLoaded: boolean = false;
  inventoryAmount: string = "";
  showState: string = "inactive";
  // valid: boolean = true; 
  css: string;
  // cssUnlocked: string = "flex-card item-card bg-container bg-container-fill bg-no-filter-animate";
  // cssLocked: string = "flex-card item-card bg-container bg-container-fill bg-filter-2-animate";
  cssUnlocked: string = "flex-card item-card bg-container bg-container-fill";
  cssLocked: string = "flex-card item-card bg-container bg-container-fill";
  cssItem: string = "info-text-xs bold text-color-overlay-s";
  cssItemDefault: string = "info-text-xs bold text-color-overlay-s";
  cssItemActive: string = "info-text-xs bold text-color-overlay-s";
  // cssItemActive: string = "info-text-xs bold text-color-overlay-s text-color-overlay-accent";
  active: boolean = false;
  isAchievement: boolean = false;
  isLocked: boolean = false;
  progressPercent: number = 0;
  newItem: boolean = false;
  showAmount: boolean = false;
  showProgress: boolean = false;
  state: boolean = false;
  timerSub = null;
  itemName: string = "";
  validIap: boolean = false;
  isIap: boolean = false;
  isLoading: boolean = false;

  @Input()
  data: IGameItem;

  @Input()
  mode: number;

  @Input()
  interact: boolean;

  @Input()
  blinkTimerSub: BehaviorSubject<boolean>;

  @Input()
  small: boolean;

  @Input()
  enableLockedDescription: boolean;

  @Input()
  reload: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  timeout = {
    blink: null,
    itemExpire: null
  };

  subscription = {
    blinkEvent: null
  };

  enableDescription: boolean = true;

  previewMode: boolean = false;

  constructor(
    private uiext: UiExtensionService
  ) {
    this.css = this.cssUnlocked;
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.checkState();
            break;
          case 'reload':
            this.checkState();
            break;
        }
      }
    }
  }

  showDescription() {
    let item: IGameItem = this.data;
    console.log("show description: ", item, this.interact, this.enableDescription);
    if (item && (this.interact || this.interact == null) && this.enableDescription) {
      if (item.isDraft) {

      } else {
        let isIap: boolean = GameUtils.checkIAP(item);
        let isAvailable: boolean = !isIap || (isIap && item.itemIap.valid);
        if (isAvailable) {
          let gioParams: IGameItemOptions = {
            item: item,
            basicView: false
          };
          let navParams: INavParams = {
            view: {
              fullScreen: false,
              transparent: false,
              large: true,
              addToStack: true,
              frame: false
            },
            params: gioParams
          };
          this.uiext.showCustomModal(null, GameItemOptionsViewComponent, navParams).then((data) => {
            if (data != null) {
              this.action.emit(data);
            }
          }).catch((err: Error) => {
            console.error(err);
          });
        } else {
          if (isIap) {
            this.uiext.showAlertNoAction(Messages.msg.itemLockedIap.after.msg, Messages.msg.itemLockedIap.after.sub);
          } else {
            this.uiext.showAlertNoAction(Messages.msg.itemLocked.after.msg, Messages.msg.itemLocked.after.sub);
          }
        }
      }
    }
  }


  /**
   * show "real time" timer for expire items
   */
  monitorItemExpireTimeout() {
    this.timeout.itemExpire = setTimeout(() => {
      if (this.data.timeLeft && this.data.timeLeft > 0) {
        this.data.timeLeft -= 1000;
        if (this.data.timeLeft < 0) {
          this.data.timeLeft = 0;
        }
        // this.data.timeLeftString = MyTime.formatTime(this.data.timeLeft / 1000);
        this.data.timeLeftString = TimeUtils.formatTimeFromSeconds(this.data.timeLeft / 1000, false);
        this.data.timeLeftPercent = this.data.timeLeft * 100 / this.data.timeExpireDef;
        this.monitorItemExpireTimeout();
      }
    }, 1000);
  }

  clearTimer() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.timerSub = ResourceManager.clearSub(this.timerSub);
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  ngOnInit() {
    this.checkState();
  }

  checkState() {
    console.log("game item: ", this.data);
    if (this.data) {
      let item: IGameItem = this.data;
      let itemName: string = item.name;
      this.itemName = StringUtils.trimName(itemName, EMessageTrim.itemNameCard);

      if (!item.icon) {
        let keys = Object.keys(GameItemDef.itemDisp);
        for (let i = 0; i < keys.length; i++) {
          if (this.data.code === GameItemDef.itemDisp[keys[i]].code) {
            item.icon = GameItemDef.itemDisp[keys[i]].icon;
            break;
          }
        }
        if (item.itemCategoryCode === EItemCategoryCodes.maps) {
          item.icon = "map";
        }
      }

      if (item.selectorMode != null) {
        this.previewMode = true;
        return;
      }

      if (item.itemIap) {
        if (item.itemIap.code === EItemIapCodes.adminRequest) {
          this.priceIap = "special request";
        } else {
          if (GeneralCache.os === EOS.ios) {
            this.priceIap = item.itemIap.priceIos;
          } else {
            this.priceIap = item.itemIap.price;
          }

          if (item.itemIap.hiddenPrice) {
            this.priceIap = "IAP";
          }

          this.validIap = item.itemIap.valid;
          this.isIap = true;
        }

        if (!item.itemIap.iapLoaded) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
      }

      // if (this.data.validCount > 0 || this.data.activeCount > 0) {
      //     this.css = this.cssUnlocked;
      // } else {
      //     this.css = this.cssLocked;
      // }

      if (this.data.achievement === 1) {
        this.isAchievement = true;

        this.priceCoins = "";
        if (this.data.validCount > 0 || this.data.activeCount > 0) {
          this.css = this.cssUnlocked;
          this.isLocked = false;
          // this.active = true;
          // this.inventoryAmount = "UNLOCKED";
          this.inventoryAmount = null;
        } else {
          this.css = this.cssLocked;
          this.isLocked = true;
          // this.active = false;
          // this.inventoryAmount = "LOCKED";
          // this.showAmount = true;
          this.showAmount = false;
        }


        if (this.data.progress) {
          this.progressPercent = this.data.progress.percent;
        }

        if (this.isAchievement && this.isLocked) {
          // only show progress bar for items that require multiple stats to unlock
          if (this.data.progress && this.data.progress.required > 1) {
            this.showProgress = true;
          }
        }
      } else {
        this.inventoryAmount = "" + this.data.validCount;
        if (this.data.priceCoins) {
          this.priceCoins = this.data.priceCoins + " LP";
        } else {
          this.priceCoins = "FREE";
        }

        if (!this.data.timeLeftPercent) {
          this.data.timeLeftPercent = 0;
        }

        if (this.data.activeCount > 0) {
          this.active = true;
          this.cssItem = this.cssItemActive;
        } else {
          this.active = false;
          this.cssItem = this.cssItemDefault;
        }

        if (this.data.consumable === 1 && this.data.timeLeft) {
          this.data.timeLeftString = TimeUtils.formatTimeFromSeconds(this.data.timeLeft / 1000, false);
          this.monitorItemExpireTimeout();
        }

        if (!this.data.delegateCode && this.inventoryAmount) {
          this.showAmount = true;
        }
      }

      if (item.new === 1 || item.newUnlocked === 1 || item.newUnlockedAux === 1) {
        this.newItem = true;
        this.isLocked = false;
        this.setViewMode(true);
      } else {
        this.newItem = false;
      }

      // sync blink
      if (this.blinkTimerSub) {
        this.subscription.blinkEvent = this.blinkTimerSub.subscribe((event: boolean) => {
          if (event !== null) {
            if (this.newItem) {
              if (!item.noBlink) {
                this.state = !this.state;
                this.setViewMode(this.state);
              }
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }

      if (this.isLocked) {
        this.enableDescription = this.data.peek === 1;

        if (this.enableLockedDescription) {
          this.enableDescription = true;
        }
      }

      if (this.checkLocked()) {
        this.showAmount = false;
      }

      // show if pending iap items too

      // if (this.isIap) {
      //   this.showAmount = false;
      // }
    }
  }

  checkLocked() {
    return this.isLocked || (this.isIap && !this.validIap);
  }

  checkValid() {
    return this.active || (this.isIap && this.validIap);
  }


  setViewMode(open: boolean) {
    // this.showState = open ? "active" : "inactive";
    if (open) {
      this.css = this.cssUnlocked;
    } else {
      this.css = this.cssLocked;
    }
  }


  /**
   * start blinking
   * @param timeout 
   */
  setBlink(timeout: boolean) {
    this.enableBlink(timeout ? 5 : 0);
    if (timeout) {
      this.timeout.blink = setTimeout(() => {
        this.disableBlink();
      }, 5500);
    }
  }

  /**
   * start blinking core
   * @param count 
   */
  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;

        this.setViewMode(this.state);

        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * stop blinking
   */
  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.timeout.blink = ResourceManager.clearTimeout(this.timeout.blink);
    this.state = false;
    this.setViewMode(this.state);
  }

}
