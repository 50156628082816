
/// <reference types="@types/google.maps" />
import {
    MarkerOptions, LatLng, GoogleMap, GoogleMapsEvent, Marker, PolylineOptions,
    CircleOptions, Circle, MarkerCluster, Polyline, ILatLng
} from "@ionic-native/google-maps/ngx";
import { IMarkerTheme, ThemeColors, EFeatureColor } from '../../classes/def/app/theme';
import { IPlaceMarkerContent, IMarkerOptionsExtended, IShowMarkerOptions, IMarkerInternal } from '../../classes/def/map/map-data';
import { MarkerUtils, ICanvasMarkerContainer, IMarkerUpdateResult, EMarkerUpdateCode } from './marker-utils';
import { EMapShapes, EMarkerTypes, ISetThisMarkerOptions } from '../../classes/def/map/markers';
import { EMarkerIcons } from '../../classes/def/app/icons';
import { GeometryUtils } from '../utils/geometry-utils';
import { ResourceManager } from '../../classes/general/resource-manager';
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { SleepUtils } from '../utils/sleep-utils';
import { SyncService } from '../app/utils/sync';
import { BenchUtils, IBenchObject, IBenchReportContainer } from '../utils/bench-utils';
import { SettingsManagerService } from '../general/settings-manager';
import { PromiseUtils } from "../utils/promise-utils";
// import { setDPI, drawMarkerText } from '../../classes/map/canvas-marker';


export interface IMarkerContainerMobile {
    marker: any;
    mox: IMarkerOptionsExtended;
}

export interface ISingleMarkerMobile {
    internal: IMarkerInternal;
    container: IMarkerContainerMobile;
}

export interface IArrayMarkerMobile {
    internal: IMarkerInternal;
    container: IMarkerContainerMobile[];
}

export interface ISingleMarkerCollectionMobile {
    [name: string]: ISingleMarkerMobile;
}
export interface IArrayMarkerCollectionMobile {
    [name: string]: IArrayMarkerMobile;
}

const MARKER_UPDATE_TIMEOUT_MOBILE = 50;
const MAP_INIT_TIMEOUT_MOBILE = 3000;
const MARKER_INIT_COUNTER_MOBILE = 2;

export class MarkersMobile {
    singleMarkers: ISingleMarkerCollectionMobile = {};
    multiMarkers: IArrayMarkerCollectionMobile = {};

    // singleMarkersTimestamps = {};
    // multiMarkersTimestamps = {};
    globalInitTimestamp = null;

    markerTypesNames: string[];

    markerIcons;

    map: GoogleMap;

    theme: IMarkerTheme;
    themeLoaded: boolean = false;

    markerClusters: MarkerCluster[] = [];

    testAccuracy: boolean = false;

    loopDelay: number = 1;

    /**
     * master lock
     */
    enable: boolean = false;

    constructor(
        public syncService: SyncService
    ) {
        this.theme = {
            name: "default",
            lineColor: ThemeColors.theme.standard.lineColor,
            markerFrameColor: ThemeColors.theme.standard.lineColor
        };
    }

    /**
     * master lock
     * @param enable 
     */
    setEnabled(enable: boolean) {
        this.enable = enable;
    }

    setMap(map: GoogleMap) {
        console.log("mobile setMap");
        this.map = map;
    }

    setTheme(theme: IMarkerTheme) {
        this.theme = theme;
        if (!this.theme.markerFrameColor) {
            this.theme.markerFrameColor = ThemeColors.theme.standard.lineColor;
        }
        this.themeLoaded = true;
    }

    getMap() {
        return this.map;
    }

    setOptions(opts) {
        this.markerIcons = opts.markerIcons;
    }

    /**
     * check if the marker container exists
     * prevent errors
     * @param layer 
     * @param multi 
     */
    private checkMarkerLayerContainer(layer: string, multi: boolean) {
        if (multi) {
            if (!(this.multiMarkers[layer] && this.multiMarkers[layer].container)) {
                return false;
            }
        } else {
            if (!(this.singleMarkers[layer] && this.singleMarkers[layer].container)) {
                return false;
            }
        }
        return true;
    }

    private getArrayMarkerIndexByUid(targetLayer: string, uid: string) {
        let layers: string[] = [targetLayer];
        if (targetLayer == null) {
            layers = Object.keys(this.multiMarkers);
        }
        for (let layer of layers) {
            if (this.checkMarkerLayerContainer(layer, true)) {
                for (let i = 0; i < this.multiMarkers[layer].container.length; i++) {
                    if (this.multiMarkers[layer].container[i].mox.markerContent.uid === uid) {
                        return i;
                    }
                }
            }
        }
        return -1;
    }

    /**
     * updates a marker from array (multi marker), set gps position
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param data 
     */
    updateArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let promise = new Promise((resolve, reject) => {
            let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);

            let mk: Marker = null;
            let refreshRequired: boolean = false;

            let container = this.multiMarkers[layer].container[index];

            if (index !== -1) {
                mk = container.marker;
            }

            if (mk != null) {
                // update place marker content too
                if (container.mox) {
                    if (container.mox.markerContent) {
                        if (data.label !== container.mox.markerContent.label) {
                            refreshRequired = true;
                        }
                        if (data.lockedForUser !== container.mox.markerContent.lockedForUser) {
                            refreshRequired = true;
                        }
                        if (data.locked !== container.mox.markerContent.locked) {
                            refreshRequired = true;
                        }
                        // use this is marker is passed by reference (cannot detect changes by attributes)
                        if (data.requiresRefresh) {
                            data.requiresRefresh = false;
                            refreshRequired = true;
                        }
                    }
                    container.mox.markerContent = data;
                }

                if (refreshRequired) {
                    this.removeArrayMarkerCore(layer, data);
                    this.insertArrayMarkerCore(layer, data).then(() => {
                        resolve(mk);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    let formatLabel = MarkerUtils.formatAddLabels(null, data.label, data.addLabel, data.addLabel2);
                    let label: string = formatLabel.label;
                    if (data.shape === EMapShapes.marker) {
                        mk.setPosition(data.location);
                        mk.setTitle(label);
                    }
                    resolve(mk);
                }
            } else {
                reject(new Error("could not update marker position"));
                return;
            }
        });
        return promise;
    }

    /**
     * removes the marker from the map and from the array
     * @param layer 
     * @param data 
     */
    private removeArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);
        this.clearArrayMarkerByIndex(layer, index);
    }


    /**
     * get extended marker options 
     * mobile specific
     * @param data 
     */
    private getMox(data: IPlaceMarkerContent) {
        let mox: IMarkerOptionsExtended = {
            markerOptions: {
                position: data.location,
                icon: data.icon,
                zIndex: data.zindex,
                // icon: "assets/images/icons8-Marker-64.png",
                title: data.label,
                label: data.label,
                // snippet: data.title
                // markerClick: ()=>{
                //     console.log("marker click");
                // }

                draggable: data.drag,

                // draggable: true,
                // animation: data.animate ? GoogleMapsAnimation.DROP : null
                animation: null
            },
            markerContent: data,
            customIcon: null,
            callback: data.callback,
            dragCallback: data.dragCallback
        };
        return mox;
    }


    /**
     * init multi marker
     * @param markers 
     * @param layer 
     */
    private initMultiMarker(markers: IArrayMarkerCollectionMobile, layer: string) {
        console.log("init multi marker: " + layer);
        if (markers[layer] && markers[layer].internal) {
            return true;
        } else {
            markers[layer] = {
                container: [],
                internal: {
                    timestamp: new Date().getTime(),
                    initCounter: MARKER_INIT_COUNTER_MOBILE,
                    layerInitialized: false,
                    syncInProgress: false,
                    visibleLayer: true,
                    animateTimeout: null
                }
            };
            return true;
        }
    }

    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private initSingleMarker(markers: ISingleMarkerCollectionMobile, layer: string) {
        markers[layer] = this.getDefaultSingleMarkerContainer();
    }

    /**
     * get default single marker container
     */
    private getDefaultSingleMarkerContainer() {
        let m: ISingleMarkerMobile = {
            container: {
                marker: null,
                mox: null
            },
            internal: {
                timestamp: new Date().getTime(),
                initCounter: 3,
                layerInitialized: false,
                syncInProgress: false,
                visibleLayer: true,
                animateTimeout: null
            }
        };
        return m;
    }


    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private checkSingleMarker(markers: ISingleMarkerCollectionMobile, layer: string) {
        if (markers[layer] && markers[layer].container && markers[layer].internal) {
            return true;
        }
        return false;
    }

    /**
     * check multi marker initialized
     * @param markers 
     * @param layer 
     */
    private checkMultiMarker(markers: IArrayMarkerCollectionMobile, layer: string) {
        // console.log("check multi marker: ", markers[layer]);
        if (this.checkMarkerLayerContainer(layer, true) && markers[layer].internal) {
            return true;
        }
        return false;
    }


    /**
     * insert a marker into the array
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param markerContent 
     */
    private insertArrayMarkerCore(layer: string, markerContent: IPlaceMarkerContent): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            // basic error check
            if (!(this.multiMarkers[layer])) {
                reject(new Error("marker array not initialized"));
                return;
            }

            this.showMarkerMobile(layer, markerContent, true).then((res: boolean) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check if the layer exists
     */
    checkLayer(layer: string, multi: boolean) {
        let defined: boolean = true;
        if (!multi) {
            if (!this.singleMarkers[layer]) {
                defined = false;
            }
        } else {
            if (!this.multiMarkers[layer]) {
                defined = false;
            }
        }
        return defined;
    }

    /**
     * show a single marker
     * marker/circle
     * plain/canvas
     * @param layer 
     * @param data 
     * @param multi 
     */
    private showMarkerMobile(layer: string, data: IPlaceMarkerContent, multi: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error("sync marker no data"));
                return;
            }

            let mox: IMarkerOptionsExtended = this.getMox(data);

            if (!this.checkLayer(layer, multi)) {
                reject(new Error("undefined layer: " + layer));
                return;
            }

            switch (data.shape) {
                case EMapShapes.marker:
                    switch (data.mode) {
                        case EMarkerTypes.plain:
                            // size used here e.g. for coins
                            let icon = null;
                            if (data.radius !== null) {
                                icon = {
                                    url: data.icon,
                                    size: {
                                        width: data.radius,
                                        height: data.radius
                                    }
                                };
                            } else {
                                icon = data.icon;
                            }
                            mox.markerOptions.icon = icon;

                            this.showPlainMarkerMobile(mox).then((marker: Marker) => {
                                // console.log("marker set");
                                try {
                                    if (!multi) {
                                        this.singleMarkers[layer].container.marker = marker;
                                        this.singleMarkers[layer].container.mox = mox;
                                        this.singleMarkers[layer].internal.layerInitialized = true;
                                    } else {
                                        this.multiMarkers[layer].container.push({
                                            marker,
                                            mox
                                        });
                                    }
                                } catch (err) {
                                    console.error(layer, err);
                                    // the layer was cleared while the marker was being placed
                                    // remove the marker
                                    this.clearMarker(marker);
                                }
                                resolve(true);
                            }).catch((err: Error) => {
                                reject(err);
                            });
                            break;
                        case EMarkerTypes.canvasFrame:
                        case EMarkerTypes.canvasPlain:
                        case EMarkerTypes.canvasPlainCenter:
                            // set default arrow size for canvas circular marker
                            let mh = 20;
                            let mw = 20;
                            if (!data.radius) {
                                data.radius = 120;
                            } else {
                                mh = Math.floor(mh * data.radius / 120);
                                mw = Math.floor(mw * data.radius / 120);
                            }
                            let opts: IShowMarkerOptions = {
                                mh,
                                mw,
                                width: data.radius,
                                type: data.layer,
                                circularFrame: data.mode === EMarkerTypes.canvasFrame,
                                color: data.color,
                                labelFrameColor: data.labelFrameColor,
                                labelTextColor: data.labelTextColor,
                                faded: data.locked
                            };
                            opts.height = opts.width + opts.mh;

                            // this.multiMarkersData[data.type].push(mox);
                            this.showCanvasMarkerMobile(mox, opts).then((marker: Marker) => {
                                // console.log(this.multiMarkers);
                                try {
                                    if (!multi) {
                                        this.singleMarkers[layer].container.marker = marker;
                                        this.singleMarkers[layer].container.mox = mox;
                                        this.singleMarkers[layer].internal.layerInitialized = true;
                                    } else {
                                        this.multiMarkers[layer].container.push({
                                            marker,
                                            mox
                                        });
                                    }
                                    // // TEST ACCURACY
                                    if (this.testAccuracy) {
                                        mox.markerOptions.icon = EMarkerIcons.testA;
                                        this.showPlainMarkerMobile(mox).then(() => {

                                        }).catch(() => {

                                        });
                                    }
                                } catch (err) {
                                    console.error(layer, err);
                                    // the layer was cleared while the marker was being placed
                                    // remove the marker
                                    this.clearMarker(marker);
                                }
                                resolve(true);
                            }).catch((err: Error) => {
                                console.error(err);
                                reject(err);
                            });
                            break;
                        default:
                            resolve(false);
                            break;
                    }
                    break;
                case EMapShapes.circle:
                    this.showCircleMobile(mox).then((circle: Circle) => {
                        if (!multi) {
                            this.singleMarkers[layer].container.marker = circle;
                            this.singleMarkers[layer].container.mox = mox;
                            this.singleMarkers[layer].internal.layerInitialized = true;
                        } else {
                            this.multiMarkers[layer].container.push({
                                marker: circle,
                                mox
                            });
                        }
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                    break;
            }
        });
        return promise;
    }

    /**
     * sync external data buffer with marker buffer
     * should be all the same type/layer e.g. places
     * @param data 
     * @param sync add markers in sync (ios) vs all at once (android)
     */
    syncMarkerArray(layer: string, data: IPlaceMarkerContent[], sync: boolean) {
        let promise = new Promise((resolve, reject) => {

            let debug: boolean = false;

            if (!data) {
                reject(new Error("data undefined"));
                return;
            }

            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                this.initMultiMarker(this.multiMarkers, layer);
            }

            // USE GLOBAL LOCK ON THE ENTIRE MARKER ARRAY SO THAT MARKERS ARE NOT DOUBLED

            if (this.multiMarkers[layer].internal.syncInProgress) {
                reject(new Error("layer sync already in progress: " + layer));
                return;
            }

            // the layer is initialized but the update is too fast
            if (this.multiMarkers[layer].internal.layerInitialized && !this.checkInitTimeout(this.multiMarkers[layer].internal.timestamp)) {
                reject(new Error("marker update too fast"));
                return;
            }

            this.multiMarkers[layer].internal.syncInProgress = true;

            let multiMarkers: IPlaceMarkerContent[] = this.multiMarkers[layer].container.map(c => c.mox.markerContent);

            let promisesU: Promise<boolean>[] = [];
            let promisesUSync: (() => Promise<boolean>)[] = [];
            let promisesI: Promise<boolean>[] = [];
            let promisesISync: (() => Promise<boolean>)[] = [];

            let added: number = 0;
            let updated: number = 0;
            let removed: number = 0;

            let removeArray: IPlaceMarkerContent[] = [];
            let updateArray: IPlaceMarkerContent[] = [];
            let insertArray: IPlaceMarkerContent[] = [];
            let update: boolean = false;
            // check update or insert
            for (let i = 0; i < data.length; i++) {
                if (!data[i]) {
                    continue;
                }
                update = false;
                for (let j = 0; j < multiMarkers.length; j++) {
                    if (multiMarkers[j].uid === data[i].uid) {
                        update = true;
                        break;
                    }
                }
                if (update) {
                    updateArray.push(data[i]);
                } else {
                    insertArray.push(data[i]);
                }
            }
            // check remove
            let remove: boolean = true;
            for (let i = 0; i < multiMarkers.length; i++) {
                remove = true;
                for (let j = 0; j < data.length; j++) {
                    if (!data[j]) {
                        continue;
                    }
                    if (multiMarkers[i].uid === data[j].uid) {
                        remove = false;
                        break;
                    }
                }
                if (remove) {
                    // console.log("remove, locksync: " + multiMarkers[i].lockSync);
                    if (!multiMarkers[i].lockSync) {
                        removeArray.push(multiMarkers[i]);
                    } else {
                        // multiMarkers[i].visible = true;
                        // updateArray.push(multiMarkers[i]);
                    }
                }
            }

            for (let i = 0; i < updateArray.length; i++) {
                updated += 1;

                if (sync) {
                    promisesUSync.push(() => {
                        return new Promise((resolve) => {
                            this.updateArrayMarkerCore(layer, updateArray[i]).then(async () => {
                                await SleepUtils.sleep(this.loopDelay);
                                resolve(true);
                            }).catch(() => {
                                resolve(false);
                            });
                        });
                    });
                } else {
                    promisesU.push(new Promise<boolean>((resolve) => {
                        this.updateArrayMarkerCore(layer, updateArray[i]).then(() => {
                            resolve(true);
                        }).catch(() => {
                            resolve(false);
                        });
                    }));
                }
            }

            let promiseUpdate: Promise<any>;

            if (updateArray.length > 0) {
                if (sync) {
                    promiseUpdate = this.syncService.runSync(promisesUSync);
                } else {
                    promiseUpdate = Promise.all(promisesU);
                }
            } else {
                promiseUpdate = Promise.resolve(true);
            }

            // update done
            promiseUpdate.then(() => {

                // insert
                for (let i = 0; i < insertArray.length; i++) {
                    added += 1;

                    if (sync) {
                        promisesISync.push(() => {
                            return new Promise((resolve) => {
                                this.insertArrayMarkerCore(layer, insertArray[i]).then(async () => {
                                    await SleepUtils.sleep(this.loopDelay);
                                    resolve(true);
                                }).catch(() => {
                                    resolve(false);
                                });
                            })
                        });
                    } else {
                        promisesI.push(new Promise((resolve) => {
                            this.insertArrayMarkerCore(layer, insertArray[i]).then(() => {
                                resolve(true);
                            }).catch(() => {
                                resolve(false);
                            });
                        }));
                    }
                }

                let promiseInsert: Promise<any>;

                if (insertArray.length > 0) {

                    if (sync) {
                        promiseInsert = this.syncService.runSync(promisesISync);
                    } else {
                        promiseInsert = Promise.all(promisesI);
                    }

                } else {
                    promiseInsert = Promise.resolve(true);
                }

                promiseInsert.then(async () => {

                    // remove is ok to be sync
                    for (let e of removeArray) {
                        removed += 1;
                        this.removeArrayMarkerCore(layer, e);
                        if (sync) {
                            await SleepUtils.sleep(this.loopDelay);
                        }
                    }

                    if (debug) {
                        console.log("updated: " + updated);
                        console.log("added: " + added);
                        console.log("removed: " + removed);
                    }

                    if (this.checkMarkerLayerContainer(layer, true)) {
                        this.multiMarkers[layer].internal.layerInitialized = true;
                        this.multiMarkers[layer].internal.syncInProgress = false;
                    }

                    await SleepUtils.sleep(this.loopDelay);
                    resolve(true);
                }).catch(async (err: Error) => {
                    console.error(err);

                    if (this.checkMarkerLayerContainer(layer, true)) {
                        this.multiMarkers[layer].internal.syncInProgress = false;
                    }

                    await SleepUtils.sleep(this.loopDelay);
                    reject(err);
                });
            }).catch(async (err: Error) => {

                console.error(err);

                if (this.checkMarkerLayerContainer(layer, true)) {
                    this.multiMarkers[layer].internal.syncInProgress = false;
                }

                await SleepUtils.sleep(this.loopDelay);
                reject(err);
            });
        });
        return promise;
    }



    /**
     * add marker for google map into the marker array
     * different for native and browser
     * @param data
     * @param type
     * @param icon
     */
    insertArrayMarker(data: IPlaceMarkerContent, show: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (!data.icon) {
                resolve(true);
                return;
            }

            if (!show) {
                resolve(false);
                return;
            }

            let layer: string = data.layer;
            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                // console.log("insert array marker check multi marker: false");
                this.initMultiMarker(this.multiMarkers, layer);
            }

            if (!(this.checkGlobalInitTimeout())) {
                reject(new Error("marker insert before map init"));
                return;
            }

            this.insertArrayMarkerCore(data.layer, data).then((data) => {
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * handle marker callback and info window management
     * @param md
     * @param marker 
     */
    private handleMarkerCallback(md: IMarkerOptionsExtended, marker: Marker) {
        // console.log(md.markerOptions);

        marker.on(GoogleMapsEvent.INFO_CLICK).subscribe(() => {
            marker.hideInfoWindow();
        }, (err: Error) => {
            console.error(err);
        });

        if (md.callback) {
            marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe(async () => {
                await SleepUtils.sleep(this.loopDelay);
                marker.hideInfoWindow();
                // if (md.data && md.data.data) {
                //     md.callback(md.data.data);
                // } else {
                //     md.callback(md.data);
                // }
                md.callback(md.markerContent);
            }, (err: Error) => {
                console.error(err);
            });
        }

        // if (md.markerOptions.draggable) {
        marker.on(GoogleMapsEvent.MARKER_DRAG).subscribe(() => {
            // console.log("drag marker");
        }, (err: Error) => {
            console.error(err);
        });

        marker.on(GoogleMapsEvent.MARKER_DRAG_END).subscribe(() => {
            try {
                let newpos: ILatLng = marker.getPosition();
                console.log("drag marker end");
                md.markerContent.location.lat = newpos.lat;
                md.markerContent.location.lng = newpos.lng;
                // should assign to nearby place e.g. geocode
                if (md.dragCallback) {
                    md.dragCallback(newpos.lat, newpos.lng);
                }
            } catch (e) {
                console.error(e);
                console.log(md.dragCallback);
            }
        }, (err: Error) => {
            console.error(err);
        });
        // }
    }


    /**
     * show plain marker i.e. that is not drawn via canvas
     * @param md 
     */
    showPlainMarkerMobile(md: IMarkerOptionsExtended): Promise<Marker> {
        let promise: Promise<Marker> = new Promise((resolve, reject) => {
            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }

            this.map.addMarker(md.markerOptions).then((marker: Marker) => {
                this.handleMarkerCallback(md, marker);
                resolve(marker);
            }).catch((err: Error) => {
                reject(err);
            });

        });
        return promise;
    }

    /**
    * show canvas marker
    * with circular frame, label, etc
    * @param md 
    * @param opts 
    */
    showCanvasMarkerMobile(md: IMarkerOptionsExtended, opts: IShowMarkerOptions): Promise<Marker> {
        let promise: Promise<Marker> = new Promise((resolve, reject) => {

            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }

            let markerOpts: MarkerOptions = Object.assign({}, md.markerOptions);

            let icon1 = md.markerOptions.icon;
            // console.log("icon url: ", icon1);
            if (typeof icon1 !== "string") {
                icon1 = this.markerIcons.test;
            }
            let canvas = document.createElement('canvas');

            let f: ICanvasMarkerContainer = MarkerUtils.formatCanvasMarkerContainer(md.markerContent, opts.width);

            canvas.width = f.fullW;
            canvas.height = f.fullH;

            // setDPI(canvas, 300);
            MarkerUtils.setDPI(canvas, null, SettingsManagerService.settings.app.settings.HDPIMode.value);

            let ctx = canvas.getContext('2d');
            let img = new Image();

            img.crossOrigin = "";
            let iconUrl = md.markerOptions.icon;

            let isIos: boolean = GeneralCache.os === EOS.ios;

            // looks cooler
            isIos = true;

            let cb: IBenchObject = BenchUtils.start();
            let bench: IBenchReportContainer = {
                main: {

                }
            }

            let label: string = null;
            let heading: string[] = [null];

            if (md.markerContent) {
                label = md.markerContent.label;
                if (md.markerContent.heading) {
                    heading = [md.markerContent.heading];
                }

                let formatLabel = MarkerUtils.formatAddLabels(heading[0], label, md.markerContent.addLabel, md.markerContent.addLabel2);
                heading = [formatLabel.heading];
                heading = formatLabel.heading2d;
                // label = formatLabel.label;
                label = formatLabel.label2d;
            }

            img.src = iconUrl;

            bench.main.preload = BenchUtils.lap(cb, true);

            let imgPromise: Promise<boolean> = new Promise((resolve) => {
                img.onload = () => {
                    if (opts.circularFrame) {
                        ctx = MarkerUtils.drawMarkerText(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                    } else {
                        ctx = MarkerUtils.drawMarkerTextPlain(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                    }

                    let icon: string = canvas.toDataURL();
                    console.log("marker content size: " + icon.length);

                    markerOpts.icon = {
                        url: icon,
                        size: {
                            width: f.fullW,
                            height: f.fullH
                        }
                    };

                    bench.main.load = BenchUtils.lap(cb, true);
                    resolve(true);
                };
                img.onerror = (err) => {
                    console.warn("canvas image error: ", err);
                    // fallback to default location icon
                    img.src = EMarkerIcons.location;
                    if (opts.circularFrame) {
                        ctx = MarkerUtils.drawMarkerText(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                    } else {
                        ctx = MarkerUtils.drawMarkerTextPlain(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                    }
                    // ctx = MarkerUtils.drawMarkerText(ctx, w, h, opts.mh, opts.mw, img, opts.color, opts.faded, "TEST");
                    // ctx = drawMarker(ctx, w, h, opts.mh, opts.mw, img, opts.color, opts.faded);
                    let icon: string = canvas.toDataURL();
                    // console.log("icon set");
                    markerOpts.icon = {
                        url: icon,
                        size: {
                            width: f.fullW,
                            height: f.fullH
                        }
                    };
                    bench.main.loadFallback = BenchUtils.lap(cb, true);
                    resolve(true);
                };
            });

            imgPromise.then(() => {
                markerOpts.visible = md.markerContent ? md.markerContent.visible : true;

                // markerOpts.anchor = [f.fullW / 2, opts.circularFrame ? f.fullH : f.fullH / 2];
                markerOpts.icon.anchor = {
                    x: f.fullW / 2,
                    y: opts.circularFrame ? f.fullH : f.fullH / 2
                };

                // console.log(markerOpts.zIndex);
                if (this.enable) {
                    this.map.addMarker(markerOpts).then((marker: Marker) => {
                        bench.main.draw = BenchUtils.lap(cb, true);
                        BenchUtils.sumBench(bench.main);
                        console.log("draw marker status bench: ", bench);
                        this.handleMarkerCallback(md, marker);
                        // marker.setVisible(md.data ? md.data.visible : true);
                        resolve(marker);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("master lock"));
                }
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });

        });

        return promise;
    }


    /**
     * show markers from marker data array
     * mobile
     * @param opts 
     */
    showMarkerArrayMobile(opts: IShowMarkerOptions) {
        opts.height = opts.width + opts.mh;
        let layer: string = opts.type;

        if (!this.checkMultiMarker(this.multiMarkers, layer)) {
            this.initMultiMarker(this.multiMarkers, layer);
        }

        let promises = [];
        let promise = new Promise((resolve, reject) => {
            if (!opts.circularFrame) {
                let mc = this.multiMarkers[opts.type].container;
                // assign created markers
                for (let i = 0; i < mc.length; i++) {
                    let md: IMarkerOptionsExtended = mc[i].mox;
                    if (md.markerOptions.icon === null) {
                        md.markerOptions.icon = this.markerIcons.location;
                    }
                    let promise = this.showPlainMarkerMobile(md);
                    promises.push(promise);
                    promise.then((marker) => {
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
            } else {
                // console.log('show canvas markers');
                let moxArray: IMarkerOptionsExtended[] = this.multiMarkers[opts.type].container.map(c => c.mox);
                for (let i = 0; i < moxArray.length; i++) {
                    let md: IMarkerOptionsExtended = moxArray[i];
                    let promise = this.showCanvasMarkerMobile(md, opts);
                    promises.push(promise);
                    promise.then((marker) => {
                        console.log("marker set");
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
            }

            Promise.all(promises).then(() => {
                console.log("all markers set");
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    /**
     * show circle marker
     * @param mox 
     */
    showCircleMobile(mox: IMarkerOptionsExtended): Promise<Circle> {
        // Add circle
        let promise: Promise<Circle> = new Promise((resolve, reject) => {
            let options: CircleOptions = {
                center: mox.markerContent.location,
                radius: mox.markerContent.radius,
                strokeColor: EFeatureColor.leplaceBlue,
                strokeOpacity: 0.2,
                strokeWeight: 2,
                fillColor: EFeatureColor.leplaceBlue,
                fillOpacity: 0.2,
                zindex: mox.markerContent.zindex,
                visible: mox.markerContent ? mox.markerContent.visible : true
            };
            this.map.addCircle(options).then((circle: Circle) => {
                resolve(circle);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * set entire layer visible/invisible
     * it can be any kind of marker, also circle
     * single/multi
     * @param layer 
     * @param visible 
     */
    setVisibleLayer(layer: string, visible: boolean) {
        if (this.multiMarkers[layer] != null) {
            this.multiMarkers[layer].internal.visibleLayer = visible;
            this.multiMarkers[layer].container.map(c => c.marker).forEach((marker: Marker) => {
                if (marker != null) {
                    marker.setVisible(visible);
                }
            });
        } else {
            if (this.singleMarkers[layer] != null) {
                this.singleMarkers[layer].internal.visibleLayer = visible;
                this.singleMarkers[layer].container.marker.setVisible(visible);
            }
        }
    }

    /**
     * get visible state of marker layer
     * single/multi
     * @param layer 
     */
    getVisibleLayer(layer: string) {
        if (this.multiMarkers[layer] != null) {
            return this.multiMarkers[layer].internal.visibleLayer;
        } else {
            if (this.singleMarkers[layer] != null) {
                return this.singleMarkers[layer].internal.visibleLayer;
            }
        }
        return false;
    }

    /**
     * this method is not currently used
     */
    addMarkerClusterer() {
        let promise = new Promise((resolve, reject) => {
            console.log("remove existing marker clusters");

            for (let i = 0; i < this.markerClusters.length; i++) {
                this.markerClusters[i].destroy();
            }
            this.markerClusters = [];
            console.log("add marker clusterer");

            let keys: string[] = Object.keys(this.multiMarkers);
            let allMultiMarkers = [];
            let allMultiMarkersOptions: MarkerOptions[] = [];
            for (let i = 0; i < keys.length; i++) {
                if (this.multiMarkers[keys[i]].container.length > 0) {
                    allMultiMarkers = allMultiMarkers.concat(this.multiMarkers[keys[i]]);
                }
                if (this.multiMarkers[keys[i]].container.length > 0) {
                    allMultiMarkersOptions = allMultiMarkersOptions.concat(this.multiMarkers[keys[i]].container.map(c => c.mox.markerOptions));
                }
            }

            this.map.addMarkerCluster({
                markers: allMultiMarkersOptions,
                boundsDraw: true,
                // maxZoomLevel: 5,
                icons: [
                    {
                        min: 2,
                        max: 100,
                        url: EMarkerIcons.location,
                        anchor: {
                            x: 16,
                            y: 16
                        }
                    },
                    {
                        min: 100,
                        max: 1000,
                        url: EMarkerIcons.location,
                        anchor: {
                            x: 16,
                            y: 16
                        }
                    },
                    {
                        min: 1000,
                        max: 2000,
                        url: EMarkerIcons.location,
                        anchor: {
                            x: 24,
                            y: 24
                        }
                    },
                    {
                        min: 2000,
                        url: EMarkerIcons.location,
                        anchor: {
                            x: 32,
                            y: 32
                        }
                    }
                ]
            }).then((markerCluster: MarkerCluster) => {
                markerCluster.on(GoogleMapsEvent.MARKER_CLICK).subscribe((params: any) => {
                    console.log('cluster was clicked: ', params);
                });
                console.log("marker cluster added: ", markerCluster);
                this.markerClusters.push(markerCluster);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    /**
     * set this circle marker, change position if existing
     * resize marker if size is changed
     */
    syncCircleMarker(layer: string, position: LatLng, options: ISetThisMarkerOptions) {
        let data: IPlaceMarkerContent = MarkerUtils.getBaseMarker();
        data.location = position;
        return this.syncMarkerCore(layer, data, true, options, null);
    }


    /**
     * sync a single marker (update or create)
     * @param layer 
     * @param data 
     */
    syncMarker(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions): Promise<boolean> {
        return this.syncMarkerCore(layer, data, false, null, opts);
    }

    /**
     * sync a single marker (update or create)
     * @param layer 
     * @param data 
     * @param circle
     */
    private syncMarkerCore(layer: string, data: IPlaceMarkerContent, circle: boolean, options: ISetThisMarkerOptions, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error("sync marker no data"));
                return;
            }

            let mox: IMarkerOptionsExtended = this.getMox(data);

            if (!options) {
                options = {
                    zindex: 0,
                    size: 0
                };
            } else {
                mox.markerContent.zindex = options.zindex;
                mox.markerOptions.zIndex = options.zindex;
            }

            this.singleMarkerUpdate(layer, data.location, circle, options, opts).then((state: IMarkerUpdateResult) => {
                // console.log(state);
                switch (state.code) {
                    case EMarkerUpdateCode.ok:
                        // console.log("ok");
                        resolve(true);
                        break;
                    case EMarkerUpdateCode.shouldWait:
                        // console.log("should wait");
                        reject(new Error(state.message));
                        break;
                    case EMarkerUpdateCode.shouldCreate:
                        // console.log("should create");
                        if (!(this.checkGlobalInitTimeout())) {
                            reject(new Error("marker create before map init"));
                            return;
                        }
                        let promise: any;
                        if (circle) {
                            promise = this.singleCircleCreate(layer, mox.markerContent.location, options);
                        } else {
                            promise = this.singleMarkerCreate(layer, mox.markerContent.location, mox, true);
                        }

                        promise.then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * add path from waypoints
     * using single markers layer
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    addPathMobile(waypoints: LatLng[], layer: string, show: boolean): Promise<PolylineOptions> {
        let promise: Promise<PolylineOptions> = new Promise((resolve, reject) => {
            if (!this.checkSingleMarker(this.singleMarkers, layer)) {
                this.initSingleMarker(this.singleMarkers, layer);
            }

            if (waypoints.length > 0) {
                let pathOptions: PolylineOptions = {
                    points: waypoints,
                    visible: show,
                    geodesic: true,
                    color: this.theme.lineColor,
                    width: 10,
                    zIndex: 100,
                    clickable: false
                };

                this.map.addPolyline(pathOptions).then((path: Polyline) => {
                    this.singleMarkers[layer].container.marker = path;
                    resolve(pathOptions);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                resolve(null);
            }
        });
        return promise;
    }


    /**
     * clear markers from marker array from google map
     * clear the marker array or single marker
     * does not clear all data
     * @param layer
     */
    clearMarkersResolve(layer: string): Promise<boolean> {
        // https://github.com/mapsplugin/cordova-plugin-googlemaps-doc/blob/master/v2.0.0/class/Marker/README.md
        // https://developers.google.com/android/reference/com/google/android/gms/maps/model/Marker
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            if (this.checkMarkerLayerContainer(layer, true)) {
                for (let c of this.multiMarkers[layer].container) {
                    let marker: Marker = c.marker;
                    try {
                        if (marker) {
                            // if this does not work, install the latest google maps plugin!!!
                            marker.remove();
                            marker.destroy();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    c.marker = null;
                    console.log("removing next marker");
                    await SleepUtils.sleep(this.loopDelay);
                }
                // this.multiMarkers[layer].container[layer] = [];
            }

            if (this.checkMarkerLayerContainer(layer, false)) {
                let marker: Marker = this.singleMarkers[layer].container.marker;
                try {
                    if (marker) {
                        marker.remove();
                        marker.destroy();
                        this.singleMarkers[layer].container.marker = null;
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            await SleepUtils.sleep(this.loopDelay);
            console.log("resolve clear markers");
            resolve(true);
        });

        return promise;
    }


    /**
     * clear the last marker from the array
     * @param layer 
     * @param remove completely remove the marker from the array
     */
    clearLastArrayMarker(layer: string, remove: boolean = false) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            let container: IMarkerContainerMobile = this.multiMarkers[layer].container[lastIndex];
            if (container) {
                let marker: Marker = container.marker;
                if (marker) {
                    marker.remove();
                }
            }

            if (remove) {
                // clear the marker with all associated data
                this.multiMarkers[layer].container.splice(-1, 1);
            } else {
                // only clear the marker from the map
                this.multiMarkers[layer].container.forEach(c => {
                    if (c) {
                        c.marker = null;
                    }
                });
            }
        }
    }


    /**
     * completely remove the marker from the array
     * @param layer 
     * @param index 
     */
    clearArrayMarkerByIndex(layer: string, index: number) {
        // Sets the map on all markers in the array.
        console.log("clear marker by index: " + index);
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let markerContainer: IMarkerContainerMobile = this.multiMarkers[layer].container[index];
            if (!markerContainer) {
                return;
            }
            let marker: Marker = markerContainer.marker;
            this.clearMarker(marker);
            if (index !== -1 && index < this.multiMarkers[layer].container.length) {
                this.multiMarkers[layer].container.splice(index, 1);
            }
        }
    }

    /**
     * clear the specified marker from the map
     * @param marker 
     */
    private clearMarker(marker: Marker) {
        if (marker != null) {
            marker.remove();
        }
    }

    /**
     * show/hide last marker from array
     * @param layer 
     * @param show 
     */
    toggleLastArrayMarkerShow(layer: string, show: boolean) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            let marker: Marker = this.multiMarkers[layer].container[lastIndex].marker;
            if (marker) {
                marker.setVisible(show);
            }
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param uid 
     */
    clearArrayMarkerByUid(layer: string, uid: string) {
        // Sets the map on all markers in the array.
        console.log("clear marker by uid: " + uid);
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        this.clearArrayMarkerByIndex(layer, index);
    }


    /**
     * clear the marker layer completely
     * @param layer 
     */
    clearMarkerLayer(layer: string) {
        if (this.multiMarkers[layer]) {
            this.multiMarkers[layer] = null;
        }
        if (this.singleMarkers[layer] != null) {
            this.singleMarkers[layer] = null;
        }
    }


    /**
     * 
     * @param index 
     * @param type 
     */
    getMarkerLocationByIndex(index: number, type: string) {
        let marker: IMarkerContainerMobile = this.getArrayMarkerByIndex(index, type);
        if (marker) {
            let pos1: ILatLng = marker.mox.markerOptions.position;
            let pos: LatLng = new LatLng(pos1.lat, pos1.lng);
            return pos;
        } else {
            return null;
        }
    }

    /**
     * get array marker by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }

            let marker: IMarkerContainerMobile;
            marker = this.multiMarkers[layer].container[index];
            return marker;
        }
        return null;
    }

    getArrayMarkerDataByUid(uid: string, layer: string) {
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        return this.getArrayMarkerDataByIndex(index, layer);
    }

    /**
     * get array marker data by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerDataByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }
            let marker: IPlaceMarkerContent;
            marker = this.multiMarkers[layer].container[index].mox.markerContent;
            return marker;
        }
        return null;
    }


    /**
     * get data layer content
     * @param layer 
     */
    getSingleMarkerDataByLayer(layer: string): IPlaceMarkerContent {
        if (this.checkSingleMarker(this.singleMarkers, layer)) {
            return this.singleMarkers[layer].container.mox.markerContent;
        }
        return null;
    }

    /**
     * get data layer content
     * @param layer 
     */
    getArrayMarkerDataByLayer(layer: string): IPlaceMarkerContent[] {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            return this.multiMarkers[layer].container.map(c => c.mox.markerContent);
        }
        return null;
    }

    /**
     * should be called just after init map
     */
    setGlobalMarkerInitTimestamp() {
        this.globalInitTimestamp = new Date().getTime();
    }


    /**
     * check marker update timeout
     * @param timestamp 
     * @param timeCrt 
     */
    private checkInitTimeout(timestamp: number) {
        let timeCrt: number = new Date().getTime();
        if (timestamp != null && ((timeCrt - timestamp) > MARKER_UPDATE_TIMEOUT_MOBILE)) {
            return this.checkGlobalInitTimeout();
        } else {
            return false;
        }
    }

    /**
     * check marker update global timeout
     */
    private checkGlobalInitTimeout() {
        let timeCrt: number = new Date().getTime();
        if (!this.globalInitTimestamp || ((timeCrt - this.globalInitTimestamp) > MAP_INIT_TIMEOUT_MOBILE)) {
            return true;
        }
        return false;
    }

    /**
     * completely remove the marker layer
     * this method may be used in a loop and contains a small delay for this matter
     * @param layer 
     */
    disposeLayerResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.log("dispose layer: " + layer);
            this.clearMarkersResolve(layer).then(async () => {
                this.clearMarkerLayer(layer);
                await SleepUtils.sleep(this.loopDelay);
                resolve(true);
            });
        });
        return promise;
    }

    /**
     * completely remove the marker layer
     * no wait
     * @param key 
     */
    disposeLayerNoAction(key: string) {
        this.disposeLayerResolve(key).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        })
    }

    /**
     * clear all markers
     * dispose all layers
     */
    async clearAll(sync: boolean) {
        let promise = new Promise(async (resolve) => {
            let keys = Object.keys(this.multiMarkers);
            for (let key of keys) {
                if (sync) {
                    await this.disposeLayerResolve(key);
                } else {
                    this.disposeLayerNoAction(key);
                }
            }

            keys = Object.keys(this.singleMarkers);
            for (let key of keys) {
                if (sync) {
                    await this.disposeLayerResolve(key);
                } else {
                    this.disposeLayerNoAction(key);
                }
            }

            resolve(true);
        });
        return promise;
    }

    /**
     * clear all without removing from the map
     * used for cleanup before map unload
     * map.clearAll should be used for clearing the map
     */
    clearAllNoRemove() {
        let promise = new Promise(async (resolve) => {
            let keys = Object.keys(this.multiMarkers);
            for (let key of keys) {
                this.clearMarkerLayer(key);
            }

            keys = Object.keys(this.singleMarkers);
            for (let key of keys) {
                this.clearMarkerLayer(key);
            }

            resolve(true);
        });
        return promise;
    }

    /**
    * set draggable once added
    * @param layer 
    * @param draggable 
    */
    setDraggable(layer: string, draggable: boolean) {
        let setFn = (marker: google.maps.Marker, markerData: IPlaceMarkerContent) => {
            if (marker != null) {
                marker.setDraggable(draggable);
                markerData.drag = draggable;
            }
        }
        // Sets the map on all markers in the array.
        if (this.multiMarkers[layer] != null) {
            this.multiMarkers[layer].container.forEach((c: IMarkerContainerMobile) => {
                if (c && c.marker && c.mox && c.mox.markerContent) {
                    if (c.mox.markerContent.canEdit) {
                        setFn(c.marker, c.mox.markerContent);
                    }
                }
            });
        }
        if (this.singleMarkers[layer] != null) {
            let c: IMarkerContainerMobile = this.singleMarkers[layer].container;
            if (c && c.marker && c.mox && c.mox.markerContent) {
                if (c.mox.markerContent.canEdit) {
                    setFn(c.marker, c.mox.markerContent);
                }
            }
        }
    }

    getDraggableSelectedByUid(layer: string, uid: string) {
        // Sets the map on all markers in the array.
        console.log("get draggable selected by uid: " + uid + " / layer: " + layer);
        let index: number = null;
        if (uid != null) {
            index = this.getArrayMarkerIndexByUid(layer, uid);
        }
        this.getDraggableSelected(layer, index);
    }

    refreshMarkerByUid(layer: string, uid: string, placeMarkerData: IPlaceMarkerContent) {
        // Sets the map on all markers in the array.
        console.log("set refresh selected by uid: " + uid + " / layer: " + layer);
        let index: number = null;
        if (uid != null) {
            index = this.getArrayMarkerIndexByUid(layer, uid);
        }
        this.setDraggableSelected(layer, index, null, placeMarkerData);
    }

    setDraggableSelectedByUid(layer: string, uid: string, animate: boolean) {
        // Sets the map on all markers in the array.
        console.log("set draggable selected by uid: " + uid + " / layer: " + layer);
        let index: number = null;
        if (uid != null) {
            index = this.getArrayMarkerIndexByUid(layer, uid);
        }
        this.setDraggableSelected(layer, index, animate, null);
    }

    setDraggableSelected(targetLayer: string, index: number, animate: boolean, placeMarkerData: IPlaceMarkerContent) {
        let setFn = (layer: string, marker: Marker, markerData: IPlaceMarkerContent) => {
            if (marker != null) {
                let refresh: boolean = true;
                if (animate != null) {
                    marker.setDraggable(animate);
                    if (animate) {
                        // marker.setAnimation(GoogleMapsAnimation.BOUNCE);
                        MarkerUtils.setColorSelected(markerData);
                    } else {
                        // marker.setAnimation(null);
                        MarkerUtils.resetColorDefaultInit(markerData);
                    }
                    if (markerData.drag === animate) {
                        refresh = false;
                    }
                    markerData.drag = animate;
                }

                if (refresh) {
                    this.removeArrayMarkerCore(layer, markerData);
                    PromiseUtils.wrapNoAction(this.insertArrayMarkerCore(layer, markerData), true);
                    // PromiseUtils.wrapNoAction(this.updateArrayMarkerCore(layer, markerData), true);
                }
            }
        };
        let layers: string[] = [targetLayer];
        if (targetLayer == null) {
            layers = Object.keys(this.multiMarkers);
        }
        for (let layer of layers) {
            // Sets the map on all markers in the array.
            if (this.multiMarkers[layer] != null) {
                if (index != null) {
                    let c: IMarkerContainerMobile = this.multiMarkers[layer].container[index];
                    if (c && c.marker && c.mox && c.mox.markerContent) {
                        if (c.mox.markerContent.canEdit) {
                            if (animate != null) {
                                c.mox.markerContent.selected = animate;
                            }
                            if (placeMarkerData != null) {
                                c.mox.markerContent = placeMarkerData;
                            }
                            setFn(layer, c.marker, c.mox.markerContent);
                        }
                    }
                } else {
                    this.multiMarkers[layer].container.forEach((c: IMarkerContainerMobile) => {
                        if (c && c.marker && c.mox && c.mox.markerContent) {
                            if (c.mox.markerContent.canEdit) {
                                if (animate != null) {
                                    c.mox.markerContent.selected = animate;
                                }
                                if (placeMarkerData != null) {
                                    c.mox.markerContent = placeMarkerData;
                                }
                                setFn(layer, c.marker, c.mox.markerContent);
                            }
                        }
                    });
                }
            }
            if (this.singleMarkers[layer] != null) {
                let c: IMarkerContainerMobile = this.singleMarkers[layer].container;
                if (c && c.marker && c.mox && c.mox.markerContent) {
                    if (c.mox.markerContent.canEdit) {
                        if (animate != null) {
                            c.mox.markerContent.selected = animate;
                        }
                        if (placeMarkerData != null) {
                            c.mox.markerContent = placeMarkerData;
                        }
                        setFn(layer, c.marker, c.mox.markerContent);
                    }
                }
            }
        }
    }


    getDraggableSelected(targetLayer: string, index: number) {
        let layers: string[] = [targetLayer];
        if (targetLayer == null) {
            layers = Object.keys(this.multiMarkers);
        }
        for (let layer of layers) {
            // Sets the map on all markers in the array.
            if (this.multiMarkers[layer] != null) {
                if (index != null) {
                    let c: IMarkerContainerMobile = this.multiMarkers[layer].container[index];
                    if (c && c.marker && c.mox && c.mox.markerContent) {
                        return c.mox.markerContent.selected;
                    }
                } else {
                    this.multiMarkers[layer].container.forEach((c: IMarkerContainerMobile) => {
                        if (c && c.marker && c.mox && c.mox.markerContent) {
                            return c.mox.markerContent.selected;
                        }
                    });
                }
            }
            if (this.singleMarkers[layer] != null) {
                let c: IMarkerContainerMobile = this.singleMarkers[layer].container;
                if (c && c.marker && c.mox && c.mox.markerContent) {
                    return c.mox.markerContent.selected;
                }
            }
        }
        return false;
    }


    /**
     * get all markers data (from all layers)
     * single and multi markers
     * returns only the actual content, not the marker itself
     */
    getAllMarkersData() {
        let keys: string[] = Object.keys(this.multiMarkers);
        let allMarkersData: IPlaceMarkerContent[] = [];
        for (let i = 0; i < keys.length; i++) {
            let m: IArrayMarkerMobile = this.multiMarkers[keys[i]];
            if (m && m.container) {
                allMarkersData = allMarkersData.concat(this.multiMarkers[keys[i]].container.map(c => c.mox.markerContent));
            }
        }
        keys = Object.keys(this.singleMarkers);
        for (let i = 0; i < keys.length; i++) {
            let m: ISingleMarkerMobile = this.singleMarkers[keys[i]];
            if (m && m.container && m.container.mox) {
                allMarkersData.push(m.container.mox.markerContent);
            }
        }
        // console.log("all markers data: ", allMarkersData);
        return allMarkersData;
    }


    /**
     * create a new marker on a single markers layer
     * only supports canvas markers at the moment
     * @param layer 
     * @param iconName 
     * @param position 
     * @param callback 
     * @param resize 
     * @param options 
     */
    singleMarkerCreate(layer: string, _location: LatLng, mox: IMarkerOptionsExtended, resize: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (resize) {
                let size = { width: 50, height: 50 };
                let icon2 = {
                    url: mox.markerOptions.icon,
                    size
                };
                mox.markerOptions.icon = icon2;
            }

            mox.markerOptions.icon = mox.markerOptions.icon.url;

            let mh = 20;
            let mw = 20;

            let data: IPlaceMarkerContent = mox.markerContent;

            if (!data.radius) {
                data.radius = 120;
            } else {
                mh = Math.floor(mh * data.radius / 120);
                mw = Math.floor(mw * data.radius / 120);
            }

            let opts: IShowMarkerOptions = {
                mh,
                mw,
                width: data.radius,
                type: layer,
                circularFrame: data.mode === EMarkerTypes.canvasFrame,
                // color: data.color,
                // color: "#dc4a38",
                color: this.theme.markerFrameColor,
                faded: data.locked
            };

            opts.height = opts.width + opts.mh;


            if (!this.singleMarkers[layer]) {
                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
                this.singleMarkers[layer].container.mox = mox;
            } else {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    reject(new Error("marker init in progress"));
                    return;
                }
            }

            this.showCanvasMarkerMobile(mox, opts).then((marker) => {
                this.singleMarkers[layer].container.marker = marker;
                this.singleMarkers[layer].internal.layerInitialized = true;
                console.log("marker was created ", layer);
                resolve(this.singleMarkers[layer]);
            }).catch((err: Error) => {
                console.error(err);
                this.singleMarkers[layer].internal.layerInitialized = true;
                reject(err);
            });
        });
        return promise;
    }


    /**
     * create a new circle marker on a single markers layer
     * @param layer 
     * @param position 
     * @param options 
     */
    singleCircleCreate(layer: string, position: LatLng, options: ISetThisMarkerOptions) {
        let promise = new Promise((resolve, reject) => {
            let circleOptions: CircleOptions = {
                center: position,
                radius: options.size,
                strokeColor: options.color ? options.color : this.theme.markerFrameColor,
                strokeOpacity: 0.2,
                strokeWeight: 2,
                fillColor: options.color ? options.color : this.theme.markerFrameColor,
                fillOpacity: 0.2,
                zindex: options.zindex,
                visible: true
            };

            if (!this.singleMarkers[layer]) {
                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
            } else {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    reject(new Error("marker init in progress"));
                    return;
                }
            }

            this.map.addCircle(circleOptions).then((circle: Circle) => {
                this.singleMarkers[layer].container.marker = circle;
                this.singleMarkers[layer].internal.layerInitialized = true;
                resolve(circle);
            }).catch((err: Error) => {
                this.singleMarkers[layer].internal.layerInitialized = true;
                reject(err);
            });
        });
        return promise;
    }


    /**
     * update marker
     * resolve to false if the marker should be created instead
     * @param layer 
     * @param position 
     */
    singleMarkerUpdate(layer: string, position: LatLng, isCircle: boolean, options: ISetThisMarkerOptions, opts: IMoveMapOptions) {
        let promise = new Promise((resolve) => {
            let result: IMarkerUpdateResult = {
                code: EMarkerUpdateCode.ok,
                message: "ok"
            };
            if (this.checkSingleMarker(this.singleMarkers, layer)) {
                // prevent double marker if set marker too fast
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    // should wait
                    result.code = EMarkerUpdateCode.shouldWait;
                    result.message = "marker create in progress";
                    resolve(result);
                    return;
                } else {
                    let timeCrt: number = new Date().getTime();
                    if (this.checkInitTimeout(this.singleMarkers[layer].internal.timestamp)) {
                        if (this.checkMarkerLayerContainer(layer, false)) {
                            if (isCircle) {
                                let c: Circle = this.singleMarkers[layer].container.marker;
                                c.setCenter(position);
                                if (c.getRadius() !== options.size) {
                                    c.setRadius(options.size);
                                }
                                resolve(result);
                            } else {

                                let enableAnimate: boolean = false;
                                if (opts && opts.animate && enableAnimate) {
                                    this.animatePositionUpdate(this.singleMarkers[layer], this.singleMarkers[layer].container.mox.markerContent.location, position).then(() => {
                                        let mk: Marker = this.singleMarkers[layer].container.marker;
                                        mk.setPosition(position);
                                        this.singleMarkers[layer].internal.timestamp = timeCrt;
                                        resolve(result);
                                    });
                                } else {
                                    let mk: Marker = this.singleMarkers[layer].container.marker;
                                    mk.setPosition(position);
                                    this.singleMarkers[layer].internal.timestamp = timeCrt;
                                    resolve(result);
                                }
                            }
                        } else {
                            result.code = EMarkerUpdateCode.shouldCreate;
                            result.message = "marker container empty";
                            resolve(result);
                        }
                    } else {
                        // should wait
                        result.code = EMarkerUpdateCode.shouldWait;
                        result.message = "marker update too fast";
                        resolve(result);
                        return;
                    }
                }
            } else {
                // should create
                result.code = EMarkerUpdateCode.shouldCreate;
                result.message = "marker should be created";
                resolve(result);
                return;
            }
        });
        return promise;
    }


    animatePositionUpdate(mc: ISingleMarkerMobile, a: LatLng, b: LatLng) {
        let promise = new Promise((resolve) => {
            let fraction: number = 0;
            let dt: number = 100;
            let targetTime: number = 500;
            let npoints: number = targetTime / dt;
            let dfraction: number = 1 / npoints;

            mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);

            let animateMk = () => {
                fraction += dfraction;
                if (fraction > 1) {
                    fraction = 1;
                    mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);
                    resolve(true);
                }
                // console.log("animate mk pos: ", fraction);
                mc.container.marker.setPosition(GeometryUtils.getInterpolate(a, b, fraction));
                mc.internal.animateTimeout = setTimeout(() => {
                    animateMk();
                }, dt);
            };

            animateMk();
        });
        return promise;
    }
}
