import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

@Component({
  selector: 'icon-wrapper',
  templateUrl: './icon-wrapper.component.html',
  styleUrls: ['./icon-wrapper.component.scss'],
})
export class IconWrapperComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  slot: string;

  @Input()
  custom: boolean;

  @Input()
  icon: string;

  @Input()
  iconIos: string;

  @Input()
  customClass: string;

  @Input()
  defaultClass: string;

  @Input()
  highClass: string;

  @Input()
  huge: boolean;

  @Input()
  duration: number;

  @Input()
  setTimeoutOnChange: boolean;


  adapter: boolean = true;

  iconIosActive: string = "";
  iconMdActive: string = "";

  btnDefaultClass: string;
  btnHighClass: string;

  blinkEnable: boolean = false;
  state: boolean = false;
  timerSub = null;
  blinkTimeout;

  baseClass: string = "leplace-icon-fixed leplace-icon";

  constructor() {
    // this.btnDefaultClass = this.baseClass + " icon-color-primary";
    this.btnDefaultClass = this.baseClass;
    this.btnHighClass = this.baseClass + " icon-color-primary-high";
  }

  ngOnInit() {
    if (this.huge) {
      this.baseClass = "leplace-icon-fixed-huge leplace-icon";
    }
    if (this.customClass) {
      this.baseClass = this.customClass;
    }
    this.btnDefaultClass = this.baseClass;
    this.btnHighClass = this.baseClass + " icon-color-primary-high";
    if (this.defaultClass) {
      this.btnDefaultClass = this.baseClass + " " + this.defaultClass;
    }
    if (this.highClass) {
      this.btnHighClass = this.baseClass + " " + this.highClass;
    }

    if (!this.custom) {
      if (this.adapter) {
        if (this.icon != null) {
          this.iconIosActive = (this.iconIos != null) ? this.iconIos : this.icon;
          this.iconMdActive = this.icon;
        }
      }
    }
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'setTimeoutOnChange':
            this.blinkEnable = true;
            this.disableBlink();
            this.setBlink(true);
            break;
        }
      }
    }
  }

  setBlink(timeout: boolean) {
    if (this.blinkEnable) {
      let duration: number = 5000;

      if (this.duration) {
        duration = this.duration;
      }
      let count: number = Math.floor(duration / 1000);

      this.enableBlink(timeout ? count : 0);
      if (timeout) {
        this.blinkTimeout = setTimeout(() => {
          this.blinkEnable = false;
          this.disableBlink();
        }, duration + 500);
      }
    } else {
      this.disableBlink();
    }
  }

  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
    this.state = false;
  }

}
