
import { NgModule } from '@angular/core';
import { PlaceSearchViewComponent } from './modals/place-search/place-search.component';
import { PlacesItemsViewComponent } from './modals/places-items/places-items.component';
import { PlacesPopupViewComponent } from './modals/places-popup/places-popup.component';
import { ProgressViewComponent } from './modals/progress-view/progress-view.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AppComponentsModule } from 'src/app/components/app/app-components.module';
import { GenericComponentsModule } from 'src/app/components/generic/generic-components.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GenericModalsModule } from '../generic/generic-modals.module';
import { GameItemOptionsViewComponent } from './modals/game-item-options/game-item-options.component';
import { PlaceSearchSelectorViewComponent } from './modals/place-search-selector/place-search-selector.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxStarsModule } from 'ngx-stars';
import { IonicSelectableModule } from 'ionic-selectable';
import { GameItemPlaceViewComponent } from './modals/game-item-place/game-item-place.component';
import { LocationSelectorViewComponent } from './modals/location-selector/location-selector.component';
import { UnlockContentViewComponent } from './modals/unlock-content/unlock-content.component';
import { UnlockContentCheckoutComponent } from './modals/unlock-content-checkout/unlock-content-checkout.component';
import { FAQListComponent } from './modals/faq-list/faq-list.component';

const components = [
    FAQListComponent,
    LocationSelectorViewComponent,
    PlaceSearchViewComponent,
    PlaceSearchSelectorViewComponent,
    PlacesItemsViewComponent,
    PlacesPopupViewComponent,
    GameItemOptionsViewComponent,
    GameItemPlaceViewComponent,
    ProgressViewComponent,
    UnlockContentViewComponent,
    UnlockContentCheckoutComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        IonicModule,
        PipesModule,
        DirectivesModule,
        AppComponentsModule,
        GenericComponentsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        NgxQRCodeModule,
        GenericModalsModule,
        RatingModule,
        NgxStarsModule,
        IonicSelectableModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [...components]
})
export class AppModalsModule { }
