import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'safe-html-renderer',
    templateUrl: './safe-html-renderer.component.html',
    styleUrls: ['./safe-html-renderer.component.scss']
})
export class SafeHtmlRendererComponent implements OnInit {

    @Input() unsafeHtmlContent: string;
    @Input() imageWrapper: boolean;
    @Input() innerClass: string;
    @Input() innerNgClass: string;
    @Output() innerClick: EventEmitter<boolean> = new EventEmitter();

    safeHtmlContent: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.unsafeHtmlContent);
    }

    onInnerClick() {
        this.innerClick.emit(true);
    }
}
