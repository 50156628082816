<div *ngIf="show" class="flex-card place-ads-card bg-container bg-container-fill item-border-radius"
  [ngClass]="{'bg-filter-2': !nofilter}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: (placeAux.photoUrlCached ? placeAux.photoUrlCached : placeAux.photoUrl)}">
  <!-- [@showState]="showState" -->
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">

      <ion-row>
        <ion-col size="12">
          <span class="info-text-m bold text-color-element" [ngClass]="{'text-color-overlay': nofilter}">
            {{placeName}}
          </span>
        </ion-col>
      </ion-row>
      <ion-row>

        <ion-col size="12">
          <span class="info-text-xs bold uppercase text-color-element" [ngClass]="{'text-color-overlay': nofilter}">
            {{placeType}}
          </span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4">
          <ion-button class="action-button-xs button-color-primary" [disabled]="!placeAux.mapsURL"
            (click)="openURL(placeAux.mapsURL)">
            <!-- <img [src]="appIcons.maps" height="30"> -->
            <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <span class="info-text-xs bold text-color-element" [ngClass]="{'text-color-overlay': nofilter}"
            *ngIf="placeId!=null">
            LP{{placeId}}
          </span>
        </ion-col>
        <ion-col size="4">
          <ion-button *ngIf="initialized" class="action-button-xs button-color-alternate" [disabled]="false"
            (click)="selectPlace()">
            <icon-wrapper slot="icon-only" [icon]="selectIcon" [custom]="selectIconCustom"></icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="rating > 0">
        <ion-col size="12">
          <div class="center-text" [ngClass]="{'text-color-overlay': nofilter}">
            <!-- <rating class="rating-view" [(ngModel)]="rating" [max]="5" [readonly]="true"></rating> -->
            <div class="center-flex">
              <ngx-stars [readonly]="true" [size]="2" [color]="'#fcd182'" [initialStars]="rating" [maxStars]="5">
              </ngx-stars>
            </div>
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>
</div>