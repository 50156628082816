import { ILocationContainer, IBackendLocation } from "../../classes/def/places/backend-location";
import { EPhotos } from "../../classes/def/app/icons";
import { IActivity } from "../../classes/def/core/activity";
import { IPlacePhotoContainer, IPlaceExtContainer, EPlacePhotoContainerFlag } from "../../classes/def/places/container";
import { EPlaceUnifiedSource } from "../../classes/def/places/provider";
import { IStory } from "../../classes/def/core/story";
import { ELocationFlag, ELocationSpecialId, IAppLocation } from "../../classes/def/places/app-location";
import { DeepCopy } from "../../classes/general/deep-copy";
import { ILeplaceReg } from "../../classes/def/places/google";
import { LatLng } from "@ionic-native/google-maps/ngx";
import { ISelectPhotoOptions, IGetPhotoOptions, ILocationPhotoResult } from "./location-utils-def";
import { LocationUtilsGoogle } from "./location-utils-google";
import { LocationUtilsHelpers } from "./location-utils-helpers";
import { EGameContext } from "../../classes/def/core/game";
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { LocationDispUtils } from "./location-disp-utils";


export class LocationUtils {

    /**
     * use this after retrieving places from server
     * updates the object
     * @param loc 
     */
    static checkExistingPhotoUrlInit(loc: IPlaceExtContainer): boolean {
        if (loc.photoUrl && !loc.isGenericPhotoLoaded) {
            // check if photo url exists AND it's not a generic link
            loc.existingPhoto = true;
        } else {
            loc.existingPhoto = false;
        }
        console.log("check existing photo url init: ", loc.googleId, loc.existingPhoto, loc);
        return loc.existingPhoto;
    }

    /**
     * use this when checking for photo to show in place card
     * check photo url already existing in db
     * @param place 
     */
    static checkPhotoLoadingRequired(place: IPlaceExtContainer): boolean {
        let required: boolean = true;
        // photo was already defined in the db
        if (place.existingPhoto) {
            required = false;
        }
        return required;
    }

    static createNewAppLocationFromLeplaceReg(coords: LatLng, lpReg: ILeplaceReg, activity: IActivity): IAppLocation {
        let loc: ILocationContainer = LocationUtilsHelpers.createNewLocationContainer();
        let place: IPlaceExtContainer = lpReg.place;

        loc.merged.name = place.name;
        loc.merged.type = place.type;
        loc.merged.photoUrl = place.photoUrl ? place.photoUrl : EPhotos.visit;
        loc.merged.photoUrlSmall = place.photoUrlSmall ? place.photoUrlSmall : EPhotos.visit;
        loc.merged.lat = place.lat;
        loc.merged.lng = place.lng;
        loc.merged.activity = activity;
        loc.merged.googleId = lpReg.place.googleId;
        loc.merged.providerCode = lpReg.place.providerCode;

        let p: IPlacePhotoContainer = {
            photoUrl: loc.merged.photoUrl,
            flag: EPlacePhotoContainerFlag.loaded
        };

        loc.dispPhoto = p;
        loc.foundLocation = lpReg;

        let appLocation: IAppLocation = {
            loc: loc,
            location: coords,
            dispDone: false,
            flag: ELocationFlag.RANDOM
        };
        return appLocation;
    }

    /**
     * create location container from db location
     * @param bloc 
     */
    static createLocationFromDB(bloc: IBackendLocation) {
        let loc: ILocationContainer = LocationUtilsHelpers.createNewLocationContainer();
        loc.db = bloc;
        LocationUtilsHelpers.mergeDBData(loc);
        return loc;
    }

    static formatTreasureLocationDB(rloc: ILeplaceTreasure) {
        // convert to standard representation
        let u: any = rloc.location as any;
        let uloc: any = rloc.location.location as any;
        let bloc: IBackendLocation = uloc as IBackendLocation;
        bloc.activity = rloc.activity;
        let loc: ILocationContainer = LocationUtils.createLocationFromDB(bloc);
        // replace location container with formatted content
        rloc.location = {
            location: loc,
            registered: u.registered
        };
    }

    /**
     * set place details retrieved from google
     * that will be used to save the actual location on the server
     * used in story mode for markers
     * then the data is used to save the location on the server
     */
    static updateAppLocation(location: IAppLocation, place: ILeplaceReg, overwrite: boolean, options: IGetPhotoOptions) {
        let result: IPlaceExtContainer = place.place;
        let p: ILocationPhotoResult = LocationUtils.getTempPhotoUrl(result, options);

        let uloc: IAppLocation;

        if (overwrite) {
            uloc = location;
        } else {
            uloc = DeepCopy.deepcopy(location);
        }

        // console.log(photoResult);

        uloc.loc.ext = place.place;
        uloc.loc = LocationUtilsHelpers.updateExtPhoto(uloc.loc, p);
        uloc.loc = LocationUtilsHelpers.mergeExtData(uloc.loc);
        // console.log(uloc.loc);

        uloc.location = new LatLng(result.lat, result.lng);

        uloc.loc.merged.registered = place.registeredBusiness;
        if (place.registeredBusiness) {
            uloc.loc.merged.locationId = place.registeredId;
        }

        console.log("update app location details: ", DeepCopy.deepcopy(uloc));
        return uloc;
    }

    /**
     * get location photo
     * if photoUrl not found then use photoUrl from activity
     * if activity photoUrl not found then use photoUrl from local resources
     * fallback to default photo
     * @param location 
     * @param hidden 
     */
    static selectPlaceDispPhoto(location: ILocationContainer, treasure: ILeplaceTreasure, options: ISelectPhotoOptions) {
        let photoTypes = Object.keys(EPhotos);

        let p: IPlacePhotoContainer = {
            photoUrl: location.merged.photoUrl,
            flag: EPlacePhotoContainerFlag.empty
        };

        // console.log(location);
        let tempUrl: string = null;

        // use large url only
        tempUrl = location.merged.photoUrl;
        p.flag = EPlacePhotoContainerFlag.loaded;

        if (options.hidden === null) {
            options.hidden = LocationDispUtils.checkHiddenPlace(location, false);
        }

        if (!tempUrl || options.hidden) {
            // photo url not available or hidden
            if (treasure && treasure.storyLocation) {
                // check treasure story location content
                let tsl = treasure.storyLocation;
                tempUrl = tsl.photoUrl;
                p.photoUrl = tsl.photoUrl;
            } else if (location.db && location.db.customPhotoUrl) {
                // use photo url from story location (if available)
                tempUrl = location.db.customPhotoUrl;
                p.photoUrl = tempUrl;
            } else if (!(location.merged.activity && location.merged.activity.photoUrl)) {
                // fallback to fallback (activity/photo url is not defined)
                let activityName: string = location.merged.activity ? location.merged.activity.name : null;
                if (activityName != null && (photoTypes.indexOf(activityName) !== -1)) {
                    // use activity url (from db)
                    tempUrl = EPhotos[activityName];
                    p.photoUrl = tempUrl;
                } else {
                    tempUrl = EPhotos.default;
                    p.photoUrl = tempUrl;
                }
            } else {
                // default fallback
                tempUrl = location.merged.activity.photoUrl;
                p.photoUrl = tempUrl;
            }
        } else {
            // photo url available
            p.photoUrl = tempUrl;
        }

        // if already loaded, do not reload (maybe large url is already loaded into view)
        if (location.dispPhoto != null) {
            location.dispPhoto.photoUrl = p.photoUrl;
        } else {
            location.dispPhoto = Object.assign({}, p);
        }

        console.log("select disp photo: ", options, location, p);
        return tempUrl;
    }

    /**
     * create default activity container if not initialized (error prevent)
     * @param loc 
     */
    static checkFormatActivity(loc: ILocationContainer): ILocationContainer {
        if (!loc.merged.activity) {
            loc.merged.activity = {
                name: "",
                description: "",
                scan: 0,
                params: {},
                photo: 0,
                icon: null,
                photoUrl: null,
                hiddenPlace: 0,
                photoValidate: 0,
                code: null,
                similarCode: null,
                title: "",
                similar: null,
                paramsList: [],
                customParams: [],
                hasCustomParams: 0,
                gameContextCode: EGameContext.all
            };
        }
        return loc;
    }

    /**
     * sync db data
     * create loc container from location (backend location) data
     * @param story 
     */
    static formatStoryLocMulti(story: IStory): IStory {
        console.log("format story ", story);
        story.locs = LocationUtils.formatLocMulti(story.locations, null);
        return story;
    }

    /**
    * sync db data
    * create loc container from location (backend location) data
    * @param story 
    */
    static formatLocMulti(blocs: IBackendLocation[], tags: string[]): ILocationContainer[] {
        let locs: ILocationContainer[] = [];
        for (let i = 0; i < blocs.length; i++) {
            locs.push(LocationUtilsHelpers.createNewLocationContainer());
            LocationUtils.formatStoryLoc(locs[i], blocs[i]);
            locs[i].index = i;
            locs[i].actualIndex = i;
            if (tags != null && tags[i] != null) {
                locs[i].customTag = tags[i];
            }
        }
        return locs;
    }

    /**
     * load the data from the db, provided as backend location
     * create/init container
     * @param loc 
     * @param bloc 
     */
    static formatStoryLoc(loc: ILocationContainer, bloc: IBackendLocation) {
        if (bloc == null) {
            // create uncharted location placeholder
            bloc = LocationUtilsHelpers.createNewBlocContainer();
            bloc.id = ELocationSpecialId.uncharted;
        }
        loc.db = bloc;
        LocationUtilsHelpers.mergeDBData(loc);
    }

    /**
     * return story locations as app locations (wrapper, to be used in gmap)
     * @param story 
     */
    static getAppLocationsFromStory(story: IStory): IAppLocation[] {
        let appLocations: IAppLocation[] = [];
        for (let i = 0; i < story.locs.length; i++) {
            let appLocation: IAppLocation = LocationUtils.getAppLocationFromLocationContainer(story.locs[i]);
            appLocations.push(appLocation);
        }
        return appLocations;
    }

    static getAppLocationFromLocationContainer(loc: ILocationContainer) {
        let appLocation: IAppLocation = {
            loc: DeepCopy.deepcopy(loc),
            // ELocationFlag.RANDOM
            flag: loc.merged.flag,
            dispDone: loc.merged.done === 1
        };
        appLocation.flag = LocationUtils.getLocationType(loc);
        return appLocation;
    }

    /**
     * clear the saved location details
     * e.g. exact name, google id, photo url
     * @param loc 
     */
    static clearSaved(loc: ILocationContainer): ILocationContainer {
        if (LocationUtils.getLocationType(loc) !== ELocationFlag.FIXED) {
            loc.merged.name = null;
            loc.merged.googleId = null;
            LocationUtils.clearPlacePhoto(loc);
        }
        return loc;
    }

    /**
     * clear external provider photo data
     * @param loc 
     */
    static clearPlacePhoto(loc: ILocationContainer) {
        let p: IPlacePhotoContainer = LocationUtilsHelpers.createNewPhotoContainer();
        if (loc.dispPhoto) {
            loc.dispPhoto = Object.assign({}, p);
        }
        loc.ext.photoUrl = null;
        loc.ext.photoUrlSmall = null;

        // loc.photoUrl contains the url from the db, do not remove it
        return loc;
    }

    /**
     * get location type
     * may be redundant
     * @param loc 
     */
    static getLocationType(loc: ILocationContainer) {
        let type: number = null;
        if (!loc.merged.flag) {
            type = ELocationFlag.RANDOM;
            if (!loc.merged.type) {
                // undefined type
                // fixed location
                type = ELocationFlag.FIXED;
            } else {
                // check if saved location either in local storage or from backend
                if (loc.merged.googleId) {
                    // has googleId
                    type = ELocationFlag.SAVED;
                } else if (loc.extLoaded) {
                    // has saved details
                    type = ELocationFlag.SAVED_LOCAL;
                } else {
                    // random location
                    type = ELocationFlag.RANDOM;
                }
            }
            return type;
        } else {
            return loc.merged.flag;
        }

    }


    /**
     * check for existing url
     * request url from google if not existing
     * update place details (cache photo url)
     */
    static getTempPhotoUrl(place: IPlaceExtContainer, options: IGetPhotoOptions) {

        let res: ILocationPhotoResult = {
            photoUrl: place.photoUrl,
            photoLoadedFinal: false,
            photoFound: false
        };

        let providerCode: number = place.providerCode;

        if (!providerCode) {
            providerCode = EPlaceUnifiedSource.google;
        }

        console.log("get temp photo url: ", providerCode);
        console.log(place);

        if (!res.photoUrl || place.isGenericPhotoLoaded) {
            if (place.aux) {
                switch (providerCode) {
                    case EPlaceUnifiedSource.google:
                        res = LocationUtilsGoogle.getPlacePhotoCore(place.aux.photos, options);
                        break;
                    case EPlaceUnifiedSource.here:
                        break;
                    default:
                        break;
                }

            }
        }

        place.photoUrl = res.photoUrl;

        console.log("get unified location photo: ", res);
        return res;
    }


    /**
     * @param url 
     */
    static downloadPhoto(url: string) {
        let promise = new Promise((resolve, reject) => {
            let baseImage = new Image();
            baseImage.setAttribute('crossOrigin', 'anonymous');
            baseImage.src = url;

            baseImage.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = baseImage.width;
                canvas.height = baseImage.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(baseImage, 0, 0);
                let dataURL = canvas.toDataURL("image/jpeg");
                resolve(dataURL);
            };
            baseImage.onerror = (err) => {
                reject(err);
            };
        });
        return promise;
    }
}
