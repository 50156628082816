import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICustomFilterElement, ICustomFilterOption, ICustomFiltersContainer } from 'src/app/classes/def/app/filters';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { ESettingsType } from 'src/app/classes/def/app/settings';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ParamHandler } from 'src/app/classes/general/params';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { PhotosService } from 'src/app/services/media/photos';
import { ObjectUtils } from 'src/app/services/utils/object-utils';

@Component({
  selector: 'modal-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormViewComponent implements OnInit {
  title: string = "";
  rightBtnIcon: string = EAppIconsStandard.close;
  types = ESettingsType;

  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  filters: ICustomFiltersContainer = {
    title: "",
    changed: false,
    filters: []
  };
  filtersOrig: ICustomFilterElement[] = [];

  np: INavParams = null;

  update: boolean = false;
  create: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public photoProvider: PhotosService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ICustomFiltersContainer = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.filters = params;
      this.title = params.title;
      this.create = params.create;

      if (!this.filters) {
        return;
      }
      this.filtersOrig = DeepCopy.deepcopy(this.filters.filters);
    }
  }

  ngOnDestroy() {

  }

  return(apply: boolean) {
    this.dismiss(apply);
  }


  checkUpdatesOnClick() {
    setTimeout(() => {
      let update: boolean = this.checkFiltersChanged();
      console.log("check updates: ", update);
      this.update = update;
      if (update) {
        this.rightBtnIcon = EAppIconsStandard.check;
      } else {
        this.rightBtnIcon = EAppIconsStandard.close;
      }
    }, 1);
  }

  checkFiltersChanged() {
    let changed: boolean = false;
    for (let i = 0; i < this.filters.filters.length; i++) {
      let filter: ICustomFilterElement = this.filters.filters[i];
      let filterOrig: ICustomFilterElement = this.filtersOrig[i];
      if (filter.selected !== filterOrig.selected) {
        changed = true;
        break;
      }
      if (filter.value !== filterOrig.value) {
        changed = true;
        break;
      }
      if (filter.options != null) {
        for (let j = 0; j < filter.options.length; j++) {
          if (filter.options[j].checked !== filterOrig.options[j].checked) {
            changed = true;
            break;
          }
          if (filter.options[j].options != null) {
            if (!ObjectUtils.objectTester(filter.options[j].options, filterOrig.options[j].options)) {
              changed = true;
              break;
            }
          }
        }
      }
      if (changed) {
        break;
      }
    }
    return changed;
  }

  dismiss(apply: boolean) {
    if (this.checkFiltersChanged() && apply) {
      this.filters.changed = true;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(this.filters).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  onChange(filter: ICustomFilterElement, set: ICustomFilterOption) {
    console.log("onchange: ", filter, set);
    this.checkUpdatesOnClick();
  }

  onInputChange(filter: ICustomFilterElement) {
    console.log("onchange: ", filter);
    this.checkUpdatesOnClick();
  }

  radioSelect(filter: ICustomFilterElement, event: any) {
    console.log("radioselect: ", filter, event);
    if (event && event.detail) {
      let value: number = event.detail.value;
      filter.selected = value;
    }
    this.checkUpdatesOnClick();
  }

  uploadPhotoFromGallery(filter: ICustomFilterElement, _event) {
    // returns actual photo url from cloud storage
    this.photoProvider.uploadPhotoFromGalleryWizard((data) => {
      filter.value = data;
      return Promise.resolve(data);
    }, false).then((photoRes: IPhotoResult) => {
      if (photoRes != null) {
        this.checkUpdatesOnClick();
      }
    }).catch((err: Error) => {
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }
}
