import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { IActivityVideoGuideSpecs } from 'src/app/classes/def/nav-params/activity-details';


export interface IYouTubeViewFrameNavParams {
  description?: string;
  title: string;
  videoId?: string;
  videoUrl?: string;
  single: boolean;
  videoList?: IActivityVideoGuideSpecs[];
}

@Component({
  selector: 'modal-youtube-view-frame',
  templateUrl: './youtube-view-frame.component.html',
  styleUrls: ['./youtube-view-frame.component.scss'],
})
export class YouTubeViewFrameComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionLines: string[];
  title: string = "";
  videoId: string = "";
  videoUrl: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();
  withButtons: boolean = false;
  params: IYouTubeViewFrameNavParams = null;
  np: INavParams = null;
  singleVideo: boolean = true;
  videoList: IActivityVideoGuideSpecs[] = [];

  constructor(
    public modalCtrl: ModalController,
    public mediaUtils: MediaUtilsService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IYouTubeViewFrameNavParams = np.params;
      this.params = params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
      this.description = params.description;
      this.title = params.title;
      this.videoId = params.videoId;
      this.videoUrl = params.videoUrl;
      this.singleVideo = params.single;
      this.videoList = params.videoList;
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
