import { IFeatureDef } from "../app/app";

export interface INewsfeedTopic {
      id: number;
      code: number;
      name: string;
      description: string;
      show?: boolean;
}

export enum ENewsfeedCategory {
      global = 0,
      services = 3
}


export interface INewsfeedEntry {
      id: number;
      code: number;
      name: string;
      /**
       * push notifications original message
       */
      message: string;

      newsfeedCategoryCode?: number;
      /**
       * format message that corresponds to the push notification
       */
      description: string;
      timestamp: string;
      timestampValue?: number;
      /**
       * timestamp display (or hide) for entry list
       */
      timestampDisp?: string;
      /**
       * timestamp for detail view
       */
      timestampDetail?: string;
      /**
       * expand/minimize entry
       */
      open?: boolean;
      /**
       * general news (for everybody)
       */
      isGeneral?: boolean;
      /**
      * the associated feature (may be used as a shortcut in the app)
      */
      featureCode: number;

      featurePrice?: IFeatureDef;
      /**
       * marks the entry as new (unseen)
       */
      newEntry: boolean;
      icon: string;
      customIcon: number;
      photoUrl: string;
      showTimestamp: number;
      isAvailable?: boolean;
}

export interface INewsfeedInbox {
      count: number;
      message: string;
  }
