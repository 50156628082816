
export interface IGetHeaders {
    headers: any,
    params: any
}

export class AuthUtils {

    /**
    * get login headers for authorized requests
    */
    static getLoginHeaders(token: string): IGetHeaders {
        let headers = AuthUtils.getHeaders();
        headers.headers = {
            Authorization: "Bearer " + token
        };
        return headers;
    }

    /**
     * get headers for GET request
     */
    static getHeaders(): IGetHeaders {
        let headers = {
            headers: {

            },
            params: {}
        };
        return headers;
    }

}