import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { IAppFlags, ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Platform } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IUserFlagDB } from 'src/app/classes/def/app/settings';
import { AppConstants } from 'src/app/classes/app/constants';
import { INavParams, IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UserDataService } from 'src/app/services/data/user';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { GenericQueueService } from 'src/app/services/general/generic-queue';
import { StorageOpsService } from 'src/app/services/general/data/storage-ops';
import { ResourceHandlerDataService } from 'src/app/services/data/resource-handler';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ERouteDef } from 'src/app/app-utils';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { PhotosService } from 'src/app/services/media/photos';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { AppVersionService } from 'src/app/services/general/app-version';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { IViewPhotoParams } from 'src/app/classes/def/nav-params/photo-view';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { TestingManagerService } from 'src/app/services/general/testing-manager';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit, OnDestroy {
  test: boolean = false;
  logoSrc: string = EPhotos.logo;
  // logoSrc: string = EPhotos.bike;
  backgroundSrc: string = EPhotos.home;
  periodic = null;
  backgroundLoaded: boolean = false;

  loading: boolean = false;

  flags: IAppFlags = {
    appRate: {
      flag: ELocalAppDataKeys.appRate,
      value: true
    },
    openCounter: {
      flag: ELocalAppDataKeys.openCounter,
      value: 0
    },
    firstBootDone: {
      flag: ELocalAppDataKeys.firstBootDone,
      value: false
    },
    tutorialSeen: {
      flag: ELocalAppDataKeys.tutorialSeen,
      value: false
    },
    codePushUpdated: {
      flag: ELocalAppDataKeys.codePushUpdated,
      value: false
    }
  };

  version: string = "1.0.0";

  platform: IPlatformFlags = {} as IPlatformFlags;

  theme: string = "theme-aubergine theme-aubergine-bg";

  subscription = {
    liveUpdateStatus: null,
    liveUpdateProgress: null
  };

  inboxCount: number = 0;

  blink = {
    inbox: false,
    account: false,
    map: false,
    tester: false
  };

  np: INavParams = null;
  vs: IViewSpecs;

  show = {
    inbox: false,
    account: true,
    tester: false
  };

  isWeb: boolean = false;

  constructor(
    public settingsProvider: SettingsManagerService,
    public resourcesProvider: ResourcesCoreDataService,
    public router: Router,
    public plt: Platform,
    public uiext: UiExtensionService,
    public locationManager: LocationManagerService,
    public backButton: BackButtonService,
    public userDataProvider: UserDataService,
    public analytics: AnalyticsService,
    public popupFeatures: PopupFeaturesService,
    public q: GenericQueueService,
    public storageOps: StorageOpsService,
    public resourceHandler: ResourceHandlerDataService,
    public tutorials: TutorialsService,
    public photoProvider: PhotosService,
    public photoViewer: PhotoViewService,
    public nps: NavParamsService,
    public resources: ResourcesCoreDataService,
    public appVersionService: AppVersionService,
    public inventory: InventoryDataService,
    public testingManager: TestingManagerService
  ) {

  }

  /**
   * the app is up to date
   * proceed with the initialization sequence with regards to user flags
   */
  afterUpdateCheck() {
    // the app is up to date, proceed with the other checks
    this.checkInitSequence().then(() => {
      console.log("HOME INIT: init sequence complete");
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * get db flags (used to sync across user devices)
   * WARNING: overrides local settings
   */
  getServerFlags() {
    this.userDataProvider.getAllFlagsServer(false).then((flags: IUserFlagDB[]) => {
      if (flags) {
        let synced: number = 0;
        flags.forEach(flag => {
          synced += this.settingsProvider.updateSettingsElement(flag.code, flag.value) ? 1 : 0;
        });
        // override local settings
        this.settingsProvider.updateLocalSettingsNoAction();
        console.log("synced flags from server: " + synced + "/" + flags.length);
      }
    });
  }


  /**
   * check flags for app rate, signup agreement, etc
   */
  checkInitSequence() {
    let promise = new Promise(async (resolve) => {
      console.log("HOME INIT: check init sequence");
      // check storage flags for app rate flags (already loaded before)

      // check for agreement signed
      let promiseAgreement = () => {
        return new Promise((resolve) => {

          if (GeneralCache.agreementPending) {
            GeneralCache.agreementPending = false;

            this.tutorials.showSignupTerms(true).then((accept: boolean) => {
              if (!accept) {
                this.uiext.showAlert(Messages.msg.termsNotAgreed.after.msg, Messages.msg.termsNotAgreed.after.sub, 2, null).then(() => {
                  resolve(true);
                }).catch(() => {
                  resolve(false);
                });
              } else {
                resolve(true);
              }
            }).catch((err: Error) => {
              console.error(err);
              resolve(false);
            });
          } else {
            resolve(true);
          }
        });
      };

      // app updated via code push
      let promiseInfoUpdated = () => {
        return new Promise(async (resolve) => {
          if (this.flags.codePushUpdated.value) {
            this.flags.codePushUpdated.value = false;
            this.storageOps.setStorageFlagNoAction(this.flags.codePushUpdated); // reset flag
            await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.codePushUpdated.after.msg, Messages.msg.codePushUpdated.after.sub, 1, null, true), true);
          }
          resolve(true);
        });
      };

      // check signup tutorial seen
      let promiseSignupTutorial = () => {
        return new Promise((resolve) => {
          // first check in local storage if the tutorial was not seen
          if (!this.flags.tutorialSeen.value) {
            // if not seen, then also check on the server
            this.resourcesProvider.getTutorialSeen().then((res: number) => {
              // if not seen also on the server, THEN show the tutorial page
              if (!res) {
                this.tutorials.showSignupTutorial().then(() => {
                  // update local storage flags
                  this.setTutorialSeenLocal();
                  // update tutorial seen on the server now
                  this.resourcesProvider.setTutorialSeen().then(() => {
                    // tutorial seen just now
                    resolve(true);
                  }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                  });
                }).catch((err: Error) => {
                  console.error(err);
                  this.setTutorialSeenLocal();
                  resolve(false);
                });
              } else {
                // was seen on the server but not local
                // this can happen when the user logs in on another device or clears app cache
                // in this case do not show the tutorial again
                this.setTutorialSeenLocal();
                resolve(false);
              }
            }).catch((err: Error) => {
              console.error(err);
              this.setTutorialSeenLocal();
              resolve(false);
            });
          } else {
            // tutorial seen
            resolve(true);
          }
        });
      };

      await promiseInfoUpdated();
      await promiseAgreement();
      await promiseSignupTutorial();

      resolve(true);
    });
    return promise;
  }


  /**
   * shortcut to check if user wants to use the existing photo from ext provider
   */
  checkUseExternalPhoto() {
    console.log("check use external photo");
    this.uiext.showAlert(Messages.msg.addProfilePhotoExt.after.msg, Messages.msg.addProfilePhotoExt.after.sub, 2, ["nope", "sure"], true).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          break;
        case EAlertButtonCodes.cancel:
          // set profile photo null
          this.photoProvider.removeProfilePicture().then(() => {
            console.log("profile photo removed");
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "home");
          })
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  viewPhotoCore(callback: () => any) {
    console.log("view and select photo");
    this.userDataProvider.getUserPublicProfile(true).then((data: IUserPublicData) => {
      if (data && data.photoUrl) {
        let params: IViewPhotoParams = {
          share: false,
          customModal: true,
          isDataUrl: false,
          changePhotoCallback: callback
        };
        this.photoViewer.viewPhoto(data.photoUrl, "Profile Photo", params);
      } else {
        // this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
        callback();
      }
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
    });
  }

  /**
   * set local storage flag (tutorial seen)
   */
  setTutorialSeenLocal() {
    this.flags.tutorialSeen.value = true;
    this.storageOps.setStorageFlagNoAction(this.flags.tutorialSeen);
  }

  /**
   * init location cache
   */
  initLocation() {
    this.locationManager.getCurrentLocationWrapper(true, false, false).then((location) => {
      console.log("init location: ", location);
    }).catch((err: Error) => {
      console.error(err);
      // this.uiext.showAlertNoAction("Location not found", "The app might not work as expected");
    });
  }

  /**
  * check app flags from local storage or set defaults if not initialized
  */
  loadAppFlagsResolve(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      console.log("HOME INIT: check flags");
      this.storageOps.loadMultipleStorageFlags(this.flags).then((newFlags: IAppFlags) => {
        if (newFlags) {
          console.log("new flags: ", newFlags);
          let newFlagKeys = Object.keys(newFlags);
          for (let i = 0; i < newFlagKeys.length; i++) {
            if (newFlags[newFlagKeys[i]].value) {
              this.flags[newFlagKeys[i]].value = newFlags[newFlagKeys[i]].value;
            }
          }
        }
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "home");
        resolve(false);
      });
    });
    return promise;
  }


  ngOnInit() {
    console.log('ion view will enter ' + new Date().getTime());

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.home, null, this.np);

      console.log("nav params: ", npInfo.params);

      this.test = true;
      this.plt.ready().then(async () => {
        this.analytics.trackView("home");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
          if (loaded) {
            this.platform = SettingsManagerService.settings.platformFlags;
            this.isWeb = this.platform.WEB;
          }
        }, (err: Error) => {
          console.error(err);
        });

        this.settingsProvider.getSettingsLoaded(false).then((res) => {
          if (res) {
            this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          }
        }).catch((err: Error) => {
          console.error(err);
        });

        if (GeneralCache.canBeTester) {
          this.show.tester = true;
        }

        let checkTbootReinit = () => {
          if (AppConstants.gameConfig.resumeAfterDays != null) {
            if ((new Date().getTime() - GeneralCache.tboot) > AppConstants.gameConfig.resumeAfterDays * 24 * 3600 * 1000) {
              return true;
            }
          }
          return false;
        };

        // initialization stuff that should be done AFTER the user is logged in
        // this will be called after the check-login that is called in app.component
        // so the tester flag should be initialized by now (on web, should load the root page for this to happen)
        if (!GeneralCache.appLoaded || checkTbootReinit()) {
          GeneralCache.appLoaded = true;
          await this.uiext.showLoadingV2Queue("Initializing..");
          // this.blink.map = true;
          this.uiext.resetOverlay();
          console.log("home init");
          await this.loadAppFlagsResolve();
          console.log("resources loaded cache: ", GeneralCache.resourceCache);
          this.settingsProvider.getSettingsLoaded(false).then((res) => {
            if (res) {
              // load server flags AFTER the local settings are loaded
              this.getServerFlags();
            }
          }).catch((err: Error) => {
            console.error(err);
          });

          // init chain
          await this.uiext.dismissLoadingV2();
          this.afterUpdateCheck();
        }

        this.backButton.replace(() => {
          if (!this.uiext.isAlert()) {
            this.uiext.showAlert(Messages.msg.exit.before.msg, Messages.msg.exit.before.sub, 2, null, false).then((res: number) => {
              if (res === EAlertButtonCodes.ok) {
                this.backButton.exitApp();
              }
            }).catch((err: Error) => {
              console.error(err);
            });
          }
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  ngOnDestroy() {
    console.log("ion view will leave " + new Date().getTime());
  }

  menuToggle() {

  }
}

