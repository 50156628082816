import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { IRegisterBusinessRequest, IRegisterBusinessMultiRequest } from '../../classes/def/requests/register-business';
import { IUpdateBusinessRequest } from '../../classes/def/requests/update-business';
import { GenericDataService } from '../general/data/generic';
import { IBusinessDataExt } from 'src/app/classes/def/business/business';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IDBModelBusinessDetail } from 'src/app/classes/def/business/account';


@Injectable({
    providedIn: 'root'
})
export class BusinessDataService {
    serverUrl: string;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService
    ) {
        console.log("business data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {

    }

    registerProvisionalAccount(details: IDBModelBusinessDetail) {
        return this.generic.genericPostStandardWData("/business/account/register-provisional-account", {
            details: details
        });
    }

    updateProvisionalAccount(details: IDBModelBusinessDetail) {
        return this.generic.genericPostStandardWData("/business/account/update-provisional-account", {
            details: details
        });
    }

    getRegisteredBusinessDetails() {
        return this.generic.genericPostStandardWData("/business/account/get-registered-business-details", {

        });
    }

    listOwnedSupervisedPlaces() {
        return this.generic.genericPostStandardWData("/business/account/list-owned-supervised-places", {
            passwordHash: GeneralCache.passwordHash
        });
    }

    findOwnedSupervisedPlaces(searchName: string) {
        return this.generic.genericPostStandardWData("/business/account/find-owned-supervised-places", {
            searchName: searchName,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getAllPlaces(mode: number, page: number) {
        return this.generic.genericPostStandardWData("/business/account/get-all-places", {
            mode: mode,
            page: page,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getAllPlacesDetails(mode: number | number[], orderBy: number, sort: number, page: number) {
        return this.generic.genericPostStandardWData("/business/account/get-all-places-details", {
            mode: mode,
            page: page,
            orderBy: orderBy,
            sort: sort,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getNearbyPlacesDetails(mode: number, lat: number, lng: number, radius: number) {
        return this.generic.genericPostStandardWData("/business/account/get-nearby-places-details", {
            mode: mode,
            lat: lat,
            lng: lng,
            radius: radius,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getPlaceOverview(locationId: number, mode: number, startDate: string, endDate: string) {
        return this.generic.genericPostStandardWData("/business/account/get-place-overview", {
            locationId: locationId,
            mode: mode,
            startDate: startDate,
            endDate: endDate,
            passwordHash: GeneralCache.passwordHash
        });
    }

    checkAccessPrivilegeType(modes: number[]) {
        return this.generic.genericPostStandardWData("/business/account/check-access-privilege-type", {
            modes: modes,
            passwordHash: GeneralCache.passwordHash
        });
    }


    getStoryOverview(storyId: number, startDate: string, endDate: string) {
        return this.generic.genericPostStandardWData("/business/stories/get-story-overview", {
            storyId: storyId,
            startDate: startDate,
            endDate: endDate,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getStoryOverviewStats(storyId: number, mode: number, startDate: string, endDate: string) {
        return this.generic.genericPostStandardWData("/business/stories/get-story-overview-stats", {
            storyId: storyId,
            mode: mode,
            startDate: startDate,
            endDate: endDate,
            passwordHash: GeneralCache.passwordHash
        });
    }

    resetUnlockCode(storyId: number, mode: number, code: string) {
        return this.generic.genericPostStandardWData("/business/stories/reset-unlock-code", {
            storyId: storyId,
            mode: mode,
            code: code
        });
    }

    getPartnerUnlockCodes(storyId: number) {
        return this.generic.genericPostStandardWData("/business/stories/get-partner-unlock-codes", {
            storyId: storyId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    generatePartnerUnlockCodes(storyId: number, amount: number, spec: string[]) {
        return this.generic.genericPostStandardWData("/business/stories/generate-partner-unlock-codes", {
            storyId: storyId,
            amount: amount,
            spec: spec,
            passwordHash: GeneralCache.passwordHash
        });
    }

    removePartnerUnlockCodes(storyId: number) {
        return this.generic.genericPostStandardWData("/business/stories/remove-partner-unlock-codes", {
            storyId: storyId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    requestEmbedQR(storyId: number, mode: number) {
        return this.generic.genericGetStandard("/stories/integration/request-embed-unlock-code", {
            storyId: storyId,
            mode: mode
        });
    }

    getStoryCustomParamsOverview(storyId: number) {
        return this.generic.genericPostStandardWData("/stories/get-story-custom-params-overview", {
            storyId: storyId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    trackScanQR(storyId: number, token: string) {
        return this.generic.genericPostNoAuthWData("/stories/track-scan-qr", {
            storyId: storyId,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }

    trackScanVar(storyId: number, token: string) {
        return this.generic.genericPostNoAuthWData("/stories/track-scan-var", {
            storyId: storyId,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }

    trackView(storyId: number, token: string) {
        return this.generic.genericPostNoAuthWData("/stories/track-view", {
            storyId: storyId,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getStoryTreasuresOverview(storyId: number) {
        return this.generic.genericPostStandardWData("/stories/custom-map/get-treasures-overview", {
            storyId: storyId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getClientsStats(storyId: number, mode: number, startDate: string, endDate: string) {
        return this.generic.genericPostStandardWData("/business/stories/get-clients-stats", {
            storyId: storyId,
            mode: mode,
            startDate: startDate,
            endDate: endDate,
            passwordHash: GeneralCache.passwordHash
        });
    }

    /**
     * get business account details
     */
    getAccountDetails() {
        return this.generic.genericPostStandardWData("/business/account/get-account-details", {
            passwordHash: GeneralCache.passwordHash
        });
    }

    registerBusinessMulti(data: IRegisterBusinessMultiRequest): Promise<IBusinessDataExt[]> {
        data.passwordHash = GeneralCache.passwordHash;
        return this.generic.genericPostStandardWData("/business/locations/register-multi", data);
    }

    registerBusiness(data: IRegisterBusinessRequest) {
        data.passwordHash = GeneralCache.passwordHash;
        return this.generic.genericPostStandard("/business/locations/register", data);
    }

    updateBusinessDetails(data: IUpdateBusinessRequest) {
        data.passwordHash = GeneralCache.passwordHash;
        return this.generic.genericPostStandard("/business/locations/update", data);
    }

    checkRegistered(gid: string) {
        return this.generic.genericPostStandardWData("/business/locations/check-registered", {
            googleId: gid,
            passwordHash: GeneralCache.passwordHash
        });
    }

    checkRegisteredMultiple(gids: string[]) {
        return this.generic.genericPostStandardWData("/business/locations/check-registered-multi", {
            googleId: gids,
            passwordHash: GeneralCache.passwordHash
        });
    }


    getGlobalToken(placeId: number, mode: number) {
        return this.generic.genericPostStandardWData("/business/locations/get-global-token", {
            placeId: placeId,
            mode: mode,
            passwordHash: GeneralCache.passwordHash
        });
    }

    setGlobalToken(placeId: number, mode: number, token: string) {
        return this.generic.genericPostStandard("/business/locations/set-global-token", {
            placeId: placeId,
            mode: mode,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }

    requestToken(placeId: number, mode: number) {
        return this.generic.genericPostStandard("/business/locations/get-token", {
            placeId: placeId,
            mode: mode,
            passwordHash: GeneralCache.passwordHash
        });
    }

    registerEmployee(placeId: number, token: string) {
        return this.generic.genericPostStandard("/business/locations/register-employee", {
            placeId: placeId,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }

    registerClient(placeId: number, token: string) {
        return this.generic.genericPostStandard("/business/locations/register-client", {
            placeId: placeId,
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }


    subscribeToPrivateStoryProvider(token: string) {
        return this.generic.genericPostStandard("/business/providers/subscribe-to-private-story-provider", {
            token: token,
            passwordHash: GeneralCache.passwordHash
        });
    }


    unsubscribeFromPrivateStoryProvider(providerId: number) {
        return this.generic.genericPostStandard("/business/locations/remove-from-private-story-provider", {
            providerId: providerId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    /**
     * TODO: implement
     * @param _storyId 
     */
    removePrivateStory(_storyId: number) {
        // return this.generic.genericPostStandard("/user-place/remove-private-story", {
        //     storyId: storyId
        // });
        return Promise.resolve(true);
    }

    removePlace(placeId: number) {
        return this.generic.genericPostStandard("/business/locations/remove", {
            placeId: placeId,
            passwordHash: GeneralCache.passwordHash
        });
    }


    removeEmployee(placeId: number) {
        return this.generic.genericPostStandard("/business/locations/remove-employee", {
            placeId: placeId,
            passwordHash: GeneralCache.passwordHash
        });
    }

    getBusinessItems(locationId: number) {
        return this.generic.genericPostStandardWData("/business/locations/get-business-items", {
            locationId: locationId,
            passwordHash: GeneralCache.passwordHash
        });
    }
}
