import wordwrap from 'wordwrapjs';

export class StringUtils {

    /**
  * use case: StringUtils.getPropToStringGenerator()((o: IPlaceExtContainer) => { o.googleId })
  * 
  * https://stackoverflow.com/questions/29600539/typescript-interface-property-to-string
  */
    static getPropToStringGenerator(): <T>(property: (object: T) => void) => string {
        const P = <T>(property: (object: T) => void) => {
            const chaine = property.toString();
            const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
            return arr[1];
        };
        return P;
    }

    static textFromHTML(text: string): string {
        if (!text) {
            return "";
        }
        var find = "<p>";
        var re = new RegExp(find, 'g');
        let desc: string = text.replace(re, "");
        var find = "</p>";
        var re = new RegExp(find, 'g');
        return desc.replace(re, "\n");
    }

    static replaceLink(text: string) {
        // Put the URL to variable $1 after visiting the URL
        var Rexp =
            /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
        // Replace the RegExp content by HTML element
        return text.replace(Rexp,
            "<a href='$1'>" + text + "</a>");
    }

    static textToHTML(text: string): string {
        let htmlText: string = "";
        if (!text) {
            htmlText = "";
        } else {
            let pars: string[] = text.split("\n");
            // remove trailing empty lines
            let lastEmptyLineIndex: number = -1;

            for (let i = pars.length - 1; i >= 0; i--) {
                if (pars[i].length === 0) {
                    lastEmptyLineIndex = i;
                } else {
                    break;
                }
            }

            for (let i = 0; i < pars.length; i++) {
                if (lastEmptyLineIndex === -1 || i < lastEmptyLineIndex) {
                    let par = pars[i];
                    let txt = par.replace("\n", "");
                    if (txt[0] === "<") {
                        // already HTML - skip format
                        htmlText += txt;
                    } else {
                        // check HTML on the same line - separate from current paragraph
                        let index = txt.indexOf("<");
                        if (index !== -1) {
                            // HTML detected on the same line
                            let txtContent = txt.slice(0, index);
                            htmlText += "<p>" + txtContent + "</p>";
                            let lastIndexOfHtmlPart = txt.lastIndexOf(">");
                            let htmlContent = txt.slice(index, lastIndexOfHtmlPart + 1);
                            htmlText += htmlContent;
                            if (lastIndexOfHtmlPart < txt.length) {
                                htmlText += txt.slice(lastIndexOfHtmlPart + 1, txt.length - 1);
                            }
                        } else {
                            // no HTML on the same line
                            htmlText += "<p>" + txt + "</p>";
                        }
                    }
                } else {
                    break;
                }
            }
        }
        return htmlText;
    }

    static isNotEmptyOrNull(str: string) {
        return (str != null) && (str.length > 0);
    }

    /**
     * returns matrix format
     * @param csvText 
     * @param headersImport 
     * @returns 
     */
    static importCSV(csvText: string, headersImport: string[]) {
        var allTextLines = csvText.split(/\r\n|\n/);
        var headers = allTextLines[0].split(',');
        var lines = [];
        for (var i = 1; i < allTextLines.length; i++) {
            var data = allTextLines[i].split(',');
            if (data.length == headers.length) {
                var tarr = [];
                for (var j = 0; j < headers.length; j++) {
                    if (headersImport == null || (headersImport != null && headersImport.indexOf(headers[j]) !== -1)) {
                        tarr.push(data[j]);
                    }
                }
                lines.push(tarr);
            }
        }
        return lines;
    }

    static parseCSVString(csvText: string) {
        var data = csvText.split(',');
        return data;
    }


    /**
     * trim name and add ..
     * @param name 
     * @param maxn 
     */
    static trimName(name: string, maxn: number) {
        let trim: string = name;
        if (!trim) {
            return trim;
        }
        if ((maxn - 2) <= 0) {
            // cannot trim to less than string length
            return trim;
        }
        if (trim.length > maxn) {
            trim = trim.slice(0, maxn - 2) + "..";
        }
        return trim;
    }

    static trimNameMultilineHTML(name: string, maxn: number) {
        let trim: string = name;
        if (!trim) {
            return trim;
        }
        let lines: string[] = [];

        lines = wordwrap.lines(name, { width: maxn })
        trim = "";
        for (let line of lines) {
            trim += "<p>" + line + "</p>";
        }
        return trim;
    }

    /**
    * trim name and add ..
    * @param name 
    * @param maxn 
    */
    static trimNameExact(name: string, maxn: number) {
        let trim = name;
        if (!trim) {
            return trim;
        }
        if ((maxn - 2) <= 0) {
            // cannot trim to less than string length
            return trim;
        }
        if (trim.length > maxn) {
            trim = trim.slice(0, maxn) + "..";
        }
        return trim;
    }


    /**
     * trim to first p
     * and max number of chars
     * and min specified number of ps
     * 
     * rule:
     * text length < maxn
     * p count > minpar && p count < minpar + 1
     * @param html 
     * @param maxn 
     * @param minpar 
     */
    static trimHtmlPar(html: string, maxn: number, minpar: number) {
        let desc = html;
        if (!desc) {
            return desc;
        }

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(desc, 'text/html');
        let rawPars = htmlDoc.getElementsByTagName("p");

        if (rawPars && rawPars.length > 0) {
            if (minpar) {
                desc = "";
                let ncount = 0;
                for (let i = 0; i < rawPars.length; i++) {
                    let crtPar = "" + rawPars[i].innerHTML;
                    let crtLen = crtPar.length;
                    ncount += crtLen;
                    let addDots = false;
                    let stop = false;

                    // check p count exceeded
                    if (i >= minpar) {
                        addDots = true;
                        stop = true;
                    }

                    // check text length exceeded
                    if (ncount >= maxn) {
                        addDots = false;
                        /*  crtPar = trimName(crtPar, ncount-maxn-1); */
                        // check the exceed length and trim to fit
                        let trimSize = crtLen - (ncount - maxn);

                        crtPar = StringUtils.trimNameExact(crtPar, trimSize);
                        stop = true;
                    }

                    desc += "<p>" + crtPar;

                    if (addDots) {
                        desc += "..</p>";

                    } else {
                        desc += "</p>";
                    }

                    if (stop) {
                        break;
                    }
                }
            } else {
                if (rawPars.length === 1) {
                    desc = "<p>" + rawPars[0].innerHTML + "</p>";
                } else {
                    desc = "<p>" + rawPars[0].innerHTML + "..</p>";
                }
            }
        } else {
            desc = StringUtils.trimNameExact(desc, maxn);
        }
        return desc;
    }

    static getParCount(html: string) {
        let desc: string = html;
        if (!desc) {
            return desc;
        }

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(desc, 'text/html');
        let rawPars = htmlDoc.getElementsByTagName("p");

        let npar: number = rawPars ? rawPars.length : 0;
        return npar;
    }


    /**
    * match string
    * handle minor typos (spaces, uppercase)
    * @param reference 
    * @param input 
    */
    static checkMatchApproxLD(reference: string, referenceList: string[], input: string, ld: number, strict: boolean): boolean {
        if (!((reference || referenceList) && input)) {
            return false;
        }

        let check = (reference: string) => {
            let maxIter: number = 10000;
            if ((input.length * reference.length) > maxIter) {
                // prevent blocking call due to unusually long input
                return false;
            }
            if (strict) {
                return input.toLowerCase() == reference.toLowerCase();
            } else {
                return StringUtils.computeLevenshteinDistance(input.toLowerCase(), reference.toLowerCase()) < ld;
            }
        }

        let match: boolean = false;
        if (referenceList != null) {
            for (let ref of referenceList) {
                if (ref != null) {
                    if (check(ref)) {
                        match = true;
                        break;
                    }
                }
            }
        } else {
            if (reference != null) {
                match = check(reference);
            }
        }
        return match;
    }

    static computeLevenshteinDistance(str1: string, str2: string) {
        const track = Array(str2.length + 1).fill(null).map(() =>
            Array(str1.length + 1).fill(null));
        for (let i = 0; i <= str1.length; i += 1) {
            track[0][i] = i;
        }
        for (let j = 0; j <= str2.length; j += 1) {
            track[j][0] = j;
        }
        for (let j = 1; j <= str2.length; j += 1) {
            for (let i = 1; i <= str1.length; i += 1) {
                const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
                track[j][i] = Math.min(
                    track[j][i - 1] + 1, // deletion
                    track[j - 1][i] + 1, // insertion
                    track[j - 1][i - 1] + indicator, // substitution
                );
            }
        }
        return track[str2.length][str1.length];
    }

}