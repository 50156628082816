
import { EActivityCodes } from "../def/core/activity";

import { MathUtils } from "../general/math";

import { IGameItem } from "../def/items/game-item";
import { ILocationContainer, ILocationItemsDef } from "../def/places/backend-location";
import { EStartCodes } from "../def/nav-params/story";
import { ETreasureType } from "../def/items/treasures";
import { GeneralUtils } from "./general";
import { ICustomParamForActivity, ECustomParamScope, ECustomParamScope2 } from "../def/core/custom-param";
import { IStory } from "../def/core/story";
import { IStatDef } from "../def/user/stats";
import { TimeUtils } from '../general/time';


/**
 * the max length of the message for different cases
 */
export enum EMessageTrim {
    storyNameTitle = 20,
    titleModalLarge = 20,
    itemNameCard = 15,
    subscriptionCard = 50,
    itemNameCardSmall = 10,
    leaderboardUsernameCard = 20,
    mpLeaderboardUsernameCard = 10,
    mpUserStatusCard = 40,
    placeNameCard = 25,
    locationInfoCard = 30,
    xpMessageHud = 41,
    markerAuxCaptionCanvas = 25,
    markerCaptionCanvas = 40,
    descriptionPreview = 350,
    newsPreview = 350,
    userInput = 200
}

export enum EShareMsgFeatures {
    activityFinished = 1,
    storyFinished = 2,
    itemFound = 3,
    achievementUnlocked = 5
}

export interface IShareMsgParams {
    photo?: {
        display: string,
        share: string
    };
    place?: {
        name: string,
        place?: ILocationContainer
    };
    flags: {
        watch: boolean
    };
    story?: {
        worldMap: boolean,
        id: number,
        name: string,
        rewardCoins: number,
        progress?: {
            finished: boolean,
            percentFinished: number
        },
        story?: IStory
    };
    xp: number;
    items?: {
        /**
         * these can be any items
         */
        collected: number,
        /**
         * reward is in LP
         */
        reward: number,
        /**
         * the value of an item (LP)
         */
        value: number
    };
    activity?: {
        photoUrl: string,
        code: number,
        baseCode: number,
        standardRewardCap: number,
        progress?: IShareActivityProgress,
        stats?: IStatDef[],
        score?: number
    };
    customParams?: ICustomParamForActivity[];
    newAchievements?: {
        items: IGameItem[],
        categoryName: string
    };
    placeItems?: {
        availableItems?: ILocationItemsDef
    };
    treasureType?: number;
}


export interface IShareActivityProgress {
    target: number[];
    current: number[];
}

export interface IShareMsg {
    share: string;
    aux: string;
    auxHTML: string;
}

export interface IShareStoryParams {
    coinsCollected: number;
    story: string;
    finished: boolean;
    percentFinished: number;
    rewardCoins?: number;
}


export class MessageUtils {

    static getActivityItemsDescription(scope: number, scope2: number) {
        let descriptionItems: string = "";
        switch (scope) {
            case ECustomParamScope.story:
                descriptionItems = "This chapter unlocks the following ";
                break;
            case ECustomParamScope.challenge:
            default:
                descriptionItems = "This challenge unlocks the following ";
                break;
        }

        switch (scope2) {
            case ECustomParamScope2.items:
                descriptionItems += "gems";
                break;
            case ECustomParamScope2.treasures:
            default:
                descriptionItems += "treasures";
                break;
        }

        return descriptionItems;
    }

    static getStartButtonText(code: number) {
        let text: string = "Start";
        switch (code) {
            case EStartCodes.start:
                text = "Start";
                break;
            case EStartCodes.continue:
                text = "Continue";
                break;
            case EStartCodes.restart:
                text = "Restart";
                break;
        }
        return text;
    }

    static buildMessage(featureCode: number, params: IShareMsgParams) {
        let shareMessage: string = "";
        let auxHTML: string = "";
        let auxMessage: string = "";
        let collectedItems: number = 0;
        let rewardLP: number = 0;
        let collectedLP: number = 0;

        function getItemsMessage(items: number, collectedLP: number, rewardLP: number) {
            let msg: string = "";
            if (rewardLP) {
                if (items > 1) {
                    // multiple items
                    msg = items + " items (" + collectedLP + " + " + rewardLP + " LP)";
                } else if (items > 0) {
                    // one item
                    msg = items + " item (" + collectedLP + " + " + rewardLP + " LP)";
                } else {
                    // reward LP only
                    msg = rewardLP + " LP";
                }
            } else {
                if (items > 1) {
                    // multiple items
                    msg = items + " items (" + collectedLP + " LP)";
                } else if (items > 0) {
                    // one item
                    msg = items + " item (" + collectedLP + " LP)";
                }
            }
            return msg;
        }

        function getXpMessage(xp: number) {
            let msg: string = "";
            if (xp && xp > 0) {
                msg = "+" + xp + " XP";
            }
            return msg;
        }


        if (params.items) {
            if (!params.items.collected) {
                params.items.collected = 0;
            }
            if (!params.items.reward) {
                params.items.reward = 0;
            }

            collectedItems = params.items.collected;
            rewardLP = params.items.reward;
            collectedLP = collectedItems * params.items.value;

            if (collectedItems || rewardLP) {
                auxHTML = "<p>You have collected " + getItemsMessage(collectedItems, collectedLP, rewardLP) + "</p>";
            }
        }

        auxHTML += getXpMessage(params.xp);

        if (params.flags.watch) {
            auxHTML += "<p>Watch a video ad to unlock a potential reward</p>";
        }


        let contextName: string = "";
        if (params.story) {
            contextName = params.story.name;
            if (!params.story.worldMap) {
                contextName += " story";
            }
        }

        switch (featureCode) {
            case EShareMsgFeatures.activityFinished:
                // add description at the beginning
                auxHTML = "<p>Activity complete</p>" + auxHTML;

                shareMessage = "Found a cool place in " + contextName;

                let foundSpecific: boolean = false;
                switch (params.activity.code) {
                    default:
                        break;

                }

                console.log("message utils activity base code: " + params.activity.baseCode);

                if (!foundSpecific) {
                    // create share message
                    switch (params.activity.baseCode) {
                        case EActivityCodes.explore:
                            shareMessage = "Explored a cool place in " + contextName;

                            if (collectedItems > 0) {
                                if (collectedItems > 1) {
                                    shareMessage = "Collected " + collectedItems + " items in " + contextName;
                                } else {
                                    shareMessage = "Collected " + collectedItems + " item in " + contextName;
                                }
                            }

                            break;
                        case EActivityCodes.escape:
                            shareMessage = "Escape complete in " + contextName;
                            break;
                        case EActivityCodes.find:
                            shareMessage = "Found " + params.place.name + " in " + contextName;
                            auxMessage = "Place found";
                            break;
                        case EActivityCodes.walk:
                            shareMessage = "Finished a " + MathUtils.formatDistanceDisp(params.activity.progress.target[0]).disp + " long walk in " + contextName;
                            break;
                        case EActivityCodes.run:
                            shareMessage = "Finished a " + MathUtils.formatDistanceDisp(params.activity.progress.target[0]).disp + " run ";
                            if (params.activity.progress.current[1]) {
                                shareMessage += "@" + MathUtils.formatSpeedDisp(params.activity.progress.current[1]).disp;
                            }
                            shareMessage += " in " + contextName;
                            break;
                        case EActivityCodes.enduranceRun:
                            shareMessage = "Finished a " + TimeUtils.formatTimeFromSeconds2(params.activity.progress.target[2], true) + " endurance run ";
                            if (params.activity.progress.current[1]) {
                                shareMessage += " of " + MathUtils.formatDistanceDisp(params.activity.progress.current[0]).disp;
                            }
                            shareMessage += " in " + contextName;
                            break;
                        case EActivityCodes.dance:
                            shareMessage = "Unlocked " + params.activity.progress.current[0] + " beats ";
                            shareMessage += " in " + contextName;
                            break;
                        case EActivityCodes.decibel:
                            shareMessage = "Listened to live music ";
                            shareMessage += " in " + contextName;
                            break;
                        case EActivityCodes.snapshot:
                            if (params.customParams) {
                                shareMessage = "Photographed: ";
                                for (let i = 0; i < params.customParams.length; i++) {
                                    shareMessage += params.customParams[i].name;
                                    if (i < params.customParams.length - 1) {
                                        shareMessage += ", ";
                                    }
                                }
                                shareMessage += " in " + contextName;
                            }
                            break;
                        case EActivityCodes.screenshotAR:
                            if (params.customParams) {
                                shareMessage = "Found AR objects: ";
                                for (let i = 0; i < params.customParams.length; i++) {
                                    shareMessage += params.customParams[i].name;
                                    if (i < params.customParams.length - 1) {
                                        shareMessage += ", ";
                                    }
                                }
                                shareMessage += " in " + contextName;
                            }
                            break;
                        default:
                            shareMessage = "Found a cool place in " + contextName;
                            break;
                    }
                }

                break;
            case EShareMsgFeatures.storyFinished:
                if (params.story.progress.finished) {
                    // add description at the beginning
                    auxHTML = "<p>Story complete</p>" + auxHTML;

                    shareMessage = "Finished " + params.story.name + " story";
                } else {
                    auxHTML = "<p>Story " + params.story.progress.percentFinished + "% complete</p><p>Try again later to unlock a reward</p>" + auxHTML;
                }
                break;
            case EShareMsgFeatures.itemFound:
                // add description at the beginning
                auxHTML = "<p>Treasure opened</p>" + auxHTML;

                if (params.treasureType === ETreasureType.treasure) {
                    if (params.place.name) {
                        shareMessage = "Found a hidden stash of " + collectedLP + " LP @" + params.place.name;
                    } else {
                        shareMessage = "Found a hidden stash of " + collectedLP + " LP";
                    }
                }

                break;
            case EShareMsgFeatures.achievementUnlocked:
                if (params.newAchievements.items && (params.newAchievements.items.length > 0)) {
                    let items: IGameItem[] = params.newAchievements.items;

                    let categories: string[] = [];
                    for (let i = 0; i < items.length; i++) {
                        let item: IGameItem = items[i];
                        if (item.itemCategory) {
                            categories.push(item.itemCategory.name);
                        }
                    }
                    categories = GeneralUtils.getUniqueValues(categories);

                    let categoryName: string = "";

                    if (categories.length > 0) {
                        auxHTML = "<p>Unlocked types: " + categories.join(", ") + "</p>";
                        categoryName = " " + categories[0];
                    }

                    if (categories.length > 1) {
                        categoryName = "";
                    }

                    shareMessage = "Unlocked" + categoryName + ": ";
                    for (let i = 0; i < items.length; i++) {
                        shareMessage += items[i].name;
                        if (i < items.length - 1) {
                            shareMessage += ", ";
                        }
                    }
                }
                break;
            default:
                break;
        }

        let res: IShareMsg = {
            share: shareMessage,
            aux: auxMessage,
            auxHTML
        };
        return res;
    }

    static getDefaultShareMsgParams() {
        let params: IShareMsgParams = {
            place: {
                name: null,
                place: null
            },
            flags: {
                watch: false
            },
            story: {
                name: null,
                id: null,
                worldMap: false,
                rewardCoins: 0,
                progress: {
                    finished: false,
                    percentFinished: 0
                }
            },
            items: {
                collected: 0,
                reward: 0,
                value: 0
            },
            xp: 0,
            activity: {
                photoUrl: null,
                code: 0,
                baseCode: 0,
                standardRewardCap: 0,
                progress: {
                    target: [],
                    current: []
                }
            },
            newAchievements: {
                items: [],
                categoryName: "new achievements"
            },
            placeItems: {
                availableItems: null
            },
            treasureType: ETreasureType.treasure
        };
        return params;
    }
}
