
import { ETreasureType } from "../def/items/treasures";
import { IGameConfig } from "./config";
import { ECodePushSource } from "../def/app/app";

export class AppConstants {

    public static appId: number = 4; // lpshop

    public static animationTime: number = 500;
    public static animationMode: string = "0.5s ease-in-out";
    // public static animationMode: string = "0.5s cubic-bezier(.1, .7, .1, 1)";
    public static animationTime2S: number = 250;
    public static animationMode2S: string = "0.25s ease-in-out";

    public static appStorageFolder: string = "lpconnect";

    // these items trigger register treasure
    public static collectibleItemsClient: number[] = [ETreasureType.treasure, ETreasureType.challenge,
    ETreasureType.storyLocation, ETreasureType.exploreObject, ETreasureType.findTarget];
    public static collectibleEventItemsClient: number[] = [ETreasureType.treasure, ETreasureType.exploreObject, ETreasureType.findTarget];
    public static collectibleItemsServer: number[] = [ETreasureType.treasure];
    public static collectibleItemsOnTap: number[] = [ETreasureType.treasure, ETreasureType.exploreObject];

    public static transparentMenus: boolean = false;

    /** disabled at the moment because of bugs related to bg mode and speechSynthesis API */
    public static enableSoundApi: boolean = false;

    public static viewTitle = {
        signupTerms: "Terms and Conditions",
        signupTutorial: "Welcome"
    };

    public static links = {
        website: "https://www.leplace.online",
        testFlightUrl: "https://testflight.apple.com/join/WXZlJtL0",
        appStoreUrl: "https://apps.apple.com/us/app/leplace-world/id1496776027",
        playStoreUrl: "https://play.google.com/store/apps/details?id=com.leplace.global",
        webUrl: "https://world.leplace.online/"
        // fbPageId: "397813247355479"
    }

    public static placeFinderRestrictedTypes: string[] = [
        "route"
    ];

    public static placeFinderAllowedTypes: string[] = [
        "accounting",
        "airport",
        "amusement_park",
        "aquarium",
        "art_gallery",
        "atm",
        "bakery",
        "bank",
        "bar",
        "beauty_salon",
        "bicycle_store",
        "book_store",
        "bowling_alley",
        "bus_station",
        "cafe",
        "campground",
        "car_dealer",
        "car_rental",
        "car_repair",
        "car_wash",
        "casino",
        "cemetery",
        "church",
        "city_hall",
        "clothing_store",
        "convenience_store",
        "courthouse",
        "dentist",
        "department_store",
        "doctor",
        "drugstore",
        "electrician",
        "electronics_store",
        "embassy",
        "fire_station",
        "florist",
        "funeral_home",
        "furniture_store",
        "gas_station",
        "gym",
        "hair_care",
        "hardware_store",
        "hindu_temple",
        "home_goods_store",
        "hospital",
        "insurance_agency",
        "jewelry_store",
        "laundry",
        "lawyer",
        "library",
        "light_rail_station",
        "liquor_store",
        "local_government_office",
        "locksmith",
        "lodging",
        "meal_delivery",
        "meal_takeaway",
        "mosque",
        "movie_rental",
        "movie_theater",
        "moving_company",
        "museum",
        "night_club",
        "painter",
        "park",
        "parking",
        "pet_store",
        "pharmacy",
        "physiotherapist",
        "plumber",
        "police",
        "post_office",
        "primary_school",
        "real_estate_agency",
        "restaurant",
        "roofing_contractor",
        "rv_park",
        "school",
        "secondary_school",
        "shoe_store",
        "shopping_mall",
        "spa",
        "stadium",
        "storage",
        "store",
        "subway_station",
        "supermarket",
        "synagogue",
        "taxi_stand",
        "tourist_attraction",
        "train_station",
        "transit_station",
        "travel_agency",
        "university",
        "veterinary_care",
        "zoo"
    ]

    public static gameConfig: IGameConfig = {
        coinValue: 10,
        defaultScanEnergy: 1,
        droneScanEnergy: 25,
        maxSpeedGPS: 30,
        placeContentScanEnergy: 10,
        placeScanRadius: 1000,
        placeScanRadiusTown: 10000,
        smartZoomOutRange: 1000,
        smartZoomInRange: 200,
        smartZoomOutDensity: 100,
        smartZoomInDensity: 0,
        mapZoomInLevel: 17,
        mapZoomOutLevel: 15,
        recordSamplingTime: 5000,

        maxPlaceMarkers: 10,
        locationAccuracyThreshold: 200,
        maxDistanceLocationMeters: 20000,
        collectDistance: 25,
        itemNotifyDistance: 50,
        itemEnableDistance: 200,
        ARCollectDistance: 15,
        reachedDistance: 50,
        revealDistance: 80,
        maxDistanceFromPath: null,
        maxDistanceFromPathNotify: 200,
        scanPlacesMinDist: 250,
        filteringRequiredMinDist: 100,
        // km/h
        scanPlacesMaxSpeed: 30,
        scanPlacesMinTimeDelta: 60 * 1000,
        refreshARMinTimeDelta: 5 * 1000,
        scanPlacesMinTimeDeltaInit: 5 * 1000,
        scanPlacesRetryTimeDelta: 10 * 1000,
        refreshARMinDist: 10,
        defaultObjectAltitude: -200,
        openCounterUntilRate: 5,
        autoAds: 0,
        dumpDistanceTimeout: 600 * 1000,
        maxTreasurePlaces: 10,
        placePhotoCacheSize: 5,
        placePhotoCacheTime: 300,
        droneBatteryDurationNominal: 180,
        maxReviewAdCounter: 5,
        customEventTracking: 1,
        customEventLogger: 1,
        debugIapEvents: 0,
        QRRefreshInterval: 19500,
        defaultMapStyleTown: 0,
        chargeAmountSubscription: 0,
        maxDataStringLength: 2000,
        resumeAfterDays: 3,
        autoSyncTimeout: 60000,

        photoTargetWidth: 1200,
        photoTargetQuality: 60,
        codePushSource: ECodePushSource.default,
        unlockCodeLength: 6,

        maxPhotoDataSize: 1024,
        externalPaymentDeduction: 1
    };
}

export enum EAppConstants {
    exploreCircleRadiusDefault = 40,
    storyDescriptionLimit = 2000
}

