export interface IThemeSettings {
    [key: string]: IThemeSettingsElement;
    standard: IThemeSettingsElement;
    aubergine: IThemeSettingsElement;
}

export interface IThemeSettingsElement {
    name: string;
    css: string;
    chartTheme: string[];
    vgTheme: IVGTheme;
    lineColor: string;
}

export interface IMarkerTheme {
    name: string;
    lineColor: string;
    markerFrameColor: string;
}


enum EPlaceColors1 {
    restaurant = "rgba(244, 125, 66, 0.8)",
    park = "rgba(40, 109, 64, 0.8)",
    museum = "rgba(30, 114, 163, 0.8)"
}


export enum EFeatureColor {
    sport = "rgb(218, 5, 5)",
    casual = "rgb(2, 127, 252)",
    explore = "rgb(2, 252, 52)",
    leplaceBlue = "#1e72a3",
    lineColor = "#1e72a3",
    lineColorLight = "#46a898",
    chartColor = "#fcd182",
    userCircleColor = "#ebb24a",
    droneCircleColor = "#ebb24a",
    // green
    action1 = "#46b666",
    // red
    action2 = "#e75f4d",
    // yellow
    action3 = "#ebb24a",
    action3v2 = "#fcd182",


    white = "#f8f8f8",
    gold = "#fcd182"
}

export enum EPlaceColors {
    default = EPlaceColors1.museum,
    restaurant = EPlaceColors1.restaurant,
    bar = EPlaceColors1.restaurant,
    cafe = EPlaceColors1.restaurant,
    bakery = EPlaceColors1.restaurant,
    park = EPlaceColors1.park,
    museum = EPlaceColors1.museum
}

export class ThemeColors {
    public static theme: IThemeSettings = {
        standard: {
            name: "standard",
            css: "theme-standard theme-standard-bg",
            chartTheme: ['#1e72a3', '#1e72a3', '#1e72a3', '#1e72a3'],
            lineColor: EFeatureColor.lineColor,
            vgTheme: {
                default: [EFeatureColor.lineColorLight, EFeatureColor.action3v2],
                accent1: [EFeatureColor.lineColorLight, EFeatureColor.action1],
                accent2: [EFeatureColor.lineColorLight, EFeatureColor.action3]
            }
        },
        aubergine: {
            name: "aubergine",
            css: "theme-aubergine theme-aubergine-bg",
            chartTheme: [EFeatureColor.chartColor, EFeatureColor.chartColor, EFeatureColor.chartColor, EFeatureColor.chartColor],
            // lineColor: "rgba(244, 244, 244, 0.8)"
            lineColor: EFeatureColor.lineColorLight,
            vgTheme: {
                default: [EFeatureColor.lineColorLight, EFeatureColor.action3v2],
                accent1: [EFeatureColor.lineColorLight, EFeatureColor.action1],
                accent2: [EFeatureColor.lineColorLight, EFeatureColor.action3]
            }
        }
    };
}

export interface IVGTheme {
    // yellow
    default: string[],
    // green
    accent1: string[],
    // red
    accent2: string[]
}

export enum EChartTheme {
    // yellow
    default = 1,
    // green
    accent1 = 2,
    // red
    accent2 = 3
}
