import { ITreasureSpec } from "../places/leplace";
import { IGameItem } from "../items/game-item";
import { IActivityQuestSpecs, IActivityPhotoSpecs, IActivityVideoSpecs } from '../nav-params/activity-details';
import { IDynamicGridContainer } from '../views/grid';
import { IEditorDetails } from './editor';

export interface ICustomParamCategory {
    id: number;
    code: number;
    name: string;
    position: number;
    customParams?: ICustomParamForActivity[];
    customParamContainers?: IDynamicGridContainer[];
}

export interface ICustomParamCategoryExt extends ICustomParamCategory {
    customParams?: ICustomParamForActivity[];
}

/**
 * custom params that are associated to a parent activity
 */
export interface ICustomParamForActivity {
    id: number;
    /**
     * the code may not be required if the API uses the tag instead
     */
    code: number;
    /**
     * the param category is related to the API that is required to validate the param
     */
    customParamCategoryCode: number;
    /**
     * the container category
     */
    customParamCategory: ICustomParamCategory;
    /**
     * category name added from the custom param category table join
     */
    categoryName: string;
    /**
     * associated map marker e.g. coin replacement
     */
    treasureSpec: ITreasureSpec;

    /**
     * associated items (unlockables)
     */
    items: IGameItem[];
    /**
     * the object tag e.g. dog, bicycle
     */
    tag: string;
    /**
     * a human friendly name
     */
    name: string;

    description: string;
    /**
     * photo url to be shown in the app
     */
    photoUrl: string;
    /**
     * external link
     */
    wikiUrl: string;
    /**
     * aux, for view only
     */
    photoUrlMarker?: string;
    /**
     * can represent the timeout associated to the custom param/challenge
     */
    value: number;

    /**
     * check if the scope is story or challenge
     */
    scope?: number;
    /**
     * check if custom options enabled (e.g. custom photo url)
     */
    custom?: number;
    /**
     * may be used for quest activity specs
     */
    dataObj?: IActivityQuestSpecs | IActivityPhotoSpecs | IActivityVideoSpecs;
    /**
    * used for quest activities (raw specs from the db)
    */
    dataString?: string

    isDraft?: boolean;
    loading?: boolean;
    draftItemLink?: number;
    draftCparamLink?: number;
    editor: IEditorDetails;
}



export enum ECustomParamScope {
    story = 1,
    challenge = 2
}

export enum ECustomParamScope2 {
    treasures = 1,
    items = 2
}

export enum ECustomParamCategoryCode {
    draft = -1,
    photoChallenge = 1,
    audioChallenge = 2,
    basicChallenge = 3,
    arPhotoChallenge = 4,
    questChallenge = 23,
    videoGuide = 101
}

export enum ECustomParamCodes {
    photo = 100,
    coin = 601,
    target = 701,
    escape = 1603,
    questText = 2301,
    questNumeric = 2302,
    questSelect = 2303,
    questCheck = 2304,
    videoGuideBefore = 10101,
    videoGuideTarget = 10102,
    videoGuideAfter = 10103
}