
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Keyboard } from '@ionic-native/keyboard/ngx';

export interface IKeyboardEvent {
    show: boolean;
    height: number;
}

@Injectable({
    providedIn: 'root'
})
export class KeyboardProvider implements OnDestroy, OnInit {
    private keyboardEvent: BehaviorSubject<IKeyboardEvent> = null;
    activeElement: Element;
    enableScrollIntoView: boolean = true;
    globalEnableScrollIntoView: boolean = false;

    constructor(
        public platform: Platform,
        public keyboard: Keyboard
    ) {
        console.log("keyboard service created");
        this.keyboardEvent = new BehaviorSubject(null);
    }

    watchKeyboardEvent() {
        this.keyboardEvent.next(null);
        return this.keyboardEvent;
    }

    setEnableScroll(enable: boolean) {
        this.enableScrollIntoView = enable;
    }

    standardKeyboardEventInit() {
        window.addEventListener("keyboardDidShow", (event: any) => {
            this.activeElement = document.activeElement;
            console.log("keyboard opened, active element: ", document.activeElement);
            console.log("keyboard height: ", event.keyboardHeight);
            console.log(event);
            if (this.enableScrollIntoView && this.globalEnableScrollIntoView) {
                this.activeElement.scrollIntoView(true);
            }
            // const elem: HTMLCollectionOf<Element> = document.getElementsByClassName("scroll-content"); // The last content shown, so the current page
            // if (elem !== undefined && elem.length > 0) {
            //     elem[elem.length - 1].scrollTop += 40; // add 40px between the keyboard and the input
            // }

            // If true, the top of the element will be aligned to the top of the visible area of the scrollable ancestor. Corresponds to scrollIntoViewOptions: {block: "start", inline: "nearest"}. This is the default value.
            // If false, the bottom of the element will be aligned to the bottom of the visible area of the scrollable ancestor. Corresponds to scrollIntoViewOptions: {block: "end", inline: "nearest"}.

            this.keyboardEvent.next({
                show: true,
                height: event.keyboardHeight
            });
        });
        window.addEventListener("keyboardDidHide", () => {
            console.log("keyboard closed");
            if (this.enableScrollIntoView && this.globalEnableScrollIntoView) {
                if (this.activeElement) {
                    // this.activeElement.scrollIntoView(false);
                }
            }
            this.activeElement = null;
            this.keyboardEvent.next({
                show: false,
                height: 0
            });
        });
    }


    ngOnInit() {
    }

    /**
     * Determine if the keyboard is visible
     *
     * @readonly
     * @type {boolean}
     * @memberof KeyboardProvider
     */
    get isVisible(): boolean {
        return this.keyboard.isVisible;
    }

    /**
     * Show the keyboard
     */
    show() {
        this.keyboard.show();
    }

    /**
     * Hide the keyboard
     *
     * @memberof KeyboardProvider
     */
    hide() {
        this.keyboard.hide();
    }

    ngOnDestroy() {
    }

}
