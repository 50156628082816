import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';

@Component({
  selector: 'leplace-paginator',
  templateUrl: './leplace-paginator.component.html',
  styleUrls: ['./leplace-paginator.component.scss'],
})
export class LeplacePaginatorComponent implements OnInit {

  @Input()
  pagination: IPaginationContainer;

  @Output()
  onSwitch: EventEmitter<number> = new EventEmitter();

  constructor(
    public paginationHandler: PaginationHandlerService
  ) { }

  ngOnInit() {

  }

  switchPage(dir: number) {
    this.onSwitch.emit(dir);
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector(() => {
      this.onSwitch.emit(0);
    });
  }
}
