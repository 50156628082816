import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IGenericEntryBase } from 'src/app/classes/def/app/entry';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'plain-entry-expand',
  templateUrl: './plain-entry-expand.component.html',
  styleUrls: ['./plain-entry-expand.component.scss'],
})
export class PlainEntryExpandComponent implements OnInit {

  @Input()
  e: IGenericEntryBase;

  @Input()
  fixed: boolean;

  @Output()
  action: EventEmitter<boolean> = new EventEmitter();

  descriptionHtml: SafeHtml;

  expanded: boolean = false;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.e) {
      if (this.fixed) {
        this.expanded = true;
      }
      this.setExpanded(this.expanded);
    }
  }

  ngOnDestroy() {

  }

  onTapSelect() {
    this.action.emit(true);
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.setExpanded(this.expanded);
  }

  setExpanded(expanded: boolean) {
    if (!expanded) {
      this.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(StringUtils.trimHtmlPar(this.e.description, null, 1));
    } else {
      this.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.e.description);
    }
  }
}

