import { ILeplaceTreasure } from "../places/leplace";

export enum ETreasureType {
    treasure = 0,
    story = 1,
    arena = 2,
    storyExplore = 101,
    storySport = 102,
    storyCasual = 103,
    exploreObject = 3,
    challenge = 4,
    findTarget = 5,
    customContent = 6,
    storyLocation = 7,
    event = 10,
    beacon = 20
}


export interface ITreasureScanResponse {
    crates: ILeplaceTreasure[];
    db: number[];
    user: number[];
}

export interface IEventTreasureScanResponse {
    count: number;
    treasures: ILeplaceTreasure[];
}




