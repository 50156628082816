import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IGenericResponse } from "../../classes/def/requests/general";
import { ICheckReferralCode } from 'src/app/classes/utils/popup-features';
import { GeneralCache } from 'src/app/classes/app/general-cache';

@Injectable({
    providedIn: 'root'
})
export class MiscDataService {

    constructor(
        public generic: GenericDataService
    ) {
        console.log("misc data service created");
    }

    /**
     * request generate unique referral code
     */
    requestReferralCode() {
        return this.generic.genericPostStandard("/user/r-code/request-code", {

        });
    }

    /**
     * request generate unique referral code
     */
    getCountries() {
        return this.generic.genericPostStandardWData("/world/main/get-countries-ext", {

        });
    }

    /**
     * resend validation email (after the user is authenticated)
     */
    requestNewValidationEmail(email: string) {
        return this.generic.genericPostStandard("/user/request-new-validation-email", {
            email: email
        });
    }

    /**
     * check if the requested referral code was used by another user
     */
    checkReferralCodeValidated(): Promise<ICheckReferralCode> {
        let promise: Promise<ICheckReferralCode> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.user.referralCheck.loaded) {
                resolve(GeneralCache.resourceCache.user.referralCheck.content);
                return;
            }
            this.generic.genericPostStandard("/user/r-code/check-validate", {

            }).then((res: IGenericResponse) => {
                let rc: ICheckReferralCode = res.data;
                GeneralCache.resourceCache.user.referralCheck.content = rc;
                GeneralCache.resourceCache.user.referralCheck.loaded = true;
                resolve(rc);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * use the referral code that was given by another user to register on the app
     */
    useReferralCode(code: string) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/user/r-code/use-code", {
                code: code
            }).then((res: IGenericResponse) => {
                resolve(res.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * submit story rating
     * @param storyId 
     * @param rating 
     * @param review 
     */
    rateStory(storyId: number, rating: number, review: string, finished: boolean) {
        return this.generic.genericPostStandard("/stories/post-rating", {
            storyId: storyId,
            rating: rating,
            review: review,
            finished: finished
        });
    }
}
