export enum EMarkerIcons {
    user = "assets/img/markers/user_v2.png",
    drone = "assets/img/markers/balloon_drone.png",
    mpUser = "assets/img/markers/mp_user.png",
    null = "assets/img/test_markers/blue_MarkerA.png",
    location = "assets/img/markers/place.png",
    locationPin = "assets/img/markers/location_pin.png",
    cluster = "assets/img/markers/m",
    waypoint = "assets/img/test_markers/green_MarkerW.png",
    treasure = "assets/img/markers/crate.png",
    story = "assets/img/markers/story_lp.png",
    gathering = "assets/img/markers/gathering.png",
    coin = "assets/img/markers/coin.png",
    testA = "assets/img/test_markers/blue_MarkerA.png",
    testB = "assets/img/test_markers/green_MarkerB.png",
    testC = "assets/img/test_markers/orange_MarkerC.png",
    testD = "assets/img/test_markers/red_MarkerD.png",
    findCircle = "assets/img/markers/search.png",
    sign = "assets/img/markers/sign.png"
}


// https://ionicons.com/v4/
export enum EAppIconsStandard {
    exit = "exit",
    back = "arrow-back",
    tutorial = "information-circle",
    mapFilter = "list",
    compass = "compass",
    hud = "speedometer",
    ar = "eye",
    view = "eye",
    placeTreasure = "flag",
    flag = "flag",
    checkin = "checkmark-circle",
    check = "checkmark-circle",
    sales = "pricetag",
    share = "share",
    ping = "wifi",
    terms = "newspaper",
    briefing = "briefcase",
    refresh = "refresh",
    home = "home",
    image = "image",
    viewPhoto = "image",
    takePhoto = "camera",
    pickPhoto = "images",
    removePhoto = "close",
    remove = "close",
    sync = "sync",
    switch = "sync",
    search = "search",
    download = "cloud-download",
    upload = "cloud-upload",
    reset = "close",
    close = "close",
    accept = "checkmark",
    validate = "checkmark",
    repeat = "repeat",
    retry = "repeat",
    clearSavedPlaces = "remove-circle",
    rate = "star",
    edit = "create",
    account = "briefcase",
    profile = "person",
    groups = "people",
    userMarker = "person-circle",
    densityFiltering = "funnel",
    key = "key",
    hourglass = "hourglass",
    frame = "scan",
    sound = "volume-high",
    locked = "lock-closed",
    unlocked = "lock-open",
    settings = "settings",
    info = "information-circle",
    mail = "mail",
    join = "person-add",
    clipboard = "clipboard",
    moveUp = "arrow-up",
    moveDown = "arrow-down",
    expand = "open",
    unexpand = "remove",
    add = "add",
    addCircle = "add-circle",
    place = "pin",
    map = "map",
    story = "bookmarks",
    bookmark = "bookmark",
    leaderboard = "ribbon",
    calendar = "calendar",
    filter = "funnel",
    send = "send",
    embed = "code-download",
    alert = "alert",
    undo = "arrow-undo",
    save = "save",
    locate = "locate"
}


export enum EAppIcons {
    backpack = "assets/icons/backpack.svg",
    alarmClock = "assets/icons/alarm-clock.svg",
    hourglass = "assets/icons/hourglass.svg",
    distance = "assets/icons/distance-road.svg",
    distanceTarget = "assets/icons/distance-target.svg",
    stopwatch = "assets/icons/stopwatch.svg",
    check = "assets/icons/checked.svg",
    play = "assets/icons/press-play-button.svg",
    cancel = "assets/icons/cancel.svg",
    // disable = "assets/icons/disable.svg",
    disable = "assets/icons/cancel.svg",
    film = "assets/icons/film.svg",
    handshake = "assets/icons/hand-shake.svg",
    www = "assets/icons/grid-world.svg",
    qr = "assets/icons/qr-code.svg",
    arView = "assets/icons/ar-view.svg",
    googleMaps = "assets/icons/google-maps-bw.svg",
    navigation = "assets/icons/navigation.svg",
    gpsFixed = "assets/icons/gps-fixed-indicator.svg",
    compass = "assets/icons/compass.svg",
    world = "assets/icons/worldwide.svg",
    // drone = "assets/icons/drone.svg",
    drone = "assets/icons/balloon-drone.svg",
    battery = "assets/icons/battery.svg",
    subscription = "assets/icons/subscription.svg",
    secureCheckout = "assets/icons/secure-checkout.svg",
    delete = "assets/icons/delete.svg",
    letter = "assets/icons/letter.svg",
    marketplace = "assets/icons/shopping-cart.svg",
    overview = "assets/icons/overview.svg",
    logOut = "assets/icons/log-out.svg",
    removePhoto = "assets/icons/remove-photo.svg",
    profilePhoto = "assets/icons/profile.svg",
    locked = "assets/icons/locked.svg",
    profileData = "assets/icons/id-card.svg",

    payment = "assets/icons/credit-card.svg",
    faq = "assets/icons/faq.svg",
    hotAirBalloon = "assets/icons/hot-air-balloon.svg",
    feedback = "assets/icons/letter.svg",
    search = "assets/icons/search.svg",
    issues = "assets/icons/technical-support.svg",
    chat = "assets/icons/chat.svg",

    personCircleOutline = "assets/icons/person-circle-outline.svg",
    exitOutline = "assets/icons/exit-outline.svg",

    personRemove = "assets/icons/person-remove.svg",
    starred = "assets/icons/starred.svg",

    rotate = "assets/icons/rotate.svg",
    flipX = "assets/icons/flip-x.svg",
    flipY = "assets/icons/flip-y.svg",

    clear = "assets/icons/eraser.svg",
    clue = "assets/icons/light-bulb.svg",

    linear = "assets/icons/route.svg",
    network = "assets/icons/neural.svg",

    swipe = "assets/icons/swipe.svg",
    groups = "assets/icons/group.svg",

    edit = "assets/icons/edit.svg",
    noEdit = "assets/icons/no-edit.svg",

    locationMap = "assets/icons/location-map.svg",
    scanRadius = "assets/icons/radius.svg",

    city = "assets/icons/smart-city.svg",
    personPlace = "assets/icons/person-place.svg",
    live = "assets/icons/live.svg",

    sort = "assets/icons/sort.svg",
    filter = "assets/icons/filter.svg",
    sortFilter = "assets/icons/sort-filter.svg",
    private = "assets/icons/private.svg",

    flagOn = "assets/icons/flag-on.svg",
    flagOff = "assets/icons/flag-off.svg"
}


export enum EImgIcons {
    maps = "assets/img/icons8-google-maps-50.png",
    appStore = "assets/img/Logo/app-store-badge-res.png",
    playStore = "assets/img/Logo/google-play-badge-res.png",
    testFlight = "assets/img/Logo/TestFlight-button-res.png",
    appleIap = "assets/img/Logo/apple_iap.png",
    googleIap = "assets/img/Logo/google_iap.png",
    web = "assets/img/Logo/click.png",
}


/**
 * obsolete because they were added into db
 */
export enum EActivityIcons {
    walk = "walk",
    // run = "speedometer",
    run = "walk",
    // visit = "image",
    visit = "pin",
    bike = "bicycle",
    explore = "compass",
    // find = "map",
    find = "search",
    eat = "restaurant",
    drink = "beer"
}

/**
 * obsolete because now we use cloud storage
 */
export enum EMapStylePhotos {
    aubergine = "assets/img/maps/aubergine.jpg"
}

/**
 * obsolete because now we use cloud storage
 */
export enum EPhotos {
    default = "assets/img/photos/bench.jpg",
    visit = "assets/img/photos/visit.jpg",
    home = "assets/img/photos/home.png",
    overview = "assets/img/photos/lp_town.jpg",
    leplaceLogo = "assets/img/Logo/logo_transparent.png",
    logo = "assets/img/Logo/logo_app.png",
    // stripeBadge = "assets/img/Logo/stripe_powered_white.png",
    stripeBadge = "assets/img/Logo/stripe_powered_transp.png",
    user = "assets/img/markers/user_v2.png",
    userPlaceholder = "assets/img/photos/user_placeholder.png",
    imageNotAvailable = "assets/img/photos/image_not_available.png",
    balloonLandscape = "assets/img/photos/balloon_landscape.jpg"
}

export enum EPhotosAndroid {
    myTown = "assets/img/photos/my_town.jpg",
    myPlaces = "assets/img/photos/my_places.jpg",
    myStats = "assets/img/photos/my_stats.jpg",
    myStories = "assets/img/photos/my_stories.jpg"
}

export enum EPhotosIos {
    myTown = "assets/img/photos/my_town_ios.jpg",
    myPlaces = "assets/img/photos/my_places_ios.jpg",
    myStats = "assets/img/photos/my_stats_ios.jpg",
    myStories = "assets/img/photos/my_stories_ios.jpg"
}

export enum EPhotosWindows {
    myTown = "assets/img/photos/my_town_ios.jpg",
    myPlaces = "assets/img/photos/my_places_ios.jpg",
    myStats = "assets/img/photos/my_stats_ios.jpg",
    myStories = "assets/img/photos/my_stories_ios.jpg"
}

export enum EPhotosMac {
    myTown = "assets/img/photos/my_town_mac.png",
    myPlaces = "assets/img/photos/my_places_mac.png",
    myStats = "assets/img/photos/my_stats_mac.png",
    myStories = "assets/img/photos/my_stories_mac.png"
}
