import { Injectable } from '@angular/core';
import { EPlatformDef, IPlatformFlags } from '../../classes/def/app/platform';
import { BehaviorSubject } from 'rxjs';
import { ISettings, IAppSettings, IAppSettingsContent, IAnySettings } from '../../classes/def/app/settings';
import { DeepCopy } from '../../classes/general/deep-copy';
import { IMeasurementDisp, measurementModesDisp } from '../../classes/def/app/measurement';
import { ELocalAppDataKeys } from '../../classes/def/app/storage-flags';
import { AppSettings } from '../utils/app-settings';
import { StorageOpsService } from './data/storage-ops';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IObservableMultiplex } from 'src/app/classes/def/mp/subs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { WaitUtils } from '../utils/wait-utils';

interface IObservableMultiplexSpec extends IObservableMultiplex {
  settingsLoaded: BehaviorSubject<boolean>,
  testerModeSet: BehaviorSubject<boolean>,
  flagsLoaded: BehaviorSubject<boolean>,
  platformFlagsLoaded: BehaviorSubject<boolean>,
  mapReady: BehaviorSubject<boolean>
};

@Injectable({
  providedIn: 'root'
})
export class SettingsManagerService {

  public static settings: ISettings = {
    platform: 0,
    platformFlags: {
      WEB: false,
      MOBILE: false,
      MOBILE_LIVERELOAD: false,
      ANDROID: false,
      IOS: false,
      ELECTRON: false
    },
    app: null
  };

  public static defaultAppSettings: IAppSettingsContent;

  settingsLoaded: boolean = false;
  flagsLoaded: boolean = false;

  observable: IObservableMultiplexSpec = {
    settingsLoaded: null,
    testerModeSet: null,
    flagsLoaded: null,
    platformFlagsLoaded: null,
    mapReady: null
  };

  globals = {

  };

  public currentLocationCoords = null;
  platform: number;
  constructor(
    public storageOps: StorageOpsService
  ) {
    console.log("settings manager service created");
    this.observable = ResourceManager.initBsubObj(this.observable);
    SettingsManagerService.settings.app = AppSettings.settings;
    SettingsManagerService.defaultAppSettings = DeepCopy.deepcopy(SettingsManagerService.settings.app.settings);
  }


  static getUnits() {
    let measurementMode: IMeasurementDisp = measurementModesDisp[SettingsManagerService.settings.app.settings.units.value];
    // console.log(measurementMode);
    return measurementMode;
  }

  static getMeasurementSystem() {
    return SettingsManagerService.settings.app.settings.units.value;
  }

  /**
   * init from local storage if same build id (set in code)
   */
  init(ios: boolean) {
    console.log("settings provider init from local storage");

    if (ios) {
      console.log("using iOS settings");
      let keys: string[] = Object.keys(AppSettings.settings.settings);
      keys.forEach((key: string) => {
        let set: IAnySettings = AppSettings.settings.settings[key];
        if (set.iosValue != null) {
          console.log("iOS update: " + key);
          set.value = set.iosValue;
        }
      });
      console.log("iOS settings replace complete");
    }

    if (!this.settingsLoaded) {
      this.checkStorageSettings().then(() => {
        console.log("storage settings check ", SettingsManagerService.settings.app);

        this.settingsLoaded = true;
        this.observable.settingsLoaded.next(true);
      }).catch((err: Error) => {
        console.error(err);
        // settings load error
        // use default settings and set loaded flag
        this.settingsLoaded = true;
        this.observable.settingsLoaded.next(true);
      });
    } else {
      this.observable.settingsLoaded.next(true);
    }
  }


  getSettings() {
    console.log("settings: ", SettingsManagerService.settings);
    return SettingsManagerService.settings;
  }

  getAppSettings() {
    return SettingsManagerService.settings.app.settings;
  }

  checkSettingsLoaded() {
    return this.observable.settingsLoaded;
  }

  checkTesterModeSet() {
    return this.observable.testerModeSet;
  }

  /**
   * set tester flag 
   * @param testerMode 
   */
  updateTesterMode(testerMode: boolean) {
    console.log("update tester mode: ", testerMode);
    AppSettings.testerMode = testerMode;
    this.observable.testerModeSet.next(true);
  }

  /**
   * resolve (true) when loaded/error
   * important for calls that depend on the loading event rather than the actual result (e.g. gmap waiting for loading)
   * @param checkTester 
   */
  getSettingsLoaded(checkTester: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.checkSettingsLoaded().subscribe((settingsLoaded: boolean) => {
        console.log("check settings loaded: ", settingsLoaded);
        if (settingsLoaded != null) {
          if (settingsLoaded) {
            if (checkTester) {
              this.checkTesterModeSet().subscribe((testerModeLoaded: boolean) => {
                console.log("check tester mode loaded: ", testerModeLoaded);
                if (testerModeLoaded != null) {
                  if (testerModeLoaded) {
                    resolve(true);
                  }
                }
              }, (err: Error) => {
                console.error(err);
                resolve(true);
              });
            } else {
              resolve(true);
            }
          }
        }
      }, (err: Error) => {
        console.error(err);
        resolve(true);
      });
    });

    return promise;
  }

  static checkMobileFeatureEnabled() {
    let platform = SettingsManagerService.settings.platformFlags;
    if (platform.MOBILE) {
      return true;
    }
    if ((platform.ELECTRON || platform.WEB) && AppSettings.testerMode) {
      return true;
    }
    return false;
  }

  static checkWebDevMode() {
    let platform = SettingsManagerService.settings.platformFlags;
    if ((platform.ELECTRON || platform.WEB) && AppSettings.testerMode) {
      return true;
    }
    return false;
  }

  checkFlagsLoaded() {
    return this.observable.flagsLoaded;
  }

  watchPlatformFlagsLoaded() {
    return this.observable.platformFlagsLoaded;
  }

  /**
   * update settings with user options
   */
  updateLocalSettings() {
    let promise = new Promise((resolve, reject) => {
      this.storageOps.setStorageFlag({
        flag: ELocalAppDataKeys.appSettings,
        value: SettingsManagerService.settings.app
      }).then(() => {
        console.log("settings updated ", SettingsManagerService.settings.app);
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  updateLocalSettingsNoAction() {
    this.updateLocalSettings().then(() => {
      console.log("local settings updated");
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * update settings element by code
   * @param code 
   * @param value 
   */
  updateSettingsElement(code: number, value: any) {
    console.log("update settings element " + code + ": " + value);
    let set: IAppSettingsContent = SettingsManagerService.settings.app.settings;
    let keys: string[] = Object.keys(set);
    let updated: boolean = false;
    for (let i = 0; i < keys.length; i++) {
      if (set[keys[i]].code === code) {
        set[keys[i]].value = value;
        updated = true;
        break;
      }
    }
    return updated;
  }

  /**
   * reset to defaults (defined in code)
   */
  resetDefaults() {
    SettingsManagerService.settings.app.settings = DeepCopy.deepcopy(SettingsManagerService.defaultAppSettings);
    this.storageOps.setStorageFlag({
      flag: ELocalAppDataKeys.appSettings,
      value: SettingsManagerService.settings.app
    }).then(() => {
      console.log("settings updated ", SettingsManagerService.settings.app);
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * retrieve storage settings
   * check for same/different build number
   * sync with server if required
   */
  checkStorageSettings() {
    let promise = new Promise((resolve, reject) => {
      this.storageOps.getLocalDataKey(ELocalAppDataKeys.appSettings).then((storageSettings: IAppSettings) => {
        if (storageSettings !== null) {
          // local storage is not empty
          console.log("local storage settings build: ", storageSettings.build);
          console.log("app build: ", AppSettings.build);
          if (storageSettings.build !== null && storageSettings.build === AppSettings.build) {
            // same build
            console.log("build code is the same, keep existing local storage settings");
            // keep existing local storage settings
            SettingsManagerService.settings.app.settings = storageSettings.settings;
            SettingsManagerService.settings.app.build = storageSettings.build;
            resolve(true);
          } else {
            SettingsManagerService.settings.app.build = AppSettings.build;
            console.log("build code changed, update local settings, sync with existing");

            this.syncStorageSettings(storageSettings.settings).then((settings: IAppSettingsContent) => {
              SettingsManagerService.settings.app.build = AppSettings.build;
              SettingsManagerService.settings.app.settings = settings;
              console.log("synced settings: ", settings);
              // update synced values, mostly because the build changed
              this.updateLocalSettings().then(() => {
                resolve(true);
              }).catch((_err) => {
                resolve(false);
              });
            }).catch((err: Error) => {
              console.error(err);
              resolve(true);
            });
          }
        } else {
          console.log("no local storage settings, syncing");
          // use settings from code and add custom sync values from server
          this.syncStorageSettings(SettingsManagerService.settings.app.settings).then((settings: IAppSettingsContent) => {
            SettingsManagerService.settings.app.build = AppSettings.build;
            SettingsManagerService.settings.app.settings = settings;
            // update synced values
            this.updateLocalSettings().then(() => {
              resolve(true);
            }).catch((_err) => {
              resolve(false);
            });
          }).catch((err: Error) => {
            console.error(err);
            resolve(true);
          });
        }
      }).catch((err: Error) => {
        // storage error!
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }


  /**
   * add new settings from code to local storage
   * remove unused settings from code to local storage
   * update the definitions of existing settings (e.g. changing name, etc)
   * @param storageSettings 
   */
  syncStorageSettings(storageSettings: IAppSettingsContent) {
    let promise = new Promise((resolve, reject) => {
      console.log("sync storage settings for incremental builds");
      let codeSettings: IAppSettingsContent = SettingsManagerService.settings.app.settings;
      let codeKeys: string[] = Object.keys(codeSettings);
      let storageKeys: string[] = Object.keys(storageSettings);

      let serverSyncPromises: Promise<boolean>[] = [];

      // check group and change it if required (rearrange)
      // update internal properties e.g. hidden
      // actually only the value should be kept the same as local storage
      // (where reset == false)
      for (let i = 0; i < storageKeys.length; i++) {
        let kstorage: string = storageKeys[i];
        for (let j = 0; j < codeKeys.length; j++) {
          let kcode: string = codeKeys[j];
          if (kstorage === kcode) {
            // update the entry
            let stset: IAnySettings = storageSettings[kstorage];
            let cset: IAnySettings = codeSettings[kcode];
            // let storageSetCodeOptions: ISettingsCodeOptions = stset.codeOptions;
            // let codeSetCodeOptions: ISettingsCodeOptions = cset.codeOptions;
            // // get the keys of the settings from CODE (may be updated)
            // let codeKeys: string[] = Object.keys(codeSetCodeOptions);

            // // add the keys to the storage as well (you never know)
            // for (let i = 0; i < codeKeys.length; i++) {
            //   storageSetCodeOptions[codeKeys[i]] = codeSetCodeOptions[codeKeys[i]];
            // }

            stset.codeOptions = cset.codeOptions;

            // update change-able params
            stset.name = cset.name;
            stset.type = cset.type;
            // update options (sync)
            stset.options = cset.options;

            if (stset.options) {
              let optionKeys: string[] = Object.keys(stset.options);
              let found: boolean = false;
              for (let i = 0; i < optionKeys.length; i++) {
                let key: string = optionKeys[i];
                if (stset.options[key].value === stset.value) {
                  console.log("update existing options extend: " + stset.name);
                  found = true;
                  break;
                }
              }

              // reset option if previous option was removed or reset flag is set
              if (!found) {
                console.log("reset options (not found): " + stset.name);
                stset.value = cset.value;
              }
            }

            // reset option if previous option was removed or reset flag is set
            if (cset.codeOptions.reset) {
              console.log("reset options: " + stset.name);
              stset.value = cset.value;
            }

            // if (Object.keys(stset.options).filter((key) => stset.options[key].value === stset.value).length > 0) {
            //   // keep value, update options (extend)
            //   console.log("update existing options extend: " + stset.name);
            // } else {
            //   // reset to new default value and update options (replace)
            //   console.log("reset options: " + stset.name);
            //   stset.value = cset.value;
            // }

            // if (storageSetCodeOptions.serverSync == null) {
            //   storageSetCodeOptions.serverSync = codeSettings[kstorage].serverSync;
            // }
            break;
          }
        }
      }

      // add new
      let existing: boolean = false;
      for (let i = 0; i < codeKeys.length; i++) {
        let kcode = codeKeys[i];
        existing = false;
        for (let j = 0; j < storageKeys.length; j++) {
          let kstorage = storageKeys[j];
          if (kstorage === kcode) {
            existing = true;
            // check if force reset flag is enabled
            if (codeSettings[kstorage].reset) {
              storageSettings[kstorage] = codeSettings[kcode];
            }
            break;
          }
        }
        // the setting does not exist in local storage, it was added in new build
        // so it has to be added to the local storage
        if (!existing) {
          storageSettings[kcode] = codeSettings[kcode];
        }
      }

      // remove unused
      for (let i = 0; i < storageKeys.length; i++) {
        let kstorage = storageKeys[i];
        existing = false;
        for (let j = 0; j < codeKeys.length; j++) {
          let kcode = codeKeys[j];
          if (kstorage === kcode) {
            existing = true;
            break;
          }
        }
        // the setting from local storage does not exist anymore in the code, it was removed in the new build
        // so it has to be removed from local storage
        if (!existing) {
          delete storageSettings[kstorage];
        }
      }

      if (serverSyncPromises.length === 0) {
        resolve(storageSettings);
        return;
      }

      // wait for server sync (server->app)
      Promise.all(serverSyncPromises).then(() => {
        resolve(storageSettings);
      }).catch((err: Error) => {
        reject(err);
      });

    });
    return promise;
  }


  getPlatform() {
    return SettingsManagerService.settings.platform;
  }


  /**
   * set the platforms object so that it reflects the current platform
   * set the platforms object so that it reflects the current mobile platform too
   * e.g. 
   * platform == WEB
   * 
   * WEB: true,
   * MOBILE: false,
   * MOBILE_LIVERELOAD: false
   */
  setPlatform(platform: number, notify: boolean) {
    SettingsManagerService.settings.platform = platform;
    console.log("settings provider set platform: " + platform);
    let platformTypeNames = Object.keys(EPlatformDef);

    for (let i = 0; i < platformTypeNames.length; i++) {
      let element: string = platformTypeNames[i];
      if (platform === EPlatformDef[element]) {
        SettingsManagerService.settings.platformFlags[element] = true;
        break;
      }
    }

    console.log(SettingsManagerService.settings.platformFlags);

    let pflags: IPlatformFlags = SettingsManagerService.settings.platformFlags;
    if (pflags.WEB) {
      GeneralCache.isWeb = true;
    }

    if (notify) {
      // the behavior subject emits the last value to new subscribers by default
      this.observable.platformFlagsLoaded.next(true);
    }
  }

  /**
   * set map ready i.e. google maps script loaded
   */
  setMapReadyWatch() {
    this.observable.mapReady.next(true);
  }

  /**
   * check map ready i.e. google maps script loaded
   */
  checkMapReady(): Promise<boolean> {
    return WaitUtils.waitObsTimeout(this.observable.mapReady, true, null);
  }
}
