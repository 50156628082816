
export enum EModalTypes {
    description = 0,
    options = 1,
    checkbox = 3,
    checkboxGrid = 4
}

export interface IWidgetContainer<tag> {
    id: number;
    element: tag;
}

export interface IAlertReturnData {
    code: number;
    data: any;
}



