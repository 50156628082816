
import { NgModule } from '@angular/core';
import { LeplaceVerticalGaugeComponent } from './components/leplace-vertical-gauge/leplace-vertical-gauge.component';
import { GameItemComponent } from './components/game-item/game-item.component';
import { GameItemDispComponent } from './components/game-item-disp/game-item-disp.component';
import { KVTableComponent } from './components/kv-table/kv-table.component';
import { LeplacePolarChartComponent } from './components/leplace-polar-chart/leplace-polar-chart.component';
import { LocationInfoComponent } from './components/location-info/location-info.component';
import { PlaceCardComponent } from './components/place-card/place-card.component';
import { PlaceItemsCardComponent } from './components/place-items-card/place-items-card.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { DynamicSlidesComponent } from './components/dynamic-slides/dynamic-slides.component';
import { DynamicContentComponent } from './components/dynamic-content/dynamic-content.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { SponsorCardComponent } from './components/sponsor-card/sponsor-card.component';
import { SupportCategoryComponent } from './components/support-category/support-category.component';
import { GenericComponentsModule } from '../generic/generic-components.module';
import { ComponentsModule } from '../components.module';
import { BplaceCardComponent } from './components/bplace-card/bplace-card.component';
import { UserCreditCardComponent } from './components/user-credit-card/user-credit-card.component';
import { StoryInfoComponent } from './components/story-info/story-info.component';
import { LocationDotsVComponent } from './components/location-dots-v/location-dots-v.component';
import { StoryInfoSpecComponent } from './components/story-info-spec/story-info-spec.component';
import { ReviewEntryComponent } from './components/review-entry/review-entry.component';
import { QuestItemComponent } from './components/quest-item/quest-item.component';
import { AutocompletePlaceComponent } from './components/autocomplete-place/autocomplete-place.component';
import { RegisteredPlaceComponent } from './components/registered-place/registered-place.component';
import { TreasureSpecCardComponent } from './components/treasure-spec-card/treasure-spec-card.component';
import { StoryInfoCatalogComponent } from './components/story-info-catalog/story-info-catalog.component';
import { IapCardComponent } from './components/iap-card/iap-card.component';
import { StripeCardComponent } from './components/stripe-card/stripe-card.component';

const components = [
  AutocompletePlaceComponent,
  BplaceCardComponent,
  GameItemComponent,
  GameItemDispComponent,
  KVTableComponent,
  LeplacePolarChartComponent,
  LeplaceVerticalGaugeComponent,
  LocationInfoComponent,
  LocationDotsVComponent,
  PlaceCardComponent,
  PlaceItemsCardComponent,
  QuestItemComponent,
  RegisteredPlaceComponent,
  ReviewEntryComponent,
  GridItemComponent,
  IapCardComponent,
  DynamicSlidesComponent,
  DynamicContentComponent,
  DynamicListComponent,
  DynamicGridComponent,
  SponsorCardComponent,
  SupportCategoryComponent,
  UserCreditCardComponent,
  StoryInfoCatalogComponent,
  StoryInfoComponent,
  StoryInfoSpecComponent,
  StripeCardComponent,
  TreasureSpecCardComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        GenericComponentsModule,
        ComponentsModule
    ],
    exports: [...components]
})
export class AppComponentsModule { }
