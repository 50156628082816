import { Injectable } from '@angular/core';
import { StoryDataHelpers } from './story-helpers';
import { LatLng } from '@ionic-native/google-maps/ngx';
import { IStory, IStoriesPageResponse, IStoryResponse, IStoriesPageRequest } from '../../classes/def/core/story';
import { IGenericResponse } from '../../classes/def/requests/general';
import { GenericDataService } from '../general/data/generic';
import { ILocationItemsDef, ILocationContainer } from '../../classes/def/places/backend-location';
import { InventoryDataService } from './inventory';
import { IStoryCategory } from '../../classes/def/core/category';
import { IEventGroupLinkData } from 'src/app/classes/def/events/links';
import { LocationUtilsWizard } from '../location/location-utils-wizard';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { IPhotoUploadsGalleryResult, IPhotoGalleryGroupValidateRequest } from 'src/app/classes/def/editor/photo-gallery';

export interface IOpenStoryList {
  resp: IStoriesPageResponse,
  cityId: number
}

@Injectable({
  providedIn: 'root'
})
export class StoryDataService {
  public openStory: IStory;
  public openStoryList: IOpenStoryList;
  public openCategoryList: IStoryCategory[];

  constructor(
    public generic: GenericDataService,
    public inventory: InventoryDataService,
    public locationUtilsWizard: LocationUtilsWizard
  ) {
    console.log("story data service created");
  }

  syncOpenStoryList(story: IStory) {
    if (this.openStoryList) {
      for (let i = 0; i < this.openStoryList.resp.stories.length; i++) {
        if (this.openStoryList.resp.stories[i].id === story.id) {
          this.openStoryList.resp.stories[i] = story;
        }
      }
    }
  }

  /**
   * get additional details for the story location
   * e.g. items 
   * @param storyId 
   * @param locationId the story location id that is fixed in the story 
   */
  getStoryLocationDetailsWithCache(storyId: number, locationId: number) {
    let promise = new Promise((resolve, reject) => {
      let load: boolean = false;
      let details: ILocationItemsDef = {
        items: []
      };
      let location: ILocationContainer = null;
      if (this.openStory.id === storyId) {
        location = this.openStory.locs.find(location => location.merged.id === locationId);
      } else {
        if (this.openStoryList) {
          for (let i = 0; i < this.openStoryList.resp.stories.length; i++) {
            if (this.openStoryList.resp.stories[i].id === storyId) {
              location = this.openStoryList[i].locations.find(location => location.id === locationId);
              break;
            }
          }
        }
      }

      if (!(location && location.merged.items)) {
        load = true;
      } else {
        details.items = location.merged.items;
      }

      if (load) {
        console.log("load details");
        this.generic.genericPostStandard("/stories/get-story-location-details", {
          storyId: storyId,
          locationId: locationId
        }).then((response: IGenericResponse) => {
          let details: ILocationItemsDef = response.data;
          location.merged.items = details.items;
          resolve(details);
        }).catch((err: Error) => {
          location.merged.items = [];
          reject(err);
        });
      } else {
        console.log("get cached details");
        resolve(details);
      }
    });
    return promise;

  }


  /**
   * get all stories from the backend that are on the same page with the story having the specified name
   * the stories don't contain details
   * they are added to the local object to be shown in the story list
   */
  async getAllBusinessStoriesFromServerSearchByName(coords: LatLng, categoryCode: number, providerId: number, cityId: number, name: string, isMarketplace: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      let req: IStoriesPageRequest = {
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        categoryCode: categoryCode,
        providerId: providerId,
        cityId: cityId,
        name: name
      };
      this.getAllStoriesFromServerCore(req, isMarketplace).then((data: IStoriesPageResponse) => {
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * get all stories from the backend
   * the stories don't contain details
   * they are added to the local object to be shown in the story list
   */
  getAllBusinessStoriesFromServer(coords: LatLng, categoryCode: number, providerId: number, cityId: number, releaseFilter: number[], orderBy: number, sort: number, page: number, isMarketplace: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      let req: IStoriesPageRequest = {
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        categoryCode: categoryCode,
        providerId: providerId,
        cityId: cityId,
        page: page,
        releaseFilter: releaseFilter,
        orderBy: orderBy,
        sort: sort
      };
      this.getAllStoriesFromServerCore(req, isMarketplace).then((data: IStoriesPageResponse) => {
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getAllStoriesFromServerCore(req: IStoriesPageRequest, isMarketplace: boolean) {
    let promise = new Promise((resolve, reject) => {
      let route: string = "/business/stories/get-all-stories";
      if (isMarketplace) {
        route = "/business/stories/get-marketplace-stories";
      }
      console.log(req);
      if (req.page == null && req.name != null) {
        route = "/business/stories/search-by-name";
      }
      this.generic.genericPostStandard(route, req).then((response: IGenericResponse) => {
        if (response.status) {
          let stories: IStory[] = [];
          let responseData: IStoriesPageResponse = response.data;
          for (let i = 0; i < responseData.stories.length; i++) {
            let story: IStory = responseData.stories[i];
            story = StoryDataHelpers._formatStoryFromBackend(story);
            stories.push(story);
          }
          console.log(stories);
          let responseDataFormat: IStoriesPageResponse = {
            stories: stories,
            page: responseData.page,
            pages: responseData.pages
          };

          this.openStoryList = {
            resp: responseDataFormat,
            cityId: req.cityId
          };

          resolve(responseDataFormat);
        } else {
          reject(new Error(response.message));
        }
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  getStoryCategoriesFromServer(): Promise<IStoryCategory[]> {
    let promise: Promise<IStoryCategory[]> = new Promise((resolve, reject) => {
      this.generic.genericGetStandard("/stories/get-categories", {}).then((response: IGenericResponse) => {
        this.openCategoryList = response.data;
        resolve(response.data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getStoryConnectLink(index: string): Promise<string> {
    return this.generic.genericGetNoAuthWData("/stories/get-connect-link-by-index", {
      index: index
    });
  }

  /**
   * get a story from server
   * save it into the local object (buffer)
   * update if existing story with id
   * @param storyId 
   */
  getStoryFromServer(storyId: number, coords: LatLng, eventGroupLinkData: IEventGroupLinkData): Promise<IStoryResponse> {
    let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
      let story: IStory;
      let req = {
        storyId: storyId,
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        eventGroupLinkData: eventGroupLinkData
      };

      this.generic.genericPostStandard("/business/stories/get-story", req).then((response: IGenericResponse) => {
        let data: IStoryResponse = response.data;
        if (!data.locked) {
          story = StoryDataHelpers._formatStoryFromBackend(data.story);
          this.openStory = story; // cache the open story
        }
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getCurrentOpenStory(storyId: number, coords: LatLng, eventGroupLinkData: IEventGroupLinkData): Promise<IStoryResponse> {
    let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
      if (this.openStory) {
        let storyResp: IStoryResponse = {
          story: this.openStory,
          locked: false,
          masterLock: null,
          message: "ok"
        };
        resolve(storyResp);
      } else {
        this.getStoryFromServer(storyId, coords, eventGroupLinkData).then((data: IStoryResponse) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCurrentStoryList(coords: LatLng, categoryCode: number, providerId: number, cityId: number, releaseStatus: number[], orderBy: number, sort: number, page: number, isMarketplace: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      if (this.openStoryList && (this.openStoryList.cityId === cityId)) {
        resolve(this.openStoryList.resp);
      } else {
        this.getAllBusinessStoriesFromServer(coords, categoryCode, providerId, cityId, releaseStatus, orderBy, sort, page, isMarketplace).then((data: IStoriesPageResponse) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCurrentCategoryList(): Promise<IStoryCategory[]> {
    let promise: Promise<IStoryCategory[]> = new Promise((resolve, reject) => {
      if (this.openCategoryList) {
        resolve(this.openCategoryList);
      } else {
        this.getStoryCategoriesFromServer().then((data) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCustomStoryTreasures(storyId: number, location: LatLng, scanRadius: number): Promise<ILeplaceTreasure[]> {
    let promise: Promise<ILeplaceTreasure[]> = new Promise((resolve, reject) => {
      let req = {
        storyId: storyId,
        lat: location.lat,
        lng: location.lng,
        scanRadius: scanRadius
      };
      this.generic.genericPostStandardWData("/stories/custom-map/get-treasures", req).then((response: ILeplaceTreasure[]) => {
        resolve(response);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getPhotoUploadsGallery(storyLocationId: number, page: number, startDate: string, endDate: string): Promise<IGenericResponse> {
    let req = {
      storyLocationId: storyLocationId,
      page: page,
      startDate: startDate,
      endDate: endDate
    };
    return this.generic.genericPostStandardWData("/stories/validation/get-photo-uploads-gallery", req);
  }

  validateCheckpoint(userStoryLocationId: number, validated: number): Promise<IPhotoUploadsGalleryResult> {
    let req = {
      userStoryLocationId: userStoryLocationId,
      validated: validated
    };
    return this.generic.genericPostStandardWData("/stories/validation/validate-ext", req);
  }

  validateCheckpointsGen(playerId: number, storyLocationId: number, validated: number): Promise<IPhotoUploadsGalleryResult> {
    let req = {
      playerId: playerId,
      storyLocationId: storyLocationId,
      validated: validated
    };
    return this.generic.genericPostStandardWData("/stories/validation/validate-ext-gen", req);
  }

  validateCheckpointsGenMulti(data: IPhotoGalleryGroupValidateRequest[]): Promise<IPhotoUploadsGalleryResult> {
    let req = {
      data: data
    };
    return this.generic.genericPostStandardWData("/stories/validation/validate-ext-gen-multi", req);
  }
}
