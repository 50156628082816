import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IActionLayer } from 'src/app/classes/def/core/objects';

@Component({
  selector: 'checkbox-card',
  templateUrl: './checkbox-card.component.html',
  styleUrls: ['./checkbox-card.component.scss'],
})
export class CheckboxCardComponent implements OnInit, OnDestroy {

  @Input()
  data: IActionLayer;

  @Output()
  action: EventEmitter<IActionLayer> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit() {
    if (this.data) {

    }
  }

  ngOnDestroy() {

  }

  onCheck(_event: any) {
    console.log("on check");
    this.action.emit(this.data);
  }

  onCheckToggle(_event: any) {
    if (!this.data) {
      return;
    }
    this.data.enabled = !this.data.enabled;
    this.action.emit(this.data);
  }
}