import { UiExtensionService } from './../ui/ui-extension';
import { IYouTubeViewFrameNavParams, YouTubeViewFrameComponent } from './../../../modals/generic/modals/youtube-view-frame/youtube-view-frame.component';
import { EOS } from './../../../classes/def/app/app';
import { GeneralCache } from './../../../classes/app/general-cache';
/**
 * cordova-plugin-ionic-keyboard Provider
 * Diego Castro <ing.diegocastro@gmail.com>
 *
 */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { IVideoRef } from '../../../classes/def/media/videos';


@Injectable({
    providedIn: 'root'
})
export class YouTubeService {

    constructor(
        public plt: Platform,
        public youtube: YoutubeVideoPlayer,
        public resources: ResourcesCoreDataService,
        public uiext: UiExtensionService
    ) {
        console.log("you tube service created");
    }

    open(id: string, title?: string) {
        if (GeneralCache.os === EOS.android) {
            this.youtube.openVideo(id);
        } else {
            this.openIframe(id, title ? title : "YouTube Video");
        }
    }

    openIframe(videoId: string, title: string) {
        let p: IYouTubeViewFrameNavParams = {
            videoId: videoId,
            videoUrl: null,
            single: true,
            title: title
        };
        this.uiext.showCustomModal(null, YouTubeViewFrameComponent, {
            view: {
                fullScreen: false,
                transparent: false,
                large: true,
                addToStack: true,
                frame: false
            },
            params: p
        }).then(() => {

        }).catch((err: Error) => {
            console.error(err);

        });
    }

    openWithCode(code: number) {
        this.resources.getVideoTutorial(code).then((response: IGenericResponse) => {
            let video: IVideoRef = response.data;
            if (video) {
                this.open(video.videoId, video.name);
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

}
