import { Injectable } from '@angular/core';
import { ApiDef, EAppId } from '../../classes/app/api';
import { GenericDataService } from '../general/data/generic';
import { IGenericResponse, IPageResponse } from '../../classes/def/requests/general';
import { IGameItem, IGameItemCategoryWithItems, IGameItemIAP } from '../../classes/def/items/game-item';
import { LatLng } from '@ionic-native/google-maps/ngx';
import { IActivityRewardItem } from '../../classes/def/places/backend-location';
import { IRemoveItemResponse, IRemovedMultipleItemsResponse } from '../../classes/def/requests/inventory';
import { IUserStatsCache, IUserStatsCacheElement, EInventorySync } from 'src/app/classes/def/items/inventory';
import { AnalyticsService } from '../general/apis/analytics';
import { ETrackedEvents } from 'src/app/classes/app/analytics';

@Injectable({
    providedIn: 'root'
})
export class InventoryDataService {
    serverUrl: string;

    localCache: IUserStatsCache = {
        items: {
            cache: null,
            inSync: false,
            enable: true
        },
        itemCategories: {
            cache: null,
            inSync: false,
            enable: true
        },
        categories: {
            cache: null,
            inSync: false,
            enable: true
        },
        publicCategories: {
            cache: null,
            inSync: false,
            enable: true
        },
        publicCategoriesStory: {
            cache: null,
            inSync: false,
            enable: true
        },
        coins: {
            cache: null,
            inSync: false,
            enable: true
        }
    };

    lastRemoveItemData: IRemoveItemResponse = null;

    constructor(
        public generic: GenericDataService,
        public analytics: AnalyticsService
        // public uiext: UiExtensionService
    ) {
        console.log("inventory data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {

    }


    /**
     * reset all flags
     * e.g. switch account
     */
    resetAllFlags() {
        let flagNames: string[] = Object.keys(this.localCache);
        for (let i = 0; i < flagNames.length; i++) {
            this.resetSyncFlag(flagNames[i]);
        }
    }

    /**
     * an achievement might have been unlocked
     * reload inventory flag
     */
    resetSyncFlag(type: string) {
        // an achievement might be unlocked
        // this.localCache.items.inSync = false;
        if (this.checkSyncFlagExists(type)) {
            console.log("reset sync flag: " + type);
            this.localCache[type].inSync = false;
        }
    }

    private checkSyncFlagExists(type: string) {
        let keys = Object.keys(this.localCache);
        if (keys.indexOf(type) !== -1) {
            return true;
        }
        return false;
    }

    getSyncFlag(type: string) {
        if (this.checkSyncFlagExists(type)) {
            return this.localCache[type].inSync;
        }
        return false;
    }

    getSyncCache(type: string) {
        if (this.checkSyncFlagExists(type)) {
            return this.localCache[type].cache;
        }
        return null;
    }

    setSyncCache(type: string, cache: any) {
        if (this.checkSyncFlagExists(type)) {
            this.localCache[type].cache = cache;
            this.localCache[type].inSync = true;
        }
    }


    /**
     * check if the cache is enabled and initialized
     * @param e 
     */
    private checkCache(e: IUserStatsCacheElement) {
        if (e.enable && e.inSync && e.cache != null) {
            return true;
        }
        return false;
    }

    private checkCachedInventoryCategoryPage(cache: IPageResponse[], code: number) {
        if (!(cache && cache.length > 0)) {
            return -1;
        }

        for (let i = 0; i < cache.length; i++) {
            let cachedData: IGameItemCategoryWithItems = cache[i].data;
            if (cachedData.code === code) {
                return i;
            }
        }
        return -1;
    }

    /**
     * not working
     * @param cache 
     */
    private checkCachedInventoryPage(cache: IPageResponse[]) {
        if (!(cache && cache.length > 0)) {
            return -1;
        }

        for (let i = 0; i < cache.length; i++) {
            // not good
            let cachedData: IGameItemCategoryWithItems = cache[i].data;
            return i;
        }
        return -1;
    }

    /**
     * get user inventory headers (category names)
     * cache locally for data optimization
     */
    getUserInventoryHeaders(reload: boolean, currentLocation: LatLng): Promise<IGameItemCategoryWithItems[]> {
        let promise: Promise<IGameItemCategoryWithItems[]> = new Promise((resolve, reject) => {
            // console.log(reload, this.localCache.categories);
            if (!reload && this.checkCache(this.localCache.categories)) {
                resolve(this.localCache.categories.cache);
                return;
            }
            this.generic.genericGetStandard("/inventory/get-headers", {
                lat: currentLocation ? currentLocation.lat : null,
                lng: currentLocation ? currentLocation.lng : null
            }).then((resp: IGenericResponse) => {
                let data: IGameItemCategoryWithItems[] = resp.data;
                if (data && data.length > 0) {
                    this.localCache.categories.cache = data;
                    this.localCache.categories.inSync = true;
                }
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get public user inventory headers (category names)
     * cache locally for data optimization
     */
    getLeaderboardInventoryHeaders(leaderId: number, story: boolean): Promise<IGameItemCategoryWithItems[]> {
        let promise: Promise<IGameItemCategoryWithItems[]> = new Promise((resolve, reject) => {
            // console.log(reload, this.localCache.categories);
            let cache: IUserStatsCacheElement = story ? this.localCache.publicCategoriesStory : this.localCache.publicCategories;
            if (this.checkCache(cache)) {
                resolve(cache.cache);
                return;
            }
            this.generic.genericGetStandard("/inventory/get-headers-leaderboard", {
                leaderId: leaderId,
                story: story,
                appId: EAppId.world
            }).then((resp: IGenericResponse) => {
                let data: IGameItemCategoryWithItems[] = resp.data;
                if (data && data.length > 0) {
                    cache.cache = data;
                    cache.inSync = true;
                }
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * get user inventory
     * cache locally for data optimization
     */
    getItemsCategory(reload: boolean, categoryCode: number, currentLocation: LatLng, page: number): Promise<IPageResponse> {
        let promise: Promise<IPageResponse> = new Promise((resolve, reject) => {

            // console.log(reload, this.localCache.items);
            if (!reload && this.checkCache(this.localCache.items)) {
                let index: number = this.checkCachedInventoryCategoryPage(this.localCache.items.cache, categoryCode);
                // console.log(index);
                if (index !== -1) {
                    resolve(this.localCache.items.cache[index]);
                    return;
                }
            }

            this.generic.genericPostStandard("/inventory/get-inventory-category", {
                category: categoryCode,
                lat: currentLocation ? currentLocation.lat : null,
                lng: currentLocation ? currentLocation.lng : null,
                page
            }).then((resp: IGenericResponse) => {
                let pageResp: IPageResponse = resp.data;
                let dataResp: IGameItemCategoryWithItems = pageResp.data;

                let existingEntry: boolean = false;
                if (this.localCache.items.cache && this.localCache.items.cache.length > 0) {
                    for (let i = 0; i < this.localCache.items.cache.length; i++) {
                        if (this.localCache.items.cache[i].data.code === dataResp.code) {
                            this.localCache.items.cache[i] = pageResp;
                            existingEntry = true;
                            break;
                        }
                    }
                }
                if (!existingEntry) {
                    if (!this.localCache.items.cache) {
                        this.localCache.items.cache = [];
                    }
                    this.localCache.items.cache.push(pageResp);
                }

                this.localCache.items.inSync = true;

                resolve(pageResp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
    * get user inventory
    * cache locally for data optimization
    */
    getInventory(reload: boolean, categories: number[], currentLocation: LatLng, page: number): Promise<IPageResponse> {
        let promise: Promise<IPageResponse> = new Promise((resolve, reject) => {

            // console.log(reload, this.localCache.items);
            if (!reload && this.checkCache(this.localCache.items)) {
                let index: number = this.checkCachedInventoryPage(this.localCache.itemCategories.cache);
                // console.log(index);
                if (index !== -1) {
                    resolve(this.localCache.items.cache[index]);
                    return;
                }
            }

            this.generic.genericPostStandard("/inventory/get-inventory", {
                categories: categories,
                lat: currentLocation ? currentLocation.lat : null,
                lng: currentLocation ? currentLocation.lng : null,
                page: page
            }).then((resp: IGenericResponse) => {
                let pageResp: IPageResponse = resp.data;
                let dataResp: IGameItemCategoryWithItems = pageResp.data;

                let existingEntry: boolean = false;
                if (this.localCache.items.cache && this.localCache.items.cache.length > 0) {
                    for (let i = 0; i < this.localCache.items.cache.length; i++) {
                        if (this.localCache.items.cache[i].data.code === dataResp.code) {
                            this.localCache.items.cache[i] = pageResp;
                            existingEntry = true;
                            break;
                        }
                    }
                }
                if (!existingEntry) {
                    if (!this.localCache.items.cache) {
                        this.localCache.items.cache = [];
                    }
                    this.localCache.items.cache.push(pageResp);
                }

                this.localCache.items.inSync = true;

                resolve(pageResp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get game item
     */
    getItemSpec(itemCode: number): Promise<IGameItem> {
        return this.generic.genericGetStandardWData("/dex/items/get-item-spec", {
            itemCode: itemCode
        });
    }

    /**
     * get user inventory
     * cache locally for data optimization
     * return active item list
     */
    getActiveItemList(_reload: boolean, categories: number[]) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/inventory/get-active-items", {
                categories
            }).then((resp: IGenericResponse) => {
                let activeItems: IGameItem[] = resp.data;
                resolve(activeItems);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get all registered iap products in db
     */
    getItemIapList(): Promise<IGameItemIAP[]> {
        let promise: Promise<IGameItemIAP[]> = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/dex/items/get-iap-items", null).then((resp: IGenericResponse) => {
                let activeItems: IGameItemIAP[] = resp.data;
                resolve(activeItems);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get user coins
     */
    getAllCoins(reload: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (!reload && this.checkCache(this.localCache.coins)) {
                resolve(this.localCache.coins.cache);
                return;
            }
            this.generic.genericGetStandard("/stats/get-all-coins", null).then((resp: IGenericResponse) => {
                this.localCache.coins.cache = resp.data.coins;
                this.localCache.coins.inSync = true;
                resolve(this.localCache.coins.cache);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;

    }

    /**
     * buy inventory item with virtual coins
     * item is available immediately
     */
    buyInventoryItemWithCoins(itemCode: number, amount: number): Promise<IGenericResponse> {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);

        return new Promise<IGenericResponse>((resolve, reject) => {
            this.generic.genericPostStandard("/inventory/buy-item-coins", {
                itemCode,
                amount
            }).then((res: IGenericResponse) => {
                this.analytics.sendCustomEvent(ETrackedEvents.spendCoins, "done", "inventory", itemCode, true);
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }


    /**
     * collect items in game
     * no coins will be spent
     * @param itemCode 
     */
    collectMultipleItemsActivityFinished(items: IActivityRewardItem[]) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        return this.generic.genericPostStandard("/inventory/add-multiple-items-activity-finished", {
            items
        });
    }

    /**
     * buy inventory item with IAP
     * item is added to pending list
     * @param itemCode 
     */
    buyInventoryItemSave(itemCode: number) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);
        return this.generic.genericPostStandard("/inventory/register-purchase", {
            itemCode
        });
    }

    /**
     * confirm buy inventory item with IAP
     * item is removed from pending list and added to available list
     * @param itemCode 
     */
    buyInventoryItemConfirm(itemCode: number, data: any) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);
        return this.generic.genericPostStandard("/inventory/confirm-purchase", {
            itemCode,
            data
        });
    }

    /**
     * activate inventory item
     * item is set as active
     * if the item is consumable it is removed from available list too
     */
    activateInventoryItem(itemCode: number, qrCode: string) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);
        return this.generic.genericPostStandard("/inventory/activate-item", {
            itemCode,
            qrCode
        });
    }

    /**
     * activate inventory item
     * item is set as active
     * if the item is consumable it is removed from available list too
     */
    requestItemOrder(itemCode: number) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);
        return this.generic.genericPostStandard("/business/orders/request-item-order", {
            itemCode
        });
    }

    /**
     * remove inventory item
     * e.g. when it is used in the game
     * e.g. on demand
     * @param itemCode 
     */
    removeInventoryItem(itemCode: number, amount: number) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);

        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/inventory/remove-item", {
                itemCode,
                amount
            }).then((res: IGenericResponse) => {
                let data: IRemoveItemResponse = res.data;
                this.lastRemoveItemData = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getLastRemoveItemData() {
        return this.lastRemoveItemData;
    }


    retryActivity() {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);

        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/actions/retry-activity", {

            }).then((res: IGenericResponse) => {
                let data: IRemovedMultipleItemsResponse = res.data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * remove pending inventory item
     * i.g. the item that is waiting for IAP purchase to complete
     * @param itemCode 
     */
    removePendingInventoryItem(itemCode: number, amount: number) {
        // the item might be added
        this.resetSyncFlag(EInventorySync.items);
        // coins might be added
        this.resetSyncFlag(EInventorySync.coins);
        return this.generic.genericPostStandard("/inventory/remove-pending-item", {
            itemCode,
            amount
        });
    }
}
