
import { Injectable } from "@angular/core";
import { GenericDataService } from "../data/generic";
import { AnalyticsTemplateService } from './analytics-template';

// event tracking on leplace backend
@Injectable({
    providedIn: 'root'
})
export class AnalyticsLeplaceService implements AnalyticsTemplateService {
    constructor(
        public generic: GenericDataService
    ) {

    }

    init() {
        let promise = new Promise((resolve) => {
            resolve(true);
        });
        return promise;
    }

    /**
     * set user id from the db
     * @param userId 
     */
    setUserId(userId: number) {
        console.log("set user id: ", userId);
    }

    /**
     * track view (changes)
     * @param title 
     */
    trackView(title: string) {
        console.log("track view: " + title);
    }

    /**
     * send event to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEvent(category: string, action: string, label: string, _value: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.generic.genericPostStandard("/logging/app/post-log", {
                event: category,
                label: label,
                data: action
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }
}
