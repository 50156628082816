import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarModalOptions, CalendarModal, CalendarComponentOptions } from 'ion2-calendar';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { IDateSelectorEntry } from 'src/app/modals/generic/modals/date-selector/date-selector.component';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';

@Component({
  selector: 'date-input-selector',
  templateUrl: './date-input-selector.component.html',
  styleUrls: ['./date-input-selector.component.scss']
})
export class DateInputSelectorComponent implements OnInit, OnDestroy {

  @Input()
  selector: IDateSelectorEntry;

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter();

  rightBtnIcon: string = "close-circle";
  appIcons = EAppIcons;
  customPickerOptions: any;
  selectors: IDateSelectorEntry[] = [];
  rangeSelectors: IDateSelectorEntry[] = [];

  loaded: boolean = false;

  range: boolean = false;

  optionsRange: CalendarComponentOptions = {
    pickMode: 'range'
  };

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService
  ) {

  }

  setup() {
    let ts: Date = new Date();
    ts.setSeconds(0);
    let sel = this.selector;
    if (!sel.value) {
      let tsVal: number = ts.getTime();
      let ts1: Date = new Date(tsVal);
      // use local time display with timezone offset in UTC format
      let tzOffset = ts1.getTimezoneOffset();
      let tzOffsetString = "+" + ("" + (-tzOffset / 60)).padStart(2, '0') + ":00";
      let dateOffset = new Date(ts1.getTime() - (tzOffset * 60000)).toISOString().replace(/\..+/, '') + tzOffsetString;
      sel.value = dateOffset;
    }
    sel.customPickerOptions = {
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-picker'
    };
  }

  ngOnInit() {
    this.setup();
    this.loaded = true;
  }

  ngOnDestroy() {

  }

  onChange(sel: IDateSelectorEntry) {
    this.onSelect.emit(sel);
  }

  onChangeRange(sel: IDateSelectorEntry) {
    console.log(sel);
  }

  onSelectCalendar(sel: IDateSelectorEntry) {

    let options: CalendarModalOptions = {
      title: sel.heading,
      canBackwardsSelected: true, //By making this true you can access the disabled dates
    };

    let params = {
      options: options
    };

    this.uiext.showCustomModalGen(null, CalendarModal, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: true,
        frame: true
      },
      params
    }).then((res: any) => {
      console.log(res);
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}
