<modal-header-container [vs]="vs">
    <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
        (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
    [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
    <div [class]="wrapperClass" fxLayout="column">
        <div [class]="innerClass" fxLayout="column">
            <div class="margin-horizontal-s margin-top-m margin-bottom-s" *ngIf="faqCategories">
                <div class="text-left">
                    <div *ngFor="let fc of faqCategories" class="list-item list-item-padding transparent-bg">
                        <div *ngFor="let e of fc.faqEntries" class="list-item list-item-padding transparent-bg">
                            <plain-entry-expand [e]="e" [fixed]="true"></plain-entry-expand>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal-container>