import { Component, OnInit, OnChanges, OnDestroy, ViewChild, Input, Output, EventEmitter, SimpleChanges, SimpleChange, ElementRef, AfterViewInit } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IonSlides } from '@ionic/angular';
import { INavBarItem } from 'src/app/classes/def/views/nav';


@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // @ViewChild(IonSlides, { read: ElementRef, static: false }) slides: IonSlides;
  // @ViewChild('sliderRef', { read: IonSlides, static: false }) slides: IonSlides;
  @ViewChild('sliderContainer', { read: ElementRef, static: false }) sliderContainer: ElementRef;
  slides: IonSlides; 

  enableTabButtons: boolean = false;
  showLeftButton: boolean = true;
  showRightButton: boolean = true;

  timeout = {
    slider: null,
    slider2: null,
    sliderInit1: null,
    sliderInit2: null
  };

  /**
   * this is only for linear navigation through tabs
   * it is initialized once
   */
  selectedIndex: number = 0;


  sliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  @Input()
  slidesPerView: number;

  // @Input()
  // selectedIndex: number;


  @Input()
  selectedTabId: number;

  /**
   * In order to do two way binding, the output variable name should be, inputVarName + 'Change'
   */
  @Output() selectedTabIdChange: EventEmitter<any> = new EventEmitter();

  get Value() {
    return this.selectedTabId;
  }
  set Value(tValue) {
    // Check some things about the new value then...
    this.selectedTabIdChange.emit(tValue);
  }

  @Input()
  loadData: boolean;

  @Output()
  select = new EventEmitter<INavBarItem>();

  @Input()
  navBarItems: INavBarItem[];

  configInit: boolean = false;
  unlockSlides: boolean = false;

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngAfterViewInit() {
    this.init();
  }

  init() {
    if (!this.slidesPerView) {
      this.slidesPerView = 3;
    }
    if (!this.selectedIndex) {
      this.selectedIndex = 0;
    }
    if (!this.selectedTabId) {
      this.selectedTabId = 0;
    }

    this.sliderOptions.slidesPerView = this.slidesPerView;
    this.sliderOptions.initialSlide = this.selectedIndex;

    console.log("selected index: ", this.selectedIndex);
    console.log("selected value/id: ", this.selectedTabId);

    let afterInit = () => {
      this.unlockSlides = true;
      this.initAutoSlide();
    };

    this.timeout.sliderInit1 = setTimeout(() => {
      this.configInit = true;

      // this.slides.lockSwipes(true).then(() => {

      // }).catch((err: Error) => {
      //   console.error(err);
      // });

      this.timeout.sliderInit2 = setTimeout(() => {
        this.slides = this.sliderContainer.nativeElement.children[0];
        this.slides.update().then(() => {
          afterInit();
        }).catch((err) => {
          console.error(err);
          afterInit();
        });
      }, 250);
    }, 250);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.clearAutoSlide();
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'loadData':
            this.initAutoSlide();
            break;
          case 'slidesPerView':
            this.sliderOptions.slidesPerView = this.slidesPerView;
            this.sliderOptions.initialSlide = this.selectedIndex;
            this.configInit = false;
            setTimeout(() => {
              this.configInit = true;
            }, 1);
            break;
        }
      }
      // const chg: SimpleChange = changes[keys[i]];
      // console.log('prev value: ', chg.previousValue);
      // console.log('got name: ', chg.currentValue);
    }
  }

  initAutoSlide() {
    if (!this.unlockSlides) {
      console.warn("slides locked");
      return;
    }
    console.log("init auto slide: ", this.selectedIndex);
    this.getSelectedIndex();
    if (this.selectedIndex > this.slidesPerView - 1) {
      console.log("will slide to: ", this.selectedIndex);
      this.enableTabButtons = false;
      this.timeout.slider = setTimeout(() => {
        if (!this.slides) {
          return;
        }
        try {
          // this.slides.slideTo(Math.ceil(this.page / this.categoryTabsGroupCount), 500, false);
          this.slides.slideTo(this.selectedIndex, 500, false).then(() => {
            this.timeout.slider2 = setTimeout(() => {
              this.enableTabButtons = true;
              this.slideChanged();
            }, 1000);
          }).catch((err: Error) => {
            console.error(err);
          });
        } catch (e) {
          console.log(e);
          this.enableTabButtons = true;
        }
      }, 1000);
    } else {
      this.enableTabButtons = true;
      // this.selectCategoryOnClick(selectedCategory);
    }
  }

  // private getSelectedTabId(index: number = null) {
  //     if (index == null) {
  //         index = this.selectedIndex;
  //     }
  //     if (index < this.navBarItems.length) {
  //         return this.navBarItems[index].value;
  //     } else {
  //         return null;
  //     }
  // }

  private getSelectedIndex() {
    let index: number = this.navBarItems.map(item => item.value).indexOf(this.selectedTabId);
    this.selectedIndex = index;
  }


  // Method executed when the slides are changed
  public slideChanged(): void {
    // this.slides.getActiveIndex().then((currentIndex) => {
    //   // this.showLeftButton = currentIndex !== 0;
    //   this.selectedIndex = currentIndex;
    //   let isLastSlide: boolean = false;
    //   this.slides.length().then((slidesLength: number) => {
    //     if (currentIndex + this.slidesPerView >= slidesLength) {
    //       isLastSlide = true;
    //     }
    //     // this.showRightButton = currentIndex !== Math.ceil(this.slides.length() / this.categoryTabsGroupCount) - 1;
    //     this.showRightButton = !isLastSlide;
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });

    // }).catch((err: Error) => {
    //   console.error(err);
    // });
  }

  public filterData(navItem: INavBarItem): void {
    // Handle what to do when a category is selected
    console.log(navItem);

  }

  /**
  * used for button tabs highlight
  */
  isSelected(navItem: INavBarItem) {
    // console.log("is selected ", navItem);
    if (!navItem) {
      return false;
    }
    if (navItem.value !== this.selectedTabId) {
      return false;
    } else {
      return true;
    }
  }


  /**
   * run on tab click
   * @param navItem 
   */
  selectCategoryOnClick(navItem: INavBarItem) {
    this.selectedTabId = navItem.value;
    // this.selectedTabId = this.getSelectedTabId();
    this.select.emit(navItem);
  }


  /**
   * Method that shows the next slide
   * update: next GROUP of slides
   */
  public slideNext(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide next");
    this.clearAutoSlide();
    this.slides.getActiveIndex().then((currentIndex: number) => {
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.clearAutoSlide();
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.slidesPerView;
      if (slideToIndex < this.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  clearAutoSlide() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }

  swipeEvent(e) {
    // console.log(e);
    if (e) {
      switch (e.direction) {
        case 2:
          // right to left
          this.slideNext();
          break;
        case 4:
          // left to right
          this.slidePrev();
          break;
      }
    }
  }
}
