import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent implements OnInit, OnDestroy {
  descriptionHtml: SafeHtml;

  @Input()
  data: IGenericEntry;

  @Input()
  small: boolean;

  @Input()
  heading: boolean;

  @Output()
  action: EventEmitter<IGenericEntry> = new EventEmitter();

  isLoading: boolean = false;
  itemName: string = "";
  isLocked: boolean = false;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.data) {
      console.log("card item: ", this.data);
      this.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.description);
      this.itemName = this.data.name;
    }
  }

  ngOnDestroy() {

  }

  onTapSelect() {
    this.action.emit(this.data);
  }
}

