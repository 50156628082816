import { IUserPublicData } from "../user/general";


export interface IGenericResponse {
    status: boolean;
    message: string;
    data: any;
}

export interface IGenericErrorContainer {
    error: boolean,
    data: string[]
}

export interface IGenericResponseDataWrapper<T> {
    data: T,
    error: IGenericErrorContainer
}

export interface IPageResponse {
    data: any;
    aux: any;
    pages: number;
    page: number;
}

export interface IPageResponseLeaderboard {
    users: IUserPublicData[];
    pages: number;
    page: number;
}

export interface ILoginResponse {
    genericMessage: IGenericResponse;
    userId: number;
    username: string;
}

export interface IAppInfoResponse {
    genericMessage: IGenericResponse;
    details: {
        buildId: number,
        buildIdString: string
    };
}

export enum ECheckLoginResponse {
    invalid = 0,
    ok = 1,
    emailNotValidated = 2,
    outdated = 3
}

export interface ICheckLoginResponse {
    valid: boolean;
    tester: number;
    /**
     * check premium account activated / registered business details
     */
    activated: boolean;
    /**
     * check resp codes e.g. email not validated
     */
    respCode: number;
    /**
     * validate the app version is not too much outdated
     */
    versionOk: boolean;
    message: string;
    passwordHash: string;
}



export enum ERefCodeResponse {
    /**
     * the referred user receives a bonus
     */
    userReferral = 1,
    /**
     * the user receives a bonus 
     */
    appReferral = 2
}

export interface IRefCodeResponse {
    /**
     * response codes
     */
    code: number;
    message: string;
}

