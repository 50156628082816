
export interface IBase64Ext {
    content: string;
    contentType: string;
}

export interface IImageSize {
    width: number;
    height: number;
}

export interface IPhotoResult {
    fileURI: string;
    data: string;
    warn: boolean;
    message: string;
}

export class PhotoUploadUtils {

    static refTag = "<img src='";

    static getPhotoURLFromHTML(photoUrl: string): string {
        let photoUrlContent: string = null;
        if (PhotoUploadUtils.checkImgContent(photoUrl)) {
            let refTag: string = PhotoUploadUtils.refTag;
            let content: string = photoUrl.slice(photoUrl.indexOf(refTag) + refTag.length).replace(refTag, "");
            let quoteIndex: number = content.indexOf("'");
            content = content.slice(0, quoteIndex);
            photoUrlContent = content;
        }
        return photoUrlContent;
    }

    static getPhotoURLsFromHTML(photoUrl: string): string[] {
        let refTag: string = PhotoUploadUtils.refTag;
        let photoUrls: string[] = [];
        if (!photoUrl) {
            return [];
        }
        while (true) {
            let index = photoUrl.indexOf(refTag);
            if (index == -1) {
                break;
            }
            let q = index + refTag.length;
            photoUrl = photoUrl.slice(q);
            let content = photoUrl.replace(refTag, "");
            let quoteIndex = photoUrl.indexOf("'");
            content = content.slice(0, quoteIndex);
            photoUrls.push(content);
        }
        return photoUrls;
    }

    static checkImgContent(photoUrl: string) {
        if (!photoUrl) {
            return false;
        }
        try {
            if (photoUrl.indexOf(PhotoUploadUtils.refTag) !== -1) {
                return true;
            }
        } catch (err) {
            console.warn("photoUrl not formatted as string");
        }
        return false;
    }

    static getImgContentIndex(str: string) {
        if (!str) {
            return -1;
        }
        return str.indexOf(PhotoUploadUtils.refTag);
    }

    static removeImgContent(str: string) {
        while (true) {
            let imgIndex: number = PhotoUploadUtils.getImgContentIndex(str);
            if (imgIndex !== -1) {
                str = str.slice(0, imgIndex);
            } else {
                break;
            }
        }
        return str;
    }

    /**
     * moved to server
     * @param photoUrl 
     * @returns 
     */
    static formatImg(photoUrl: string) {
        return "<img src='" + photoUrl + "' class='border-radius img-small'>"
    }

    static updatePhotoImgContent(htmlContent: string, photoUrl: string) {
        htmlContent = PhotoUploadUtils.removeImgContent(htmlContent);
        if (photoUrl) {
            let imgTag: string = PhotoUploadUtils.formatImg(photoUrl);
            htmlContent += imgTag;
        }
        return htmlContent;
    }
}
