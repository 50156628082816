import { Injectable } from "@angular/core";
import { IPromiseStatus } from 'src/app/classes/utils/sync';


@Injectable({
    providedIn: 'root'
})
export class SyncService {

    constructor(

    ) {
        console.log("sync service created");
    }

    runSyncNoAction(promiseList: (() => Promise<any>)[]) {
        this.runSync(promiseList).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * example declaration
     *  promiseList.push(()=>{return new Promise((resolve)=>{
     * 	setTimeout(()=>{
     * 	        console.log(i);
     * 	        resolve(true);
     * 	        }, i * 100); 
     * })});
     * 
     * @param promiseList list of callbacks to promises with preset params
     */
    runSync(promiseList: (() => Promise<any>)[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            async function syncPromise(fn: () => Promise<any>) {
                await fn();
            }

            async function syncList() {
                for (let i = 0; i < promiseList.length; i++) {
                    // console.log("run sync: " + i);
                    await syncPromise(promiseList[i]);
                }
            }

            syncList().then(() => {
                // console.log("run sync complete");
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * run sync, continue if rejected
     * @param promiseList 
     */
    runSyncAnyResolve(promiseList: (() => Promise<any>)[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            async function syncPromise(fn: () => Promise<any>) {
                await fn();
            }

            async function syncList() {
                for (let i = 0; i < promiseList.length; i++) {
                    // console.log("run sync: " + i);
                    try {
                        await syncPromise(promiseList[i]);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }

            syncList().then(() => {
                // console.log("run sync complete");
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * wrapper around Promise.all
     * using promise callbacks instead of raw promises as params
     * @param promiseList 
     */
    whenAll(promiseList: (() => Promise<any>)[]): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let res: IPromiseStatus = {
                count: promiseList.length,
                completed: 0,
                failed: 0
            };

            let promiseAsyncList: Promise<any>[] = promiseList.map(p => {
                return new Promise((resolve) => {
                    p().then(() => {
                        res.completed += 1;
                        resolve(true);
                    }).catch(() => {
                        res.failed += 1;
                        resolve(false);
                    });
                });
            });

            Promise.all(promiseAsyncList).then(() => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}




