import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { GeneralCache } from './app/classes/app/general-cache';

/**
 * Hammerjs must be imported for gestures
 */
// import 'hammerjs';


if (environment.production) {
  enableProdMode();
}

function addHashIfNeeded() {
  // If no hash fragment exists, add a default one
  const currentURL = window.location.href;
  const origin = window.location.origin;
  // const path = window.location.pathname;
  // const search = window.location.search;
  // const hash = window.location.hash;

  console.log("current URL: ", currentURL);
  GeneralCache.initURL = currentURL;

  if (!currentURL.includes('#')) {
    // If there is no hash in the URL, add it before the route
    // const updatedURL = currentURL.replace(/\/?$/, '#/');
    const updatedURL = window.location.origin + "/#" + currentURL.replace(origin, "");
    console.log("updated URL: ", updatedURL);
    window.history.replaceState({}, document.title, updatedURL);
    // window.location.href = updatedURL;
  }

  // Check if the URL already has a hash
  // if (!currentURL.includes('#')) {
  //   const updatedURL = `${origin}/#${path}${search}`;
  //   console.log("updated URL: ", updatedURL);
  //   window.history.replaceState({}, document.title, updatedURL);
  // }

}

// Function to detect if the app is running in a WebView
function isWebView(): boolean {
  const userAgent = navigator.userAgent;
  console.log("user agent: ", userAgent);
  // return true;
  return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1) ||
    (userAgent.indexOf('Instagram') > -1) || (userAgent.indexOf('Twitter') > -1) ||
    (userAgent.indexOf('WebView') > -1) || (userAgent.indexOf('wv') > -1);
}

// Function to open URL in the system browser
function openInSystemBrowser(url: string) {
  // Open the URL in a new tab or window
  const newWindow = window.open(url, '_blank');
  // Fallback to setting window.location if window.open fails
  if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
    window.location.href = url;
  }
}

// Function to check if the redirect flag is set
function isRedirected(): boolean {
  return window.location.search.includes('redirected=true');
}

// Function to set the redirect flag in the URL
function setRedirectFlag(url: string): string {
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}redirected=true`;
}

// if (isWebView() && !isRedirected()) {
//   const currentURL = window.location.href;
//   console.log("current URL: ", currentURL);
//   const targetUrl = setRedirectFlag(currentURL);
//   console.log("target URL: ", targetUrl);
//   // Redirect to the target URL if in WebView
//   setTimeout(() => {
//     openInSystemBrowser(targetUrl);
//   }, 2000);
// } else {
//   // Bootstrap the Angular application if not in WebView
//   addHashIfNeeded();
//   platformBrowserDynamic().bootstrapModule(AppModule)
//     .catch(err => console.error(err));
// }

addHashIfNeeded();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
