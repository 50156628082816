import { LatLng } from "@ionic-native/google-maps/ngx";
import { Injectable } from "@angular/core";
import { ILeplaceReg, ILeplaceRegMulti } from "../../classes/def/places/google";
import { BusinessDataService } from "../data/business";
import { PlacesDataService } from "../data/places";
import { IAppLocation } from "../../classes/def/places/app-location";
import { LocationApiHelpersService } from "./location-api-helpers";
import { BehaviorSubject } from "rxjs";
import { UiExtensionService } from "../general/ui/ui-extension";
import { Messages } from "../../classes/def/app/messages";
import { IPlaceExtContainer } from "../../classes/def/places/container";
import { EPlaceUnifiedSource, PlaceProvider } from "../../classes/def/places/provider";
import { GenericDataService } from "../general/data/generic";
import { ApiDef } from "../../classes/app/api";
import { IHereMapsRequestNearby, IHereMapsResponseNearby, IHereMapsItem } from "../../classes/def/places/here";
import { LocationUtilsHere } from "./location-utils-here";
import { LocationUtilsGoogle } from "./location-utils-google";
import { EGoogleMapsRequestStatus } from './location-utils-def';
import { IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { IBusinessDataExt } from "src/app/classes/def/business/business";
import { IEditorStoryLocationDetails } from "src/app/classes/def/core/editor";

export interface ILocationScanSpecs {
    type: string,
    standardLow: number,
    standardHigh: number,
    googleId: string,
    providerCode: number,
    radius: number,
    minDistance: number
}

@Injectable({
    providedIn: 'root'
})
export class LocationApiService {
    placesService: google.maps.places.PlacesService;

    resultBuffer: ILeplaceReg[] = [];
    resultBufferOutIndex: number = 0;

    googleStatusObs: BehaviorSubject<number>;

    notifyEnabled: boolean = true;

    lastTimestamp: number = null;

    constructor(
        public businessDataProvider: BusinessDataService,
        public placesDataProvider: PlacesDataService,
        public locationApiHelpers: LocationApiHelpersService,
        public uiext: UiExtensionService,
        public gdp: GenericDataService
    ) {
        console.log("location api service created");
        this.googleStatusObs = new BehaviorSubject(null);
    }


    /**
     * subscribe to google status
     * e.g. quota exceeded
     */
    getGoogleStatusObs() {
        return this.googleStatusObs;
    }

    setPlacesService(ps: google.maps.places.PlacesService) {
        this.placesService = ps;
    }

    clearResultBuffer() {
        this.resultBuffer = [];
        this.resultBufferOutIndex = 0;
    }

    getScanSpecs(placeSpecs: IAppLocation): ILocationScanSpecs {
        let bloc: IBackendLocation = placeSpecs.loc.merged;
        let specs: ILocationScanSpecs = {
            type: bloc.type,
            standardLow: bloc.standardLow,
            standardHigh: bloc.standardHigh,
            googleId: bloc.googleId,
            providerCode: bloc.providerCode,
            radius: null,
            minDistance: null
        };
        return specs;
    }

    /**
     * merge editor content into location content
     * @param bplace 
     */
    checkEditorLocationMergeContent(bplace: IBusinessDataExt) {
        let ed: IEditorStoryLocationDetails = bplace.editorDraft;
        if (ed != null) {
            if (ed.noLink) {
                bplace.location.noLink = 1;
                bplace.location.photoUrl = ed.photoUrl;
                bplace.location.photoUrlSmall = ed.photoUrl;
            }
            if (ed.lat != null && ed.lng != null) {
                bplace.location.lat = ed.lat;
                bplace.location.lng = ed.lng;
            }
        }
    }

    /**
     * check fixed location override with story location details
     * @param place 
     * @param bloc 
     */
    checkFixedLocationOverrides(place: IBusinessDataExt, bloc: IBackendLocation) {
        if (!bloc) {
            return;
        }
        if (bloc.noLink) {
            place.location.photoUrl = bloc.photoUrl;
            place.location.photoUrlSmall = bloc.photoUrlSmall;
            // place.place.googleId = null;
            // googleId is used in multiple places so it breaks things to set it to null
            place.location.name = bloc.name;
            console.log("fixed location override > selected: ", place);
        }
        if (bloc.overrideCoords) {
            console.log("fixed location override coords");
            place.location.lat = bloc.lat;
            place.location.lng = bloc.lng;
        }
    }


    /**
     * find places nearby suggestion
     * for multiple types
     * with separate request for each type 
     * and results are combined after processing on the server
     * @param currentLocation 
     * @param types 
     */
    findPlacesNearbySuggestionMultiPass(currentLocation: LatLng, types: string[], limitPerType: number[], mode: number) {
        let promise = new Promise((resolve, reject) => {
            this.findPlacesNearbySuggestionMultiPassCore(currentLocation, types, limitPerType).then((placeResults: IPlaceExtContainer[]) => {
                this.showPlacesDebug(placeResults);
                this.locationApiHelpers.chooseRandomLocation(currentLocation, placeResults, [null, null], [], true, mode).then((r: ILeplaceRegMulti) => {
                    this.resultBuffer = r.array;
                    this.showPlaceResultsDebug(r.array);
                    resolve(r);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    showPlaceResultsDebug(placeResults: ILeplaceReg[]) {
        let placeNames: string[] = [];
        for (let i = 0; i < placeResults.length; i++) {
            let p: ILeplaceReg = placeResults[i];
            if (p && p.place) {
                placeNames.push(i + ". " + p.place.name + "/" + p.place.googleId + "/" + p.registeredBusiness);
            }
        }
        console.log("place results processed: ", placeNames);
    }

    showPlacesDebug(placeResults: IPlaceExtContainer[]) {
        let placeNames: string[] = [];
        for (let i = 0; i < placeResults.length; i++) {
            let p: IPlaceExtContainer = placeResults[i];
            if (p) {
                placeNames.push(i + ". " + p.name + "/" + p.googleId);
            }
        }
        console.log("place results: ", placeNames);
    }




    /**
     * find places nearby suggestion
     * for multiple types
     * with separate request for each type 
     * and results are combined after processing on the server
     * @param currentLocation 
     * @param types 
     */
    findPlacesNearbySuggestionMultiPassCore(currentLocation: LatLng, types: string[], limitPerType: number[]): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {

            this.resultBuffer = [];
            this.resultBufferOutIndex = 0;

            if (!types || types.length === 0) {
                reject(new Error("no types specified"));
                return;
            }

            let providerCode: number = PlaceProvider.getOneAvailableLocationProviderDefault();
            switch (providerCode) {
                case EPlaceUnifiedSource.google:
                    this.findRandomLocationsGoogle(currentLocation, types, limitPerType).then((data: IPlaceExtContainer[]) => {
                        resolve(data);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                    break;
                case EPlaceUnifiedSource.here:
                    this.findRandomLocationsHere(currentLocation, types, limitPerType).then((data: IPlaceExtContainer[]) => {
                        resolve(data);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                    break;
                default:
                    reject(new Error("unknown place provider"));
                    break;
            }
        });
        return promise;
    }

    /**
     * notify the user about the possible errors related to google maps services
     * e.g. quota exceeded
     * @param status 
     */
    sendGoogleStatusEvent(status: any) {
        if (this.notifyEnabled) {
            this.notifyEnabled = false;
            let ts: number = new Date().getTime();
            let skip: boolean = false;

            if (this.lastTimestamp === null) {

            } else {
                // do not spam the user with popups if they is less than one second between them
                if ((ts - this.lastTimestamp) < 1000) {
                    skip = true;
                }
                this.lastTimestamp = ts;
            }

            if (skip) {
                return;
            }

            console.log("send google status event: " + status);

            let alert: boolean = false;
            let msg: string;
            let sub: string;
            switch (status) {
                case google.maps.places.PlacesServiceStatus.OK:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.ok);
                    break;
                case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.quotaExceeded);
                    msg = Messages.msg.googleQuotaExceeded.after.msg;
                    sub = Messages.msg.googleQuotaExceeded.after.sub;
                    alert = true;
                    break;
                default:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.error);
                    msg = Messages.msg.googleError.after.msg;
                    sub = Messages.msg.googleError.after.sub;
                    alert = true;
                    break;
            }

            if (alert) {
                this.uiext.showAlert(msg, sub, 1, null).then(() => {
                    this.notifyEnabled = true;
                }).catch(() => {
                    this.notifyEnabled = true;
                });
            }
        }
    }


    /**
     * search by single type
     * @param currentLocation 
     * @param placeSpecs 
     * @param radius 
     */
    private searchRandomLocationOfTypeGoogle(currentLocation: LatLng, type: string, radius: number): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            let resultsUnified: IPlaceExtContainer[] = [];

            // if (placeSpecs.backendLocation.type === Constants.locationType.park) {
            //     openNow = false;
            //     // minPriceLevel = 2; //Valid values range between 0 (most affordable) to 4 (most expensive)
            //     // maxPriceLevel = 4;
            // }

            let minPriceLevel: number = null;
            let maxPriceLevel: number = null;

            let searchOptions: google.maps.places.PlaceSearchRequest = {
                location: currentLocation,
                radius: radius,
                type: type,
                // types: [placeSpecs.backendLocation.type],
                openNow: false,
                rankBy: !radius ? google.maps.places.RankBy.DISTANCE : null, // DISTANCE, PROMINENCE
                minPriceLevel: minPriceLevel, // Valid values range between 0 (most affordable) to 4 (most expensive)
                maxPriceLevel,
                // keyword: null, 
                // A term to be matched against all available fields, including but not limited to name, type, and address, 
                // as well as customer reviews and other third-party content.
            };

            this.placesService.nearbySearch(searchOptions, (results: google.maps.places.PlaceResult[], status) => {
                this.sendGoogleStatusEvent(status);
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.resultBufferOutIndex = 0;
                    this.resultBuffer = [];
                    for (let i = 0; i < results.length; i++) {
                        resultsUnified.push(LocationUtilsGoogle.load(results[i]));
                    }
                    resolve(resultsUnified);
                } else {
                    reject(new Error("Google maps place service error: " + status));
                }
            });
        });
        return promise;
    }

    private searchRandomLocationOfTypeHere(currentLocation: LatLng): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            let options: IHereMapsRequestNearby = {
                app_id: ApiDef.hereMapsAppId,
                app_code: ApiDef.hereMapsAppCode,
                at: currentLocation.lat + "," + currentLocation.lng,
                pretty: true
            };

            let resultsUnified: IPlaceExtContainer[] = [];

            this.gdp.genericGetExt("https://places.cit.api.here.com/places/v1/discover/here", options).then((data: IHereMapsResponseNearby) => {
                for (let i = 0; i < data.results.items.length; i++) {
                    let item: IHereMapsItem = data.results.items[i];
                    resultsUnified.push(LocationUtilsHere.load(item));
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * search by types
     * @param currentLocation 
     * @param types 
     * @param limitPerType 
     */
    private findRandomLocationsHere(currentLocation: LatLng, types: string[], limitPerType: number[]) {
        let promise = new Promise((resolve, reject) => {
            let options: IHereMapsRequestNearby = {
                app_id: ApiDef.hereMapsAppId,
                app_code: ApiDef.hereMapsAppCode,
                at: currentLocation.lat + "," + currentLocation.lng,
                pretty: true
            };

            console.log(types);
            console.log(limitPerType);

            let resultsUnified: IPlaceExtContainer[] = [];

            this.gdp.genericGetExt("https://places.cit.api.here.com/places/v1/discover/here", options).then((data: IHereMapsResponseNearby) => {
                for (let i = 0; i < data.results.items.length; i++) {
                    let item: IHereMapsItem = data.results.items[i];
                    resultsUnified.push(LocationUtilsHere.load(item));
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * search by types
     * @param currentLocation 
     * @param types 
     * @param limitPerType 
     */
    private findRandomLocationsGoogle(currentLocation: LatLng, types: string[], limitPerType: number[]) {
        let promise = new Promise((resolve, reject) => {
            let promises = [];
            let googleMapsStatus;
            let resultsUnified: IPlaceExtContainer[] = [];
            for (let i = 0; i < types.length; i++) {
                promises.push((new Promise((resolve) => {
                    let searchOptions: google.maps.places.PlaceSearchRequest = {
                        location: currentLocation,
                        type: types[i],
                        // types: [types[t]],
                        openNow: false,
                        rankBy: google.maps.places.RankBy.DISTANCE
                    };

                    this.placesService.nearbySearch(searchOptions, (results: google.maps.places.PlaceResult[], status) => {
                        this.sendGoogleStatusEvent(status);
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            for (let j = 0; (j < results.length && j < limitPerType[i]); j++) {
                                resultsUnified.push(LocationUtilsGoogle.load(results[j]));
                            }
                        } else {
                            googleMapsStatus = status;
                        }
                        resolve(true);
                    });
                })));
            }

            Promise.all(promises).then(() => {
                if (resultsUnified.length === 0) {
                    reject(new Error("Google maps place service error: " + googleMapsStatus));
                    return;
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    private searchFixedLocationCoreGoogle(googleId: string) {
        let promise = new Promise((resolve, reject) => {
            this.placesService.getDetails({
                placeId: googleId
            }, (result: google.maps.places.PlaceResult, status) => {
                this.sendGoogleStatusEvent(status);
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(LocationUtilsGoogle.load(result));
                } else {
                    reject(new Error("Google maps place service error: " + status));
                }
            });
        });
        return promise;
    }

    private searchFixedLocationCoreHere(_hereId: string) {
        let promise = new Promise((_resolve, reject) => {
            reject(new Error("not implemented"));
            return;
        });
        return promise;
    }

}



