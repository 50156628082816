import { ICustomParamForActivity, ICustomParamCategory } from "./custom-param";
import { IShareActivityProgress } from "../../utils/message-utils";
import { IActivityParam, IGenericActivityStats } from "./activity-stats";
import { IStatDef } from "../user/stats";
import { IAppLocation } from '../places/app-location';
import { IActivityQuestSpecs } from "../nav-params/activity-details";
import { IKVStat } from "../app/kval";


export interface IActivityOld {
    id?: number;
    name: string;
    description: string;
    value: number; // server generated or not
    coinCap: number;
    timeLimit: number;
    radius: number;
    arMode: number;
    scan: number;
    aux1?: number;
    aux2?: number;
    aux3?: number;
}

export interface IActivity {
    id?: number;
    /**
     * the keyword
     */
    name: string;
    /**
     * display a title e.g. in modals
     */
    title: string;
    /**
     * the (long) description of the activity
     */
    description: string;
    /**
     * unique identifier
     */
    code: number;
    scan: number;

    /**
     * useful for challenge view
     */
    level?: number;

    /**
     * activity params that are defined for the specific location-activity-level
     */
    params: any;

    /**
     * used to link to stats def
     */
    paramsList: IActivityParam[];
    paramsTable?: IKVStat[];

    /**
     * define if the activity has custom params
     * excl. the actual activity that may be assigned to a custom param (as challenge)
     */
    hasCustomParams: number;

    /**
     * array of custom params e.g. objects that have to be photographed, sounds that have to be recorded
     */
    customParams: ICustomParamForActivity[];
    customParamCategory?: ICustomParamCategory;
    fixedCoins?: IFixedCoin[];
    fixedCoinsNav?: IFixedCoin[];

    gameContextCode: number;

    icon: string;
    photo: number;
    photoUrl: string;
    similarCode: number;
    similar: IActivity;
    photoValidate: number;

    appVersionMin?: number;
    appVersionMinIos?: number;

    hiddenPlace: number;
    qrCode?: string;

    /**
    * app handled
    */
    questData?: IActivityQuestSpecs;
    /**
     * story location only
     */
    enableSales?: number;
}

export interface IFixedCoin {
    locationIndex: number,
    locationId: number,
    customParamCode: number,
    draftId: number,
    lat: number,
    lng: number,
    tempUid?: string,
    index?: number,
    category?: number,
    tag?: string,
    customParamIndex?: number,
    cparam?: ICustomParamForActivity,
    isNav?: boolean
}

export enum EActivityCodes {
    run = 1,
    eat = 2,
    walk = 3,
    visit = 4,
    explore = 5,
    drink = 6,
    find = 7,
    bike = 8,
    photo = 9,
    snapshot = 10,
    screenshotAR = 11,
    dance = 12,
    decibel = 14,
    enduranceRun = 15,
    escape = 16,
    pursue = 17,
    walkTarget = 18,
    runTarget = 19,
    exploreFind = 20,
    exploreTarget = 21
}

export enum EActivityTags {
    quest = "quest",
    photo = "photo",
    snapshot = "snapshot"
}


export enum EFinishedActionParams {
    default = 0,
    reward = 1,
    retry = 2,
    cancel = 3,
    removeTreasure = 10
}

export enum ECheckActivityResult {
    failed = 0,
    done = 1,
    retry = 2,
    inProgress = 3,
    skipped = 4,
    validated = 5
}

export enum EValidateTypes {
    distance = 1,
    scanCode = 2
}

export interface IRunActivityDef {
    distance: number;
    coinCap: number;
    speed: number;
    coinRadius: number;
    maxSpeed: number;
    timeLimit: number;
    standardRewardCap: number;
}


export interface IEnduranceRunActivityDef {
    coinCap: number;
    speed: number;
    coinRadius: number;
    maxSpeed: number;
    timeLimit: number;
    standardRewardCap: number;
}


export interface IWalkActivityDef {
    distance: number;
    coinCap: number;
    coinRadius: number;
    maxSpeed: number;
    standardRewardCap: number;
}


/**
 * params that are common to all move activities
 */
export interface IGenericMoveActivityDef {
    coinCap: number;
    coinRadius: number;
}


export interface IPhotoActivityDef {
    timeLimit: number;
    standardRewardCap: number;
}


export interface IExploreActivityDef {
    coinCap: number;
    timeLimit: number;
    coinRadius: number;
    mode: number;
    standardRewardCap: number;

    // radius of generator object
    distance?: number;
    // speed of object
    targetSpeed?: number;
    // evade distance
    evadeDistance?: number;
}

export interface IARExploreActivityDef {
    timeLimit: number;
    standardRewardCap: number;
}

export interface IFindActivityDef {
    timeLimit: number;
    circleRadius: number;
    revealDistance: number;
    standardRewardCap: number;

    coinCap: number;
}

export interface IDanceActivityDef {
    timeLimit: number;
    /**
     * the number of beats
     */
    requiredCount: number;
    /**
     * the required BPM (if not using real time music BPM)
     */
    BPM: number;
}

export interface IDecibelActivityDef {
    timeLimit: number;
    /**
     * the decibel level
     */
    soundLevel: number;
    requiredDuration: number;
    /**
     * the required BPM (if required)
     */
    BPM: number;
}

export interface IEatActivityDef {
    itemCount: number;
    timeLimit: number;
    coinCap: number;
    standardRewardCap: number;
}

export interface IDrinkActivityDef {
    itemCount: number;
    timeLimit: number;
    coinCap: number;
    standardRewardCap: number;
}

export interface IVisitActivityDef {
    itemCount: number;
    timeLimit: number;
    coinCap: number;
    standardRewardCap: number;
}

export interface IQuestActivityDef {
    timeLimit: number;
    standardRewardCap: number;
    maxRetry: number;
}

export enum EStandardCode {
    activityFailed = 1000,
    story = 2000,
    treasure = 3000
}

export enum EStandardStoryCode {
    locked = 1
}

export enum EStandardTreasureCode {
    foundTreasure = 1,
    foundArena = 2
}

export enum EStandardActivityFailedCode {
    timeExpired = 1,
    speedLimitExceeded = 2,
    photoValidationFailed = 3,
    gotCaught = 4
}

export interface IActivityResultCore {
    /**
     * activity return status
     */
    status: number;
    /**
     * the code associated to the fail reason
     */
    failCode: number;
    /**
     * enable retry option
     */
    retryEnabled: boolean;
    /**
     * e.g. activity failed message
     */
    message: string;
    /**
     * params for sharing progress
     */
    shareParams: IShareActivityProgress;
    activityStats: IActivityStatsContainer;
    placeFound?: IAppLocation;
}

export interface IActivityStatsContainer {
    stats: IGenericActivityStats;
    statsList: IStatDef[];
}
