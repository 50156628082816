

export class FallbackUtils {
    /**
    * retry fallback
    * wait for prompt (onRetryPromise) if specified
    * @param loadFn 
    * @param maxRetry 
    * @param interval ms
    * @param onRetryPromise 
    * @param onErrorState
    */
    static retry(loadFn: () => Promise<any>, maxRetry: number, interval: number, onRetryPromise: () => Promise<boolean>, onErrorState: () => any): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let maxRetryCrt: number = maxRetry ? maxRetry : 0;
            let fn = () => {
                loadFn().then((data) => {
                    resolve(data);
                }).catch((err: Error) => {
                    console.error(err);
                    if (onRetryPromise) {
                        // do retry promise before actual retry
                        // use max retry as relative (intermediate error state)

                        if (onErrorState) {
                            onErrorState();
                        }

                        let callRetryPromise: boolean = false;
                        if (maxRetryCrt > 0) {
                            maxRetryCrt -= 1;
                            setTimeout(() => {
                                fn();
                            }, interval);
                        } else {
                            maxRetryCrt = maxRetry ? maxRetry : 0;
                            // retry counter exceeded, ask for retry to continue
                            callRetryPromise = true;
                        }

                        if (callRetryPromise) {
                            let t0: number = new Date().getTime();
                            onRetryPromise().then((c: boolean) => {
                                // keep trying?
                                let t1: number = new Date().getTime();
                                let diff: number = t1 - t0;
                                if (c) {
                                    // try again
                                    // start over with retry counter
                                    if (diff >= interval) {
                                        fn();
                                    } else {
                                        setTimeout(() => {
                                            fn();
                                        }, interval - diff);
                                    }
                                } else {
                                    reject(err);
                                }
                            });
                        }
                    } else {
                        // just use max retry (absolute)

                        if (onErrorState) {
                            onErrorState();
                        }

                        if (maxRetryCrt > 0) {
                            maxRetryCrt -= 1;
                            setTimeout(() => {
                                fn();
                            }, interval);
                        } else {
                            reject(err);
                        }
                    }
                });
            };
            fn();
        });

        return promise;
    }
}