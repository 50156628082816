export interface IAppMessage {
    before?: {
        msg: string,
        sub: string
    },
    after?: {
        msg: string,
        sub: string
    }
}

export interface IAppMessageGroup {
    [key: string]: IAppMessage
}

export interface IAppSimpleMessageGroup {
    [key: string]: string
}

export enum ETutorialEntries {
    about = 0,
    signupTerms = 1,
    signupTutorial = 2,
    accountTutorial = 3,
    storyTutorial = 4,
    gmapTutorial = 5,
    inventoryTutorial = 6,
    AR = 8,
    placesTutorial = 9,
    chatTutorial = 10,
    placeAdsTutorial = 11,
    groupMemberTutorial = 12,
    groupAdminTutorial = 13,
    storylineTutorial = 14,
    storiesTutorial = 15,
    extras = 16,
    events = 17,
    eventHome = 18,
    eventJoin = 19,
    rating = 20,
    warning = 21,
    liveChat = 22,
    adConsent = 23,

    myPlaceQR = 24,
    myPlaceDetails = 25,
    myPlaceRegister = 26,
    marketplace = 27,

    prominentDisclosure = 28,
    prominentDisclosureTown = 29,

    warningTown = 32,
    subscriptionsTown = 33,
    editorTemplate = 34,
    editorMode = 35,
    storyCreatorMode = 36,
    storylineCreatorMode = 37,

    editControls = 38,
    reviewControls = 39,
    productionControl = 40,

    ttsChallenge = 301,
    ttsMeetingPlace = 302,
    ttsStory = 303,
    ttsTreasureFound = 304,
    ttsTreasureUnlocked = 305,
    ttsInventory = 306,


    localTtsBackgroundMode = -100,

    activateStoryTutorialEn = 51,
    storylineTutorialVideo = 52,

    reviewContact = 58,
    howItWorks = 59,
    checkoutNote = 60,
    appNotes = 61,
    testFlightNotes = 62,
    webNotes = 63,
    checkoutNotes = 64,
    unlockNotes = 65,
    findStoryNotes = 66
};

export class Messages {
    public static notification = {
        activityFinished: {
            after: {
                msg: "LP Connect",
                sub: "Activity finished"
            }
        },
        activityFailed: {
            after: {
                msg: "LP Connect",
                sub: "Activity failed"
            }
        },
        destinationReached: {
            after: {
                msg: "LP Connect",
                sub: "Destination reached"
            }
        },
        searchZoneReached: {
            after: {
                msg: "LP Connect",
                sub: "Search zone reached"
            }
        },
        checkpointReached: {
            after: {
                msg: "LP Connect",
                sub: "Checkpoint reached"
            }
        },
        stashFound: {
            after: {
                msg: "LP Connect",
                sub: "Hidden stash found"
            }
        },
        backgroundMode: {
            after: {
                msg: "LP Connect",
                sub: "Running in background mode"
            }
        }
    };

    /**
     * text content request codes
     * 0xx tutorials
     * 1xx activities
     */
    public static codes = {
        about: 0,
        signupTerms: 1,
        signupTutorial: 2,
        accountTutorial: 3,
        storyTutorial: 4,
        gmapTutorial: 5,
        inventoryTutorial: 6,
        AR: 8,
        placesTutorial: 9,
        chatTutorial: 10,
        placeAdsTutorial: 11,
        groupMemberTutorial: 12,
        groupAdminTutorial: 13,
        storylineTutorial: 14,
        storiesTutorial: 15,
        extras: 16,
        events: 17,
        eventHome: 18,
        eventJoin: 19,
        rating: 20,
        warning: 21,
        liveChat: 22,
        adConsent: 23,

        myPlaceQR: 24,
        myPlaceDetails: 25,
        myPlaceRegister: 26,
        marketplace: 27,

        prominentDisclosure: 28,
        prominentDisclosureTown: 29,

        warningTown: 32,
        subscriptionsTown: 33,
        editorTemplate: 34,
        editorMode: 35,
        storyCreatorMode: 36,
        storylineCreatorMode: 37,

        editControls: 38,
        reviewControls: 39,
        publishControls: 40,

        ttsChallenge: 301,
        ttsMeetingPlace: 302,
        ttsStory: 303,
        ttsTreasureFound: 304,
        ttsTreasureUnlocked: 305,
        ttsInventory: 306,


        localTtsBackgroundMode: -100
    };

    public static mp = {
        init: "<p>Connect to the Meeting Place while nearby to meet other players. Create or join a group then start a MultiPlayer session. Your location is shared with the other members in the group.</p><p>This feature is quite experimental.</p>",
        leaderInit: "<p>INIT</p><p>Tap ready after the others have joined the group</p>",
        leaderReady: "<p>READY</p><p>Please wait for the others to start</p><p>If everyone has connected but the map button is not active after some time, please disconnect (red button) and try again</p>",

        leaderSetAndTappedStart: "<p>WAIT</p><p>Please wait for the other apps to be in sync</p>",
        leaderSet: "<p>SET</p><p>Let the game begin</p><p>Tap start</p>",

        memberInit: "<p>INIT</p><p>Tap ready to start the game</p>",
        memberReady: "<p>READY</p><p>The game will start as soon as the host is ready</p>",

        memberSet: "<p>SET</p><p>The app is now ready to join the game</p>",

        memberSetGo: "<p>START</p><p>The app will now connect to the game</p>",

        gameOn: "<p>GO</p><p>The game is on</p>",
        leaderHandleMemberDisconnected: `<p>EXCEPTION</p><p>A member has disconnected</p><p>(1) Wait for the connection to resume</p>
        <p>(2) Remove the member from the current game</p><p>It is recommended to start a new session if possible</p>`,
        memberHandleLeaderDisconnected: "<p>EXCEPTION</p><p>The host has disconnected</p><p>(1) Wait for the connection to resume</p>(2) Exit MP game</p>",

        selectChallenge: "Pick a challenge on the map to start the game",
        waitForChallenge: "Wait for the host to start the game",



        createGroupHome: `
        <p>Create a group and write down the group ID</p>
        <p>You will be the host for the new group</p>  
        <p>Tell the others to join using the group ID</p>
        <p>You can also use the QR code. The others can scan it to join the group</p>`,

        createGroupHomeExistingGroup: `
        <p>You are the group host</p>
        <p>You have to start the game</p>
        <p>The other members have to join the game</p>
        <p>Others can join your group using the QR code or group ID</p>`,

        createGroupHomeChat: `<p>You are the group host</p>
        <p>Here you can chat with your group and plan a game</p>
        <p>Others can join your group using the QR code or group ID</p>
        <p>You have to start a game from a Meeting Place on the map</p>`,

        joinGroupHomeJoined: `
        <p>You are already a member</p>
        <p>Here you can chat with your group and plan a game</p>
        <p>You have to join a group event from a Meeting Place on the map</p>`,

        joinGroupHomeNotJoined: `
        <p>Request the group ID from the host or scan the QR code to join the group</p>`,

        joinGroupHomeJoined2: `
        <p>You are already a member</p>
        <p>You can join the game as soon as the host is ready</p>`
    };

    public static tts = {
        // typos may be there for correct pronounciation
        destinationReached: "Please proceed to the next location. Once you get there, you will have to complete a challenge. Please be aware of your surroundings. You may keep the phone in your pocket while doing outdoor activities.",
        welcomeToWorldMap: "Welcome to world map! Please wait for the content to load.",
        welcomeToWorldMapStoryMode: "Welcome to world map! The story will start now. Please wait for the content to load.",
        errorLoadingResources: "There was an error loading some resources. Please check your connection.",
        networkDisconnected: "Network disconnected. Please check your connection.",
        GPSNotAvailable: "G.P.S. signal is not available. Please check your location settings and be aware that it may take a while to acquire location.",
        searchZoneReached: "Search zone, reached!",
        checkpointReached: "Checkpoint reached!",
        achievementsUnlocked: "You have unlocked new achievements.",
        challengeComplete: "Challenge complete! Check the world map to continue.",
        challengeFailed: "Challenge failed! Check the world map to continue.",
        verifiedPlaces: "There are verified places nearby that might offer special discounts.",
        bgModeDefault: "The app is running in background mode.",
        bgModeFirst: "The app is running in background mode. Please allow background location always if requested, and be aware that the app is still using battery."
    };

    public static msg = {
        success: {
            after: {
                msg: "Success",
                sub: ""
            }
        },
        joinedEvent: {
            after: {
                msg: "Joined",
                sub: "Let the treasure hunting begin"
            }
        },
        codePushUpdated: {
            after: {
                msg: "App updated",
                sub: "Push update installed"
            }
        },
        wannabetester: {
            after: {
                msg: "Test account",
                sub: "Use tester mode?"
            }
        },
        authenticationError: {
            after: {
                msg: "Authentication error",
                sub: ""
            }
        },
        authenticationPreloadError: {
            after: {
                msg: "Preload error",
                sub: ""
            }
        },
        emailUpdated: {
            after: {
                msg: "Email updated",
                sub: "Please check your inbox for validation"
            }
        },
        registerPaymentSave: {
            after: {
                msg: "Request validated",
                sub: "Automatic billing is enabled. Your payment invoice will be delivered by email at the end of the billing cycle"
            }
        },
        registerPaymentNoSave: {
            after: {
                msg: "Request validated",
                sub: "Your payment invoice will be delivered by email"
            }
        },
        registerCreditCard: {
            after: {
                msg: "Billing",
                sub: "Confirm secure checkout"
            }
        },
        registerCreditCardAutomaticBilling: {
            after: {
                msg: "Billing",
                sub: "Confirm secure checkout with automatic billing"
            }
        },
        cancelBilling: {
            before: {
                msg: "Cancel billing",
                sub: "This will remove your payment method from the secure vault"
            },
            after: {
                msg: "Success",
                sub: "Your payment method has been removed from the secure vault"
            }
        },

        cancelSubscription: {
            before: {
                msg: "Cancel subscription",
                sub: "<p>The subscription will be disabled at the end of the current billing cycle</p>"
            },
            after: {
                msg: "Success",
                sub: "<p>Your subscription has been cancelled and will be disabled at the end of the current billing cycle</p><p>You may restart your subscription at any time</p>"
            }
        },

        unauthorizedAccess: {
            after: {
                msg: "Unavailable",
                sub: "This section requires authentication"
            }
        },

        cancelPackage: {
            before: {
                msg: "Cancel package",
                sub: "<p>The package and associated benefits will be disabled at once, with no refund options</p><p>Should you require a refund, please contact us via email instead</p>"
            },
            after: {
                msg: "Success",
                sub: "<p>The selected package has been removed from your account</p>"
            }
        },

        featureLockedAtTheMoment: {
            after: {
                msg: "Locked",
                sub: "This feature is not available at the moment"
            }
        },
        requestFailedTryAgain: {
            after: {
                msg: "Request failed",
                sub: "Please try again later"
            }
        },
        removeGroupMember: {
            before: {
                msg: "Remove member",
                sub: "This will remove the member from the group"
            }
        },
        loadIapDetails: {
            before: {
                msg: "Store products",
                sub: "Load and display store product details?"
            }
        },
        storeTimeout: {
            after: {
                msg: "Store Timeout",
                sub: "Some items failed to load from the store and might not display properly"
            }
        },
        moveTargetMoveComplete: {
            after: {
                msg: "Checkpoint reached",
                sub: "Capture the target to complete the challenge"
            }
        },
        runTargetExploreComplete: {
            after: {
                msg: "Checkpoint reached",
                sub: "Run the target distance to complete the challenge"
            }
        },
        walkTargetExploreComplete: {
            after: {
                msg: "Checkpoint reached",
                sub: "Walk the target distance to complete the challenge"
            }
        },
        removePhoto: {
            before: {
                msg: "Remove photo",
                sub: ""
            },
            after: {
                msg: "Info",
                sub: "Photo removed"
            }
        },
        photoNotAvailableGen: {
            after: {
                msg: "Info",
                sub: "Photo not available"
            }
        },
        photoNotAvailable: {
            after: {
                msg: "Info",
                sub: "You don't have a profile photo at the moment"
            }
        },
        platformNotSupported: {
            after: {
                msg: "Unknown platform",
                sub: "<p>Your device is not supported.</p><p>The app might not work as expected.</p>"
            }
        },
        iPadDetected: {
            after: {
                msg: "Platform",
                sub: "<p>It seems you are using an iPad.</p><p>Please be aware that the app is optimized for iPhones.</p>"
            }
        },
        waitForGroupConnect: {
            after: {
                msg: "Connecting",
                sub: "Please wait until everybody is connected"
            }
        },
        exit: {
            before: {
                msg: "Exit app",
                sub: ""
            },
        },
        joinOrSelectGroup: {
            before: {
                msg: "Lobby",
                sub: "Join new group or select from your list"
            },
        },
        worldMapUpdated: {
            after: {
                msg: "Success",
                sub: "World Edit registered"
            }
        },
        info: {
            after: {
                msg: "Info",
                sub: ""
            }
        },
        gpsNotAvailableRestartDevice: {
            after: {
                msg: "Location",
                sub: "<p>GPS location could not be acquired</p><p>Please restart your device and try again</p><p>Keep in mind that GPS location is usually not available indoors</p>"
            }
        },
        storyInProgress: {
            before: {
                msg: "Story in progress",
                sub: "Continue or change story"
            }
        },
        passwordChanged: {
            after: {
                msg: "Info",
                sub: "Password changed"
            }
        },
        accountValidated: {
            after: {
                msg: "Info",
                sub: "Account validated"
            }
        },
        referralCodeValidated: {
            after: {
                msg: "R-Code",
                sub: ""
            }
        },
        featureUnlocked: {
            after: {
                msg: "Unlocked",
                sub: "Feature available in the game"
            }
        },
        featureLocked: {
            after: {
                msg: "Locked",
                sub: "Proceed to unlock"
            }
        },
        featureLockedTemp: {
            after: {
                msg: "Locked",
                sub: "<p>Not available in this context</p>Check location, app version, release date</p>"
            }
        },

        referralCodeUsed: {
            after: {
                msg: "R-Code",
                sub: "<p>Thanks for using the R-Code to register</p><p>Your friend will receive a bonus</p>"
            }
        },
        requiresRestartBgMode: {
            after: {
                msg: "Restart required",
                sub: "<p>To ensure that the background mode works properly this change requires restarting the app.</p><p>Please restart the app or reset to the previous setting.</p>"
            }
        },
        returnToMapAfterRequiredItemBought: {
            after: {
                msg: "Got it",
                sub: "Return to world map"
            }
        },
        pauseOrStopStory: {
            before: {
                msg: "Game paused",
                sub: "Quit story"
            }
        },
        pauseOrStopStoryFromStoryline: {
            before: {
                msg: "Game paused",
                sub: "Return to storyline"
            }
        },
        usernameChanged: {
            after: {
                msg: "Info",
                sub: "Username changed"
            }
        },
        scanEnergyLow: {
            after: {
                msg: "Scanner",
                sub: "Energy low. Please recharge soon"
            }
        },
        scanEnergyDepleted: {
            after: {
                msg: "Scanner",
                sub: "Please recharge your energy"
            }
        },
        exitDroneMode: {
            before: {
                msg: "Land drone",
                sub: "Return to home"
            }
        },
        rechargeDrone: {
            before: {
                msg: "Recharge",
                sub: "<p>Drone battery empty</p><p>Please recharge</p>"
            }
        },
        droneRecharged: {
            after: {
                msg: "Recharge complete",
                sub: "<p>Drone battery recharged</p><p>Fly high!</p>"
            }
        },
        updateLevelRecursive: {
            before: {
                msg: "Update",
                sub: "Update chapter levels to match story level?"
            }
        },
        saveTour: {
            before: {
                msg: "Record",
                sub: "Save current tour"
            }
        },
        saveEdits: {
            before: {
                msg: "Save edits",
                sub: "Save/discard map edits"
            }
        },
        resetTextDraft: {
            before: {
                msg: "Reset draft",
                sub: "Warning: This will reset your edits made with text editor and regenerate the text draft based on the current story draft"
            }
        },
        saveDraft: {
            after: {
                msg: "Draft saved",
                sub: "Story draft was synced to your account. Contact us when you are ready to publish the story."
            }
        },
        removeDraft: {
            before: {
                msg: "Remove draft",
                sub: "<p>Warning: this operation is permanent. Please confirm.</p>"
            }
        },
        removeDraftPublished: {
            before: {
                msg: "Remove draft",
                sub: "<p>Warning: this operation is permanent. Please confirm.</p><p>Warning: this operation will remove your published story too.</p>"
            }
        },
        hiddenPlace: {
            after: {
                msg: "Hidden",
                sub: "You have to find the place first"
            }
        },
        goToFeature: {
            before: {
                msg: "Feature shortcut",
                sub: "Go"
            }
        },
        submitWithoutReview: {
            after: {
                msg: "Review",
                sub: "Submit without review"
            }
        },
        accountDeleted: {
            after: {
                msg: "Info",
                sub: "Acount deleted"
            }
        },
        newInbox: {
            after: {
                msg: "Newsfeed",
                sub: "<p>You have new messages in the Newsfeed</p>"
            }
        },
        mpSelectChallengeLeader: {
            after: {
                msg: "MP",
                sub: "<p>Pick a challenge on the map to start a game</p>"
            }
        },
        businessAccountUnlocked: {
            after: {
                msg: "Business account unlocked",
                sub: "<p>Your provisional account has been registered.</p><p>Please contact us for activation when you are ready to publish content.</p>"
            }
        },
        mpChallengeRejected: {
            after: {
                msg: "MP",
                sub: "<p>The game was rejected by a group member</p><p>You may start another game</p>"
            }
        },
        mpSelectChallengeMember: {
            after: {
                msg: "MP",
                sub: "<p>Wait for the host to start a game</p>"
            }
        },
        treasureNotInRange: {
            after: {
                msg: "You are too far away",
                sub: "Get closer to reveal the item"
            }
        },
        cannotAcquireLocation: {
            after: {
                msg: "Location",
                sub: "<p>Could not acquire location</p><p>Please check your location services and open the map again to retry</p>"
            }
        },
        treasureNotAvailable: {
            after: {
                msg: "Locked",
                sub: "This item is not available at the moment"
            }
        },
        treasureLocked: {
            after: {
                msg: "Locked",
                sub: "This item is locked at the moment"
            }
        },
        itemLocked: {
            after: {
                msg: "Locked",
                sub: "This item has to be unlocked first"
            }
        },
        itemLockedIap: {
            after: {
                msg: "Locked",
                sub: "<p>This item is currently unavailable for purchase.</p><p>Please contact support if you have pending orders.</p>"
            }
        },
        coinsPurchased: {
            after: {
                msg: "Account updated",
                sub: "LP has been added to your account"
            }
        },
        feedbackReceived: {
            after: {
                msg: "Thank you",
                sub: "Your feedback has been registered"
            }
        },
        treasureLockedForMember: {
            after: {
                msg: "Locked",
                sub: "Wait for the host to start a game"
            }
        },
        treasureLockedForMp: {
            after: {
                msg: "Locked",
                sub: "This item is not available in multiplayer mode"
            }
        },
        photoUploaded: {
            after: {
                msg: "Info",
                sub: "Photo uploaded"
            }
        },
        photoUploadSource: {
            before: {
                msg: "Upload photo",
                sub: "Select source"
            }
        },
        addProfilePhoto: {
            after: {
                msg: "Profile photo",
                sub: "Do you want to add a profile photo now?"
            }
        },
        addProfilePhotoExt: {
            after: {
                msg: "Profile photo",
                sub: "Do you want to use your existing signup photo?"
            }
        },
        addPhotoTreasure: {
            before: {
                msg: "Content creator",
                sub: "Upload photo"
            }
        },
        photoDeleted: {
            after: {
                msg: "Info",
                sub: "Photo deleted"
            }
        },
        passwordsDoNotMatch: {
            after: {
                msg: "Request failed",
                sub: "Passwords do not match"
            }
        },
        cannotSelectNewDestination: {
            after: {
                msg: "Locked",
                sub: "Finish current activity first"
            }
        },
        logOut: {
            before: {
                msg: "Log out",
                sub: "This will clear your local data"
            }
        },
        deleteGroup: {
            before: {
                msg: "Delete group",
                sub: "Please confirm"
            }
        },
        leaveGroup: {
            before: {
                msg: "Leave group",
                sub: "Please confirm"
            }
        },
        accountNotValidated: {
            after: {
                msg: "Account",
                sub: "Please validate your email account"
            },
        },

        termsNotAgreed: {
            after: {
                msg: "Account",
                sub: "You may delete your account if for some reason you don't find the Terms and Conditions acceptable"
            },
        },

        serviceNotAvailable: {
            after: {
                msg: "Not available",
                sub: "This service is not available at the moment"
            },
        },
        exitMap: {
            before: {
                msg: "Exit map",
                sub: ""
            }
        },
        exitMapStory: {
            before: {
                msg: "Exit map",
                sub: "Note: you have a story in progress"
            }
        },
        exitMapChallenge: {
            before: {
                msg: "Exit map",
                sub: "Note: you have a challenge in progress"
            }
        },
        exitMapMP: {
            before: {
                msg: "Exit map",
                sub: "Note: you have group session in progress"
            }
        },
        exitAR: {
            before: {
                msg: "Exit AR View",
                sub: ""
            }
        },
        updateAppRequiredLock: {
            after: {
                msg: "Locked",
                sub: "Update your app to unlock this feature"
            }
        },
        settingsUpdated: {
            after: {
                msg: "Info",
                sub: "Settings updated"
            }
        },
        cannotAddStoryLocation: {
            after: {
                msg: "Unavailable",
                sub: "Cannot add a new location on an empty draft. Please create a new draft from a template."
            }
        },
        registerClient: {
            after: {
                msg: "Request validated",
                sub: "Continue"
            }
        },
        subscribeToPrivateStory: {
            after: {
                msg: "Request validated",
                sub: "Subscribed"
            }
        },
        formRequiredFields: {
            after: {
                msg: "Form",
                sub: "Please fill in the required fields"
            }
        },
        invalidFormData: {
            after: {
                msg: "Invalid format",
                sub: "Please check the required format"
            }
        },
        registerClientError: {
            after: {
                msg: "Request failed",
                sub: "Invalid code"
            }
        },
        subscriptionCreated: {
            after: {
                msg: "Checkout",
                sub: "Subscription created"
            }
        },
        paymentRegistered: {
            after: {
                msg: "Checkout",
                sub: "Payment registered"
            }
        },
        registerPlace: {
            before: {
                msg: "Register place",
                sub: "This place will be registered in your account"
            },
            after: {
                msg: "Place registered",
                sub: "You can now interact with clients"
            }
        },
        registerPlacesMulti: {
            before: {
                msg: "Register places",
                sub: "The selected places will be registered on your account"
            },
            after: {
                msg: "Place registered",
                sub: "The selected places have been registered on your account"
            }
        },
        registerEmployee: {
            before: {
                msg: "Become admin",
                // sub: "<p>Request and scan the QR code from a place owner/admin.</p><p>Note: This operation costs LP. You can refer to Account/News & Services for details.</p>"
                sub: "<p>Request and enter / scan the QR join code from a place owner/admin</p>"
            },
            after: {
                msg: "Registered admin",
                sub: "You can now interact with clients"
            }
        },

        removeContentCard: {
            before: {
                msg: "Remove item",
                sub: "Remove selected item from the list"
            }
        },

        syncPublishRequest: {
            before: {
                msg: "Warning",
                sub: "<p>This operation will publish the updates into production.</p><p>Please make sure everything is set up correctly if it's a live story and that there are no ongoing games.</p><p>Note: User progress and ratings will be lost, except for leaderboards and scores.</p>"
            },
            after: {
                msg: "Story updated",
                sub: "<p>Published story update complete. Please try it in Leplace World or request a review before launch.</p>"
            }
        },

        syncPublishItemRequest: {
            before: {
                msg: "Warning",
                sub: "<p>This operation will publish the updates into production.</p><p>Please make sure everything is set up correctly if it's a live story and that there are no ongoing games.</p>"
            },
            after: {
                msg: "Item updated",
                sub: "<p>Published item update complete. Please try it in Leplace World or request a review before launch.</p>"
            }
        },

        syncUnpublishItemRequest: {
            before: {
                msg: "Warning",
                sub: "<p>This operation will remove the selected items from production.</p><p>Please make sure everything is set up correctly if it's a live story and that there are no ongoing games.</p>"
            },
            after: {
                msg: "Item updated",
                sub: "<p>Unpublished item complete.</p>"
            }
        },

        syncMapRequest: {
            before: {
                msg: "Warning",
                sub: "<p>This operation will reset the treasure map.</p><p>Please make that there are no ongoing games.</p>"
            },
            after: {
                msg: "Story updated",
                sub: "<p>Published story update complete. Please try it in Leplace World or request a review before launch.</p>"
            }
        },

        syncPublishRequestWithWarnings: {
            after: {
                msg: "Story updated",
                sub: "<p>Published story update complete with warnings. Please try it in Leplace World or request a review before launch.</p>"
            }
        },

        storyNotPublished: {
            after: {
                msg: "Unavailable",
                sub: "<p>Published story not available.</p><p>Contact us to publish the story for the first time.</p>"
            }
        },

        viewEditorMap: {
            before: {
                msg: "Open map",
                sub: "View your fixed places within the story template on the map. Note: this will close the current view"
            }
        },

        removeLastItem: {
            after: {
                msg: "Remove item",
                sub: "Cannot remove the only item in the list"
            }
        },

        removePlace: {
            before: {
                msg: "Remove place",
                sub: "<p>Remove this place from your account.</p><p>Warning: This operation permanently removes the place from the database.</p><p>For changing the owner, please contact support instead.</p>"
            },
            after: {
                msg: "Place removed",
                sub: "Place removed from your account and LP database"
            }
        },
        removeEmployee: {
            before: {
                msg: "Remove place",
                sub: "<p>Remove this place from your account.</p><p>You will no longer be associated to this place or be able to scan clients.</p>"
            },
            after: {
                msg: "Place removed",
                sub: "Place removed from your account"
            }
        },

        unlockedTreasuresDetected: {
            before: {
                msg: "World Edit",
                sub: "You have unlocked treasures in your selection. Do you want to lock them into place?"
            }
        },

        confirmOwner: {
            before: {
                msg: "Register place",
                sub: "<p>Please confirm or specify if you are the owner of this place</p><p>Please have in mind that there cannot be multiple owners</p><p>You may register as an associate if you do not own this place</p>"
            }
        },

        removeCard: {
            before: {
                msg: "Remove payment method",
                sub: "Remove selected payment method"
            },
            after: {
                msg: "Remove payment method",
                sub: "Payment method removed"
            }
        },
        confirmCheckoutSubscription: {
            before: {
                msg: "Checkout",
                sub: "Confirm subscription"
            },
            after: {
                msg: "Checkout",
                sub: "Subscription confirmed"
            }
        },

        confirmCheckoutProduct: {
            before: {
                msg: "Checkout",
                sub: "Confirm payment"
            },
            after: {
                msg: "Checkout",
                sub: "Payment confirmed"
            }
        },

        registerPlaceViaGmap: {
            after: {
                msg: "Register new place",
                sub: "Go to My Town and use the search function to find and register your place on the map"
            }
        },
        signup: {
            after: {
                msg: "Account registered",
                sub: "Please log in"
            }
        },
        requestNewValidationEmail: {
            after: {
                msg: "Email sent",
                sub: "Please validate your email account"
            }
        },
        requestNewPasswordEmail: {
            after: {
                msg: "Email sent",
                sub: "Check your email for temporary password"
            }
        },
        skip: {
            before: {
                msg: "Skip place",
                sub: "You can come back later"
            },
        },
        thanksForRating: {
            after: {
                msg: "Thank you",
                sub: "You make stories unique"
            }
        },
        unsubscribeFromStoryProvider: {
            before: {
                msg: "Unsubscribe",
                sub: "Remove private stories from provider"
            }
        },
        removePrivateStory: {
            before: {
                msg: "Unsubscribe",
                sub: "Remove private story"
            }
        },
        startOptions: {
            before: {
                msg: "Start",
                sub: "Begin the story"
            }
        },
        restartOptions: {
            before: {
                msg: "Replay",
                sub: "Replay or reset the story"
            }
        },
        serverError: {
            after: {
                msg: "Request failed",
                sub: "Not available"
            }
        },
        sessionExpired: {
            after: {
                msg: "Session expired",
                sub: "Please log in again"
            }
        },
        preCheckoutError: {
            after: {
                msg: "Checkout error",
                sub: "There was an error processing your request"
            }
        },
        notingToUpdate: {
            after: {
                msg: "Unchanged",
                sub: "Nothing to update"
            }
        },
        googlePlaceNotFound: {
            after: {
                msg: "Resource unavailable",
                sub: "Google place not found"
            }
        },
        shareLocationTeam: {
            before: {
                msg: "Location updates",
                sub: "<p>Do you want to share your location with your team members?</p><p>This feature is in beta and might not work as expected</p><p>Game experience should not be affected either way</p>"
            }
        },
        loginFailed: {
            after: {
                msg: "Authentication error",
                sub: "Could not log in to your account"
            }
        },
        loginAgain: {
            after: {
                msg: "Login",
                sub: "Log in with your (existing) account"
            }
        },
        mpError: {
            after: {
                msg: "MP Exception",
                sub: "Check your connection"
            }
        },
        mpChallengeLoadError: {
            after: {
                msg: "MP Exception",
                sub: "Requested challenge could not be loaded"
            }
        },
        treasureScannerLoadError: {
            after: {
                msg: "Request failed",
                sub: "Treasure Scanner is not available at the moment"
            }
        },
        itemNotAvailable: {
            after: {
                msg: "Out of stock",
                sub: "Collect or buy more items"
            }
        },
        clearSavedPlaces: {
            before: {
                msg: "Clear story",
                sub: "Visit other places"
            }
        },
        transferStoryDraft: {
            after: {
                msg: "Transfer complete",
                sub: "This story was transferred successfully to the new user account"
            }
        },
        storyNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "This story is not available at the moment"
            }
        },
        eventNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "This event is not available at the moment"
            }
        },
        eventStartNotAvailable: {
            after: {
                msg: "Warning",
                sub: "This event is not available at the moment"
            }
        },
        clearProgress: {
            before: {
                msg: "Replay story",
                sub: "Reset progress and keep saved places"
            }
        },
        dropItem: {
            before: {
                msg: "Drop item",
                sub: "Please confirm"
            }
        },
        cancelItem: {
            before: {
                msg: "Cancel item order",
                sub: "Please confirm"
            }
        },
        orderCancelled: {
            after: {
                msg: "Store",
                sub: "Order cancelled"
            }
        },
        error: {
            after: {
                msg: "Error",
                sub: ""
            }
        },
        localError: {
            after: {
                msg: "Local exception",
                sub: "Unexpected content error"
            }
        },
        networkErrorRetry: {
            after: {
                msg: "Network error",
                sub: "<p>Check your network connection and try again to continue.</p>"
            }
        },
        applicationError: {
            after: {
                msg: "Unexpected error",
                sub: "<p>An unexpected error occured in the application</p><p>Please report the issue with the integrated support service</p><p>Then, please restart the app to continue</p>"
            }
        },
        adsRemoved: {
            after: {
                msg: "Ads removed",
                sub: "Removed the Ads that were shown automatically in the app"
            }
        },
        rewardAlreadyIssued: {
            before: {
                msg: "Info",
                sub: "<p>You have already received a reward.</p><p>You may watch another ad to get a bigger reward (not adding to the previous one).</p>"
            }
        },
        mpConnectChatError: {
            after: {
                msg: "Connection error",
                sub: "Please close and open the page again"
            }
        },
        challengeInProgress: {
            after: {
                msg: "Locked",
                sub: "Finish your current challenge first"
            }
        },
        quitChallenge: {
            before: {
                msg: "Game paused",
                sub: "Quit challenge"
            }
        },
        quitChallengeExt: {
            before: {
                msg: "Game paused",
                sub: "The host has stopped the game"
            }
        },
        quitEvent: {
            before: {
                msg: "Quit event",
                sub: "This will remove you from all event groups"
            }
        },
        alreadyRegistered: {
            before: {
                msg: "Action unavailable",
                sub: "You are already registered"
            }
        },
        alreadyCheckedOut: {
            before: {
                msg: "Warning",
                sub: "<p>You have already completed checkout.</p><p>Your unlock code is shown below.</p><p>You may proceed to purchase another access code if you need.</p>"
            }
        },
        requestFailed: {
            after: {
                msg: "Request failed",
                sub: "Not available"
            }
        },
        qrCodeNotRegistered: {
            after: {
                msg: "Unavailable",
                sub: "QR Code not enabled"
            }
        },
        resourceUnavailable: {
            after: {
                msg: "Resource unavailable",
                sub: "Check data connection."
            }
        },
        usernameUndefined: {
            after: {
                msg: "Undefined username",
                sub: "Please set a username"
            }
        },
        itemRequestOrder: {
            after: {
                msg: "Order ready",
                sub: "Please open the item again and request contact to proceed with the order"
            }
        },
        itemRequestOrderConfirmation: {
            after: {
                msg: "Order sent",
                sub: "You will receive an email about the order. If not, please contact us via email to proceed with the order"
            }
        },
        layersNotInitialized: {
            after: {
                msg: "GMAP",
                sub: "Network error: layers not initialized"
            }
        },
        batteryCheckFailed: {
            after: {
                msg: "Request failed",
                sub: "<p>Could not sync drone battery level</p><p>Check your internet connection</p>"
            }
        },
        groupNotSelected: {
            after: {
                msg: "Request failed",
                sub: "Select a team first"
            }
        },
        roleNotSelected: {
            after: {
                msg: "Request failed",
                sub: "Select a role first"
            }
        },
        resourceInitFailed: {
            after: {
                msg: "Resource unavailable",
                sub: "<p>Service not available at the moment</p><p>Check your internet connection</p>"
            }
        },
        mediaServiceNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "Media service not available"
            }
        },
        mediaServiceError: {
            after: {
                msg: "Request failed",
                sub: "Media service error"
            }
        },
        ARObjectNotFound: {
            after: {
                msg: "Info",
                sub: "<p>The required objects were not found in the photo</p><p>You may try again</p>"
            }
        },
        linkNotFound: {
            after: {
                msg: "Not found",
                sub: "The requested link could not be found"
            }
        },
        photoValidateOk: {
            after: {
                msg: "Nice photo!",
                sub: "Visit the place to continue"
            }
        },
        clearLeaderboardCommit: {
            before: {
                msg: "Warning",
                sub: "<p>Warning: this operation is permanent. Please confirm.</p>"
            }
        },
        clearUnlockCodesCommit: {
            before: {
                msg: "Warning",
                sub: "<p>Warning: this operation is permanent. Registered unlock codes will be lost. Please confirm.</p>"
            }
        },
        beaconSpecsNotDefined: {
            after: {
                msg: "Missing details",
                sub: "Please define the specs for adding a new beacon"
            }
        },
        pleaseReload: {
            after: {
                msg: "Please reload page",
                sub: "Some data could not be retrieved from the server"
            }
        },
        resetQRCode: {
            before: {
                msg: "Reset QR code",
                sub: "Note: The previous code will be replaced"
            },
            after: {
                msg: "Reset QR code",
                sub: "QR code has been updated"
            }
        },
        storyLocked: {
            after: {
                msg: "Locked",
                sub: "This story is locked at the moment"
            }
        },
        storyLockedCoins: {
            after: {
                msg: "Locked",
                sub: "Proceed to unlock this story with LP"
            }
        },
        reviewTerms: {
            after: {
                msg: "Info",
                sub: "Please review the terms and conditions"
            }
        },
        compassError: {
            after: {
                msg: "Warning",
                sub: "<p>Compass not available</p><p>Some features might not work as expected:</p><p>>AR</p><p>>Compass mode</p>"
            }
        },
        compassNeedsCalibration: {
            after: {
                msg: "Warning",
                sub: "<p>Your compass needs calibrating</p><p>Wave your device in a figure-eight motion</p>"
            }
        },
        warning: {
            after: {
                msg: "Warning",
                sub: ""
            }
        },
        googleError: {
            after: {
                msg: "Not found",
                sub: "Google services are temporary unavailable. Please try again later"
            }
        },
        googleQuotaExceeded: {
            after: {
                msg: "Overloaded",
                sub: "Google services are temporary unavailable. Please try again later"
            }
        },
        noNearbyPlaces: {
            after: {
                msg: "Uncharted",
                sub: "Cannot place object here"
            }
        },
        noRegisteredPlacesInSelection: {
            after: {
                msg: "Unavailable",
                sub: "There are no available places in the selection"
            }
        },
        noNearbyPlacesScan: {
            after: {
                msg: "No places",
                sub: "Please do a place/treasure scan first"
            }
        }
    };
}
