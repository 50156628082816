import { Injectable } from "@angular/core";
import { IStatDef, IGameStats, EStatCodes, ISpecificStat } from "../../../classes/def/user/stats";
import { ResourcesCoreDataService } from "../../data/resources-core";
import { IStory } from "../../../classes/def/core/story";
import { IActivity } from "../../../classes/def/core/activity";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IEditorStory } from 'src/app/classes/def/core/editor';

@Injectable({
    providedIn: 'root'
})
export class GameStatsService {

    constructor(
        public resourcesProvider: ResourcesCoreDataService
    ) {
        console.log("game stats service created");
    }

    /**
    * get game stat def as object
    * @param stats 
    */
    getGameStatDef(stats: IStatDef[]) {
        let gameStats: IGameStats = {} as IGameStats;
        let statkeys = Object.keys(EStatCodes);
        for (let i = 0; i < stats.length; i++) {
            for (let j = 0; j < statkeys.length; j++) {
                if (stats[i].code === EStatCodes[statkeys[j]]) {
                    gameStats[statkeys[j]] = stats[i];
                    break;
                }
            }
        }
        return gameStats;
    }


    init() {

    }


    /**
     * e.g. placeVisited -> placeVisited/level 3
     * replace default weight
     * @param stats 
     * @param genericCode 
     */
    getGradedStat(genericCode: number, level: number): IStatDef {
        let stats: IStatDef[] = GeneralCache.resourceCache.general.statScores.content;
        if (!stats) {
            return null;
        }
        let gs: IStatDef;
        let gsFound: boolean = false;

        for (let i = 0; i < stats.length; i++) {
            let s: IStatDef = stats[i];
            if (s.code === genericCode) {
                // check specific stats
                if (s.statsLevelActivities) {
                    for (let j = 0; j < s.statsLevelActivities.length; j++) {
                        let sla: ISpecificStat = s.statsLevelActivities[j];
                        // level can also be null
                        if (sla.levelCode === level) {
                            gsFound = true;
                            gs = Object.assign({}, s);
                            // assign spec weight
                            gs.weight = sla.weight;
                            break;
                        }
                    }
                }
            }
        }

        if (!gsFound) {
            // fallback to generic stat
            gs = Object.assign({}, stats.find(s => s.code === genericCode));
        }

        console.log("get graded stat: ", gs);
        console.log("stats: ", stats);
        return gs;
    }

    getGradedStatWeight(genericCode: number, level: number): number {
        console.log("get graded stat weight: " + genericCode + ", " + level);
        let stat: IStatDef = this.getGradedStat(genericCode, level);
        if (!stat) {
            return 0;
        }
        return stat.weight;
    }

    getStoryFinishedWeight(story: IStory): number {
        console.log("get story finished weight: ", story);
        if (!story) {
            return 0;
        }

        return this.getStoryFinishedWeightCore(story.level, story.rewardXp);
    }

    getStoryFinishedWeightCore(level: number, rewardXp: number): number {
        let stat: IStatDef = this.getGradedStat(EStatCodes.storiesFinished, level);
        if (!stat) {
            return 0;
        }

        let totalXp: number = stat.weight;

        if (rewardXp) {
            totalXp += rewardXp;
        }

        return totalXp;
    }

    getStoryTemplateFinishedWeight(story: IEditorStory): number {
        console.log("get story finished weight: ", story);
        if (!story) {
            return 0;
        }
        let stat: IStatDef = this.getGradedStat(EStatCodes.storiesFinished, story.level);
        if (!stat) {
            return 0;
        }

        let totalXp: number = stat.weight;

        if (story.rewardXp) {
            totalXp += story.rewardXp;
        }

        return totalXp;
    }

    getActivityFinishedWeight(activity: IActivity): number {
        console.log("get activity finished weight: ", activity);
        if (!activity) {
            return 0;
        }
        let stat: IStatDef = this.getGradedStat(EStatCodes.placesVisited, activity.level);
        if (!stat) {
            return 0;
        }
        return stat.weight;
    }
}
