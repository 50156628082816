
import { NgModule } from '@angular/core';
import { BlinkButtonComponent } from './components/blink-button/blink-button.component';
import { ContentDrawerComponent } from './components/content-drawer/content-drawer.component';
import { ExpanderComponent } from './components/expander/expander.component';
import { HourglassComponent } from './components/hourglass/hourglass.component';
import { LeplaceHudCaptionComponent } from './components/leplace-hud-caption/leplace-hud-caption.component';
import { LeplaceHudSlideComponent } from './components/leplace-hud-slide/leplace-hud-slide.component';
import { LeplaceHudComponent } from './components/leplace-hud/leplace-hud.component';
import { LeplacePlateComponent } from './components/leplace-plate/leplace-plate.component';
import { LeplaceToolbarComponent } from './components/leplace-toolbar/leplace-toolbar.component';
import { LevelComponent } from './components/level/level.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ProgressLoadingComponent } from './components/progress-loading/progress-loading.component';
import { ResponsiveImgComponent } from './components/responsive-img/responsive-img.component';
import { SegmentDisplayComponent } from './components/segment-display/segment-display.component';
import { ModalHeaderContainerComponent } from './components/modal-header-container/modal-header-container.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ComponentsModule } from '../components.module';
import { IconWrapperComponent } from './components/icon-wrapper/icon-wrapper.component';
import { YouTubeViewComponent } from './components/youtube-view/youtube-view.component';
import { PlainEntryComponent } from './components/plain-entry/plain-entry.component';
import { PlainEntryHeaderComponent } from './components/plain-entry-header/plain-entry-header.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';

// angular modules
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { IconWrapperExtraComponent } from './components/icon-wrapper-extra/icon-wrapper-extra.component';
import { BlinkSpanComponent } from './components/blink-span/blink-span.component';

import { CurtainMenuComponent } from './components/curtain-menu/curtain-menu.component';
import { LeplaceHudButtonComponent } from './components/leplace-hud-button/leplace-hud-button.component';
import { LeplacePaginatorComponent } from './components/leplace-paginator/leplace-paginator.component';
import { HtmlDivComponent } from './components/html-div/html-div.component';
import { EditTextareaComponent } from './components/edit-textarea/edit-textarea.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { UploadImgComponent } from './components/upload-img/upload-img.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { EditInputComponent } from './components/edit-input/edit-input.component';
import { EditOptionsComponent } from './components/edit-options/edit-options.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { CheckboxCardComponent } from './components/checkbox-card/checkbox-card.component';
import { EditCodeComponent } from './components/edit-code/edit-code.component';
import { EditInfoComponent } from './components/edit-info/edit-info.component';
import { IconSpanComponent } from './components/icon-span/icon-span.component';
import { EditCheckboxComponent } from './components/edit-checkbox/edit-checkbox.component';
import { PlainEntryExpandComponent } from './components/plain-entry-expand/plain-entry-expand.component';
import { SafeHtmlRendererComponent } from './components/safe-html-renderer/safe-html-renderer.component';
import { DateInputSelectorComponent } from './components/date-input-selector/date-input-selector.component';

// import { AngularPieModule } from 'angular-pie';

const components = [
  BlinkButtonComponent,
  BlinkSpanComponent,
  CardItemComponent,
  ContentDrawerComponent,
  CurtainMenuComponent,
  CustomSelectComponent,
  DateInputSelectorComponent,
  EditButtonComponent,
  EditCodeComponent,
  EditCheckboxComponent,
  EditInputComponent,
  EditOptionsComponent,
  EditTextareaComponent,
  EditInfoComponent,
  ExpanderComponent,
  HourglassComponent,
  IconSpanComponent,
  LeplaceHudCaptionComponent,
  LeplaceHudSlideComponent,
  LeplaceHudComponent,
  LeplaceHudButtonComponent,
  LeplacePaginatorComponent,
  LeplacePlateComponent,
  LeplaceToolbarComponent,
  LevelComponent,
  ModalContainerComponent,
  NavBarComponent,
  ProgressLoadingComponent,
  ResponsiveImgComponent,
  SafeHtmlRendererComponent,
  SegmentDisplayComponent,
  ModalHeaderContainerComponent,
  SidenavComponent,
  IconWrapperComponent,
  YouTubeViewComponent,
  PlainEntryComponent,
  PlainEntryExpandComponent,
  PlainEntryHeaderComponent,
  QrCodeComponent,
  IconWrapperExtraComponent,
  UploadImgComponent,
  HtmlDivComponent,
  CheckboxCardComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    ComponentsModule,
    NgxQRCodeModule
  ],
  exports: [...components]
})
export class GenericComponentsModule { }
