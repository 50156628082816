import { Subscription } from 'rxjs';
import {
  Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, Input, Output, EventEmitter, ComponentRef,
  ComponentFactoryResolver, AfterViewInit, OnChanges, SimpleChanges, SimpleChange
} from '@angular/core';
import { GameItemComponent } from 'src/app/components/app/components/game-item/game-item.component';
import { GameItemDispComponent } from '../game-item-disp/game-item-disp.component';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { PlainEntryComponent } from 'src/app/components/generic/components/plain-entry/plain-entry.component';
import { CardItemComponent } from 'src/app/components/generic/components/card-item/card-item.component';
import { CheckboxCardComponent } from 'src/app/components/generic/components/checkbox-card/checkbox-card.component';
import { QrCodeComponent } from 'src/app/components/generic/components/qr-code/qr-code.component';

@Component({
  selector: 'dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  // Metadata Properties:

  // selector - The directive type or the name used for querying.
  // read - True to read a different token from the queried elements.
  // static - True to resolve query results before change detection runs

  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

  @Input()
  type: string;

  @Input()
  context: any;

  @Input()
  update: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  sub: Subscription = null;

  private mappings = {
    gameItem: GameItemComponent,
    gameItemDisp: GameItemDispComponent,
    plainEntry: PlainEntryComponent,
    cardItem: CardItemComponent,
    checkboxCard: CheckboxCardComponent,
    qrCode: QrCodeComponent
  };

  private componentRef: ComponentRef<{}>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver) {
  }

  getComponentType(typeName: string) {
    let type = this.mappings[typeName];
    // tslint:disable-next-line: no-use-before-declare
    return type || UnknownDynamicComponent;
  }


  ngAfterViewInit() {
    if (this.type) {
      setTimeout(() => {
        let componentType = this.getComponentType(this.type);
        // note: componentType must be declared within module.entryComponents
        let factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.componentRef = this.container.createComponent(factory);
        let test: boolean = false;
        let instance = this.componentRef.instance as DynamicComponent;
        if (test) {
          console.log(instance);
        }
        this.setComponentProps(this.componentRef);
      }, 1);
    }
  }

  setComponentProps(componentRef: ComponentRef<{}>) {
    // set component context
    let instance = componentRef.instance as DynamicComponent;
    // extract the params from the context input and pass to the dynamic component
    if (this.context) {
      let keys = Object.keys(this.context);
      // console.log("component props: ", keys);
      for (let i = 0; i < keys.length; i++) {
        instance[keys[i]] = this.context[keys[i]];
      }
    }

    if (instance.onClick && !this.sub) {
      this.sub = instance.onClick.subscribe(
        (data) => this.handler(data),
        (err: Error) => {
          console.error(err);
        });
    }

    if (instance.action && !this.sub) {
      this.sub = instance.action.subscribe(
        (data) => this.handler(data),
        (err: Error) => {
          console.error(err);
        });
    }
  }

  handler(data) {
    this.action.emit(data);
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'update':
            console.log("dynamic content update event");
            if (this.componentRef) {
              this.setComponentProps(this.componentRef);
            }
            break;
        }
      }
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.sub = ResourceManager.clearSub(this.sub);
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}

export abstract class DynamicComponent {
  // context: any;
  [key: string]: any
}

export class UnknownDynamicComponent extends DynamicComponent { }
