
/**
 * keys that are saved in local storage
 */
export enum ELocalAppDataKeys {
    userId = "user_id",
    userName = "user_name",
    token = "token",

    appRate = "enable_app_rate",
    openCounter = "open_counter",
    codePushUpdated = "code_push_updated",
    firstBootDone = "first_boot_done",
    tutorialSeen = "tutorial_seen",
    tutorialSeenLocal = "tutorial_seen_local",
    gmapTutorialSeenLocal = "gmap_tutorial_seen_local",
    appSettings = "app_settings",
    versionCode = "version_code",

    pushId = "push_notifications_id",

    isTester = "is_tester",
    mapStyles = "map_styles",
    photoUrl = "photo_url_storage",
    itemImages = "item_images",

    treasureCache = "treasure_cache",

    refreshTest = "refresh_test",
    navParams = "nav_params",

    localShowFlags = "show_flags",
    trackEventFlags = "track_events",
    adConsent = "ad_consent",
    cameraInitialized = "camera_initialized",
    locationChecked = "location_checked"
}

export interface IAppFlagsGen {
    [key: string]: IAppFlagsElement;
}

export interface IAppFlagsGenContent {
    [key: string]: any;
}

/**
 * a collection of app flags
 */
export interface IAppFlags extends IAppFlagsGen {
    appRate: IAppFlagsElement;
    openCounter: IAppFlagsElement;
    tutorialSeen: IAppFlagsElement;
}

/**
 * app flags element key-value pair
 */
export interface IAppFlagsElement {
    flag: string;
    value: any;
}

// custom storage flags

export interface ILocalShowFlags {
    gmapTutorial: boolean,
    droneTutorial: boolean,
    arTutorial: boolean
}