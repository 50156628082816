
import { Injectable } from '@angular/core';
import { BusinessDataService } from '../../data/business';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { AnalyticsService } from '../../general/apis/analytics';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../../general/settings-manager';
import { IBusinessFeaturesEnabled } from 'src/app/classes/def/business/business';
import { UiExtensionStandardService } from '../../general/ui/ui-extension-standard';

@Injectable({
    providedIn: 'root'
})
export class BusinessFeaturesService {
    platform: IPlatformFlags = {} as IPlatformFlags;
    enabled: IBusinessFeaturesEnabled = {
        qr: false,
        sales: false
    };

    constructor(
        public businessDataProvider: BusinessDataService,
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public analytics: AnalyticsService,
        public settings: SettingsManagerService
    ) {
        console.log("business features service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    /**
     * view sales associated to the location
     * then disable sales until the next init
     * @param blocation 
     */
    viewSalesCoreNoAction(googleId: string, placeName: string, locationId: number) {
       
    }

    /**
     * view sales associated to the location
     * then disable sales until the next init
     * @param blocation 
     */
    viewSalesCore(googleId: string, placeName: string, locationId: number) {
       
    }

    resetBusinessFeatures() {
        let keys: string[] = Object.keys(this.enabled);
        for (let i = 0; i < keys.length; i++) {
            this.enabled[keys[i]] = false;
        }
    }

    getCurrentBusinessFeatures() {
        return this.enabled;
    }

}
