export enum EMenuCommand {
  logout = 1,
  exit = 2
}

export interface IPageInterface {
  // title as shown in the menu
  title: string;
  // unique identifier
  name: string;
  url: string;
  icon: string;
  cmd?: number;
  index: number;
  tabName?: string;
  tabComponent?: any;
  loginRequired?: boolean;
  enableDrawer: boolean;
  enabled?: any;
  customIcon?: boolean;
  transparent?: boolean;
  fullView?: boolean;
}

export interface IMenuInterface {
  name: string;
  pages: IPageInterface[];
}

export interface ITabInterface {
  id: number;
  url: string;
  name: string;
  icon: string;
  active?: boolean;
  transparentView?: boolean;
  fullView?: boolean;
  showTabs?: boolean;
  enabled?: boolean;
  transparent?: boolean;
  cmd?: number;
}

export enum ERouteDef {
  landing = "landing",
  home = "home", 
  login = "login", 
  signup = "signup",
  signupTerms = "terms",
  signupTutorial = "tutorial",
  marketplace = "marketplace",
  map = "map",
  storyline = "storyline",
  support = "support",
  validate = "validate"
}

