import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IDictTable, IDictTableEntry } from 'src/app/classes/def/table/name-value';
import { IButtonCollection, IButtonOptions } from 'src/app/classes/def/app/ui';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IGameItem, EItemActions, ILocationGameItem, IGameItemOptions, EGameItemSelectorMode } from 'src/app/classes/def/items/game-item';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';
import { ParamHandler } from 'src/app/classes/general/params';
import { IItemAction } from 'src/app/classes/def/items/action';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { GeneralUtils } from 'src/app/classes/utils/general';
import { Util } from 'src/app/classes/general/util';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ISponsor } from 'src/app/classes/def/business/sponsor';


enum EViewMode {
  description = 1,
  buy = 2,
  providers = 3
}

enum EButtonCodes {
  buy = 10,
  use = 20,
  scan = 21,
  drop = 30,
  dropActive = 35,
  retry = 40,
  providers = 50
}

interface IButtonPattern {
  pattern: number[];
  layout: number[];
}

interface IButtonPatternCollection {
  [key: string]: IButtonPattern;
}

interface IButtonCollectionView1 extends IButtonCollection {
  none: IButtonOptions;
  buy: IButtonOptions;
  use: IButtonOptions;
  // aux
  scan: IButtonOptions;
  drop: IButtonOptions;
  dropValid: IButtonOptions,
  retry: IButtonOptions;
  providers: IButtonOptions;
}

interface IButtonCollectionView2 extends IButtonCollection {
  switchPreview: IButtonOptions;
  decreaseAmount: IButtonOptions;
  increaseAmount: IButtonOptions;
  submitPurchase: IButtonOptions;
}

interface IButtonCollectionView3 extends IButtonCollection {
  switchPreview: IButtonOptions;
  use: IButtonOptions;
}

interface IGameItemOptionsViewContainer {
  description: IGameOptionsView1;
  buy: IGameOptionsView2;
  providers: IGameOptionsView3;
}

interface IGameOptionsView1 {
  buttons: IButtonCollectionView1;
}

interface IGameOptionsView2 {
  buttons: IButtonCollectionView2;
}

interface IGameOptionsView3 {
  buttons: IButtonCollectionView3;
}



interface IStatsDispTable extends IDictTable {
  item: IDictTableEntry;
  unitPriceBuy: IDictTableEntry;
  unitPriceSell: IDictTableEntry;
  total: IDictTableEntry;
  balance: IDictTableEntry;
  activeOrders: IDictTableEntry;
  activeItems: IDictTableEntry;
}


@Component({
  selector: 'modal-game-item-options',
  templateUrl: './game-item-options.component.html',
  styleUrls: ['./game-item-options.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        opacity: 0,
      })),
      state('active', style({
        opacity: 1,
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class GameItemOptionsViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionLines: string[];
  title: string = "";
  heading: string;
  selectAll: boolean = false;
  item: IGameItem;
  isIAP: boolean = false;
  actionDescription: string = "";
  statusDescription: string = "";
  submitDescription: string = "";
  buyDescription: string = "";
  photoUrl: string = "";

  actionTitle: string = "";
  noButtons: boolean = false;

  appIcons = EAppIcons;

  hasSponsors: boolean = false;
  sponsors: ISponsor[] = [];

  selectorMode: number = null;
  isSelectorMode: boolean = false;
  customButtonOptions: IButtonOptions = null;

  isDraft: boolean = false;
  withAmount: boolean = false;
  lockedAmount: boolean = false;
  selectAmount: number = 1;

  viewContainer: IGameItemOptionsViewContainer = {
    description: {
      buttons: {
        none: {
          name: null,
          icon: null,
          class: null,
          size: 1,
          disabled: true,
          enabled: false,
          highlight: false,
          callback: () => {

          },
          code: null
        },
        buy: {
          name: null,
          icon: "basket",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.switchBuyMode();
          },
          code: EButtonCodes.buy
        },
        use: {
          name: null,
          icon: EAppIconsStandard.mail,
          customIcon: false,
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.use();
          },
          code: EButtonCodes.use
        },
        // aux
        scan: {
          name: null,
          icon: EAppIcons.qr,
          customIcon: true,
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.use();
          },
          code: EButtonCodes.scan
        },
        drop: {
          name: null,
          icon: EAppIcons.cancel,
          customIcon: true,
          class: "action-button-fill button-color-warn",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.cancelOrDrop();
          },
          code: EButtonCodes.drop
        },
        dropValid: {
          name: null,
          icon: EAppIcons.delete,
          customIcon: true,
          class: "action-button-fill button-color-primary",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.cancelOrDropValid();
          },
          code: EButtonCodes.dropActive
        },
        retry: {
          name: null,
          icon: "refresh",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.retry();
          },
          code: EButtonCodes.retry
        },
        providers: {
          name: null,
          icon: "pin",
          class: "action-button-fill button-color-primary-70",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.switchProvidersMode();
          },
          code: EButtonCodes.providers
        }
      }
    },
    buy: {
      buttons: {
        switchPreview: {
          name: null,
          icon: "arrow-back",
          class: "action-button-fill button-color-warn",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.switchPreviewMode();
          },
          code: null
        },
        decreaseAmount: {
          name: null,
          // icon: "arrow-down",
          icon: "remove",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.decreaseAmount();
          },
          code: null
        },
        increaseAmount: {
          name: null,
          // icon: "arrow-up",
          icon: "add",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.increaseAmount();
          },
          code: null
        },
        submitPurchase: {
          name: null,
          icon: "basket",
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.submitPurchase();
          },
          code: null
        }
      }
    },
    providers: {
      buttons: {
        switchPreview: {
          name: null,
          icon: "arrow-back",
          class: "action-button-fill button-color-warn",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.switchPreviewMode();
          },
          code: null
        },
        use: {
          name: null,
          icon: EAppIcons.qr,
          customIcon: true,
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.use();
          },
          code: EButtonCodes.use
        }
      }
    }
  };

  viewModes = EViewMode;
  submitView: number = EViewMode.description;

  strictMode: boolean = true;

  itemExpireTimeout = null;

  locationItems: ILocationGameItem[] = [];

  colSize: number = 3;

  hasProviders: boolean = false;

  showState: string = "inactive";

  segmentDisplayLoaded: boolean = false;

  selectAmountText: string = "select amount";
  withReference: boolean = false;


  /**
   * layout patterns for known types
   */
  patterns: IButtonPatternCollection = {
    maps: {
      pattern: [EButtonCodes.drop, EButtonCodes.use],
      layout: [1, 0, 0, 1]
    },
    items: {
      pattern: [EButtonCodes.drop, EButtonCodes.buy, EButtonCodes.use],
      layout: [1, 2, 1]
    },
    powers: {
      pattern: [EButtonCodes.buy],
      layout: [4]
    },
    sales1: {
      pattern: [EButtonCodes.drop, EButtonCodes.providers, EButtonCodes.buy, EButtonCodes.use],
      layout: [1, 1, 1, 1]
    },
    sales2: {
      pattern: [EButtonCodes.drop, EButtonCodes.providers, EButtonCodes.buy, EButtonCodes.scan],
      layout: [1, 1, 1, 1]
    },
    sell: {
      pattern: [EButtonCodes.drop, EButtonCodes.dropActive],
      layout: [1, 0, 0, 1]
    },
    iap: {
      pattern: [EButtonCodes.drop, EButtonCodes.buy, EButtonCodes.retry],
      layout: [1, 2, 1]
    }
  };

  // add the buttons for exact fit on the grid
  buttons: IButtonOptions[] = [];

  /**
   * buy/drop submit
   */
  submit = {
    amount: 1,
    min: 1,
    max: 1,
    action: EItemActions.cancel,
    unitPriceCoins: 0,
    totalPriceCoins: 0,
    unitPriceUSD: "0",
    totalPriceUSD: "0"
  };

  dispTable: IStatsDispTable = {
    item: {
      name: "Item",
      value: null,
      show: false
    },
    unitPriceBuy: {
      name: "Unit price (buy)",
      value: null,
      show: false
    },
    unitPriceSell: {
      name: "Unit price (sell)",
      value: null,
      show: false
    },
    total: {
      name: "Total",
      value: null,
      show: false
    },
    balance: {
      name: "Balance",
      value: null,
      show: false
    },
    activeOrders: {
      name: "Active orders",
      value: null,
      show: false
    },
    activeItems: {
      name: "Active items",
      value: null,
      show: false
    }
  };

  timeouts = {
    refresh: null
  };

  availableCoins: number = GeneralCache.resourceCache.user.general.content.cachedCoins;
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;

  basicView: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public scriptLoader: ScriptLoaderService
  ) {

  }


  decreaseAmount() {
    if (this.submit.amount > this.submit.min) {
      this.submit.amount -= 1;
    }

    this.checkLimits();
    this.formatSubmitDescription();
  }

  increaseAmount() {
    let crt: any = this.submit.amount;
    crt = parseInt(crt, 10);
    this.submit.amount = crt + 1;
    if (this.submit.amount > this.submit.max) {
      this.submit.amount = this.submit.max;
    }

    this.checkLimits();
    this.formatSubmitDescription();
  }

  /**
   * use item
   * some items may require qr code to activate (e.g. sales)
   */
  use() {
    let itemAction: IItemAction = {
      code: EItemActions.use,
      data: {
        qrCode: null,
        amount: null
      }
    };

    this.dismiss(itemAction);
  }

  formatSubmitDescription() {
    let keys: string[] = Object.keys(this.dispTable);
    for (let i = 0; i < keys.length; i++) {
      this.dispTable[keys[i]].value = null;
      this.dispTable[keys[i]].show = false;
    }
    this.submitDescription = "";
    this.actionTitle = "";

    if (!this.item) {
      return;
    }

    switch (this.submit.action) {
      case EItemActions.buy:

        let unitPriceBuy: number = this.item.priceCoins;
        if (!this.item.resellValuePercent) {
          this.item.resellValuePercent = 0;
        }
        let unitPriceSell: number = Math.floor(this.item.priceCoins * this.item.resellValuePercent / 100);

        let unitPriceBuyDisp: string = "FREE";
        let unitPriceSellDisp: string = "0";
        let totalPriceDisp: string = "FREE";

        // buy/sell
        if (this.item.priceCoins) {
          if (this.submit.amount > 0) {
            // buy
            this.submit.unitPriceCoins = unitPriceBuy;
          } else {
            // sell
            this.submit.unitPriceCoins = unitPriceSell;
          }
          this.submit.totalPriceCoins = this.submit.unitPriceCoins * this.submit.amount;
        } else {
          this.submit.unitPriceCoins = 0;
          this.submit.totalPriceCoins = 0;
        }

        let priceUSD: string = this.item.itemIap ? GameUtils.getPriceIAP(this.item) : null;
        let hiddenPrice: boolean = this.item.itemIap ? this.item.itemIap.hiddenPrice : false;

        if (priceUSD && !hiddenPrice) {
          this.submit.unitPriceUSD = priceUSD;
          this.submit.totalPriceUSD = this.submit.amount + " x " + priceUSD;
        }

        if (this.submit.unitPriceCoins) {
          unitPriceBuyDisp = unitPriceBuy + " LP";
          unitPriceSellDisp = unitPriceSell + " LP";
          totalPriceDisp = this.submit.totalPriceCoins + " LP";
        } else {
          if (this.submit.unitPriceUSD) {
            unitPriceBuyDisp = this.submit.unitPriceUSD;
            totalPriceDisp = this.submit.totalPriceUSD;
          }
        }

        let balance: number = null;
        if (this.availableCoins != null) {
          balance = this.availableCoins - this.submit.totalPriceCoins;
        }

        this.submitDescription = "<p>Supply</p>";
        this.actionTitle = "Supply";
        this.dispTable.item.value = this.item.name;
        this.dispTable.item.show = true;

        if (!hiddenPrice) {
          this.dispTable.unitPriceSell.value = unitPriceSellDisp;
          this.dispTable.unitPriceSell.show = true;
          this.dispTable.unitPriceBuy.value = unitPriceBuyDisp;
          this.dispTable.unitPriceBuy.show = true;
          this.dispTable.total.value = totalPriceDisp;
          this.dispTable.total.show = true;
          if (balance != null) {
            this.dispTable.balance.value = balance;
            this.dispTable.balance.show = true;
          }
          if (priceUSD) {
            this.dispTable.balance.show = false;
          }
        }

        break;
      case EItemActions.cancel:
        this.submitDescription = "<p>Cancel order</p>";
        this.actionTitle = "Cancel";
        this.dispTable.activeOrders.value = this.submit.max;
        this.dispTable.activeOrders.show = true;
        break;
      case EItemActions.drop:
        this.submitDescription = "<p>Drop item</p>";
        this.actionTitle = "Drop";
        this.dispTable.activeItems.value = this.submit.max;
        this.dispTable.activeItems.show = true;
        break;
      default:
        this.submitDescription = "";
        break;
    }
  }

  /**
   * preview options
   */
  switchPreviewMode() {
    this.submitView = EViewMode.description;
    this.submit.amount = 1;
    this.refreshShowState();
  }

  /**
   * buy options
   */
  switchBuyMode() {
    this.submit.action = EItemActions.buy;
    if (this.item) {
      this.submit.max = Math.floor(this.availableCoins / this.item.priceCoins);
      if (this.item.itemIap) {
        this.submit.min = 1;
      } else {
        this.submit.min = -this.item.validCount;
      }
      if (this.item.consumable === 0) {
        // non consumable/one time supply e.g. maps
        this.submit.max = 1;
      }
      if (this.item.itemIap) {
        this.submit.max = 1;
      }
    } else {
      this.submit.max = Number.MAX_SAFE_INTEGER;
    }

    // console.log("buy mode limits: ", this.submit);
    this.checkLimits();
    this.formatSubmitDescription();
    this.submitView = EViewMode.buy;
    this.buyDescription = "<p>Select buy/sell amount (negative amount for sell operation)</p>";
    this.selectAmountText = "supply amount (buy/sell)";
    this.refreshShowState();
  }

  /**
   * show providers
   */
  switchProvidersMode() {
    this.hasProviders = this.locationItems && (this.locationItems.length > 0);
    this.submitView = EViewMode.providers;
    this.refreshShowState();
  }

  refreshShowState() {
    this.showState = "inactive";
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    this.timeouts.refresh = setTimeout(() => {
      this.showState = "active";
    }, 100);
  }


  /**
   * submit purchase (buy/sell)
   */
  submitPurchase() {
    let itemAction: IItemAction;

    itemAction = {
      code: this.submit.action,
      data: {
        qrCode: null,
        amount: this.submit.amount
      }
    };
    this.dismiss(itemAction);
  }

  retry() {
    let itemAction: IItemAction = {
      code: EItemActions.retry,
      data: null
    };
    this.dismiss(itemAction);
  }

  onTapZoom(zoom: boolean) {
    console.log("on tap zoom: ", zoom);
  }

  cancelOrDrop() {
    if (this.item.pendingCount > 0) {
      this.cancel();
    } else {
      this.drop();
    }
  }

  cancelOrDropValid() {
    if (this.item.pendingCount > 0) {
      this.cancel();
    } else {
      this.dropValid();
    }
  }

  cancel() {
    this.submit.action = EItemActions.cancel;
    this.submit.max = this.item.pendingCount;
    this.checkLimits();
    this.formatSubmitDescription();
    this.submitView = EViewMode.buy;
    this.selectAmountText = "dismiss amount";
  }

  drop() {
    this.submit.action = EItemActions.drop;
    this.submit.max = this.item.activeCount;
    this.checkLimits();
    this.formatSubmitDescription();
    this.submitView = EViewMode.buy;
    this.buyDescription = "<p>Are you sure you want to remove the item(s) from your account?</p><p>Warning: this operation is permanent.</p>";
    this.selectAmountText = "drop amount";
  }

  dropValid() {
    this.submit.action = EItemActions.drop;
    this.submit.max = this.item.validCount;
    this.checkLimits();
    this.formatSubmitDescription();
    this.submitView = EViewMode.buy;
    this.buyDescription = "<p>Are you sure you want to remove the item(s) from your account?</p><p>Warning: this operation is permanent.</p>";
    this.selectAmountText = "drop amount";
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  checkLimits() {
    if (this.submit.amount <= this.submit.min) {
      this.viewContainer.buy.buttons.decreaseAmount.disabled = true;
    } else {
      this.viewContainer.buy.buttons.decreaseAmount.disabled = false;
    }

    if (this.submit.amount >= this.submit.max) {
      this.viewContainer.buy.buttons.increaseAmount.disabled = true;
    } else {
      this.viewContainer.buy.buttons.increaseAmount.disabled = false;
    }
  }


  select() {
    let itemAction: IItemAction = {
      code: EItemActions.use,
      item: this.item,
      data: {
        qrCode: null,
        amount: this.selectAmount
      }
    };
    this.dismiss(itemAction);
  }

  remove() {
    let itemAction: IItemAction = {
      code: EItemActions.drop,
      item: this.item,
      data: null
    };
    this.dismiss(itemAction);
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let gioParams: IGameItemOptions = np.params;
      this.item = gioParams.item;
      this.basicView = gioParams.basicView;
      this.selectorMode = this.item.selectorMode;
      this.isSelectorMode = this.selectorMode != null;
      this.isDraft = this.item.isDraft;

      console.log("game item options nav params: ", gioParams);

      switch (this.selectorMode) {
        case EGameItemSelectorMode.add:
          this.customButtonOptions = {
            name: "Select",
            icon: EAppIconsStandard.accept,
            class: "action-button-fill button-color-accent",
            size: 1,
            disabled: false,
            enabled: false,
            highlight: false,
            callback: () => {
              this.select();
            },
            code: null
          };
          this.withAmount = true;
          break;
        case EGameItemSelectorMode.remove:
          this.customButtonOptions = {
            name: "Remove",
            icon: EAppIconsStandard.remove,
            class: "action-button-fill button-color-warn",
            size: 1,
            disabled: false,
            enabled: false,
            highlight: false,
            callback: () => {
              this.remove();
            },
            code: null
          };
          if (this.item.amount != null) {
            this.selectAmount = this.item.amount;
            this.withAmount = true;
            this.lockedAmount = true;
          }
          break;
        default:
          break;
      }
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.description = this.item.description;
      // this.descriptionLines = this.formatText(this.description);
      let maxN: number = 20;
      this.heading = this.item.tempName;
      this.title = this.item.name ? this.item.name.slice(0, maxN) : "";
      if (this.title.length >= maxN) {
        this.title += "..";
      }
      // this.title = "Item";
    }

    this.showState = "active";
    this.checkLinks();

    if (this.isSelectorMode) {
      this.withReference = false;
    }

    if (this.basicView) {
      return;
    }

    if (this.item) {
      this.isIAP = GameUtils.checkIAP(this.item);

      if (this.item.timeLeft) {
        // this.monitorItemExpireTimeout();
      }

      if (!this.strictMode) {
        let keys = Object.keys(this.viewContainer.description.buttons);
        keys.forEach((key) => {
          this.viewContainer.description.buttons[key].enabled = true;
          this.viewContainer.description.buttons[key].disabled = false;
        });
        return;
      }

      let isInInventory: boolean = (this.item.validCount > 0) || (this.item.activeCount > 0);

      /**
       * the item can be bought if
       * the item is consumable [strict]
       * or the item is not consumable and the item does not exist in the user inventory [strict]
       */
      this.viewContainer.description.buttons.buy.enabled = (this.item.consumable === 1) || ((this.item.consumable === 0) && !isInInventory);
      if (this.item.achievement === 1) {
        this.viewContainer.description.buttons.buy.enabled = false;
      }

      this.viewContainer.description.buttons.buy.disabled = false;


      /**
       * the item can be activated if
       * the item requires activation [strict]
       * there is at least an item available/purchased in the user inventory [gray]
       * there is no other active item of the same type in the user inventory [gray]
       */
      //  this.viewContainer.description.buttons.use.enabled = ((this.item.requiresEnable == 1) && (this.item.validCount > 0) && (this.item.activeCount == 0));

      this.viewContainer.description.buttons.use.enabled = (this.item.requiresEnable === 1);
      if (this.item.validCount === 0) {
        this.viewContainer.description.buttons.use.disabled = true;
      } else {
        if (this.item.activeCount > 0) {
          this.viewContainer.description.buttons.use.disabled = true;
        }
      }


      /**
       * the item can be disabled if
       * the item is not app handled [strict]
       * there is at least an item ACTIVE/enabled in the user inventory [gray]
       */
      this.viewContainer.description.buttons.drop.enabled = this.item.appHandled === 0;

      if (this.item.activeCount === 0 && this.item.pendingCount === 0) {
        this.viewContainer.description.buttons.drop.disabled = true;
      } else {
        this.viewContainer.description.buttons.drop.disabled = false;
      }

      if (this.viewContainer.description.buttons.drop.enabled && this.viewContainer.description.buttons.drop.disabled) {
        if (this.item.validCount) {
          this.viewContainer.description.buttons.dropValid.enabled = true;
          this.viewContainer.description.buttons.dropValid.disabled = false;
        }
      }

      if (!this.isIAP) {
        if (GameUtils.checkSpecialIAP(this.item) && (this.item.validCount > 0)) {
          this.viewContainer.description.buttons.drop.disabled = false;
        }
      }

      /**
       * the retry option is enabled if
       * the item has iap
       * there is at least a pending item in the user inventory [gray]
       */
      this.viewContainer.description.buttons.retry.enabled = this.isIAP;
      if (this.item.pendingCount > 0) {
        this.viewContainer.description.buttons.retry.disabled = false;
      } else {
        this.viewContainer.description.buttons.retry.disabled = true;
      }

      let avk = Object.keys(this.viewContainer.description.buttons);
      let hasOptions: boolean = false;
      for (let i = 0; i < avk.length; i++) {
        if (this.viewContainer.description.buttons[avk[i]].enabled) {
          hasOptions = true;
          break;
        }
      }

      this.setStatusDescription();
      this.checkLocation();

      if (hasOptions) {

        this.actionDescription = "<p>Available options: </p>";

        if (this.viewContainer.description.buttons.use.enabled) {
          this.actionDescription += "<p>>Activate</p>";
        }

        if (this.viewContainer.description.buttons.drop.enabled) {
          if (this.isIAP) {
            this.actionDescription += "<p>>Remove item/Cancel order</p>";
          } else {
            this.actionDescription += "<p>>Disable item</p>";
          }
        }

        if (this.viewContainer.description.buttons.dropValid.enabled) {
          if (this.isIAP) {
            this.actionDescription += "<p>>Remove item/Cancel order</p>";
          } else {
            this.actionDescription += "<p>>Remove item</p>";
          }
        }

        if (this.viewContainer.description.buttons.buy.enabled) {
          if (this.isIAP) {
            this.actionDescription += "<p>>Buy via in app purchase</p>";
          } else {
            this.actionDescription += "<p>>Buy with LP credits</p>";
          }
        }

        if (this.viewContainer.description.buttons.retry.enabled) {
          this.actionDescription += "<p>>Retry purchase</p>";
        }
      }

      if (this.item.enableByQr) {
        this.mergeClass(this.viewContainer.description.buttons.use, this.viewContainer.description.buttons.scan);
      }

      this.computeGrid();

      // check sponsors
      if (this.item.itemSponsors && this.item.itemSponsors.length > 0) {
        this.hasSponsors = true;
        this.sponsors = this.item.itemSponsors.map(e => e.sponsor);
      }

    }
  }

  mergeClass(target: IButtonOptions, ext: IButtonOptions) {
    target.icon = ext.icon;
    target.class = ext.class;
  }

  checkLinks() {
    if (this.item.photoUrl) {
      this.photoUrl = this.item.photoUrl;
    }

    if (this.item.wikiUrl) {
      this.withReference = true;
    }
  }

  /**
   * compute col size
   * add buttons, format layout
   */
  computeGrid() {
    let keys: string[] = Object.keys(this.viewContainer.description.buttons);
    let enableCount: number = 0;

    this.colSize = 3;

    let buttons: IButtonOptions[];
    buttons = [];
    let btnEmpty: IButtonOptions = this.viewContainer.description.buttons.none;

    for (let i = 0; i < keys.length; i++) {
      let key: string = keys[i];
      let btn: IButtonOptions = this.viewContainer.description.buttons[key];
      if (btn.enabled) {
        enableCount += 1;
        btn.size = this.colSize;
        buttons.push(btn);
      }
    }

    // if (!enableCount) {
    //     enableCount = 1;
    // }

    // let colSize: number = 12;
    // colSize = Math.floor(colSize / enableCount);
    // this.colSize = colSize;

    // other patterns are based on number of buttons
    // 1,1,1,1
    // 1,2,1
    // 4
    // 1,0,0,1

    // check known patterns
    let pk: string[] = Object.keys(this.patterns);
    for (let i = 0; i < pk.length; i++) {
      let p: IButtonPattern = this.patterns[pk[i]];
      let btnCodes: number[] = buttons.map(b => b.code);
      let btnPattern: number[] = p.pattern;
      // console.log("check pattern: ", btnPattern);
      let match: boolean = GeneralUtils.arrayEquals(btnCodes, btnPattern);
      if (match) {
        // console.log("matched pattern: ", btnPattern);
        // sort to match the predefined pattern
        buttons = buttons.sort((a, b) => {
          return btnPattern.indexOf(a.code) - btnPattern.indexOf(b.code);
        });
        // console.log("sorted by pattern: ", buttons.map(b => b.code));
        break;
      }
    }

    switch (enableCount) {
      case 0:
        break;
      case 1:
        buttons[0].size = 4 * this.colSize;
        this.buttons.push(buttons[0]);
        break;
      case 2:
        this.buttons.push(buttons[0]);
        btnEmpty.size = 2 * this.colSize;
        this.buttons.push(btnEmpty);
        this.buttons.push(buttons[1]);
        break;
      case 3:
        this.buttons.push(buttons[0]);
        buttons[1].size = 2 * this.colSize;
        this.buttons.push(buttons[1]);
        this.buttons.push(buttons[2]);
        break;
      case 4:
        this.buttons = buttons;
        break;
      default:
        // keep only the first 4 buttons (there might be some error behind this case)
        for (let i = 0; i < 4; i++) {
          buttons[i].size = this.colSize;
          this.buttons.push(buttons[i]);
        }
        break;
    }

    this.noButtons = this.buttons.length === 0;
    console.log(this.buttons);
  }

  goToReference() {
    if (this.item.wikiUrl) {
      Util.openURLAdaptive(this.item.wikiUrl);
    }
  }

  setStatusDescription() {
    this.statusDescription = "";

    if (this.item.achievement === 1) {
      if (this.item.activeCount > 0 || this.item.validCount > 0) {
        this.statusDescription += "<p>UNLOCKED</p>";
      } else {
        this.statusDescription += "<p>LOCKED</p>";
      }

      if (this.item.progress && (this.item.progress.required > 1)) {
        this.statusDescription += "<p>Progress: " + this.item.progress.current + " out of " + this.item.progress.required + "</p>";
      }
    } else {
      if (this.item.activeCount > 0) {
        this.statusDescription += "<p>Enabled: " + this.item.activeCount + "</p>";
        if (this.item.timeLeftString) {
          this.statusDescription += "<p>Expires in: " + this.item.timeLeftString + "</p>";
        }
      }

      if (this.item.validCount > 0) {
        this.statusDescription += "<p>Inventory: " + this.item.validCount + "</p>";
      }

      if (this.item.pendingCount > 0) {
        this.statusDescription += "<p>Pending: " + this.item.pendingCount + "</p>";
      }
    }

    if (this.isSelectorMode) {
      this.statusDescription = "";
    }
  }


  checkLocation() {
    if (this.item.locationItems) {
      this.locationItems = this.item.locationItems;
      this.viewContainer.description.buttons.providers.enabled = true;
    }
  }


  getPlaceDetails(index) {
    console.log(index);
  }


  ngOnDestroy() {
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
  }
}
