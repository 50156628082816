<div class="img-container">
  <ion-button *ngIf="interact" class="as-text element-selector info-text-s text-color-primary upload-btn"
    (click)="downloadAsImage()">
    <icon-wrapper slot="icon-only" [icon]="appIconsStandard.pickPhoto" [custom]="false"></icon-wrapper>
  </ion-button>

  <ion-button *ngIf="interact" class="as-text element-selector info-text-s text-color-primary zoom-btn"
    (click)="copyToClipboard()">
    <icon-wrapper slot="icon-only" [icon]="appIconsStandard.clipboard" [custom]="false"></icon-wrapper>
  </ion-button>

  <ngx-qrcode *ngIf="show" [id]="customId" elementType="canvas" [value]="code" [version]="version" cssClass="qr-code"
    errorCorrectionLevel="L">
  </ngx-qrcode>
</div>