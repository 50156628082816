

import { LocationUtilsGooglePlus } from './location-utils-google-plus';
import { Injectable } from '@angular/core';
import { ILocationPhotoResult } from './location-utils-def';
import { EPlaceUnifiedSource } from '../../classes/def/places/provider';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';

@Injectable({
    providedIn: 'root'
})
export class LocationUtilsPlus {
    constructor(
        public locationUtilsGooglePlus: LocationUtilsGooglePlus
    ) {
    }



    /**
     * can't download directly from google link? no problem
     * cache the image and return the local path
     * fallback to original url if request failed
     * @param url 
     */
    extractToCacheReturnLocalPathResolve(url: string): Promise<string> {
        let promise: Promise<string> = new Promise((resolve) => {
            if (!url) {
                resolve(null);
                return;
            }
            resolve(url);
        });
        return promise;
    }

    /**
     * resolve only
     * @param providerCode 
     * @param photoUrl 
     * @param photoUrlSmall 
     */
    getRedirectPhotoUrl(providerCode: number, photoUrl: string, placeId: string): Promise<ILocationPhotoResult> {
        let promise: Promise<ILocationPhotoResult> = new Promise((resolve) => {
            if (!photoUrl) {
                resolve(null);
                return;
            }

            if (!providerCode) {
                providerCode = EPlaceUnifiedSource.google;
            }
            console.log("get redirect photo url: ", providerCode);

            switch (providerCode) {
                case EPlaceUnifiedSource.google:
                    this.locationUtilsGooglePlus.getGoogleRedirectPhotoUrl(placeId, photoUrl).then((res: ILocationPhotoResult) => {
                        resolve(res);
                    });
                    break;
                case EPlaceUnifiedSource.here:
                    resolve(null);
                    break;
                default:
                    resolve(null);
                    break;
            }
        });
        return promise;
    }
}
