import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { CanvasLoaderService } from 'src/app/services/utils/canvas-loader';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IPlacesPopup } from 'src/app/classes/def/nav-params/place-popup';
import { ETutorialEntries } from 'src/app/classes/def/app/messages';

@Component({
  selector: 'modal-places-popup',
  templateUrl: './places-popup.component.html',
  styleUrls: ['./places-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlacesPopupViewComponent implements OnInit, OnDestroy {
  hasParams: boolean = false;
  title: string = "Places";
  places: ILeplaceReg[] = [];
  theme: string = "theme-aubergine theme-aubergine-bg";
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    public canvasLoader: CanvasLoaderService,
    public tutorials: TutorialsService
  ) {

  }

  ngOnInit() {
    this.hasParams = ParamHandler.checkParams(this.np);

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    if (!this.hasParams) {

    } else {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IPlacesPopup = np.params;
      console.log(params);
      this.title = params.title;
      this.places = params.places;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
    }
  }

  ngOnDestroy() {

  }

  selectPlace(place: ILeplaceReg) {
    this.dismiss(place);
  }


  getDetails() {

  }

  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  showDescription() {
    this.tutorials.showTutorialNoAction("Place Scan Tutorial", ETutorialEntries.placeAdsTutorial, null, null, false);
  }

  nop() {

  }

}
