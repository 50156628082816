import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class SplitPaneService {

    splitPaneState: boolean;

    constructor(public platform: Platform) {
        console.log("split pane service created");
        this.splitPaneState = false;
    }

    setSplitPane(state: boolean) {
        if (this.platform.width() > 768) {
            this.splitPaneState = state;
        } else {
            this.splitPaneState = false;
        }
    }

    getSplitPane() {
        return this.splitPaneState;
    }

}
