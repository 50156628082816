import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IAutocompletePlace } from 'src/app/classes/def/editor/place-finder';

@Component({
  selector: 'autocomplete-place',
  templateUrl: './autocomplete-place.component.html',
  styleUrls: ['./autocomplete-place.component.scss'],
})
export class AutocompletePlaceComponent implements OnInit, OnChanges {
  @Input()
  item: IAutocompletePlace;

  @Input()
  update: boolean;

  leplaceId: string = "";
  name: string = "";
  address: string = "";
  tags: string = "";
  type: string = "";
  icon: string = EAppIconsStandard.place;

  appIconsStandard = EAppIconsStandard;
  show: boolean = true;

  constructor() { }

  ngOnInit() {
    this.initFn();
  }

  initFn() {
    if (this.item != null) {
      this.name = this.item.name;
      this.address = this.item.address;
      this.tags = this.item.tagsString;
      if (this.item.customLocation) {
        this.icon = EAppIconsStandard.locate;
        this.name = "~" + this.item.name;
        this.address = "" + this.item.customLat + ", " + this.item.customLng;
      } else {
        this.icon = EAppIconsStandard.place;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'update':
            this.initFn();
            this.show = false;
            setTimeout(() => {
              this.show = true;
            }, 1);
            break;
        }
      }
    }
  }

}
