import { Injectable } from "@angular/core";
import { ErrorMessage } from "../../../classes/general/error-message";
import { AppSettings } from "../../utils/app-settings";
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { AppConstants } from 'src/app/classes/app/constants';
import { AnalyticsFirebaseXService } from './anlytics-firebase-x';
import { AnalyticsTemplateService } from './analytics-template';
import { AnalyticsLeplaceService } from "./analytics-leplace";

enum EAnalyticsProvider {
    firebase = 1,
    firebaseX = 2,
    firebaseDisp = 3
}

// event tracking on firebase analytics
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    enabled: boolean = false;

    initialized: boolean = false;
    mode: number = EAnalyticsProvider.firebaseDisp;
    analyticsService: AnalyticsTemplateService = null;

    constructor(
        public firebaseX: AnalyticsFirebaseXService,
        public leplace: AnalyticsLeplaceService
    ) {
        console.log("analytics service created");
        console.log("analytics: ", this.enabled);
    }

    init() {
        this.mode = EAnalyticsProvider.firebaseX;

        // service factory
        switch (this.mode) {
            case EAnalyticsProvider.firebaseX:
                this.analyticsService = this.firebaseX;
                break;
            default:
                break;
        }

        this.initialized = true;
        return this.analyticsService.init();
    }

    /**
     * set user id from the db
     * @param userId 
     */
    setUserId(userId: number) {
        if (!this.enabled) {
            return;
        }
        this.analyticsService.setUserId(userId);
    }

    /**
     * track view (changes)
     * @param title 
     */
    trackView(title: string) {
        if (!this.enabled) {
            return;
        }
        console.log("track view: " + title);
        this.analyticsService.trackView(title);
    }


    /**
     * this method can save error messages into an array 
     * to show them in debug mode in the interface
     */
    dispatchError(err: any, type: string = "") {
        if (!this.enabled) {
            return;
        }

        // errors are sent regardless of the user preference
        this.sendEvent(ETrackedEvents.error, ErrorMessage.parse(err), type, 0, true);
    }


    setEnabled(val: boolean) {
        console.log("set analytics: ", val);
        this.enabled = val;
    }

    /**
    * send event to analytics service
    * custom event category (may be disabled via app flag)
    * @param category 
    * @param action 
    * @param label 
    * @param value 
    */
    sendCustomEvent(category: string, action: string, label: string, value: number, customOnly: boolean): Promise<boolean> {
        if (!this.enabled) {
            return;
        }
        if (!customOnly) {
            return this.sendEvent(category, action, label, value, false);
        }
        if (AppConstants.gameConfig.customEventTracking === 1) {
            return this.sendEvent(category, action, label, value, false);
        } else {
            console.warn("custom event tracking is disabled");
            return Promise.resolve(false);
        }
    }

    /**
     * send event to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     * @param force 
     */
    sendEvent(category: string, action: string, label: string, value: number, force: boolean = false): Promise<boolean> {
        if (!this.enabled) {
            return;
        }
        let promise: Promise<boolean> = null;
        if (this.initialized && AppSettings.globalEnableAnalytics) {
            console.log("send event");
            if (this.enabled || force) {
                promise = this.analyticsService.sendEvent(category, action, label, value);
            } else {
                promise = Promise.resolve(false);
            }
        } else {
            promise = Promise.resolve(false);
        }

        // additional event processing
        if (AppConstants.gameConfig.customEventLogger) {
            // custom logger
            this.leplace.sendEvent(category, action, label, value).then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        }

        return promise;
    }
}
