import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { Platform } from '@ionic/angular';
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from '../../classes/def/app/platform';
declare var window;

/**
 * handles the file related operations
 * save to file
 * read from file
 * file system path management
 */
@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    serverUrl: string;
    basePath: string = "";
    savePath: string = "";
    prevInputElement: HTMLInputElement;
    prevOutputElement: HTMLAnchorElement;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public plt: Platform,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("file service created");
        this.serverUrl = ApiDef.mainServerURL;

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    downloadAsFileViaBrowser(filename: string, type: string, textInput: string) {
        if (!type) {
            type = 'data:text/plain;charset=utf-8,';
        }
        this.downloadAsFileViaBrowserCore(filename, type + encodeURIComponent(textInput));
    }

    downloadAsFileViaBrowserCore(filename: string, encodedInput: string) {
        let element: HTMLAnchorElement = document.createElement('a');
        element.setAttribute('href', encodedInput);
        element.setAttribute('download', filename);
        if (this.prevOutputElement) {
            document.body.removeChild(this.prevOutputElement);
            this.prevOutputElement = null;
        }
        document.body.appendChild(element);
        this.prevOutputElement = element;
        element.click();
        // let cleanup = () => {
        //     this.prevOutputElement = null;
        //     document.body.removeChild(element);
        // };
        // cleanup();
    }

    openURLAsAnchor(url: string) {
        let element: HTMLAnchorElement = document.createElement('a');
        element.setAttribute('href', url);
        if (this.prevOutputElement) {
            document.body.removeChild(this.prevOutputElement);
            this.prevOutputElement = null;
        }
        document.body.appendChild(element);
        this.prevOutputElement = element;
        element.click();
    }

    downloadAsFileViaBrowserLocal(filename: string, url: string) {
        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';
        req.onload = function () {
            const blob = new Blob([req.response], {
                type: 'application/pdf',
            });
            const windowUrl = window.URL || window.webkitURL;
            const href = windowUrl.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('download', filename);
            a.setAttribute('href', href);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
        req.send();
    }

    uploadFileViaBrowser(accept: string, returnDataUrl: boolean, readCallback: (file: Blob) => any) {
        return new Promise<string>((resolve, reject) => {
            let input: HTMLInputElement = document.createElement('input');
            input.id = "uploadFileElementTempInput";
            input.type = "file";
            input.hidden = true;
            input.accept = accept ? accept : ".png, .jpg";
            // <input *ngIf="withUpload" id="fileSelect" type='file' (change)="onSelectFile($event)" style="display: none;">

            if (this.prevInputElement) {
                document.body.removeChild(this.prevInputElement);
                this.prevInputElement = null;
            }

            document.body.appendChild(input);
            this.prevInputElement = input;

            let cleanup = () => {
                this.prevInputElement = null;
                document.body.removeChild(input);
            };

            input.onchange = (event: any) => { // called each time file input changes
                if (event.target.files && event.target.files[0]) {
                    if (returnDataUrl) {
                        var reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]); // read file as data url
                        reader.onload = (event: any) => { // called once readAsDataURL is completed
                            let url = event.target.result;
                            cleanup();
                            resolve(url as string);
                        };
                        reader.onerror = (err) => {
                            console.error(err);
                            cleanup();
                            reject(err);
                        };
                    } else {
                        if (readCallback) {
                            readCallback(event.target.files[0]);
                        }
                        cleanup();
                        resolve(null);
                    }
                } else {
                    cleanup();
                    resolve(null);
                }
            };

            input.onerror = (err) => {
                console.error(err);
                cleanup();
                reject(err);
            };

            // let state: number = 0;
            // input.onfocus = () => {
            //     console.log("onfocus");
            //     state = 1;
            // };
            // input.onclick = () => {
            //     console.log("onclick");
            //     state = 0;
            // };
            // input.onblur = (event: any) => {
            //     console.log("onblur");
            //     console.log("state: ", state)
            //     if (state === 1 && !(event.target.files && event.target.files[0])) {
            //         // blur after focus and no file
            //         console.log("cancel button detected");
            //     }
            // };
            // input.oncancel = () => {
            //     console.log("oncancel");
            // };

            input.click();
        });
    }

    readAsTextFile(file: Blob) {
        return new Promise<string>((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsText(file); // read file as data url
            reader.onload = (event: any) => { // called once readAsDataURL is completed
                let data = event.target.result;
                resolve(data as string);
            };
            reader.onerror = (err) => {
                console.error(err);
                reject(err);
            };
        });
    }
}




