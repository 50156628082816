

import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';


@Component({
  selector: 'blink-button',
  templateUrl: './blink-button.component.html',
  styleUrls: ['./blink-button.component.scss'],
})
export class BlinkButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  name: string;
  @Input()
  icon: string;
  @Input()
  disabled: boolean;
  /**
   * blink (toggle on state change)
   */
  @Input()
  toggle: boolean;
  /**
   * blink (absolute)
   */
  @Input()
  set: boolean;

  @Input()
  huge: boolean;

  /**
   * blink (toggle on state change) for a finite amount of time
   */
  @Input()
  toggleTimeout: boolean;

  /**
   * blink (absolute) for a finite amount of time
   */
  @Input()
  setTimeout: boolean;

  /**
   * blink (absolute) for a finite amount of time, on state transition
   */
  @Input()
  setTimeoutOnChange: boolean;

  @Input()
  fab: boolean;

  @Input()
  defaultClass: string;

  @Input()
  highClass: string;

  @Input()
  noBorder: boolean;

  @Input()
  xs: boolean;

  @Input()
  customIcon: boolean;

  @Input()
  customBaseClass: string;

  @Input()
  duration: number;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  blinkEnable: boolean = false;
  state: boolean = false;
  timerSub = null;
  blinkTimeout;

  btnBaseClass: string = "action-button-fill";
  fabBtnBaseClass: string = "round-fab round-fab-size";

  btnDefaultClass: string;
  btnHighClass: string;
  fabBtnDefaultClass: string;
  fabBtnHighClass: string;

  iconOnly: boolean = false;

  constructor() {
    this.initDefaults();
  }

  initDefaults() {
    this.btnDefaultClass = this.btnBaseClass + " button-color-alternate";
    this.btnHighClass = this.btnBaseClass + " button-color-accent";
    this.fabBtnDefaultClass = this.fabBtnBaseClass + " button-color-primary-70";
    this.fabBtnHighClass = this.fabBtnBaseClass + " button-color-alternate";
    // this.fabBtnDefaultClass = this.fabBtnBaseClass + " button-color-alternate";
    // this.fabBtnHighClass = this.fabBtnBaseClass + " button-color-accent";
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'name':
            this.checkName();
            break;
          case 'toggle':
            this.blinkEnable = !this.blinkEnable;
            this.setBlink(false);
            break;
          case 'toggleTimeout':
            // only start blinking
            // the timeout will take care of the rest
            if (!this.blinkEnable) {
              this.blinkEnable = true;
              this.setBlink(true);
            }
            break;
          case 'set':
            this.blinkEnable = chg.currentValue;
            this.setBlink(false);
            break;
          case 'setTimeout':
            this.blinkEnable = chg.currentValue;
            if (this.blinkEnable) {
              // start blinking and timeout
              this.setBlink(true);
            } else {
              // stop blinking on demand
              this.disableBlink();
            }
            break;
          case 'setTimeoutOnChange':
            this.blinkEnable = true;
            this.disableBlink();
            this.setBlink(true);
            break;
        }
      }
      // const chg: SimpleChange = changes[keys[i]];
      // console.log('prev value: ', chg.previousValue);
      // console.log('got name: ', chg.currentValue);
    }
  }

  setBlink(timeout: boolean) {
    if (this.blinkEnable) {
      let duration: number = 5000;

      if (this.duration) {
        duration = this.duration;
      }
      let count: number = Math.floor(duration / 1000);

      this.enableBlink(timeout ? count : 0);
      if (timeout) {
        this.blinkTimeout = setTimeout(() => {
          this.blinkEnable = false;
          this.disableBlink();
        }, duration + 500);
      }
    } else {
      this.disableBlink();
    }
  }

  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
    this.state = false;
  }


  clicked(index: number) {
    this.select.emit([index]);
  }


  checkName() {
    if (!this.name) {
      this.iconOnly = true;
    } else {
      this.iconOnly = false;
    }
  }

  ngOnInit() {
    this.checkName();

    if (this.xs) {
      this.btnBaseClass = "action-button-xs";
      this.fab = false;
      this.iconOnly = true;
    }

    if (this.huge) {
      this.fabBtnBaseClass = "round-fab round-fab-size-huge";
      this.initDefaults();
    }

    if (this.customBaseClass) {
      this.btnBaseClass = this.customBaseClass;
      this.fabBtnBaseClass = this.customBaseClass;
      this.initDefaults();
    }

    if (this.defaultClass) {
      this.btnDefaultClass = this.btnBaseClass + " " + this.defaultClass;
      this.fabBtnHighClass = this.fabBtnBaseClass + " " + this.defaultClass;
    }
    if (this.highClass) {
      this.btnHighClass = this.btnBaseClass + " " + this.highClass;
      this.fabBtnHighClass = this.fabBtnBaseClass + " " + this.highClass;
    }

    if (this.noBorder) {
      this.btnDefaultClass += " no-border";
      this.fabBtnDefaultClass += " no-border";
      this.btnHighClass += " no-border";
      this.fabBtnHighClass += " no-border";
    }
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
  }
}
