import { Injectable } from '@angular/core';
import { StorageOpsService } from './data/storage-ops';
import { SettingsManagerService } from './settings-manager';
import { AppSettings } from '../utils/app-settings';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';

@Injectable({
  providedIn: 'root'
})
export class TestingManagerService {
  constructor(
    public settingsManager: SettingsManagerService,
    public storageOps: StorageOpsService
  ) {
    console.log("tester manager service created");
  }

  /**
   * update tester mode and all associated services
   * this is to be called after all resources have been loaded (e.g. app settings)
   * @param tester 
   */
  updateTesterMode(tester: boolean) {
    AppSettings.testerMode = tester;
    console.log("set tester: ", AppSettings.testerMode);
    // set local storage as NOT tester (actually confirmed NOT tester!!)
    this.storageOps.setStorageFlagNoAction({
      flag: ELocalAppDataKeys.isTester,
      value: AppSettings.testerMode
    });
    this.settingsManager.updateTesterMode(tester);
    // this.ads.initializeAdService(tester);
  }
}
