

import { Injectable } from '@angular/core';
import { StoryDataService } from 'src/app/services/data/story';
import { PlacesDataService } from 'src/app/services/data/places';
import { IStoryResponse } from 'src/app/classes/def/core/story';
import { ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { IEditorSettings, IEditorStory } from 'src/app/classes/def/core/editor';
import { IBusinessDataExt, BusinessUtils } from 'src/app/classes/def/business/business';
import { IFixedCoin } from 'src/app/classes/def/core/activity';
import { DeepCopy } from 'src/app/classes/general/deep-copy';

@Injectable({
    providedIn: 'root'
})
export class StoriesLoaderService {
    constructor(
        public storyDataProvider: StoryDataService,
        public placesData: PlacesDataService
    ) {
        console.log("stories loader service created");
    }

    /**
     * load story/draft places in bplace format
     * @param id 
     * @param isDraft 
     */
    loadStoryPlacesCore(id: number, _isDraft: boolean, _preloadedStoryDraft: IEditorStory, _editorSettings: IEditorSettings): Promise<IBusinessDataExt[]> {
        return new Promise(async (resolve, reject) => {
            try {
                let storyData: IStoryResponse;
                let blocs: ILocationContainer[] = [];

                let formatFn = (fixedCoins: IFixedCoin[], locationIndex: number) => {
                    if (fixedCoins != null) {
                        // index in story for coin assignment
                        for (let fc of fixedCoins) {
                            fc.locationIndex = locationIndex;
                        }
                    }
                };


                storyData = await this.storyDataProvider.getStoryFromServer(id, null, null);
                blocs = LocationUtils.formatLocMulti(storyData.story.locations, null);
                console.log("merged blocs: ", blocs);
                console.log("merged blocs copy: ", DeepCopy.deepcopy(blocs));

                let bplaces: IBusinessDataExt[] = [];

                if (blocs && (blocs.length > 0)) {
                    bplaces = blocs.map(loc => {
                        try {
                            let bde: IBusinessDataExt = BusinessUtils.blocContainerAdapter(loc);
                            bde.locked = true;
                            return bde;
                        } catch (err) {
                            console.error(err);
                            return null;
                        }
                    }).filter(bloc => bloc != null);
                }

                resolve(bplaces);
            } catch (err) {
                reject(err);
            }
        });
    }
}

