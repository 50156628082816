import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IFaqCategory } from 'src/app/classes/def/support/support';
import { ParamHandler } from 'src/app/classes/general/params';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';

export interface IFAQListParams {
  title: string;
  faqCategories: IFaqCategory[];
}

@Component({
  selector: 'faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FAQListComponent implements OnInit {

  title: string = "";

  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";

  loading: boolean = false;

  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IFAQListParams = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  faqCategories: IFaqCategory[] = null;

  constructor(
    public modalCtrl: ModalController,
    public analytics: AnalyticsService,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IFAQListParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.title = params.title;
      this.faqCategories = params.faqCategories;
    }

    this.getClass();
  }

  getClass() {
    this.wrapperClass = "";
    this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border-flush";
    this.buttonBar = "theme-border-top";
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  cancel() {
    this.dismiss(null);
  }

}
