<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title" (rightClick)="dismiss()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">

  <div class="scrollbar-y theme-border-bottom padding-s" [ngClass]="{'scroll-content-modal-1' : withButtons}">
    <responsive-img [photoUrl]="photoUrl" [showLoading]="true"></responsive-img>
  </div>

  <div fxFlex>
  </div>

  <div class="stick-bottom">
    <ion-grid class="full-w">
      <ion-row *ngIf="enable.upload">
        <ion-col size="12">
          <ion-button class="action-button-fill button-color-primary" (click)="uploadPhoto()">
            <span class="button-font-size-s">Upload</span>
            <icon-wrapper [icon]="appIconsStandard.image" [custom]="false"></icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</modal-container>