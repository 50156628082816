import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDynamicListSelection } from 'src/app/classes/def/dynamic/list';


@Component({
  selector: 'dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss'],
})
export class DynamicListComponent implements OnInit {
  @Input()
  type: string;
  @Input()
  data: any[];
  @Input()
  emptyMessage: string;
  @Input()
  update: boolean;
  @Input()
  customSelect: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  loaded: boolean = false;

  availableItems: boolean = false;

  constructor(

  ) {
  }

  itemAction(index: number, item: any) {
    let d: IDynamicListSelection = {
      index: index,
      item: item
    };
    this.action.emit(d);
  }

  ngOnInit() {
    if (this.data) {
      setTimeout(() => {
        let items: any[] = this.data;
        this.availableItems = items && (items.length > 0);
        this.loaded = true;
      }, 1);
    }
  }
}
