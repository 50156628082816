

export class TimeUtils {

    /**
     * get seconds and returns a standard representation in string format
     * @param seconds 
     * @param showSeconds 
     */
    static formatTimeFromSeconds(seconds: number, showSeconds: boolean): string {
        if ((seconds == null) && (seconds !== 0)) {
            return null;
        }

        const sec: number = Math.floor(seconds);
        const h = Math.floor(sec / 3600);
        const m = Math.floor((sec % 3600) / 60);
        const s = sec % 60;

        // let hours: number = Math.floor(secondsInput / 3600);
        // let minutes: number = Math.floor((secondsInput - (hours * 3600)) / 60);
        // let seconds: number = secondsInput - (hours * 3600) - (minutes * 60);

        let ts: string = "";

        if (h < 10) {
            ts += "0";
        }
        ts += h + ":";
        if (m < 10) {
            ts += "0";
        }
        ts += m;

        if (showSeconds) {
            ts += ":";
            if (s < 10) {
                ts += "0";
            }
            ts += s;
        }

        return ts;
    }

    /**
     * get seconds and returns a standard representation in string format
     * @param seconds 
     * @param showSeconds 
     */
    static formatTimeSpeechFromSeconds(seconds: number, adaptivePrecision: boolean): string[] {
        if ((seconds == null) && (seconds !== 0)) {
            return null;
        }

        const sec: number = Math.floor(seconds);
        const h = Math.floor(sec / 3600);
        const m = Math.floor((sec % 3600) / 60);
        const s = sec % 60;

        // let hours: number = Math.floor(secondsInput / 3600);
        // let minutes: number = Math.floor((secondsInput - (hours * 3600)) / 60);
        // let seconds: number = secondsInput - (hours * 3600) - (minutes * 60);

        let ts: string = "";
        let article: string = ""; // there is/are

        if (!adaptivePrecision) {
            if (h > 0) {
                ts += h + ((h > 1) ? " hours" : " hour");
                article = (h > 1) ? "are" : "is";
                if (m > 0) {
                    ts += ", " + m + ((m > 1) ? " minutes" : " minute");
                }
                if (s > 0) {
                    ts += " and " + s + ((s > 1) ? " seconds" : " second");
                }
            } else {
                if (m > 0) {
                    ts += m + ((m > 1) ? " minutes" : " minute");
                    article = (m > 1) ? "are" : "is";
                    if (s > 0) {
                        ts += " and " + s + ((s > 1) ? " seconds" : " second");
                    }
                } else {
                    ts += s + ((s > 1) ? " seconds" : " second");
                    article = (s > 1) ? "are" : "is";
                }
            }
        } else {
            if (h > 0) {
                ts += h + ((h > 1) ? " hours" : " hour");
                article = (h > 1) ? "are" : "is";
                if (m > 0) {
                    ts += " and " + m + ((m > 1) ? " minutes" : " minute");
                }
            } else {
                if (m > 0) {
                    ts += m + ((m > 1) ? " minutes" : " minute");
                    article = (m > 1) ? "are" : "is";
                } else {
                    ts += s + ((s > 1) ? " seconds" : " second");
                    article = (s > 1) ? "are" : "is";
                }
            }
        }

        return [ts, article];
    }


    static isNight() {
        // Returns true if the time is between
        // 7pm to 5am
        let time = new Date().getHours();
        return (time > 5 && time < 19) ? false : true;
    }


    /**
     * get seconds and return a message context representation in string format
     * @param secondsInput 
     * @param attribute 
     */
    static formatTimeFromSeconds2(secondsInput: number, attribute: boolean): string {
        let valString: string = "";
        if ((secondsInput == null) && (secondsInput !== 0)) {
            return null;
        }

        let hours: number = Math.floor(secondsInput / 3600);
        let minutes: number = Math.floor((secondsInput - (hours * 3600)) / 60);
        // let seconds: number = secondsInput - (hours * 3600) - (minutes * 60);

        if (hours > 0) {
            valString += hours + "h";
            if (minutes > 0) {
                valString += minutes + "'";
            }
        } else {
            if (minutes > 0) {
                if (attribute) {
                    valString += minutes + "-Minute";
                } else {
                    valString += minutes + " minutes";
                }
            }
        }
        return valString;
    }


    /**
     * format date as [numeric day] [string month] [full year]
     * @param dateString 
     */
    static formatDateDisp(dateString: string | number): string {
        let date = new Date(dateString);
        let yourDateformat = date.getUTCDate() + " " + TimeUtils.getShortMonthName(date.getUTCMonth()) + " " + date.getUTCFullYear();
        return yourDateformat;
    }

    /**
     * format date as [string day], [string month] [numeric day]
     * @param dateString 
     */
    static formatDateDispV2(dateString: string | number): string {
        if (dateString == null) {
            return "";
        }

        let date = new Date(dateString);
        let yourDateformat = TimeUtils.getShortDayNames(date.getUTCDay()) + ", " + TimeUtils.getShortMonthName(date.getUTCMonth()) + " " + date.getUTCDate();
        return yourDateformat;
    }

    /**
   * format date time as [numeric day] [string month] [full year] [hh:mm:ss]
   * @param dateString 
   * @param contextDate 
   */
    static formatDateTimeDisp(dateString: string | number, contextDate: boolean, withSeconds: boolean): string {
        if (dateString == null) {
            return "";
        }

        let date: Date = new Date(dateString);
        let dateNow: Date = new Date();

        let yourDateformat: string = "";

        if (contextDate) {
            if ((dateNow.getTime() - date.getTime()) > (24 * 3600 * 1000)) {
                yourDateformat += TimeUtils.formatDateDisp(dateString) + " ";
            }
        } else {
            yourDateformat += TimeUtils.formatDateDisp(dateString) + " ";
        }

        yourDateformat += TimeUtils.formatTimeDispExtract(dateString, withSeconds);

        return yourDateformat;
    }

    static formatTimeDispExtract(dateString: string | number, withSeconds: boolean): string {
        let date = new Date(dateString);
        let seconds: number = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
        return TimeUtils.formatTimeFromSeconds(seconds, withSeconds);
    }

    static getShortMonthName(month: number): string {
        let monthNames: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month];
    }

    static getShortDayNames(day: number): string {
        let dayNames: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return dayNames[day];
    }





    /**
        Format Dates
     
        moment().format('MMMM Do YYYY, h:mm:ss a'); // May 13th 2019, 9:37:28 pm
        moment().format('dddd');                    // Monday
        moment().format("MMM Do YY");               // May 13th 19
        moment().format('YYYY [escaped] YYYY');     // 2019 escaped 2019
        moment().format();                          // 2019-05-13T21:37:28+03:00

        Relative Time

        moment("20111031", "YYYYMMDD").fromNow(); // 8 years ago
        moment("20120620", "YYYYMMDD").fromNow(); // 7 years ago
        moment().startOf('day').fromNow();        // a day ago
        moment().endOf('day').fromNow();          // in 2 hours
        moment().startOf('hour').fromNow();       // 38 minutes ago

        Calendar Time

        moment().subtract(10, 'days').calendar(); // 05/03/2019
        moment().subtract(6, 'days').calendar();  // Last Tuesday at 9:38 PM
        moment().subtract(3, 'days').calendar();  // Last Friday at 9:38 PM
        moment().subtract(1, 'days').calendar();  // Yesterday at 9:38 PM
        moment().calendar();                      // Today at 9:38 PM
        moment().add(1, 'days').calendar();       // Tomorrow at 9:38 PM
        moment().add(3, 'days').calendar();       // Thursday at 9:38 PM
        moment().add(10, 'days').calendar();      // 05/23/2019

        Multiple Locale Support
        moment.locale();         // en
        moment().format('LT');   // 9:38 PM
        moment().format('LTS');  // 9:38:48 PM
        moment().format('L');    // 05/13/2019
        moment().format('l');    // 5/13/2019
        moment().format('LL');   // May 13, 2019
        moment().format('ll');   // May 13, 2019
        moment().format('LLL');  // May 13, 2019 9:38 PM
        moment().format('lll');  // May 13, 2019 9:38 PM
        moment().format('LLLL'); // Monday, May 13, 2019 9:38 PM
        moment().format('llll'); // Mon, May 13, 2019 9:38 PM
                        
     */
}




