import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TabsPage } from './tabs-page';

const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'home',
        children: [
          {
            path: '',
            loadChildren: () => import('../home/home.module').then(m => m.HomePageModule)
          }
        ]
      },
      {
        path: 'support',
        children: [
          {
            path: '',
            loadChildren: () => import('../support/support.module').then(m => m.SupportPageModule)
          }
        ]
      },
      {
        path: 'validate',
        children: [
          {
            path: '',
            loadChildren: () => import('../validate/validate.module').then(m => m.ValidatePageModule)
          }
        ]
      },
      {
        path: 'browse',
        children: [
          {
            path: '',
            loadChildren: () => import('../marketplace/browse/browse.module').then(m => m.MarketplaceBrowsePageModule)
          }
        ]
      },
      {
        path: 'map',
        children: [
          {
            path: '',
            loadChildren: () => import('../marketplace/map/map.module').then(m => m.MarketplaceMapPageModule)
          }
        ]
      },
      {
        path: 'content',
        children: [
          {
            path: '',
            loadChildren: () => import('../marketplace/content/content.module').then(m => m.MarketplaceContentPageModule)
          }
        ]
      },
      {
        path: '',
        redirectTo: '/tabs/home',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule { }

