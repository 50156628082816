import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { GenericDataService } from '../general/data/generic';
import { SettingsManagerService } from '../general/settings-manager';
import { StorageOpsService } from '../general/data/storage-ops';
import { ResourcesCoreDataService } from './resources-core';
import { UserDataService } from './user';
import { GameStatsService } from '../app/utils/game-stats';
import { PlacesDataService } from './places';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IAppResources, CAppResources, IAppResourcesResponse, IGenericResources, IAppResourcesGeneral, IAppResourcesUser } from '../../classes/def/app/resources';
import { EPlaceUnifiedSource } from '../../classes/def/places/provider';
import { IAnySettings, EServerInitStatus, IUserFlagDB, ESettingsType } from '../../classes/def/app/settings';
import { AppConstants } from '../../classes/app/constants';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ResourceMonitorDataService } from './resource-monitor';

/**
 * general app resource provider
 */
@Injectable({
    providedIn: 'root'
})
export class ResourceHandlerDataService {
    serverUrl: string;

    loaded: boolean = false;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
        public storageOps: StorageOpsService,
        public settingsManager: SettingsManagerService,
        public resourcesCore: ResourcesCoreDataService,
        public userDataProvider: UserDataService,
        public gameStatsProvider: GameStatsService,
        public placesProvider: PlacesDataService,
        public resourceMonitor: ResourceMonitorDataService
    ) {
        console.log("resource handler data service created");
        this.serverUrl = ApiDef.mainServerURL;
        this.initResourcesContainer();
    }


    initResourcesContainer() {
        GeneralCache.resourceCache = new CAppResources();
        let rc: IAppResources = GeneralCache.resourceCache;

        rc.general.placeProviders.content = [{
            id: null,
            code: EPlaceUnifiedSource.google,
            name: "google",
            enabled: 1,
            enabledDev: 1
        }, {
            id: null,
            code: EPlaceUnifiedSource.here,
            name: "here",
            enabled: 0,
            enabledDev: 1
        }];
    }

    /**
     * load all app resources
     * some of them (those that are general for app) may be cached
     * the user related resources are reloaded (e.g. profile picture)
     */
    loadResources() {

        // this.userDataProvider.getProfilePictureUrl().then((_url: string) => {
        //     // GeneralCache.photoUrl = url;
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.premiumProvider.getFeatureDef(false).then(() => {
        //     console.log("feature def list loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.resourcesCore.getStandardPhotos().then(() => {
        //     console.log("standard photos loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // })

        // this.resourcesCore.getStandardConfig().then(() => {
        //     console.log("standard config loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.resourcesCore.getLocationProviders().then(() => {
        //     console.log("location providers loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.gameStatsProvider.loadStatDef(true).then(() => {
        //     console.log("game stats loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.placesProvider.getPlaceTemplates().then(() => {
        //     console.log("place templates loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

        // this.resourcesCore.getMapStyleList().then(() => {
        //     console.log("map style list loaded");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });

    }

    getAllResources(setupServiceUrl: boolean): Promise<IAppResources> {
        let promise: Promise<IAppResources> = new Promise((resolve, reject) => {
            // reject(new Error("test reject"));
            // return;
            this.generic.genericPostNoAuth("/resources/load-resources", {
                categories: null,
                userId: GeneralCache.userId
            }).then((resp: IGenericResponse) => {
                let res: IAppResourcesResponse = resp.data;

                if (res != null) {
                    let keys: string[] = Object.keys(GeneralCache.resourceCache);
                    keys.forEach(key => {
                        if (res.resources[key]) {
                            this.updateResourceContainer(GeneralCache.resourceCache[key], res.resources[key]);
                        }
                    });

                    // reload game constants
                    if (GeneralCache.resourceCache.general.gameConfig.loaded) {
                        AppConstants.gameConfig = GeneralCache.resourceCache.general.gameConfig.content;
                    }
                }

                console.log("get all resources: ");
                console.log("game config: ");
                console.log(AppConstants.gameConfig);

                if (setupServiceUrl) {
                    this.setupServiceUrl().then(() => {
                        resolve(GeneralCache.resourceCache);
                    });
                } else {
                    this.loaded = true;
                    resolve(GeneralCache.resourceCache);
                }

            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    setupServiceUrl() {
        return new Promise<boolean>((resolve) => {
            this.resourcesCore.setupServiceUrl().then(() => {
                this.resourceMonitor.setLoaded(true);
                resolve(true);
            });
        })
    }

    clearAllResources() {
        console.log("clear all resources");
        let keys: string[] = Object.keys(GeneralCache.resourceCache);
        keys.forEach(key => {
            let keys1: string[] = Object.keys(GeneralCache.resourceCache[key]);
            let res: IGenericResources = GeneralCache.resourceCache[key];
            keys1.forEach(key1 => {
                res[key1].loaded = false;
            });
        });
    }


    /**
     * get all resources via a single request
     */
    getGeneralResources(): Promise<IAppResourcesGeneral> {
        let promise: Promise<IAppResourcesGeneral> = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/resources/load-resources", {
                categories: [1]
            }).then((resp: IGenericResponse) => {
                let res: IAppResourcesResponse = resp.data;

                if (res != null) {
                    this.updateResourceContainer(GeneralCache.resourceCache.general, res.resources.general);
                    // reload game constants
                    if (GeneralCache.resourceCache.general.gameConfig.loaded) {
                        AppConstants.gameConfig = GeneralCache.resourceCache.general.gameConfig.content;
                    }
                }

                resolve(GeneralCache.resourceCache.general);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
    * get all resources via a single request
    */
    getUserResources(): Promise<IAppResourcesUser> {
        let promise: Promise<IAppResourcesUser> = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/resources/load-resources", {
                categories: [2]
            }).then((resp: IGenericResponse) => {
                let res: IAppResourcesResponse = resp.data;
                if (res != null) {
                    this.updateResourceContainer(GeneralCache.resourceCache.user, res.resources.user);
                    this.syncAppSettings();
                }

                resolve(GeneralCache.resourceCache.user);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * update resources only when loaded flag is set
     * otherwise keep hardcoded resources
     * @param container 
     * @param server 
     */
    updateResourceContainer(container: IGenericResources, server: IGenericResources) {
        let keys: string[] = Object.keys(container);
        keys.forEach(key => {
            if (server[key] && server[key].loaded) {
                container[key] = server[key];
            }
        });
    }


    /**
     * sync local app settings that are found in db user settings
     */
    syncAppSettings() {
        if (!GeneralCache.resourceCache.user.userFlags.loaded) {
            console.warn("sync settings not loaded");
            return;
        }

        let syncSettings: IAnySettings[] = Object.keys(SettingsManagerService.settings.app.settings).map(key => SettingsManagerService.settings.app.settings[key])
            .filter((e: IAnySettings) => e.codeOptions.serverSync === EServerInitStatus.sync);
        console.log("sync settings: ", syncSettings);
        let syncFlags: IUserFlagDB[] = GeneralCache.resourceCache.user.userFlags.content;
        console.log("sync flags: ", syncFlags);

        for (let i = 0; i < syncSettings.length; i++) {
            let code1: number = syncSettings[i].codeOptions.code;
            for (let j = 0; j < syncFlags.length; j++) {
                let code2: number = syncFlags[j].code;
                if (code1 === code2) {
                    this.syncAppSettingsElement(syncSettings[i], syncFlags[j]);
                    break;
                }
            }
        }
    }

    syncAppSettingsElement(syncSetting: IAnySettings, syncFlag: IUserFlagDB) {
        switch (syncSetting.type) {
            case ESettingsType.checkbox:
                syncSetting.value = syncFlag.value ? true : false;
                break;
            case ESettingsType.select:
                syncSetting.value = syncFlag.value;
                break;
            default:
                syncSetting.value = syncFlag.value;
                break;
        }
    }
}
