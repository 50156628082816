import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// theming
import { IonicModule, IonicRouteStrategy, Config } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponentsModule } from './components/app/app-components.module';
import { AppModalsModule } from './modals/app/app-modals.module';
import { GenericModalsModule } from './modals/generic/generic-modals.module';
import { PipesModule } from './pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from './directives/directives.module';
import { HttpClientModule } from '@angular/common/http';
import { GoogleMaps, Spherical } from '@ionic-native/google-maps/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IsDebug } from '@ionic-native/is-debug/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@ionic-native/file/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GenericComponentsModule } from './components/generic/generic-components.module';
import { PagesLinksModule } from './pages/pages-links.module';

import { TabsModule } from './pages/tabs-page/tabs-page.module';

// import { SocialLoginModule } from '@abacritt/angularx-social-login';

import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { RatingConfig } from 'ngx-bootstrap/rating';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Keyboard } from '@ionic-native/keyboard/ngx';

import { FacebookModule } from 'ngx-facebook';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DirectivesModule,
    AppComponentsModule,
    GenericComponentsModule,
    AppModalsModule,
    GenericModalsModule,
    PipesModule,
    FlexLayoutModule,
    // SocialLoginModule,
    IonicModule.forRoot({
      // scrollPadding: false,
      // scrollAssist: true,
      mode: 'md',
      menuType: 'overlay'
      // popoverEnter: 'custom-popover-enter'      
    }),
    IonicStorageModule.forRoot({
      name: '__lpconnectdb',
      // driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    // SharedModule.forRoot(),
    FacebookModule.forRoot(),
    AppRoutingModule,
    PagesLinksModule,
    TabsModule,
    FontAwesomeModule,
    NgbModule,
    NgbCarouselModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // only include native plugins as the others are provided in root
    GoogleMaps,
    Geolocation,
    Spherical,
    File,
    IsDebug,
    GooglePlus,
    FirebaseX,
    HTTP,
    YoutubeVideoPlayer,
    RatingConfig,
    NgbCarouselConfig,
    Keyboard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(
    public config: Config,
    public injector: Injector
  ) {
    this.setCustomTransitions();
    AppModule.injector = injector;
  }
  private setCustomTransitions() {
    // this.config.setTransition('leplace-modal-transition-enter', ModalTranslateEnterTransition);
    // this.config.setTransition('leplace-modal-transition-leave', ModalTranslateLeaveTransition);
  }
}
