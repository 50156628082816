import { IStory, EStoryMode } from "../def/core/story";
import { EStartCodes } from "../def/nav-params/story";
import { EAppIcons } from '../def/app/icons';
import { ECategoryCodes } from '../def/core/category';
import { IEditorDraft, IEditorStoryTreasureItem, IEditorStoryLocationCustomParam, IEditorStory } from '../def/core/editor';
import { IGameItem, EItemCategoryCodes } from '../def/items/game-item';
import { ICustomParamForActivity, ECustomParamCategoryCode, ECustomParamCodes } from '../def/core/custom-param';
import { IActivityPhotoSpecs } from '../def/nav-params/activity-details';
import { IBackendLocation } from "../def/places/backend-location";


export interface IStoryProgress {
    storyLength: number,
    progressRaw: number,
    inProgress: boolean,
    finished: boolean,
    progress: number
};

export interface ICheckStoryProviders {
    hasProviders: boolean,
    providers: IBackendLocation[]
};

export class StoryUtils {
    /**
     * check if story is complete
     * @param story 
     */
    static checkFinishedStoryComplete(story: IStory) {
        let finished: boolean = true;
        for (let i = 0; i < story.locations.length; i++) {
            if (!story.locs[i].merged.done) {
                finished = false;
                break;
            }
        }
        return finished;
    }

    static checkResumeStory(story: IStory) {
        let startCode: number = EStartCodes.start;
        // for (let i = 0; i < story.locations.length; i++) {
        //     if (story.locations[i].googleId) {
        //         startCode = StartCodes.continue;
        //         break;
        //     }
        // }

        let finished: boolean = true;

        for (let i = 0; i < story.locations.length; i++) {
            if (story.locs[i].merged.done) {
                startCode = EStartCodes.continue;
                break;
            }
        }

        for (let i = 0; i < story.locations.length; i++) {
            if (!story.locs[i].merged.done) {
                finished = false;
                break;
            }
        }

        if (finished) {
            startCode = EStartCodes.restart;
        }

        return startCode;
    }

    static getStoryProgress(story: IStory): IStoryProgress {
        let res = {
            storyLength: 0,
            progressRaw: 0,
            inProgress: false,
            finished: false,
            progress: 0
        }

        if (!story.locations) {
            return res;
        }

        res.storyLength = story.locations.length;
        res.progressRaw = story.locs.filter(location => location.merged.done).length;
        res.inProgress = res.progressRaw > 0;
        res.finished = res.progressRaw === res.storyLength;
        let storyProgress: number = (res.progressRaw * 100) / res.storyLength;
        res.progress = storyProgress;
        return res;
    }

    static getModeIconCore(mode: number) {
        let modeIcon: string = null;
        switch (mode) {
            case EStoryMode.linear:
                modeIcon = EAppIcons.linear;
                break;
            case EStoryMode.preload:
            case EStoryMode.mp:
                modeIcon = EAppIcons.network;
                break;
        }
        return modeIcon;
    }

    static getModeIcon(story: IStory) {
        return StoryUtils.getModeIconCore(story.mode);
    }

    static getModeText(story: IStory) {
        let modeText: string = null;
        switch (story.mode) {
            case EStoryMode.linear:
                modeText = "linear"
                break;
            case EStoryMode.preload:
            case EStoryMode.mp:
                modeText = "nonlinear";
                break;
        }
        return modeText;
    }

    static checkProviders(story: IStory): ICheckStoryProviders {
        let hasProviders: boolean = false;
        let hasPrimaryProvider: boolean = false;
        let storyProviders: IBackendLocation[] = [];

        if (story.primaryProvider != null) {
            storyProviders = [story.primaryProvider];
            hasPrimaryProvider = true;
        }

        hasProviders = story.providers && story.providers.length > 0;
        if (hasProviders) {
            let primaryProviderId: number = null;
            if (hasPrimaryProvider) {
                primaryProviderId = story.providerId;
            }
            let providers: IBackendLocation[] = story.providers.filter(sp => sp.locationId !== primaryProviderId).map(sp => sp.location);
            storyProviders = storyProviders.concat(providers);
        }

        let res: ICheckStoryProviders = {
            hasProviders: hasPrimaryProvider || hasProviders,
            providers: storyProviders
        };

        return res;
    }

    /**
     * handle server optimizations (e.g. not loading providers with the stories request)
     */
    static checkProvidersLoadingRequired(story: IStory): boolean {
        if (story.providerId != null && story.primaryProvider == null) {
            return true;
        }
        if (story.providers && story.providers.length > 0) {
            for (let provider of story.providers) {
                if (provider.location == null) {
                    return true;
                }
            }
        }
        return false;
    }

    static checkLoadProvidersWizard(story: IStory, onLoadProviders: () => any): ICheckStoryProviders {
        let res: ICheckStoryProviders = {
            hasProviders: false,
            providers: []
        };

        let check = StoryUtils.checkProviders(story);
        if (check.hasProviders) {
            res.providers = check.providers;
            if (StoryUtils.checkProvidersLoadingRequired(story)) {
                setTimeout(() => {
                    onLoadProviders();
                }, 1);
            } else {
                res.hasProviders = true;
            }
        } else {
            if (StoryUtils.checkProvidersLoadingRequired(story)) {
                setTimeout(() => {
                    onLoadProviders();
                }, 1);
            }
        }
        return res;
    }

    static checkQRUnlock(story: IStory) {
        if (story.qrUnlock != null && story.qrUnlock !== 0) {
            return true;
        }
        return false;
    }

    /**
     * editor story to story
     * @param draft 
     */
    static editorDraftToStoryAdapter(draft: IEditorDraft) {
        let story: IStory = {
            id: draft.id,
            name: draft.name,
            shortName: draft.draftName,
            description: null,
            finishedDescription: null,
            locs: [],
            locations: [],
            dynamic: 0,
            islocal: false,
            locked: false,
            categoryCode: ECategoryCodes.global,
            categoryName: "DRAFT",
            timestamp: draft.timestamp,
            lat: 0,
            lng: 0,
            level: draft.level,
            regionType: null,
            appVersionMin: null,
            rating: null,
            ratingCount: null,
            providerId: null,
            mapStyleCode: null,
            mapStyle: null,
            photoUrl: draft.photoUrl,
            photoUrlSmall: null,
            priceCoins: null,
            providers: [],
            rewardCoins: null,
            rewardXp: null,
            rewardXpUnlock: null,
            itemIapCode: null,
            itemIap: null,
            premium: null,
            storyUnlocks: [],
            qrUnlock: null,
            devOnly: null,
            enabled: null,
            mode: null,
            customMap: null,
            found: false,
            loading: null,
            isDraft: true,
            draft: draft,
            droneOnly: null,
            teams: null,
            enableAr: null
        }
        return story;
    }

    static editorStoryToStoryAdapter(draft: IEditorStory) {
        let story: IStory = {
            id: null,
            name: draft.storyName,
            shortName: draft.storyName,
            description: draft.description,
            finishedDescription: draft.finishedDescription,
            locs: [],
            locations: [],
            dynamic: 0,
            islocal: false,
            locked: false,
            categoryCode: ECategoryCodes.global,
            categoryName: "DRAFT",
            timestamp: null,
            lat: 0,
            lng: 0,
            level: draft.level,
            regionType: null,
            appVersionMin: null,
            rating: null,
            ratingCount: null,
            providerId: null,
            mapStyleCode: null,
            mapStyle: null,
            photoUrl: draft.photoUrl,
            photoUrlSmall: null,
            priceCoins: null,
            providers: [],
            rewardCoins: null,
            rewardXp: null,
            rewardXpUnlock: null,
            itemIapCode: null,
            itemIap: null,
            premium: null,
            storyUnlocks: [],
            qrUnlock: null,
            devOnly: null,
            enabled: null,
            mode: draft.mode,
            customMap: null,
            found: false,
            loading: null,
            isDraft: true,
            draft: null,
            droneOnly: draft.droneOnly,
            teams: draft.teams,
            enableAr: draft.enableAR
        }
        return story;
    }

    /**
     * editor story item to game item
     * @param draftItem 
     */
    static editorDraftToGameItemAdapter(draftItem: IEditorStoryTreasureItem) {
        let gi: IGameItem = {
            id: draftItem.id,
            code: draftItem.code,
            name: null,
            description: null,
            shortDescription: null,
            buyAmount: null,
            itemIap: null,
            count: null,
            status: null,
            itemCategoryCode: null,
            itemCategory: null,
            photo: null,
            photoUrl: null,
            wikiUrl: null,
            version: null,
            icon: null,
            consumable: null,
            priceCoins: null,
            resellValuePercent: null,
            timestamp: null,
            timeLeft: null,
            timeLeftPercent: null,
            timeLeftString: null,
            timeExpireDef: null,
            level: null,
            value: null,
            requiresEnable: null,
            delegateCode: null,
            appHandled: null,
            achievement: null,
            pendingCount: null,
            activeCount: null,
            validCount: null,
            enableByQr: false,
            progress: null,
            new: 0,
            newUnlocked: 0,
            newUnlockedAux: 0,
            noBlink: false,
            peek: 1,
            isDraft: draftItem.isDraft,
            amount: draftItem.count,
            loading: true,
            editor: null
        };
        return gi;
    }

    /**
     * editor story item to game item reverse
     * @param item 
     */
    static editorDraftToGameItemReverseAdapter(item: IGameItem) {
        let di: IEditorStoryTreasureItem = {
            id: item.id,
            code: item.code,
            count: item.amount,
            isDraft: item.isDraft
        };
        return di;
    }

    static addGameItemData(item: IGameItem, specs: IGameItem[]) {
        let onMatch = (spec: IGameItem) => {
            Object.assign(item, spec);
            item.loading = false;
        };

        for (let spec of specs) {
            if (item.isDraft && (item.id != null)) {
                // draft find by id
                if (spec.isDraft && (spec.id === item.id)) {
                    onMatch(spec);
                    break;
                }
            } else {
                // game items find by code
                if (spec.code === item.code) {
                    onMatch(spec);
                    break;
                }
            }
        }
        return item;
    }

    /**
     * editor story custom param to custom param
     * @param cparSpec 
     */
    static editorDraftToCustomParamAdapter(cparSpec: IEditorStoryLocationCustomParam) {
        let cpar: ICustomParamForActivity = {
            id: cparSpec.id,
            code: null,
            customParamCategoryCode: cparSpec.category,
            customParamCategory: null,
            categoryName: null,
            treasureSpec: null,
            items: [],
            tag: cparSpec.tag,
            name: null,
            description: null,
            photoUrl: null,
            wikiUrl: null,
            value: null,
            dataObj: cparSpec.dataObj,
            isDraft: cparSpec.isDraft,
            loading: true,
            editor: null
        };
        return cpar;
    }

    /**
     * editor story custom param to custom param reverse
     * @param cpar 
     */
    static editorDraftToCustomParamReverseAdapter(cpar: ICustomParamForActivity) {
        let cparSpec: IEditorStoryLocationCustomParam = {
            category: cpar.customParamCategoryCode,
            tag: cpar.tag,
            dataObj: cpar.dataObj,
            isDraft: cpar.isDraft,
            id: cpar.id
        };
        return cparSpec;
    }

    static formatCustomParam(cpar: ICustomParamForActivity) {
        if (cpar.treasureSpec) {
            cpar.photoUrlMarker = cpar.treasureSpec.photoUrl;
        }
    }

    static formatCustomParamData(cpar: ICustomParamForActivity) {
        if (cpar.dataString != null) {
            try {
                cpar.dataObj = JSON.parse(cpar.dataString);
            } catch (err) {
                console.error(err);
            }
        }
        return cpar;
    }

    static addCustomParamData(cpar: ICustomParamForActivity, specs: ICustomParamForActivity[]) {
        let onMatch = (spec: ICustomParamForActivity) => {
            Object.assign(cpar, spec);
            if (spec.treasureSpec) {
                cpar.photoUrlMarker = spec.treasureSpec.photoUrl;
            }
            if (spec.dataObj) {
                cpar.dataObj = spec.dataObj;
            }
            cpar.loading = false;
        };

        if (specs != null) {
            for (let spec of specs) {
                if (cpar.isDraft && (cpar.id != null)) {
                    // draft find by id
                    if (spec.isDraft && (spec.id === cpar.id)) {
                        onMatch(spec);
                        break;
                    }
                } else {
                    // custom param find by code
                    if (spec.customParamCategoryCode === cpar.customParamCategoryCode && spec.tag === cpar.tag) {
                        onMatch(spec);
                        break;
                    }
                }
            }
        }
        return cpar;
    }

    /**
     * set custom photo url from dataObj if defined
     * @param cpar 
     */
    static useCustomPhotoUrlSpec(cpar: ICustomParamForActivity) {
        switch (cpar.code) {
            case ECustomParamCodes.photo:
                let photoData: IActivityPhotoSpecs = cpar.dataObj as IActivityPhotoSpecs;
                if (photoData != null) {
                    if (photoData.ref != null) {
                        cpar.photoUrl = photoData.ref;
                    } else {
                        if (photoData.refTemp != null) {
                            cpar.photoUrl = photoData.refTemp;
                        }
                    }
                }
                break;
            default:
                break;
        }
        return cpar;
    }

    /**
     * user draft to custom param
     * draft only
     * @param cparSpec 
     */
    static userDraftToCustomParamAdapter(cparSpec: IEditorDraft) {
        let cpar: ICustomParamForActivity = {
            id: cparSpec.id,
            code: null,
            customParamCategoryCode: ECustomParamCategoryCode.draft,
            customParamCategory: null,
            categoryName: null,
            treasureSpec: null,
            items: [],
            tag: cparSpec.draftName,
            name: cparSpec.name,
            description: cparSpec.description,
            photoUrl: cparSpec.photoUrl,
            wikiUrl: null,
            value: null,
            dataObj: null,
            isDraft: true,
            draftItemLink: cparSpec.itemId,
            draftCparamLink: cparSpec.customParamId,
            editor: {
                userId: cparSpec.userId,
                locationId: cparSpec.locationId,
                user: cparSpec.user
            }
        };
        return cpar;
    }

    /**
     * user draft to custom param reverse
     * draft only
     * @param cpar 
     */
    static userDraftToCustomParamReverseAdapter(cpar: ICustomParamForActivity) {
        let cparSpec: IEditorDraft = {
            id: cpar.id,
            userId: cpar.editor ? cpar.editor.userId : null,
            locationId: cpar.editor ? cpar.editor.locationId : null,
            user: cpar.editor ? cpar.editor.user : null,
            draftCategoryCode: null,
            name: cpar.name,
            draftName: cpar.tag,
            description: cpar.description,
            photoUrl: cpar.photoUrl,
            itemId: cpar.draftItemLink,
            customParamId: cpar.draftCparamLink,
            timestamp: null,
            timestampEdit: null,
            level: null,
            flag: 0
        };
        return cparSpec;
    }

    /**
     * user draft to game item
     * draft only
     * @param itemSpec 
     */
    static userDraftToGameItemAdapter(itemSpec: IEditorDraft) {
        let item: IGameItem = {
            id: itemSpec.id,
            code: EItemCategoryCodes.draft,
            name: itemSpec.draftName,
            tempName: itemSpec.name,
            description: itemSpec.description,
            shortDescription: null,
            buyAmount: null,
            itemIap: null,
            count: null,
            status: null,
            itemCategoryCode: null,
            itemCategory: null,
            photo: null,
            photoUrl: itemSpec.photoUrl,
            wikiUrl: null,
            version: null,
            icon: null,
            consumable: null,
            priceCoins: null,
            resellValuePercent: null,
            timestamp: null,
            timeLeft: null,
            timeLeftPercent: null,
            timeLeftString: null,
            timeExpireDef: null,
            level: null,
            value: null,
            requiresEnable: null,
            delegateCode: null,
            appHandled: null,
            achievement: null,
            pendingCount: null,
            activeCount: null,
            validCount: null,
            enableByQr: false,
            progress: null,
            new: 0,
            newUnlocked: 0,
            newUnlockedAux: 0,
            noBlink: false,
            peek: 1,
            isDraft: true,
            isNew: itemSpec.isNew,
            loading: true,
            draftItemLink: itemSpec.itemId,
            draftCparamLink: itemSpec.customParamId,
            editor: {
                userId: itemSpec.userId,
                locationId: itemSpec.locationId,
                user: itemSpec.user
            }
        };
        return item;
    }

    /**
     * user draft to game item reverse
     * draft only
     * @param item 
     */
    static userDraftToGameItemReverseAdapter(item: IGameItem) {
        let cparSpec: IEditorDraft = {
            id: item.id,
            userId: item.editor ? item.editor.userId : null,
            locationId: item.editor ? item.editor.locationId : null,
            user: item.editor ? item.editor.user : null,
            draftCategoryCode: null,
            name: item.tempName,
            draftName: item.name,
            description: item.description,
            photoUrl: item.photoUrl,
            itemId: item.draftItemLink,
            customParamId: item.draftCparamLink,
            timestamp: null,
            timestampEdit: null,
            level: null,
            isNew: item.isNew,
            flag: 0
        };
        return cparSpec;
    }

    static checkPublished(draft: IEditorDraft) {
        if (!draft) {
            return false;
        }
        if (draft.customParamId != null || draft.itemId != null) {
            return true;
        }
        return false;
    }
}
