<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
  <div [class]="wrapperClass" fxLayout="column">
    <div [class]="innerClass" fxLayout="column">
      <div linkInterceptor [innerHTML]="description" class="info-text-m description-frame text-color-primary"></div>
      <div *ngIf="photoUrl" class="padding-s">
        <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
      </div>
    </div>

    <div fxFlex>
    </div>

    <div class="stick-bottom" [ngClass]="buttonBar">
      <ion-grid class="full-w no-padding">
        <ion-row *ngIf="mode===modes.withOk">
          <ion-col size="12" class="no-padding">
            <ion-button class="action-button-fill button-color-primary" (click)="ok()">
              <span class="button-font-size-m">
                Ok
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withOkDismiss">
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-warn" (click)="cancel()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-accent" (click)="ok()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withCustomButton">
          <ion-col size="12">
            <ion-button *ngIf="!customButton.name" class="action-button-fill button-color-alternate" (click)="ok()">
              <icon-wrapper slot="icon-only" [icon]="customButton.icon" [custom]="customButton.customIcon">
              </icon-wrapper>
            </ion-button>
            <ion-button *ngIf="customButton.name" class="action-button-fill button-color-alternate" (click)="ok()">
              <span class="button-font-size-s">{{customButton.name}}</span>
              <icon-wrapper slot="start" [icon]="customButton.icon" [custom]="customButton.customIcon"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="mode===modes.withCustomButtonArray && buttons">
          <!-- dynamic button grid -->
          <ion-col *ngFor="let b of buttons" [dynamicCol]="b.size">
            <ion-button *ngIf="b.enabled" [disabled]='b.disabled' [ngClass]="b.class" (click)="customButtonTap(b)">
              <span class="button-font-size-s">{{b.name}}</span>
              <icon-wrapper slot="start" [icon]="b.icon" [custom]="b.customIcon"></icon-wrapper>
              <!-- <icon-wrapper [custom]="b.customIcon" slot="icon-only" [icon]="b.icon"></icon-wrapper> -->
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>