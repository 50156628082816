
export enum EFilePathOption {

    // Read-only directory where the application is installed. (iOS, Android, BlackBerry 10, OSX, windows)
    APP_DIR = 1,

    // Root directory of the application's sandbox; on iOS & windows this location is read-only (but specific subdirectories 
    // [like /Documents on iOS or /localState on windows] are read-write). All data contained within is private to the app. (iOS, Android, BlackBerry 10, OSX)
    APP_STORAGE_DIR = 2,

    // Directory for cached data files or any files that your app can re-create easily. 
    // The OS may delete these files when the device runs low on storage, nevertheless, apps should not rely on the OS to delete files in here. 
    // (iOS, Android, BlackBerry 10, OSX, windows)
    CACHE_DIR = 3,

    // Persistent and private data storage within the application's sandbox using internal memory 
    // (on Android, if you need to use external memory, use .externalDataDirectory). 
    // On iOS, this directory is not synced with iCloud (use .syncedDataDirectory). (iOS, Android, BlackBerry 10, windows)
    DATA_DIR = 4,

    // Files private to the app, but that are meaningful to other application (e.g. Office files). Note that for OSX this is the user's ~/Documents directory. (iOS, OSX)
    DOC_DIR = 5,

    // Where to put app-specific data files on external storage. (Android)
    // here: default base path android
    EXT_DATA_DIR = 6,

    // Application space on external storage. (Android)
    EXT_APP_STORAGE_DIR = 7,

    // External storage (SD card) root. (Android, BlackBerry 10)
    // here: default save path android
    EXT_ROOT_DIR = 8,

    // Files globally available to all applications (BlackBerry 10)
    SHARED_DIR = 10,

    // Temp directory that the OS can clear at will. Do not rely on the OS to clear this directory; your app should always remove files as applicable. (iOS, OSX, windows)
    TEMP_DIR = 11
}
