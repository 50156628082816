export enum EAlertButtonCodes {
    ok = 0,
    cancel = 10,
    aux1 = 1,
    aux2 = 2,
    aux3 = 3,
    aux4 = 4,
    aux5 = 5,
}

export enum EModalClass {
    modal = "modal",
    overlay = "overlay"
}

export enum EClass {
    HUD_BAN_NORMAL = "hud-xp gmap-margin-bottom semi-transp-uniform",
    HUD_BAN_SMALL = "hud-xp gmap-margin-bottom-small semi-transp-uniform",
    HUD_BAN_BOTTOM = "hud-xp semi-transp-uniform",
    MAP_VIEW = "map-view gmap-transp",
    MAP_VIEW_HIDE = "map-view map-hide gmap-transp",

    HUD_INFO = "semi-transp-uniform",
    HUD_WARN = "semi-transp-uniform-warn",
    HUD_ERROR = "semi-transp-uniform-error",

    HUD_INNER_INFO = "text-color-primary",
    HUD_INNER_WARN = "text-color-alternate",
    HUD_INNER_ERROR = "text-color-warn"
}

export enum EFooterClass {
    FOOTER_NORMAL = "toolbar-background-md semi-transp-uniform leplace-footer",
    FOOTER_SMALL = "toolbar-background-md semi-transp-uniform leplace-footer-small"
}

export enum EHeaderClass {
    HEADER_NORMAL = "toolbar-background-md semi-transp-uniform leplace-header",
    HEADER_SMALL = "toolbar-background-md semi-transp-uniform leplace-header-small"
}

export interface IButtonOptions {
    name: string;
    icon: string;
    customIcon?: boolean;
    class: string;
    class2?: string;

    /**
     * col size
     */
    size: number;
    disabled: boolean;
    enabled: boolean;
    highlight: boolean;
    callback: () => any;
    code: number;
}

export interface IButtonCollection {
    [key: string]: IButtonOptions;
}

export class ButtonDefaults {
    static getDefaultButton() {
        let button: IButtonOptions = {
            name: "",
            icon: "",
            class: "",
            class2: "",
            size: 1,
            disabled: true,
            enabled: true,
            highlight: false,
            callback: () => {

            },
            code: 1
        };
        return button;
    }
}

