import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ERouteDef } from './app-utils';


export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: ERouteDef.landing,
  //   pathMatch: 'full'
  // },

  { path: ERouteDef.landing, pathMatch: 'full', loadChildren: () => import('./pages/logins/landing/landing.module').then(m => m.LandingPageModule) },
  { path: ERouteDef.login, pathMatch: 'full', loadChildren: () => import('./pages/logins/login/login.module').then(m => m.LoginPageModule) },
  { path: ERouteDef.signup, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup/signup.module').then(m => m.SignupPageModule) },
  { path: ERouteDef.signupTerms, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup-terms/signup-terms.module').then(m => m.SignupTermsPageModule) },
  { path: ERouteDef.signupTutorial, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup-tutorial/signup-tutorial.module').then(m => m.SignupTutorialPageModule) },

  { path: ERouteDef.home, pathMatch: 'full', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: ERouteDef.storyline, pathMatch: 'full', loadChildren: () => import('./pages/marketplace/content/content.module').then(m => m.MarketplaceContentPageModule) },
  { path: ERouteDef.support, pathMatch: 'full', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) },
  { path: ERouteDef.validate, pathMatch: 'full', loadChildren: () => import('./pages/validate/validate.module').then(m => m.ValidatePageModule) },

  // { path: ERouteDef.marketplace, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/marketplace/browse/browse.module').then(m => m.MarketplaceBrowsePageModule) },

  { path: ERouteDef.marketplace, pathMatch: 'full', loadChildren: () => import('./pages/marketplace/browse/browse.module').then(m => m.MarketplaceBrowsePageModule) },
  { path: ERouteDef.map, pathMatch: 'full', loadChildren: () => import('./pages/marketplace/map/map.module').then(m => m.MarketplaceMapPageModule) },

  { path: '**', loadChildren: () => import('./pages/logins/dispatch/dispatch.module').then(m => m.DispatchPageModule) }, 
  
  // Wildcard route for a 404 page (not matched previous URLs)
  // { path: '**', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
