import { EOS } from "../def/app/app";
import { ESkillCategory } from "../def/user/skills";
import { EItemCategoryCodes } from "../def/items/game-item";
import { ENewsfeedCategory } from "../def/newsfeed/newsfeed";
import { IAppResources } from '../def/app/resources';
import { IAppVersionDB } from '../def/app/app-version';
import { EStoryListMode } from '../def/core/story';
import { EFilterStoriesBy, EOrderBySort, EOrderPlacesBy, EOrderStoriesBy, ICustomFilterOption } from "../def/app/filters";
import { EUserType } from "../def/business/business";

export class GeneralCache {

    public static resourceCache: IAppResources;

    public static initURL: string = null;

    public static userId: number = 0;
    public static isOnline: boolean = false;
    public static appLoaded: boolean = false;
    public static sessionExpired: boolean = false;
    public static iapLoaded: boolean = false;
    public static versionString: string = "1.0.0";

    /**
     * the version code is used for http requests
     * enable features based on the defined availability in the current app version
     * IOS/Android different format, should be handled by server using also OS spec
     */
    public static versionCode: number = null;
    public static codePushLabel: string = null;

    // keep selected tabs e.g. inventory, newsfeed, metrics
    public static inventorySelectedCategory: number = EItemCategoryCodes.items;
    public static newsfeedSelectedCategory: number = ENewsfeedCategory.services;
    public static metricsSelectedCategory: number = ESkillCategory.general;
    public static storyListSelectedCategory: number = EStoryListMode.draft;
    public static editorItemsSelectedCategory: number = null;
    public static editorParamsSelectedCategory: number = null;

    /**
     * app paused/active
     */
    public static paused: boolean = false;
    public static pausedTimestamp: number = null;

    /**
     * check app resumed in home page (then reset flag)
     */
    public static appResumedFlag: boolean = false;

    // show warning the first time map is opened, each time the app is opened
    public static warningShown: boolean = false;

    /**
     * db user: tester = {0, 1}
     */
    public static canBeTester: boolean = false;
    public static agreementPending: boolean = false;

    public static os: number = EOS.android;
    public static isWeb: boolean = false;
    public static isDev: boolean = false;

    public static shiftView: boolean = false;

    public static codePushReload: boolean = false;

    public static appDetails: IAppVersionDB = null;

    public static storylineMapOpened: boolean = false;
    public static externalLogin: boolean = false;
    public static adShown: boolean = false;
    public static justLoggedIn: boolean = false;
    public static tboot: number = 0;

    public static locationChecked: boolean = false;

    public static QRCodeVersion: number = 10;

    /** use WebGL API (vector maps, dynamic maps) */
    public static useWebGL: boolean = false;
    /** use WebGL API on mobile too */
    public static useWebGLMobile: boolean = false;

    public static firstOpen = {
        home: false
    };

    public static passwordHash: string = null;

    public static sort: ISortFilter<number> = {
        places: EOrderBySort.ascending,
        stories: EOrderBySort.descending
    };

    public static orderBy: ISortFilter<number> = {
        places: EOrderPlacesBy.default,
        stories: EOrderStoriesBy.default
    };

    public static filter: ISortFilter<ICustomFilterOption[]> = {
        places: [{
            name: "Owner",
            code: EUserType.OWNER,
            checked: true
        }, {
            name: "Admin",
            code: EUserType.EMPLOYEE,
            checked: true
        }, {
            name: "Associate",
            code: EUserType.ASSOCIATE,
            checked: true
        }, {
            name: "Supervisor",
            code: EUserType.SUPERVISOR,
            checked: true
        }],
        stories: [{
            name: "Unlisted",
            code: EFilterStoriesBy.unlisted,
            checked: true
        }, {
            name: "Listed",
            code: EFilterStoriesBy.listed,
            checked: true
        }]
    };

    constructor() {
    }
}

export interface ISortFilter<T> {
    places: T,
    stories: T
}

