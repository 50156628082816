export interface ITutorial {
    id: number;
    code: number;
    tutorialCategoryCode: number;
    name: string;
    title: string;
    description: string;
    descriptionIos?: string;
    photoUrl: string;
    photoUrlIos: string;
    mode: number;
}

export interface ITutorialCollection {
    [key: string]: ITutorial
}

export enum ETutorialCategory {
    /** specific tutorial for activities */
    spec = 1,
    /** walkthrough tutorial for activities */
    walkthroughSpec = 5
}

export enum ETutorialMode {
    leftGauge = 1,
    rightGauge = 2
}
