import { NgModule } from '@angular/core';

import { TabsPage } from './tabs-page';
import { TabsPageRoutingModule } from './tabs-page-routing.module';
import { PagesModule } from '../pages.module';
import { HomePageModule } from '../home/home.module';


@NgModule({
  imports: [
    PagesModule,
    HomePageModule,
    TabsPageRoutingModule
  ],
  declarations: [
    TabsPage,
  ]
})
export class TabsModule { }
