import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IonSlides, ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { AppConstants } from 'src/app/classes/app/constants';
import { IQRFrameParams, IQRCodeSlide, IQRFrameCallbackData } from 'src/app/classes/utils/story-qr-utils';

@Component({
  selector: 'modal-qr-frame',
  templateUrl: './qr-frame.component.html',
  styleUrls: ['./qr-frame.component.scss'],
})
export class QRFrameViewComponent implements OnInit, OnDestroy {
  @ViewChild('sliderContainer', { read: ElementRef, static: false }) sliderContainer: ElementRef;
  slides: IonSlides;
  description: SafeHtml = "";
  title: string = "";
  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";
  version: number = GeneralCache.QRCodeVersion;
  vs: IViewSpecs = ViewSpecs.getDefault();
  params: IQRFrameParams = null;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  slidesData: IQRCodeSlide[] = [];
  sliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };
  np: INavParams = null;
  isDesktop: boolean = false;
  loaded: boolean = false;

  timeout = {
    sliderInit1: null,
    sliderInit2: null
  };

  showLeftButton: boolean = true;
  showRightButton: boolean = true;
  slideCount: number = 1;
  unlockCodeShortLength: number = 5;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService,
    public sanitizer: DomSanitizer,
    public popupFeatures: PopupFeaturesService
  ) {

  }

  ngOnInit() {
    let platform = SettingsManagerService.settings.platformFlags;
    if (platform && platform.ELECTRON) {
      this.isDesktop = true;
    }

    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IQRFrameParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      if (params.description) {
        this.description = this.sanitizer.bypassSecurityTrustHtml(params.description);
      }
      this.title = params.title;
      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;
    }
    this.getClass();
  }

  checkUseInputCode(code: string) {
    if (code == null) {
      return false;
    }
    return code.length === this.unlockCodeShortLength;
  }

  ngAfterViewInit() {
    this.initSlides();
  }

  getClass() {
    this.wrapperClass = "modal-qr-frame";
    this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-flush";
    this.buttonBar = "theme-border-top";
  }

  initSlides() {
    this.slidesData = this.params.qs;
    this.timeout.sliderInit1 = setTimeout(() => {
      this.loaded = true;
      this.timeout.sliderInit2 = setTimeout(() => {
        this.slides = this.sliderContainer.nativeElement.children[0];
        this.slides.update().then(() => {
          this.checkSlides();
        }).catch((err) => {
          console.error(err);
        });
      }, 100);
    }, 100);
  }

  sliderChanged() {

  }

  ngOnDestroy() {
    ResourceManager.clearTimeoutObj(this.timeout);
  }

  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }

  applyCallback(slide: IQRCodeSlide, params: any) {
    if (!slide.callback) {
      return;
    }
    slide.callback(params).then((res: IQRFrameCallbackData) => {
      if (res.qrCode != null) {
        slide.qrCode = res.qrCode;
      }
      if (res.dismiss) {
        this.ok();
      }
    });
  }

  /**
  * Method that shows the next slide
  * update: next GROUP of slides
  */
  public slideNext(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide next");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.sliderOptions.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.sliderOptions.slidesPerView;
      if (slideToIndex < this.sliderOptions.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkSlides() {
    this.slides.length().then((slidesLength: number) => {
      this.slideCount = slidesLength;
      if (this.slideCount > 1) {

      } else {
        this.showLeftButton = false;
        this.showRightButton = false;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  copyToClipboard(code: string) {
    this.popupFeatures.copyToClipboard(code);
  }
}
