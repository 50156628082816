
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../../general/ui/ui-extension';
import { AnalyticsService } from '../../../general/apis/analytics';
import { MiscDataService } from '../../../data/misc';
import { IGenericResponse, ECheckLoginResponse } from '../../../../classes/def/requests/general';
import { Messages } from '../../../../classes/def/app/messages';
import { EAlertButtonCodes } from '../../../../classes/def/app/ui';

import { ResourcesCoreDataService } from '../../../data/resources-core';
import { SettingsManagerService } from '../../../general/settings-manager';
import { IPlatformFlags } from '../../../../classes/def/app/platform';
import { INewsfeedInbox } from '../../../../classes/def/newsfeed/newsfeed';
import { ErrorMessage } from '../../../../classes/general/error-message';

import { UserDataService } from '../../../data/user';

import { IGetReferralCode, ICheckReferralCode } from 'src/app/classes/utils/popup-features';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { Util } from 'src/app/classes/general/util';
import { EmailFormatUils } from 'src/app/services/utils/email-format';
import { IEditorOpt } from 'src/app/classes/def/core/editor';
import { IListSelectorItem, IListSelectorParams } from 'src/app/classes/def/dynamic/list';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { ListSelectorViewComponent } from 'src/app/modals/generic/modals/list-selector/list-selector.component';
import { AppConstants } from 'src/app/classes/app/constants';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { IPopoverInputs, IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { FallbackUtils } from 'src/app/services/utils/fallback-utils';
import { IPlacesPopup } from 'src/app/classes/def/nav-params/place-popup';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { PlacesPopupViewComponent } from 'src/app/modals/app/modals/places-popup/places-popup.component';
import { ItemScannerService } from '../item-scanner';


@Injectable({
    providedIn: 'root'
})
export class PopupFeaturesService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    isRewardPopupActive: boolean = false;

    constructor(
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public authentication: AuthRequestService,
        public analytics: AnalyticsService,
        public misc: MiscDataService,
        public resources: ResourcesCoreDataService,
        public settingsProvider: SettingsManagerService,
        public userData: UserDataService,
        public itemScanner: ItemScannerService
    ) {
        console.log("popup features service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    openCustomEditorOptionSelector(title: string, items: IEditorOpt[], context: any, key: string) {
        console.log("open custom selector");
        let listItems: IListSelectorItem[] = items.map(item => {
            let ge: IGenericEntry = {
                code: item.value,
                name: item.name,
                description: item.description,
                photoUrl: item.photoUrl,
                icon: null,
                customIcon: false,
                enabled: 1,
                position: null,
                withButton: true,
                center: true
            };
            let li: IListSelectorItem = {
                selected: false,
                item: ge,
                available: true,
                inputName: "data"
            };
            return li;
        });

        console.log(listItems);

        let params: IListSelectorParams = {
            title: title,
            componentType: "plainEntry",
            listItems: listItems,
            direct: true,
            customSelect: true
        };

        this.uiext.showCustomModal(null, ListSelectorViewComponent, {
            view: {
                fullScreen: false,
                transparent: AppConstants.transparentMenus,
                large: true,
                addToStack: true,
                frame: false
            },
            params: params
        }).then((res: IListSelectorItem) => {
            console.log(res);
            if (res) {
                let ge: IGenericEntry = res.item;
                context[key] = ge.code;
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    showNearbyPlacesPopup(): Promise<ILeplaceReg> {
        let promise: Promise<ILeplaceReg> = new Promise(async (resolve, reject) => {
            let places: ILeplaceReg[] = this.itemScanner.getCache().places.filter(p => p.place != null).map(p => p.place);
            if (places.length === 0) {
                this.uiext.showAlertNoAction(Messages.msg.noNearbyPlacesScan.after.msg, Messages.msg.noNearbyPlacesScan.after.sub);
                resolve(null);
                return;
            }
            let params: IPlacesPopup = {
                places: places,
                title: "Place scan"
            };
            let navParams: INavParams = {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            };
            this.uiext.showCustomModal(null, PlacesPopupViewComponent, navParams).then((selected: ILeplaceReg) => {
                resolve(selected);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * request referral code
     * that will return an LP bonus if another user signs up with the code
     */
    getReferralCode() {
        this.misc.requestReferralCode().then((resp: IGenericResponse) => {
            let rd: IGetReferralCode = resp.data;

            //  <input class="text-box info-text-lg bold" type="text" value=" ` + rd.code + `" readonly>
            let description: string = `
                <p>Your referral code is:</p>
                <p class="margin-top-s"> 
                   <span class="text-center info-text-lg bold">` + rd.code + `</span>
                </p>
                <p class="margin-top-s">You will receive a reward when your friends sign up with this code</p>`;
            this.uiextStandard.showStandardModal(null, EModalTypes.description, "Referral program", {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: false,
                    addToStack: true,
                    frame: false
                },
                params: { description: description }
            }).then(() => {
                // this.copyToClipboard(rd.code);
            }).catch((err: Error) => {
                console.error(err);
            });

            // this.uiext.showAlertNoAction("Referral program", 
            // `<ion-item class="transparent">
            // <ion-label stacked class="text-color">Your referral code is:</ion-label>
            // <ion-input [(ngModel)]="` + rd.code + `"class="text-color">
            // </ion-input>
            // </ion-item>
            // <p>You will receive a reward when your friends sign up with this code</p>` );
        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "popup-features");
        });
    }

    contactActivation() {
        this.resources.getContactEmail().then((response: IGenericResponse) => {
            // let link = "mailto:leplace.app@gmail.com?subject=Contact";
            let link: string = response.data;
            if (link) {
                let subject = "Request Activation";
                let body = "I am interested in activating my business account on Leplace.\r\nuserId: " + GeneralCache.userId + "\r\n\r\n";
                let uri: string = EmailFormatUils.formatExistingContent(link, subject, body);
                Util.openURLAdaptive(uri);
            }
        }).catch((err: Error) => {
            this.analytics.dispatchError(err, "about");
        });
    }

    copyToClipboard(text: string) {
        // const el = document.createElement('textarea');
        // el.value = text;
        // el.setAttribute('readonly', '');
        // el.style.position = 'absolute';
        // el.style.left = '-9999px';
        // document.body.appendChild(el);
        // el.select();
        // document.execCommand('copy');
        // document.body.removeChild(el);     
        navigator.clipboard.writeText(text);
        this.uiext.showToastNoAction("copied to clipboard", false, 1000);
    }

    /**
     * check if another user used the referral code
     * (and you won the prize)
     */
    checkReferralCodeValidatedCore(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.misc.checkReferralCodeValidated().then((rd: ICheckReferralCode) => {
                if (rd.validated) {
                    this.uiext.showAlert(Messages.msg.referralCodeValidated.after.msg, rd.message, 1, null).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * check service available
     * resolve only
     * @param serviceCode 
     */
    checkServiceAvailable(serviceCode: number): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.resources.checkAvailableService(serviceCode).then((available: boolean) => {
                if (!available) {
                    this.uiext.showAlert(Messages.msg.serviceNotAvailable.after.msg, Messages.msg.serviceNotAvailable.after.sub, 1, null, true).then(() => {
                        resolve(false);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                } else {
                    resolve(true);
                }
            }).catch(() => {
                this.uiext.showAlert(Messages.msg.serviceNotAvailable.after.msg, Messages.msg.serviceNotAvailable.after.sub, 1, null, true).then(() => {
                    resolve(false);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            });
        });
        return promise;
    }

    checkLoginCode(code: number, message: string) {
        let emailValidationPrompt: boolean = false;
        switch (code) {
            case ECheckLoginResponse.emailNotValidated:
                if (emailValidationPrompt) {
                    // show option to resend validation email
                    this.uiext.showAlert(Messages.msg.info.after.msg, ErrorMessage.parse(message), 2, ["ok", "resend"]).then((res: number) => {
                        switch (res) {
                            case EAlertButtonCodes.ok:
                                // request resend email
                                this.misc.requestNewValidationEmail(null).then((_response: IGenericResponse) => {
                                    this.uiext.showAlertNoAction(Messages.msg.requestNewValidationEmail.after.msg, Messages.msg.requestNewValidationEmail.after.sub);
                                }).catch((err: Error) => {
                                    this.analytics.dispatchError(err, "popup-features");
                                    this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                                });
                                break;
                            case EAlertButtonCodes.cancel:

                                break;
                            default:
                                break;
                        }
                    });
                }
                break;
            default:
                this.uiext.showAlertNoAction(Messages.msg.info.after.msg, ErrorMessage.parse(message));
                break;
        }
    }

    setPasswordWizard() {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            FallbackUtils.retry(() => {
                return this.setPassword();
            }, 1000, 500, null, null).then((passwordHash: string) => {
                if (passwordHash != null) {
                    GeneralCache.passwordHash = passwordHash;
                    resolve(true);
                } else {
                    reject(new Error("Password not registered"));
                }
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    setPassword() {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            let popoverInputs: IPopoverInputs = {
                password: {
                    name: "password",
                    value: "",
                    placeholder: "new password",
                    type: "password",
                    enabled: true
                },
                confirmPassword: {
                    name: "confirmPassword",
                    value: "",
                    placeholder: "confirm password",
                    type: "password",
                    enabled: true
                }
            };
            let popoverActions: IPopoverActions = {
                // cancel: {
                //     name: "Cancel",
                //     code: 0,
                //     enabled: false
                // },
                ok: {
                    name: "Ok",
                    code: 1,
                    enabled: true
                }
            };

            this.uiext.showCustomAlertInput("Set account password", null, popoverInputs, popoverActions, false).then(async (res: any) => {
                let code = res.code;
                let data = res.data;
                switch (code) {
                    case 0:
                        break;
                    case 1:
                        if (data.password === data.confirmPassword) {
                            this.authentication.changePasswordServer(null, data.password).then(async (res: IGenericResponse) => {
                                if (res.data) {
                                    resolve(res.data);
                                } else {
                                    await this.uiext.showAlert(Messages.msg.passwordsDoNotMatch.after.msg, Messages.msg.passwordsDoNotMatch.after.sub, 1, null, true);
                                    reject(new Error("Password hash not found"));
                                }
                            }).catch(async (err: Error) => {
                                console.error(err);
                                await this.uiext.showAlert(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub), 1, null, true);
                                reject(err);

                            });
                        } else {
                            await this.uiext.showAlert(Messages.msg.passwordsDoNotMatch.after.msg, Messages.msg.passwordsDoNotMatch.after.sub, 1, null, true);
                            reject(new Error(Messages.msg.passwordsDoNotMatch.after.sub));
                        }
                        break;
                }
            }).catch((err) => {
                console.error(err);
                reject(new Error("UI Error"));
            });
        });
        return promise;
    }

}
