<ion-grid>
  <ion-row class="center-items">
    <ion-col size="2">
      <icon-wrapper *ngIf="show" [icon]="icon" [custom]="false"></icon-wrapper>
    </ion-col>
    <ion-col size="10">
      <ion-row>
        <span class="info-text-m bold text-color-primary">{{ name }}</span>
      </ion-row>
      <ion-row>
        <span class="info-text-s text-color-primary">{{ address }}</span>
      </ion-row>
      <ion-row>
        <span class="info-text-s text-color-alternate">{{ tags }}</span>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>