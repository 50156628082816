<div *ngIf="small" class="flex-card item-card-small bg-container bg-container-fill item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="onTapSelect()">
</div>

<div *ngIf="!small" class="flex-card bg-container bg-container-fill item-card item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl:null}" (click)="onTapSelect()">
  <div class="content" fxLayout="column">
    <div *ngIf="data && isLoading">
      <div class="progress-spinner-center">
        <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
    <ion-grid class="full-w grid-center-absolute" *ngIf="data && !isLoading">
      <ion-row>
        <ion-col size="12" *ngIf="heading">
          <div class="width-m text-color-overlay">
            <span class="text-color-element bold heading-item">{{itemName}}</span>
          </div>
        </ion-col>
        <ion-col size="12" *ngIf="!heading">
          <div class="text-color-overlay">
            <span class="text-color-element bold info-text-xs">{{itemName}}</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>