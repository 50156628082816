<modal-container [vs]="vs" wrapperClass="center-all" class="reward-popup-toast-container"
  [ngClass]="vs.large?'half-height':'modal-small-height'">
  <div [class]="wrapperClass">
    <div [class]="innerClass" *ngIf="loaded">
      <ion-grid class="full-w center-items ion-justify-content-center" height-100>
        <ion-row class="center-flex">
          <ion-col size="2" class="text-left">
            <div *ngIf="mode.timeout">
              <hourglass class="progress-spinner-center" [timeout]="params.timeout"></hourglass>
            </div>
          </ion-col>
          <ion-col size="8">
            <div [innerHTML]="descriptionText" class="info-text-mlg bold description-frame text-color-primary ">
            </div>
          </ion-col>
          <ion-col size="2" class="text-right">
            <ion-button (click)="dismiss(null)" class="as-text menu-button-size menu-button-size-right">
              <div class="leplace-menu-icon icon-color-primary">
                <icon-wrapper icon="close" [custom]="false"></icon-wrapper>
              </div>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>