

import { LatLng } from "@ionic-native/google-maps/ngx";
import { IPlatformFlags } from "../../classes/def/app/platform";
import { IMarkerTheme } from "../../classes/def/app/theme";
import { IPathContent, IPlaceMarkerContent, IShowMarkerOptions } from "../../classes/def/map/map-data";
import { Injectable, NgZone } from "@angular/core";
import { SettingsManagerService } from "../general/settings-manager";
import { MarkersWeb } from "./markers-web";
import { MarkersMobile } from "./markers-mobile";
import { GenericQueueService } from "../general/generic-queue";
import { MarkerUtils } from "./marker-utils";
import { EQueues, EOS } from "../../classes/def/app/app";
import { ISetThisMarkerOptions } from "../../classes/def/map/markers";
import { AnalyticsService } from "../general/apis/analytics";
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { SyncService } from '../app/utils/sync';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IGenericFlags } from 'src/app/classes/utils/general';
import { IObservableMultiplex } from 'src/app/classes/def/mp/subs';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../utils/wait-utils';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { PlacesDataService } from "../data/places";


interface IMasterLockFlags extends IGenericFlags {
    updatePath: boolean
}

interface IMarkerHandlerObservableMultiplex extends IObservableMultiplex {
    updatePath: BehaviorSubject<boolean>
}

@Injectable({
    providedIn: 'root'
})
export class MarkerHandlerService {

    markersClassWeb: MarkersWeb;
    markersClassMobile: MarkersMobile;
    platform: IPlatformFlags = {} as IPlatformFlags;
    test: boolean = false;

    masterLock: IMasterLockFlags = {
        updatePath: false
    };

    masterLockObs: IMarkerHandlerObservableMultiplex = {
        updatePath: null
    };

    useNativeMap: boolean = false;
    initialized: boolean = false;

    constructor(
        public settings: SettingsManagerService,
        public q: GenericQueueService,
        public ngZone: NgZone,
        public analytics: AnalyticsService,
        public syncService: SyncService,
        public placeData: PlacesDataService
    ) {
        console.log("marker handler service created");
        this.masterLockObs = ResourceManager.initBsubObj(this.masterLockObs);
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    setNativeMode(enable: boolean) {
        this.useNativeMap = enable;
        if (!this.initialized) {
            this.initialized = true;
            if (!this.platform.WEB) {
                this.markersClassMobile = new MarkersMobile(this.syncService);
            }
            // mobile may use web api in modals
            this.markersClassWeb = new MarkersWeb(this.ngZone, this.placeData);
        }
    }

    /**
     * master lock
     * @param enable 
     */
    setEnabled(enable: boolean) {
        if (this.useNativeMap) {
            this.markersClassMobile.setEnabled(enable);
        } else {
            this.markersClassWeb.setEnabled(enable);
        }
    }


    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
        console.log("MarkersModule platform: ", this.platform);
        if (!this.platform.WEB) {
            this.useNativeMap = true;
        }
    }

    setTheme(theme: IMarkerTheme) {
        if (this.useNativeMap) {
            this.markersClassMobile.setTheme(theme);
        } else {
            this.markersClassWeb.setTheme(theme);
        }
    }

    /**
     * clear all markers and associated resources
     */
    async clearAllResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeMap) {
                this.markersClassMobile.clearAll(GeneralCache.os === EOS.ios).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                this.markersClassWeb.clearAll();
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * clear all marker layers without actually removing the markers from the map
     * to be used with map.clearAll before map unload
     */
    clearAllNoRemove(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeMap) {
                this.markersClassMobile.clearAllNoRemove().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                this.markersClassWeb.clearAllNoRemove();
                resolve(true);
            }
        });
        return promise;
    }

    clearAllNoAction() {
        this.clearAllResolve().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    checkOverlaps(lat: number, lng: number, zoom: number) {
        if (this.useNativeMap) {
            return MarkerUtils.checkOverlapsCore(lat, lng, zoom, this.markersClassMobile.getAllMarkersData());
        } else {
            return MarkerUtils.checkOverlapsCore(lat, lng, zoom, this.markersClassWeb.getAllMarkersData());
        }
    }

    setMap(map: any) {
        console.log("module setMap");
        if (this.useNativeMap) {
            this.markersClassMobile.setMap(map);
        } else {
            this.markersClassWeb.setMap(map);
        }
    }

    setJsMap(map: any) {
        console.log("module setMap");
        this.markersClassWeb.setMap(map);
    }

    setOptions(opts: any) {
        if (this.useNativeMap) {
            this.markersClassMobile.setOptions(opts);
        } else {

        }
    }


    /**
     * add marker clusterer to all registered markers
     */
    async addMarkerClusterer() {
        let promise = new Promise((resolve, reject) => {
            if (this.useNativeMap) {
                this.markersClassMobile.addMarkerClusterer().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.addMarkerClusterer();
                resolve(true);
            }
        });
        return promise;
    }


    /**
     * set this marker, change position if existing
     * @param layer 
     * @param data 
     */
    async syncMarker(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // console.log("sync marker: ", layer, data.mode, data);
            if (this.useNativeMap) {
                this.markersClassMobile.syncMarker(layer, data, opts).then((result) => {
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncMarker(layer, data, opts).then((result) => {
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * set this marker of circle type, change position if existing
     * @param layer 
     * @param position 
     * @param options 
     */
    syncCircleMarker(layer: string, position: LatLng, options: ISetThisMarkerOptions) {
        let promise = new Promise((resolve, reject) => {
            if (this.useNativeMap) {
                this.markersClassMobile.syncCircleMarker(layer, position, options).then((thisMarker) => {
                    resolve(thisMarker);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncCircleMarker(layer, position, options).then((thisMarker) => {
                    resolve(thisMarker);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * set entire layer visible/invisible
     * it can be any kind of marker, also circle
     * @param layer 
     * @param visible 
     */
    setVisibleLayer(layer: string, visible: boolean) {
        if (this.useNativeMap) {
            this.markersClassMobile.setVisibleLayer(layer, visible);
        } else {
            this.markersClassWeb.setVisibleLayer(layer, visible);
        }
    }

    getVisibleLayer(layer: string) {
        if (this.useNativeMap) {
            return this.markersClassMobile.getVisibleLayer(layer);
        } else {
            return this.markersClassWeb.getVisibleLayer(layer);
        }
    }


    /**
     * clear markers from marker array from google map
     * clear the marker array
     * auto browser/mobile
     * @param layer
     */
    async clearMarkersResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeMap) {
                this.markersClassMobile.clearMarkersResolve(layer).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                })
            } else {
                this.markersClassWeb.clearMarkers(layer);
                resolve(true);
            }
        });
        return promise;
    }



    clearMarkersNoAction(layer: string) {
        this.clearMarkersResolve(layer).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     */
    clearLastArrayMarker(layer: string, clearData: boolean = false) {
        if (this.useNativeMap) {
            this.markersClassMobile.clearLastArrayMarker(layer, clearData);
        } else {
            this.markersClassWeb.clearLastArrayMarker(layer, clearData);
        }
    }

    /**
     * show/hide last marker from array
     * @param layer 
     * @param show 
     */
    toggleLastArrayMarkerShow(layer: string, show: boolean) {
        if (this.useNativeMap) {
            this.markersClassMobile.toggleLastArrayMarkerShow(layer, show);
        } else {
            this.markersClassWeb.toggleLastArrayMarkerShow(layer, show);
        }
    }


    /**
     * warning: this is not reliable to use directly
     * please clear marker by uid instead
     */
    clearArrayMarkerByIndex(layer: string, index: number) {
        if (this.useNativeMap) {
            this.markersClassMobile.clearArrayMarkerByIndex(layer, index);
        } else {
            this.markersClassWeb.clearArrayMarkerByIndex(layer, index);
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param uid 
     */
    clearMarkerByUid(layer: string, uid: string) {
        if (this.useNativeMap) {
            this.markersClassMobile.clearArrayMarkerByUid(layer, uid);
        } else {
            this.markersClassWeb.clearArrayMarkerByUid(layer, uid);
        }
    }

    /**
     * get data layer content
     * @param layer 
     */
    getMarkerDataSingleLayer(layer: string) {
        if (this.useNativeMap) {
            return this.markersClassMobile.getSingleMarkerDataByLayer(layer);
        } else {
            return this.markersClassWeb.getSingleMarkerDataByLayer(layer);
        }
    }

    /**
     * get data layer content
     * @param layer 
     */
    getMarkerDataMultiLayer(layer: string) {
        if (this.useNativeMap) {
            return this.markersClassMobile.getArrayMarkerDataByLayer(layer);
        } else {
            return this.markersClassWeb.getArrayMarkerDataByLayer(layer);
        }
    }

    /**
     * clear the marker layer permanently
     * @param layer 
     */
    clearMarkerLayer(layer: string) {
        if (this.useNativeMap) {
            this.markersClassMobile.clearMarkerLayer(layer);
        } else {
            this.markersClassWeb.clearMarkerLayer(layer);
        }
    }

    /**
     * completely remove the marker layer
     * @param layer 
     */
    async disposeLayerResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeMap) {
                this.markersClassMobile.disposeLayerResolve(layer).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                try {
                    this.markersClassWeb.disposeLayer(layer);
                    resolve(true);
                }
                catch (e) {
                    console.error(e);
                    // reject(e);
                    resolve(false);
                }
            }
        });
        return promise;
    }

    /**
     * add marker for google map into the marker array
     * different for native and browser
     * the jobs are offloaded to the queue manager, 
     * so that markers are added in the same order that they were requested and there are no overlapping calls
     * @param data
     * @param type
     * @param icon
     */
    async insertArrayMarker(data: IPlaceMarkerContent, show: boolean) {
        let promise = new Promise((resolve, reject) => {
            let inSequence: boolean = false;

            let res = (_value: any) => {

            };

            let promise: any;

            if (inSequence) {
                promise = new Promise((resolve) => {
                    res = resolve;
                });
            }

            if (this.useNativeMap) {
                if (inSequence) {
                    this.q.enqueueWithDataSnapshot((data) => {
                        return this.markersClassMobile.insertArrayMarker(data, show);
                    }, res, null, data, EQueues.map, {
                        size: 10,
                        delay: 10,
                        timeout: 3000
                    });
                } else {
                    promise = this.markersClassMobile.insertArrayMarker(data, show);
                }

                promise.then((val: any) => {
                    resolve(val);
                }).catch((err: any) => {
                    reject(err);
                });
            } else {
                // let promise;
                // promise = this.markersClassWeb.addMarker(data, show);
                if (inSequence) {
                    this.q.enqueueWithDataSnapshot((data) => {
                        return this.markersClassWeb.insertArrayMarker(data, show);
                    }, res, null, data, EQueues.map, {
                        size: 10,
                        delay: 10,
                        timeout: 3000
                    });
                } else {
                    promise = this.markersClassWeb.insertArrayMarker(data, show);
                }

                promise.then((val: any) => {
                    resolve(val);
                }).catch((err: any) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * add multiple markers while not preserving the original order
     * async
     * @param data 
     * @param show 
     */
    async insertMultipleMarkers(data: IPlaceMarkerContent[], show: boolean): Promise<boolean> {
        // let index = 1;
        // let promises = [];
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            let sync: boolean = GeneralCache.os === EOS.ios;
            let promises = [];

            // sync = true;

            if (sync) {
                try {
                    // never use forEach when you want to wait for all to complete via await
                    for (let element of data) {
                        await this.insertArrayMarker(element, show);
                        console.log("insert 1");
                    }
                    console.log("insert all complete");
                    resolve(true);
                } catch (e) {
                    reject(e);
                }
            } else {
                for (let element of data) {
                    let promise = this.insertArrayMarker(element, show);
                    promises.push(promise);
                }

                Promise.all(promises).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    async syncMarkerArrayMultilayer(data: IPlaceMarkerContent[]) {
        let promise = new Promise(async (resolve, reject) => {
            try {
                let dataGrouped: { [key: string]: IPlaceMarkerContent[] } = {};
                for (let pm of data) {
                    if (dataGrouped[pm.layer] != null) {
                        dataGrouped[pm.layer].push(pm);
                    } else {
                        dataGrouped[pm.layer] = [pm];
                    }
                }
                let layers: string[] = Object.keys(dataGrouped);
                for (let layer of layers) {
                    await this.syncMarkerArray(layer, dataGrouped[layer]);
                }
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }


    /**
     * sync external buffered data array with marker array using CRUD operations
     * dynamic update markers e.g. position
     * @param data 
     */
    async syncMarkerArray(layer: string, data: IPlaceMarkerContent[]) {
        let promise = new Promise((resolve, reject) => {
            if (this.useNativeMap) {
                this.markersClassMobile.syncMarkerArray(layer, data, GeneralCache.os === EOS.ios).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncMarkerArray(layer, data).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * show markers on the map from the marker array
     * @param layer
     */
    async showMarkerArray(layer: string, circularFrame: boolean) {
        console.log("show " + layer + " markers ");
        // marker size for canvas
        let opts: IShowMarkerOptions = {
            mh: 40,
            mw: 20,
            width: 120,
            type: layer,
            circularFrame
        };

        let promise = new Promise((resolve, reject) => {
            if (this.useNativeMap) {
                this.markersClassMobile.showMarkerArrayMobile(opts).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            } else {
                this.markersClassWeb.showMarkerArrayWeb(opts).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }

            if (this.test) {
                reject();
            }
        });

        return promise;
    }


    async waitForUpdatePath() {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let ok: boolean = await WaitUtils.waitFlagResolve(this.masterLock.updatePath, this.masterLockObs.updatePath, [false], 5000);
            if (!ok) {
                resolve(false);
                return;
            } else {
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * update path (polyline)
     * defined by waypoints
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    async updatePath(waypoints: LatLng[], layer: string, pd: IPathContent, show: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {

            let ok: boolean = await WaitUtils.waitFlagResetResolve(this.masterLock.updatePath, this.masterLockObs.updatePath);
            if (!ok) {
                resolve(false);
                return;
            }

            this.masterLock.updatePath = true;
            this.masterLockObs.updatePath.next(true);

            await this.disposeLayerResolve(layer);
            if (this.useNativeMap) {
                this.markersClassMobile.addPathMobile(waypoints, layer, show).then(() => {

                    this.masterLock.updatePath = false;
                    this.masterLockObs.updatePath.next(false);

                    resolve(true);
                }).catch((err: Error) => {

                    this.masterLock.updatePath = false;
                    this.masterLockObs.updatePath.next(false);

                    reject(err);
                });
            } else {
                this.markersClassWeb.addPathWeb(waypoints, layer, pd, show);              

                this.masterLock.updatePath = false;
                this.masterLockObs.updatePath.next(false);

                resolve(true);
            }
        });
        return promise;
    }

    updatePathNoAction(waypoints: LatLng[], layer: string, pd: IPathContent, show: boolean) {
        this.updatePath(waypoints, layer, pd, show).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "markers");
        });
    }

    /**
     * create path (polyline)
     * defined by waypoints
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    async addPath(waypoints: LatLng[], layer: string, show: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.useNativeMap) {
                this.markersClassMobile.addPathMobile(waypoints, layer, show).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                let pd: IPathContent = {
                    arrows: false,
                    path: layer,
                    callback: null
                };
                this.markersClassWeb.addPathWeb(waypoints, layer, pd, show);
                resolve(true);
            }
        });
        return promise;
    }

    /**
     *  to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     * @param index 
     * @param layer 
     */
    getMarkerLocationByIndex(index: number, layer: string) {
        let pos;
        if (this.useNativeMap) {
            pos = this.markersClassMobile.getMarkerLocationByIndex(index, layer);
        } else {
            pos = this.markersClassWeb.getMarkerLocationByIndex(index, layer);
        }
        return pos;
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed) 
     * @param index 
     * @param layer 
     */
    getMarkerByIndex(index: number, layer: string) {
        let marker: any;
        if (this.useNativeMap) {
            marker = this.markersClassMobile.getArrayMarkerByIndex(index, layer);
        } else {
            marker = this.markersClassWeb.getArrayMarkerByIndex(index, layer);
        }
        return marker;
    }

    getMarkerDataByUid(uid: string, layer) {
        let marker: any;
        if (this.useNativeMap) {
            marker = this.markersClassMobile.getArrayMarkerDataByUid(uid, layer);
        } else {
            marker = this.markersClassWeb.getArrayMarkerDataByUid(uid, layer);
        }
        return marker;
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     * @param index 
     * @param layer 
     */
    getMarkerDataByIndex(index: number, layer: string) {
        let marker: any;
        if (this.useNativeMap) {
            marker = this.markersClassMobile.getArrayMarkerDataByIndex(index, layer);
        } else {
            marker = this.markersClassWeb.getArrayMarkerDataByIndex(index, layer);
        }
        return marker;
    }

    /**
     * should be called just after init map
     */
    setGlobalMarkerInitTimestamp() {
        if (this.useNativeMap) {
            this.markersClassMobile.setGlobalMarkerInitTimestamp();
        } else {
            this.markersClassWeb.setGlobalMarkerInitTimestamp();
        }
    }

    setDraggable(layer: string, draggable: boolean) {
        if (this.useNativeMap) {
            this.markersClassMobile.setDraggable(layer, draggable);
        } else {
            this.markersClassWeb.setDraggable(layer, draggable);
        }
    }

    setDraggableSelected(layer: string, uid: string, animate: boolean) {
        if (this.useNativeMap) {
            this.markersClassMobile.setDraggableSelectedByUid(layer, uid, animate);
        } else {
            this.markersClassWeb.setDraggableSelectedByUid(layer, uid, animate);
        }
    }

    getDraggableSelected(layer: string, uid: string) {
        if (this.useNativeMap) {
            this.markersClassMobile.getDraggableSelectedByUid(layer, uid);
        } else {
            this.markersClassWeb.getDraggableSelectedByUid(layer, uid);
        }
    }

    refreshSelectedMarker(layer: string, uid: string, placeMarkerData: IPlaceMarkerContent) {
        if (this.useNativeMap) {
            this.markersClassMobile.refreshMarkerByUid(layer, uid, placeMarkerData);
        } else {
            this.markersClassWeb.refreshMarkerByUid(layer, uid, placeMarkerData);
        }
    }
}
