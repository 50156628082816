export interface ISkillSet {
    root: ISkill;
    tree: ISkill[];
}

export interface ISkillSetResponse {
    skillSet: ISkillSet;
    max: number;
}

export interface ISkill {
    id: number;
    label: string;
    categoryCode: number;
    level: number;
    max: number;
    xp: number;
    xpLevelUp: number;
    image?: string;
    color?: {
        border: string,
        background: string
    };
    shape?: string;
    plot: boolean;
}

export enum ESkillCategory {
    general = -1
}
