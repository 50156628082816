import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  uppercase: boolean;

  @Input()
  size: string;

  @Input()
  warning: boolean;

  @Input()
  overlay: boolean;

  class: string = "";

  constructor() { }

  ngOnInit(): void {
    if (this.warning) {
      this.class = "text-color-warn-high";
    } else {
      this.class = "text-color-element";
    }
    if (this.uppercase) {
      this.class += " uppercase";
    }
    if (!this.size) {
      this.class += " info-text-xs";
    } else {
      this.class += " info-text-" + this.size;
    }
    if (this.overlay) {
      this.class += " text-color-overlay text-color-overlay-primary-high";
    }
  }
}
