import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { IStory, IStoryExtended } from 'src/app/classes/def/core/story';
import { GameStatsService } from 'src/app/services/app/utils/game-stats';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { StoryUtils } from 'src/app/classes/utils/story-utils';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EStoryReleaseFlag } from 'src/app/classes/def/business/story';

@Component({
  selector: 'story-info',
  templateUrl: './story-info.component.html',
  styleUrls: ['./story-info.component.scss'],
})
export class StoryInfoComponent implements OnInit, OnChanges, OnDestroy {

  locationType: string = "";

  @Input()
  data: IStoryExtended;

  @Input()
  large: boolean;

  @Input()
  showCategory: boolean;

  @Input()
  reload: boolean;

  level: number = 0;
  hasLevel: boolean = false;

  paidStory: boolean = false;
  qrEnabled: boolean = false;
  locked: boolean = false;
  // photoUrlLoaded: string;

  price: string = "";
  pricePromo: string = "";
  levelDisp: string = "";
  xpDisp: string = "";
  unlisted: boolean = false;
  private: boolean = false;

  isLoading: boolean = false;
  validIap: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  constructor(
    private gameStats: GameStatsService
  ) {

  }

  ngOnInit() {
    if (!(this.data && this.data.story)) {
      return;
    }

    console.log("story info: ", this.data);
    if (this.data.story.itemIapCode || this.data.story.defaultPrice != null) {
      this.paidStory = true;
      this.loadPriceData();
    } else {
      if (this.data.story.priceCoins) {
        this.paidStory = true;
        let price: string = this.data.story.priceCoins ? this.data.story.priceCoins + "" : "";
        this.price = price + " LP";
      } else {
        this.price = "FREE";
      }
    }

    this.unlisted = this.data.story.devOnly === EStoryReleaseFlag.unlisted;
    this.private = this.data.story.devOnly === EStoryReleaseFlag.devOnly;

    if (StoryUtils.checkQRUnlock(this.data.story)) {
      this.qrEnabled = true;
    }

    this.locked = this.data.story.locked;

    if (this.data.story.level != null) {
      this.level = this.data.story.level;
      this.hasLevel = true;
    }

    let potentialXp: number = this.gameStats.getStoryFinishedWeight(this.data.story);
    this.levelDisp = "Level " + this.level;

    if (this.showCategory && this.data.story.categoryName) {
      this.levelDisp += " " + this.data.story.categoryName.toUpperCase();
    }

    if (potentialXp) {
      // this.xpDisp = potentialXp + " XP";
      this.levelDisp += " (" + potentialXp + " XP)";
    }
  }

  loadPriceData() {
    let story: IStory = this.data.story;
    if (story) {
      this.price = story.defaultPrice + " " + story.currency.name;
      if (story.promoPrice != null) {
        this.pricePromo = story.promoPrice + " " + story.currency.name;
      } else {
        this.pricePromo = "";
      }
      this.isLoading = false;
    } else {
      this.price = "";
      this.pricePromo = "";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'reload':
            this.loadPriceData();
            break;
        }
      }
    }
  }

  ngOnDestroy(): void {

  }
}
