

import { Injectable, isDevMode } from "@angular/core";
import { GeneralCache } from 'src/app/classes/app/general-cache';

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {
    constructor(
  
    ) {
        console.log("app version service created");
    }

    checkWebProdInit() {
        if (this.checkWebProd()) {
            window.console.warn = function () { };
            window.console.error = function () { };
            window.console.log = function () { };
        } else {
            GeneralCache.isDev = true;
            GeneralCache.useWebGL = false;
        }
    }

    checkWebProd() {
        return !isDevMode();
    }
}