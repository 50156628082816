import { IKeypoint } from "../places/keypoints";

export interface IAppService {
    id?: number;
    code: number;
    name?: string;
    description?: string;
    /**
     * current/selected url
     */
    url: string;
    /**
     * production url (non tester)
     */
    urlProd: string;
    /**
     * tester url
     */
    urlTest: string;
    status: number;

    // check if test mode (url) is selected
    isTestUrl?: boolean;
}


// keys should be as names column in db service_url table
export interface IAppServiceDef {
    // [key: string]: IServiceUrl,
    /**
     * mp websocket server
     */
    mp: IAppService;
    /**
     * mp REST server
     */
    mpHttp: IAppService;
    /**
     * media file server (processing)
     */
    media: IAppService;
    /**
     * benchmark server (stream real time data e.g. gyroscope calibration experiment)
     */
    bench: IAppService;
}

export interface IServiceUrlResponse {
    array: IAppService[];
    object: IAppServiceDef;
}

export enum EServiceUrlCodes {
    mp = 2
}

export interface IFeatureDef {
    id: number;
    code: number;
    name: string;
    description: string;
    price: number;
    message: string;
    /**
     * check if premium feature unlocked
     */
    unlocked: boolean;
    /**
     * aggregate available conditions (e.g. app version min, release date, location/global)
     */
    available1: boolean;
    /**
     * available1 && unlocked
     */
    available: boolean;

    /**
     * show up in extras
     */
    list: number;
    /**
     * consumable/unlockable
     */
    type: number;
    releaseDate: string;
    enabled: number;
    global: number;
    keypoints: IFeatureDefKeypoint[];
    major: number;
    photoUrl: string;
}

export interface IFeatureDefKeypoint {
    id: number;
    featurePriceCode: number;
    keypointId: number;
    keypoint: IKeypoint;
}

export interface IStandardPhoto {
    code: number;
    photoUrl: string;
}

export enum EFeatureTypes {
    consumable = 1,
    unlockable = 2
}

export enum EFeatureCode {
    privateStories = 1,
    createGroup = 2,
    scanPlace = 101,
    retryActivity = 102,
    getReferralCode = 201,
    worldEditorMode = 1001,
    events = 1002,

    worldMap = 2001,
    storyline = 2002,
    inventory = 2003,
    myAccount = 2004,
    leaderboard = 2005,
    extras = 2006,
    newsfeed = 2007,
    support = 2008,

    activateAccount = 110,
    registerPlace = 111,
    scanClient = 112,
    placeSubscription = 113
}

export enum EOS {
    browser = 0,
    android = 1,
    ios = 2,
    electronWindows = 3,
    electronMac = 4
}

export enum EPlacesViewMode {
    list = 0,
    slides = 1
}

export enum EQueues {
    map = 0,
    messaging = 1,
    home = 2,
    hudMsg = 3,
    tts = 4,
    refreshCrates = 5,
    audio = 6,
    loadingModal = 7,
    rewardModal = 8,
}

export interface IGameContext {
    id: number;
    code: number;
    name: string;
}

export interface IGenericReport<T> {
    status: boolean,
    message: string,
    warnings?: string[],
    data: T
}

export enum ECodePushSource {
    none = 0,
    default = 1
}

export interface IDistFlags {
    android: boolean,
    ios: boolean,
    mobile: boolean,
    web: boolean
  }