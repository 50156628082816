import { Component } from '@angular/core';
import { ITabInterface } from 'src/app/app-utils';


@Component({
  templateUrl: 'tabs-page.html'
})
export class TabsPage {
  tabs: ITabInterface[] = [
   
  ]
  constructor() {

  }
}
