import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IGenericResponse, ICheckLoginResponse } from "../../classes/def/requests/general";
import { ResourcesCoreDataService } from "./resources-core";
import { GeneralCache } from "../../classes/app/general-cache";
import { AppSettings } from "../utils/app-settings";
import { StorageOpsService } from "../general/data/storage-ops";
import { ELocalAppDataKeys } from "../../classes/def/app/storage-flags";
import { SettingsManagerService } from "../general/settings-manager";
import { IUserFlagUpdate, IUserFlagDB, EUserFlagDB } from "../../classes/def/app/settings";
import { IUserPublicData, IUserPrivateData } from "../../classes/def/user/general";
import { UiExtensionService } from '../general/ui/ui-extension';



@Injectable({
    providedIn: 'root'
})
export class UserDataService {

    userPrivateData: IUserPrivateData;

    constructor(
        public generic: GenericDataService,
        public resources: ResourcesCoreDataService,
        public settingsManager: SettingsManagerService,
        public storageOps: StorageOpsService,
        public uiext: UiExtensionService
    ) {
        console.log("user data service created");
    }


    static checkFlag(flags: IUserFlagDB[], code: number) {
        if (!flags) {
            return null;
        }
        for (let i = 0; i < flags.length; i++) {
            if (flags[i].code === code) {
                return flags[i].value;
            }
        }
        return null;
    }


    /**
     * update the device id for onesignal
     * @param playerId 
     */
    updatePushNotificationsId(playerId: string) {
        let req = {
            playerId: playerId
        };
        return this.generic.genericPostStandard("/user/update-push-notifications-id", req);
    }

    /**
     * update settings flag in the db
     * @param flag 
     * @param value 
     */
    setFlagServerNoAction(flag: number, value: number) {
        this.setFlagServer(flag, value).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        })
    }

    /**
     * update settings flag in the db
     * @param flag 
     * @param value 
     */
    setFlagServer(flag: number, value: number) {
        let req: IUserFlagUpdate = {
            flag: flag,
            value: value
        };
        return this.generic.genericPostStandard("/user/set-flag", req);
    }

    /**
     * get settings flag that is stored in the db
     * @param flag 
     */
    getFlagServer(flag: number): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {
            let req = {
                flag: flag
            };
            this.generic.genericPostStandard("/user/get-flag", req).then((res: IGenericResponse) => {
                resolve(res.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * update settings flag in the db
     * @param flag 
     * @param value 
     */
    setMultipleFlagsServer(flags: IUserFlagUpdate[]) {
        let req = {
            flags: flags
        };
        return this.generic.genericPostStandard("/user/set-multiple-flags", req);
    }

    /**
     * get settings flag that is stored in the db
     * @param flag 
     */
    getAllFlagsServer(overrideCache: boolean): Promise<IUserFlagDB[]> {
        let promise: Promise<IUserFlagDB[]> = new Promise((resolve, reject) => {
            if (!overrideCache) {
                if (GeneralCache.resourceCache.user.userFlags.loaded) {
                    resolve(GeneralCache.resourceCache.user.userFlags.content);
                    return;
                }
            }
            this.generic.genericPostStandard("/user/get-all-flags", null).then((res: IGenericResponse) => {
                let flags: IUserFlagDB[] = res.data;
                GeneralCache.resourceCache.user.userFlags.content = flags;
                GeneralCache.resourceCache.user.userFlags.loaded = true;
                resolve(flags);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check user flags for removeAds flag
     */
    checkAdsDisabledFlag(): boolean {
        let adsDisabled: boolean = false;
        if (GeneralCache.resourceCache.user.userFlags.loaded) {
            for (let i = 0; i < GeneralCache.resourceCache.user.userFlags.content.length; i++) {
                let flag: IUserFlagDB = GeneralCache.resourceCache.user.userFlags.content[i];
                if (flag.code === EUserFlagDB.removeAds) {
                    if (flag.value === 1) {
                        adsDisabled = true;
                    }
                    break;
                }
            }
        }
        return adsDisabled;
    }

    /**
     * get profile info for MP/leaderboard user
     */
    getMPUserProfile(): Promise<IUserPublicData> {
        let promise: Promise<IUserPublicData> = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/leaderboard/get-user-basic-details", null).then((resp: IGenericResponse) => {
                resolve(resp.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get public user profile info
     * update cache
     */
    getUserPublicProfile(reload: boolean): Promise<IUserPublicData> {
        let promise: Promise<IUserPublicData> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.user.general.loaded && !reload) {
                resolve(GeneralCache.resourceCache.user.general.content);
                return;
            }
            console.log("user profile reloading");
            this.generic.genericPostStandard("/user/get-public-profile", null).then((resp: IGenericResponse) => {
                GeneralCache.resourceCache.user.general.content = resp.data;
                GeneralCache.resourceCache.user.general.loaded = true;
                console.log("user profile reloaded");
                resolve(resp.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


     /**
     * get private user profile info (e.g. email)
     * update cache
     */
    getUserPrivateProfile(reload: boolean): Promise<IUserPrivateData> {
        let promise: Promise<IUserPrivateData> = new Promise((resolve, reject) => {
            if (this.userPrivateData && !reload) {
                resolve(this.userPrivateData);
                return;
            }
            this.generic.genericPostStandard("/user/get-private-profile", null).then((resp: IGenericResponse) => {
                this.userPrivateData = resp.data;
                resolve(resp.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    reloadProfile() {
        return this.getUserPublicProfile(true);
    }

    reloadProfileNoAction() {
        this.reloadProfile().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * check if the user has the tester flag in the db
     */
    checkAuthorizedTester() {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/user/check-authorized-tester", {}).then((resp: IGenericResponse) => {
                resolve(resp.data.authorized);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * to be called on login check in the app component
     */
    afterSetLoggedInFlag(loggedin: boolean, respdata: ICheckLoginResponse) {
        // check if the user can be also a tester (tester flag in db)
        if (loggedin) {
            this.checkCanBeTester(respdata);
        }
    }

    // /**
    //  * check if can be tester
    //  * also check if it's already set as tester in the local storage
    //  */
    // checkCanBeTester(respdata: ICheckLoginResponse) {
    //     let askTesterResolve = () => {
    //         this.uiext.showAlert(Messages.msg.wannabetester.after.msg, Messages.msg.wannabetester.after.sub, 2, null, true).then((res: number) => {
    //             if (res === EAlertButtonCodes.ok) {
    //                 this.setTesterModeAndLoadApis(true, true);
    //             } else {
    //                 this.setTesterModeAndLoadApis(false, true);
    //             }
    //         }).catch((err: Error) => {
    //             console.error(err);
    //             this.setTesterModeAndLoadApis(false, false);
    //         });
    //     };

    //     if (respdata && respdata.tester) {
    //         GeneralCache.canBeTester = true;
    //         // only use the app as tester from the start if the tester flag is true in local storage
    //         // this flag is set from the settings, only by authorized testers
    //         this.storageOps.getLocalDataKey(ELocalAppDataKeys.isTester).then((isTester: boolean) => {
    //             console.log("check tester: ", isTester);
    //             if (isTester) {
    //                 this.setTesterModeAndLoadApis(true, false);
    //             } else {
    //                 askTesterResolve();
    //             }
    //         }).catch((err: Error) => {
    //             console.error(err);
    //             askTesterResolve();
    //         });
    //     } else {
    //         this.setTesterModeAndLoadApis(false, false);
    //     }
    // }


    /**
     * check if can be tester
     * also check if it's already set as tester in the local storage
     */
    checkCanBeTester(respdata: ICheckLoginResponse) {
        if (respdata && respdata.tester) {
            GeneralCache.canBeTester = true;
            // only use the app as tester from the start if the tester flag is true in local storage
            // this flag is set from the settings, only by authorized testers
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.isTester).then((isTester: boolean) => {
                console.log("check tester: ", isTester);
                if (isTester) {
                    this.setTesterModeAndLoadApis(true, false);
                } else {
                    this.setTesterModeAndLoadApis(false, false);
                }
            }).catch((err: Error) => {
                console.error(err);
                this.setTesterModeAndLoadApis(false, false);
            });
        } else {
            this.setTesterModeAndLoadApis(false, false);
        }
    }



    setCountry(lat: number, lng: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/user/set-country", {
                lat: lat,
                lng: lng
            }).then((resp: IGenericResponse) => {
                resolve(resp.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    setTesterModeAndLoadApis(tester: boolean, writeStorage: boolean) {
        if (!AppSettings.allowSwitchDevMode) {
            this.settingsManager.updateTesterMode(false);
            return;
        }

        this.settingsManager.updateTesterMode(tester);

        this.resources.getServiceUrl(tester, true).then(() => {
            console.log("service url loaded");
        }).catch((err: Error) => {
            console.error(err);
        });

        if (writeStorage) {
            this.storageOps.setStorageFlagNoAction({
                flag: ELocalAppDataKeys.isTester,
                value: tester
            });
        }
    }

}
