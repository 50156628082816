import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[enterKeyPress]'
})
export class EnterKeyPressDirective {
    @Output() enterKeyPress: EventEmitter<void> = new EventEmitter<void>();

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            this.enterKeyPress.emit();
        }
    }
}
