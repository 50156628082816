<div *ngIf="data" class="flex-card bg-container bg-container-fill item-border-radius bg-hidden"
  [ngClass]="large?'story-card-large':'story-card'"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.story.photoUrl}">
  <!-- bg-filter-2 -->
  <div class="content">

    <div *ngIf="isLoading">
      <div class="progress-spinner-center">
        <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>

    <ion-grid *ngIf="!isLoading" class="full-w grid-center-absolute text-div-container">
      <div class="text-color-overlay-s full-w margin-horizontal-s">
        <ion-row>
          <ion-col size="12">
            <span class="info-text-m bold text-color-element">{{data.story.name}}</span>
          </ion-col>
        </ion-row>

        <story-info-spec [data]="data"></story-info-spec>

        <ion-row *ngIf="data.modeText" class="padding-s">
          <ion-col size="12">
            <span class="info-text-s text-color-element">{{data.modeText}}</span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12" center-text>
            <span class="info-text-s text-color-element">{{levelDisp}}</span>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="data.story.regionType" class="center-all">
          <!-- <div class="flex-row">
            <div class="ion-float-left leplace-icon-m icon-color-primary padding-right-s">
              <icon-wrapper [icon]="appIcons.locationMap" [custom]="true"></icon-wrapper>
            </div>
            <div>
              <span class="info-text-s margin-top-s text-color-element">{{data.story.regionType}}</span>
            </div>
          </div> -->
          <ion-col size="12" center-text>
            <span class="info-text-s margin-top-s text-color-element">{{data.story.regionType}}</span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="4" class="text-left">
            <span class="info-text-s text-color-element" *ngIf="unlisted">UNLISTED</span>
            <span class="info-text-s text-color-element" *ngIf="private">DEV</span>
          </ion-col>
          <ion-col size="8" class="text-right">
            <div class="ion-float-right flex-row" *ngIf="paidStory && price">
              <span class="info-text-xs">[from]</span>
              <div class="flex-col padding-left-s">
                <span *ngIf="!pricePromo" class="info-text-xs">{{price}}</span>
                <span *ngIf="pricePromo" class="info-text-xxs text-strikethrough">{{price}}</span>
                <span *ngIf="pricePromo" class="info-text-xs">{{pricePromo}}</span>
              </div>
            </div>
            <div class="ion-float-right" *ngIf="!paidStory || !price">
              <span class="info-text-xs">FREE</span>
            </div>
          </ion-col>
        </ion-row>

      </div>
    </ion-grid>
  </div>
</div>