
import { ILeplaceReg } from "../places/google";
import { IStory, IMasterLockResponse, IStoryExtended } from "../core/story";
import { IStoryCategory } from "../core/category";
import { IEventGroupLinkData } from '../events/links';
import { IEditorDraft } from '../core/editor';
import { IBusinessDataExt } from '../business/business';

export interface IStoryListNavParams {
    storyId: number;
    dynamic: boolean;
    reload: boolean;
    category: IStoryCategory;
    categoryCode: number;
    mapStyle?: string;
    parentView?: any;
    loadStory: boolean;
    entryPlace?: ILeplaceReg;
    storyOverview?: IStory;
    storyOverviewExt?: IStoryExtended;
    eventGroupLinkData?: IEventGroupLinkData;
    fromMapOpened?: boolean;
    localStories: boolean;
    selectedCityId: number;
    providerId: number;
    showQR: boolean;
    isMapSelector?: boolean;
}

export interface IStoryListReturnParams {
    showQR: boolean;
    unlockCode: string;
    storySelected: string;
    locations?: IBusinessDataExt[];
}


export interface IStoryBuyNavParams {
    storyExt: IStory;
    eventGroupLinkData: IEventGroupLinkData;
    masterLockData: IMasterLockResponse;
}

export interface IDBModelStoryRating {
    userId?: number,
    rating?: number,
    timestamp?: string,
    review?: string,
    timestampDisp?: string;
}

export interface IStoryRatingResponseContent {
    id: number,
    rating: number,
    count: number,
    ratingLocations?: number,
    reviews?: IDBModelStoryRating[]
}

export interface IStoryRatingNavParams {
    title: string;
    description: string;
    /** combined rating */
    averageRating: number;
    /** user input rating */
    rating: number;
    /** number of ratings*/
    count: number;
    /** reviews */
    ratingContext?: IStoryRatingResponseContent;
}

export enum EStoryProvider {
    leplace = -1
}

export enum EStartCodes {
    start = 0,
    continue = 1,
    restart = 2
}


export interface IStoryDraftNavParams {
    draftId: number;
    draftSpec: IEditorDraft;
    isTemplate: boolean;
}