import { ILocationContainer } from "./backend-location";
import { LatLng } from "@ionic-native/google-maps/ngx";

export interface IAppLocation {
  loc: ILocationContainer;
  prevLoc?: ILocationContainer;
  flag: number;
  dispDone: boolean;
  inProgress?: boolean;
  fakeLocation?: LatLng;
  location?: LatLng;
  photoValidated?: boolean;
}

export enum ELocationFlag {
  RANDOM = 0,
  FIXED = 1,
  SAVED = 2,
  SAVED_LOCAL = 3
}

export enum ELocationSpecialId {
  placeholder = -1,
  virtual = -2,
  uncharted = -3
};