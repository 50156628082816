import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IStoryExtended } from 'src/app/classes/def/core/story';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EDroneMode } from 'src/app/services/data/activities';

@Component({
  selector: 'story-info-spec',
  templateUrl: './story-info-spec.component.html',
  styleUrls: ['./story-info-spec.component.scss'],
})
export class StoryInfoSpecComponent implements OnInit, OnDestroy {
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  loaded: boolean = false;
  teams: boolean = false;
  droneOnly: boolean = false;
  enableAR: boolean = false;

  @Input()
  data: IStoryExtended;

  constructor(
  ) {

  }

  ngOnInit() {
    if (this.data) {
      console.log("story info spec: ", this.data);
      if (this.data.story != null) {
        if (this.data.story.teams) {
          this.teams = true;
        }
        if (this.data.story.droneOnly === EDroneMode.onlyDrone) {
          this.droneOnly = true;
        }
        if (this.data.story.enableAr === 1) {
          this.enableAR = true;
        }
      }
      this.loaded = true;
    }
  }

  ngOnDestroy(): void {

  }
}
