import { ESettingsCode, EServerInitStatus, IAppSettings, ESettingsType, IAppSettingsContent, SettingsGroupsDef } from '../../classes/def/app/settings';
import { EGeolocationModes, EMeasurementSystem } from '../../classes/def/map/geolocation';
import { ThemeColors } from '../../classes/def/app/theme';
import { EFilePathOption } from 'src/app/classes/def/media/file';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';


export class AppSettings {

    /**
     * update the build number when updating anything that uses local storage
     * used for development mode so that you don't have to change the version 
     * every time you update the data
     * 
     * also update when renaming a settings object
     * 
     * updating an option (name, add, remove) requires setting the reset flag to true, and updating the build number at the same time
     * the change will take effect immediately at the next update
     * 
     * code settings will be overridden regardless the reset flag state
     * 
     */
    public static build: number = 4;

    public static useMobileStyleNavigationAlways: boolean = true; // no links but works in prod mode

    /**
     * allow access to unreleased content and features
     * may be enabled only by users that have the tester flag in the DB
     */
    public static testerMode: boolean = false;

    /**
     * only use in debug mode
     */
    public static allowSwitchDevMode: boolean = true;

    public static globalEnableAnalytics: boolean = true;

    public static settingsArray: IAppSettingsContent;

    public static settings: IAppSettings = {
        build: null, // change it when updating setting definition
        settings: {

            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // APP
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            keepScreenOn: {
                name: "Keep screen on (map)",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 10,
                    icon: EAppIconsStandard.view
                },
                type: ESettingsType.checkbox
            },

            enableTutorial: {
                name: "Enable tutorial messages",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 40,
                    icon: EAppIconsStandard.tutorial
                },
                type: ESettingsType.checkbox
            },

            enableWalkthroughs: {
                name: "Enable tutorial overlays",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 50,
                    icon: EAppIconsStandard.tutorial
                },
                type: ESettingsType.checkbox
            },

            mapDensityFiltering: {
                name: "Map density filtering",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 60,
                    icon: EAppIconsStandard.densityFiltering
                },
                type: ESettingsType.checkbox
            },

            smartMapSelect: {
                name: "Map select auto zoom",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 70,
                    icon: EAppIconsStandard.search
                },
                type: ESettingsType.checkbox
            },

            backgroundMode: {
                name: "Allow background mode*",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 80,
                    icon: EAppIconsStandard.key
                },
                type: ESettingsType.checkbox
            },

            useDefaultPlacePhotos: {
                name: "Generic place photos",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 90,
                    icon: EAppIconsStandard.viewPhoto
                },
                type: ESettingsType.checkbox
            },

            HDPIMode: {
                name: "HDPI mode (map markers)",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 120,
                    icon: EAppIconsStandard.viewPhoto
                },
                type: ESettingsType.checkbox
            },

            vibrateAlways: {
                name: "Vibrate always",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    requiresAppRestart: false,
                    position: 140,
                    icon: EAppIconsStandard.ping
                },
                type: ESettingsType.checkbox
            },


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // APP EXTRAS
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            useProfilePictureOnTheMap: {
                name: "Map photo marker",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.showUserMarker,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 10,
                    icon: EAppIconsStandard.userMarker
                },
                type: ESettingsType.checkbox
            },

            ////

            mapDebugMode: {
                name: "Map debug mode",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 50
                },
                type: ESettingsType.checkbox,
            },
            mapDebugModeFab: {
                name: "Map debug fab",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 60
                },
                type: ESettingsType.checkbox
            },
            useMarkerClustering: {
                name: "Marker clustering",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 100
                },
                type: ESettingsType.checkbox
            },

            useLocalServiceUrl: {
                name: "Local service URL",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 170
                },
                type: ESettingsType.checkbox
            },

            useDirectServiceConnection: {
                name: "Use direct service connection",
                value: false,
                codeOptions: {
                    code: ESettingsCode.serviceUrls,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 190
                },
                type: ESettingsType.checkbox
            },

            useDebugServiceUrls: {
                name: "Use debug service URL",
                value: false,
                codeOptions: {
                    code: ESettingsCode.serviceUrls,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 200
                },
                type: ESettingsType.checkbox
            },

            showMarkerIds: {
                name: "Show marker ids",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 220,
                    icon: EAppIconsStandard.place
                },
                type: ESettingsType.checkbox
            },

            includeNearbyScan: {
                name: "Treasure scaner dual mode",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 240
                },
                type: ESettingsType.checkbox
            },


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // OPTIONS
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            units: {
                name: "Units",
                value: EMeasurementSystem.metric,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 30,
                    icon: EAppIconsStandard.hud
                },
                type: ESettingsType.select,
                options: {
                    metric: {
                        name: "Metric",
                        value: EMeasurementSystem.metric
                    },
                    imperial: {
                        name: "Imperial",
                        value: EMeasurementSystem.imperial
                    }
                },
            },


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // OPTIONS EXTRA
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


            theme: {
                name: "UI theme",
                value: ThemeColors.theme.aubergine.name,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.theme,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 30
                },
                type: ESettingsType.select,
                options: {
                    speed: {
                        name: "Standard",
                        value: ThemeColors.theme.standard.name
                    },
                    position: {
                        name: "Aubergine",
                        value: ThemeColors.theme.aubergine.name
                    }
                }
            },

            locationMode: {
                name: "Location mode",
                value: EGeolocationModes.cordova,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 40
                },
                type: ESettingsType.select,
                options: {
                    navigator: {
                        name: "Navigator",
                        value: EGeolocationModes.native
                    },
                    // navigatorPolling: {
                    //     name: "Navigator (polling)",
                    //     value: EGeolocationModes.nativePolling
                    // },
                    // background: {
                    //   name: "BGL",
                    //   value: GeolocationModes.background
                    // },
                    cordova: {
                        name: "Geo-watch",
                        value: EGeolocationModes.cordova
                    },
                    cordovaPolling: {
                        name: "Geo-locate",
                        value: EGeolocationModes.cordovaPolling
                    }
                }
            },

            fileBasePath: {
                name: "File base path",
                value: EFilePathOption.EXT_DATA_DIR,
                iosValue: EFilePathOption.DATA_DIR,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.basePath,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 41
                },
                type: ESettingsType.select,
                options: {
                    s1: {
                        name: "app",
                        value: EFilePathOption.APP_DIR
                    },
                    s2: {
                        name: "app storage",
                        value: EFilePathOption.APP_STORAGE_DIR
                    },
                    s3: {
                        name: "cache",
                        value: EFilePathOption.CACHE_DIR
                    },
                    s4: {
                        name: "data",
                        value: EFilePathOption.DATA_DIR
                    },
                    s5: {
                        name: "doc",
                        value: EFilePathOption.DOC_DIR
                    },
                    s6: {
                        name: "ext app storage",
                        value: EFilePathOption.EXT_APP_STORAGE_DIR
                    },
                    s7: {
                        name: "ext data",
                        value: EFilePathOption.EXT_DATA_DIR
                    },
                    s8: {
                        name: "ext root data",
                        value: EFilePathOption.EXT_ROOT_DIR
                    },
                    s9: {
                        name: "shared",
                        value: EFilePathOption.SHARED_DIR
                    },
                    s10: {
                        name: "temp",
                        value: EFilePathOption.TEMP_DIR
                    }
                }
            },
            fileSavePath: {
                name: "File save path",
                value: EFilePathOption.EXT_ROOT_DIR,
                iosValue: EFilePathOption.DATA_DIR,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.savePath,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 42
                },
                type: ESettingsType.select,
                options: {
                    s1: {
                        name: "app",
                        value: EFilePathOption.APP_DIR
                    },
                    s2: {
                        name: "app storage",
                        value: EFilePathOption.APP_STORAGE_DIR
                    },
                    s3: {
                        name: "cache",
                        value: EFilePathOption.CACHE_DIR
                    },
                    s4: {
                        name: "data",
                        value: EFilePathOption.DATA_DIR
                    },
                    s5: {
                        name: "doc",
                        value: EFilePathOption.DOC_DIR
                    },
                    s6: {
                        name: "ext app storage",
                        value: EFilePathOption.EXT_APP_STORAGE_DIR
                    },
                    s7: {
                        name: "ext data",
                        value: EFilePathOption.EXT_DATA_DIR
                    },
                    s8: {
                        name: "ext root data",
                        value: EFilePathOption.EXT_ROOT_DIR
                    },
                    s9: {
                        name: "shared",
                        value: EFilePathOption.SHARED_DIR
                    },
                    s10: {
                        name: "temp",
                        value: EFilePathOption.TEMP_DIR
                    }
                }
            }
        }
    };

    constructor(

    ) {

    }
}
