import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'dynamic-grid',
  templateUrl: './dynamic-grid.component.html',
  styleUrls: ['./dynamic-grid.component.scss'],
})
export class DynamicGridComponent implements OnInit, OnChanges {
  @Input()
  type: string;
  @Input()
  data: any[];
  @Input()
  emptyMessage: string;
  @Output()
  action: EventEmitter<any> = new EventEmitter();
  @Input()
  rowItems: number;
  @Input()
  blanks: boolean;
  @Input()
  update: boolean;

  availableItems: boolean = false;
  loaded: boolean = false;

  itemGrid: Array<Array<any>>;
  rowSpec: number = 2;

  constructor(

  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.updateItemGrid(this.data);
            break;
          case 'update':
            this.updateItemGrid(this.data);
            break;
        }
      }
    }
  }

  itemAction(item: any) {
    console.log("dynamic grid item action: ", item);
    this.action.emit(item);
  }

  ngOnInit() {
    // console.log("dynamic grid on init data: ", this.data);
    if (this.rowItems != null) {
      this.rowSpec = this.rowItems;
    }

    if (this.data) {
      setTimeout(() => {
        this.updateItemGrid(this.data);
      }, 1);
    }
  }

  /**
   * update item grid for display
   * @param category 
   */
  updateItemGrid(items: any[]) {
    // console.log("update item grid: ", items);
    let rowNum: number = 0; // counter to iterate over the rows in the grid

    // console.log(DeepCopy.deepcopy(items));
    this.availableItems = items && (items.length > 0);

    this.loaded = true;

    if (!this.availableItems) {
      // console.log("no available items");
      return;
    }

    this.itemGrid = Array(Math.ceil(items.length / this.rowSpec));

    // add rows
    for (let i = 0; i < items.length; i += this.rowSpec) {
      this.itemGrid[rowNum] = Array(this.rowSpec); // declare two elements per row
      // add items
      for (let j = 0; j < this.rowSpec; j++) {
        if (items[i + j]) {
          this.itemGrid[rowNum][j] = items[i + j];
        } else {
          if (this.blanks) {          
            this.itemGrid[rowNum][j] = null;
          } else {
            this.itemGrid[rowNum].splice(j);
          }
        }
      }

      rowNum++; // go on to the next row
    }

    console.log("update item grid:");
    console.log(this.itemGrid);
  }
}
