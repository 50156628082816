
import { IHereMapsItem } from "../../classes/def/places/here";
import { IPlaceExtContainer } from "../../classes/def/places/container";
import { EPlaceUnifiedSource } from "../../classes/def/places/provider";
import { ELocationFlag } from "../../classes/def/places/app-location";

export class LocationUtilsHere {
    static load(place: IHereMapsItem) {
        let puc: IPlaceExtContainer = {
            providerCode: EPlaceUnifiedSource.here,
            type: place.category.type,
            flag: ELocationFlag.RANDOM,
            name: place.title,
            id: null,
            googleId: place.id,
            photoUrl: null,
            isGenericPhotoLoaded: false,
            rating: place.averageRating,
            lat: place.position[0],
            lng: place.position[1],
            reviewCount: 0,
            aux: {
                photoUrlFn: null,
                photos: []
            }
        };
        return puc;
    }
}
