<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title" (rightClick)="cancel()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">

  <div class="theme-border-bottom center-text">
    <ion-row>
      <ion-col size="12">
        <ion-input [(ngModel)]="amount" ngDefaultControl type="number" [max]="max" min="min"
          class="text-color-primary ion-input-center">
        </ion-input>
      </ion-col>
    </ion-row>
  </div>

  <div fxFlex>
  </div>

  <div class="stick-bottom margin-s">
    <ion-row class="stick-bottom">
      <ion-col size="3">
        <ion-button class="action-button-fill button-color-alternate" (click)="decreaseAmount()">
          <icon-wrapper slot="icon-only" icon="arrow-down"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button class="action-button-fill button-color-accent" (click)="ok()">
          <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="3">
        <ion-button class="action-button-fill button-color-alternate" (click)="increaseAmount()">
          <icon-wrapper slot="icon-only" icon="arrow-up"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

</modal-container>