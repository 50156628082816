

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HTTPErrorHandlerService {

    constructor(

    ) {
        console.log("http error handler service created");
    }

    /**
     * handle http error messages
     * @param error 
     */
    handleHttpError(error: HttpErrorResponse) {
        let errorMessage: any = "Unexpected error";
        console.error(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            errorMessage = error.error.message;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            // console.log(error.status, " ", error.error);

            // error contains (error) response body
            // e.g. {status: false, message: "Server error"}

            if (error.error && error.error.status != null) {
                errorMessage = error.error.message;
            }
            // console.log(error.error);
            // console.log(error.status);
            // console.log(error.message);
        }
        // return an observable with a user-facing error message
        return errorMessage;
    }

}
