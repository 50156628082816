import { Component, Input, OnInit } from '@angular/core';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IBusinessDataExt } from 'src/app/classes/def/business/business';

@Component({
  selector: 'registered-place',
  templateUrl: './registered-place.component.html',
  styleUrls: ['./registered-place.component.scss'],
})
export class RegisteredPlaceComponent implements OnInit {

  @Input()
  item: IBusinessDataExt;

  leplaceId: string = "";
  name: string = "";
  type: string = "";
  icon: string = EAppIconsStandard.place;

  appIconsStandard = EAppIconsStandard;

  constructor() { }

  ngOnInit() {
    if (this.item && this.item.location) {
      this.leplaceId = "LP" + this.item.locationId;
      this.name = this.item.location.name;
      this.type = this.item.location.type;      
    }
  }

}
