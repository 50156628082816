import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IStoryExtended, IStory, IStoryResponse, IStoryUnlock, IStoryPhoto, IBookingProvider, IStoryProduct } from 'src/app/classes/def/core/story';
import { LatLng } from '@ionic-native/google-maps/ngx';
import { IAppLocation } from 'src/app/classes/def/places/app-location';
import { IonContent, IonHeader, ModalController, Platform } from '@ionic/angular';
import { INavParams, IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages, ETutorialEntries } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { EStandardCode, EStandardStoryCode } from 'src/app/classes/def/core/activity';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { ICheckStoryProviders, StoryUtils } from 'src/app/classes/utils/story-utils';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { IBackendLocation, ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { GameStatsService } from 'src/app/services/app/utils/game-stats';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard, EAppIcons, EImgIcons } from 'src/app/classes/def/app/icons';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QRFrameViewComponent } from 'src/app/modals/generic/modals/qr-frame/qr-frame.component';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { BusinessDataService } from 'src/app/services/data/business';
import { EStoryReleaseFlag, EStoryUnlock, EStoryUnlockType } from 'src/app/classes/def/business/story';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { IMarketplaceBrowseNavParams, IMarketplaceDetailNavParams } from 'src/app/classes/def/nav-params/marketplace';
import { MarketplaceDataService } from 'src/app/services/data/marketplace';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ITutorial } from 'src/app/classes/def/app/tutorials';
import { FileManagerService } from 'src/app/services/media/file';
import { IQRFrameCallbackData, IQRCodeSlide, IQRFrameParams, StoryQRUtils } from 'src/app/classes/utils/story-qr-utils';
import { PlacesDataService } from 'src/app/services/data/places';
import { Util } from 'src/app/classes/general/util';
import { IFaqCategory } from 'src/app/classes/def/support/support';
import { EFAQCategories, SupportDataService } from 'src/app/services/data/support';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { PaymentStripeDataService } from 'src/app/services/data/payment-stripe';
import { EmailFormatUils } from 'src/app/services/utils/email-format';
import { IUnlockContentCheckoutParams, UnlockContentCheckoutComponent } from 'src/app/modals/app/modals/unlock-content-checkout/unlock-content-checkout.component';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { EDroneMode } from 'src/app/services/data/activities';
import { IDBModelCountry } from 'src/app/classes/def/world/world';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { IDistFlags } from 'src/app/classes/def/app/app';
import { ICustomFiltersContainer } from 'src/app/classes/def/app/filters';
import { ESettingsType } from 'src/app/classes/def/app/settings';
import { CustomFormViewComponent } from 'src/app/modals/generic/modals/custom-form/custom-form.component';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { Title } from "@angular/platform-browser";

interface IUnlockCodeSpecDict {
  searchShort: IUnlockCodeSpec,
  search: IUnlockCodeSpec,
  unlockShort: IUnlockCodeSpec,
  unlock: IUnlockCodeSpec,
  unlockPartner: IUnlockCodeSpec,
  redeem: IUnlockCodeSpec,
  redeemShort: IUnlockCodeSpec
}

interface IUnlockCodeSpec {
  name: string,
  input: boolean,
  length: number,
  code: string,
  show: boolean,
  isQR: boolean
}

@Component({
  selector: 'app-marketplace-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MarketplaceContentPage implements OnInit, OnDestroy {
  @ViewChild("header", { read: IonHeader, static: false }) header: HTMLElement;
  @ViewChild("content", { read: IonContent, static: false }) content: HTMLElement;

  website: string = AppConstants.links.website;
  testFlightUrl: string = AppConstants.links.testFlightUrl;
  appStoreUrl: string = AppConstants.links.appStoreUrl;
  playStoreUrl: string = AppConstants.links.playStoreUrl;
  webUrl: string = AppConstants.links.webUrl;

  storyExt: IStoryExtended;
  story: IStory;
  description: string;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  imgIcons = EImgIcons;

  storyId: number = 0;
  title: string = "Storyline";
  loaded: boolean = false;
  loadedPage: boolean = false;
  saved: boolean = false;
  storyLoaded: boolean = false;
  reload: boolean = true;
  locationURL: string = null;
  useDefaultLocationPhotos: boolean = true;
  params: IMarketplaceDetailNavParams;
  coords: LatLng;
  startButtonText: string = "Start";
  showState: string = "inactive";
  timeouts = {
    init: null
  };
  theme: string = "theme-light theme-light-bg";
  mapStyleName: string = null;
  appLocations: IAppLocation[] = [];
  photoUrl: string = "";
  photoLoaded: boolean = false;
  animate: boolean = true;

  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = true;
  fromMarket: boolean = false;

  potentialXp: number = 0;
  levelDisp: string = "";
  unlockInProgress: boolean = false;
  zoomState: boolean = false;

  categoryName: string = "";

  platform: IPlatformFlags = {} as IPlatformFlags;

  tutorialEmbed: string;
  tutorialEmbedPDFLink: any;
  tutorialPreviewLink: string;

  infoText = {
    reviewContact: null,
    howItWorks: null,
    reviewContactIcon: null,
    howItWorksIcon: null,
    appNotes: null,
    webNotes: null,
    testFlightNotes: null,
    checkoutNotes: null,
    unlockNotes: null,
    findStoryNotes: null,

    appStoreNotes: null,
    playStoreNotes: null
  };

  isDev: boolean = false;
  isBeta: boolean = false;

  distInit: IDistFlags = {
    android: false,
    ios: false,
    mobile: true,
    web: true
  };

  dist: IDistFlags = {

  } as IDistFlags;

  hasProviders: boolean = false;
  storyProviders: IBackendLocation[] = [];
  hasStoryProducts: boolean = false;
  storyProducts: IStoryProduct[] = [];

  activationCodes: IUnlockCodeSpecDict = {
    search: null,
    searchShort: null,
    unlock: null,
    unlockShort: null,
    unlockPartner: null,
    redeem: null,
    redeemShort: null
  };

  activationCodesList: IUnlockCodeSpec[] = [];
  activationCodesQRList: IUnlockCodeSpec[] = [];

  hasActivationCodes: boolean = false;
  hasActivationQRCodes: boolean = false;
  hasRedeemQRCodes: boolean = false;

  partnerUnlock: boolean = false;
  linkPreview: boolean = true;

  token: string = null;
  unlockCodeGranted: string = null;

  extDataLoaded: boolean = false;
  faqCategories: IFaqCategory[];

  isPremium: boolean = false;
  isTestFlightOnly: boolean = false;

  tutorialVideoUrl: string = null;
  tutorialVideoExtUrlMobile: string = null;

  showActivationGuide: boolean = false;

  // appDetails: IAppVersionDB = null;

  isCheckout: boolean = false;
  enableCheckout: boolean = false;
  noCheckout: boolean = false;
  isQRTracking: boolean = false;
  isTracking: boolean = false;
  isVanityTracking: boolean = false;
  alternativePrice: string = "";
  alternativePricePromo: string = "";

  storyPhotos: IStoryPhoto[] = null;

  defaultUnlock: string = "UNLOCK";
  buyMoreLabel: string = "<buy more>";


  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public marketplaceDataProvider: MarketplaceDataService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public plt: Platform,
    public analytics: AnalyticsService,
    public backButton: BackButtonService,
    public resourcesProvider: ResourcesCoreDataService,
    public settingsProvider: SettingsManagerService,
    public businessData: BusinessDataService,
    public modalCtrl: ModalController,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public gameStats: GameStatsService,
    public popupFeatures: PopupFeaturesService,
    public domSanitizer: DomSanitizer,
    public fileManager: FileManagerService,
    public placesData: PlacesDataService,
    public element: ElementRef,
    public renderer: Renderer2,
    public support: SupportDataService,
    public paymentStripe: PaymentStripeDataService,
    public meta: Meta,
    public el: ElementRef,
    public titleService: Title
  ) {
    // customize default values of carousels used by this component tree
    // carouselConfig.interval = 2000;
    // carouselConfig.keyboard = true;
    // carouselConfig.pauseOnHover = true;
    this.resetDistFlags();
  }

  // in my case i'm using ionViewWillEnter
  ionViewWillEnter() {
    console.log("will enter");
    if (!this.isModal) {
      this.renderer.setStyle(this.header['el'], 'webkitTransition', 'top 300ms');
      this.renderer.setStyle(this.content['el'], 'webkitTransition', 'top 300ms');
    }
  }

  onContentScroll(event) {
    if (!this.isModal) {
      let target = event.target;
      let offsetHeader: number = 56;
      let offsetContent: number = 26;
      if (target.scrollTop >= 50) {
        this.renderer.setStyle(this.header['el'], 'top', '-' + offsetHeader + 'px');
        this.renderer.setStyle(this.content['el'], 'top', '-' + offsetContent + 'px');
      } else {
        this.renderer.setStyle(this.header['el'], 'top', '0px');
        this.renderer.setStyle(this.content['el'], 'top', '0px');
      }
    }
  }

  scrollTo(sectionId: string) {
    const section = this.el.nativeElement.querySelector(`#${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    // ScrollUtils.scrollIntoView(null, this.el.nativeElement.querySelector(`#${sectionId}`), null, 30);
  }

  updatePageMetadata() {
    // this.meta.updateTag({ property: 'og:title', content: 'YOUR_TITLE' });
    // this.meta.updateTag({ property: 'og:image', content: 'YOUR_IMAGE' });
    this.meta.updateTag({ property: 'og:url', content: 'https://connect.leplace.online/storyline?storyId=' + this.storyId + "&token=" + this.token });
  }

  updatePageTitle(title: string) {
    this.titleService.setTitle("LP Connect | " + title);
    // this.timeouts.init = setTimeout(() => {
    //   this.titleService.setTitle("LP Connect | " + title);
    // }, 1000);
  }

  /**
   * load aux data (e.g., tutorials)
   */
  async loadExtData() {
    try {
      let category: number = EFAQCategories.stories;
      if (this.story.droneOnly === EDroneMode.onlyDrone) {
        category = EFAQCategories.storiesVirtual;
      }

      let cats: IFaqCategory[] = await this.support.getFaqV2([category, EFAQCategories.app]);
      this.faqCategories = cats;
      this.extDataLoaded = true;
      // cache all tutorials before loading
      await this.resourcesProvider.getInfoDataMultiCache([
        ETutorialEntries.reviewContact,
        ETutorialEntries.howItWorks,
        ETutorialEntries.checkoutNote,
        ETutorialEntries.activateStoryTutorialEn,
        ETutorialEntries.storylineTutorialVideo,
        ETutorialEntries.appNotes,
        ETutorialEntries.testFlightNotes,
        ETutorialEntries.webNotes,
        ETutorialEntries.checkoutNotes,
        ETutorialEntries.unlockNotes,
        ETutorialEntries.findStoryNotes], true);

      let tutorial: ITutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.reviewContact, true);
      this.infoText.reviewContact = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);
      this.infoText.reviewContactIcon = tutorial.photoUrl;

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.howItWorks, true);
      this.infoText.howItWorks = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);
      this.infoText.howItWorksIcon = tutorial.photoUrl;

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.appNotes, true);
      this.infoText.appNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.testFlightNotes, true);
      this.infoText.testFlightNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.webNotes, true);
      this.infoText.webNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.checkoutNotes, true);
      this.infoText.checkoutNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.unlockNotes, true);
      this.infoText.unlockNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.findStoryNotes, true);
      this.infoText.findStoryNotes = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);

      this.infoText.appStoreNotes = "<p>App Store release is not available in your country. Please use TestFlight or web app following the instructions below.</p>";
      this.infoText.playStoreNotes = "<p>Play Store release is not officially available in your country. Please use the web app instead.</p>"

      // tutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.howItWorks, true);
      // this.howItWorks = this.domSanitizer.bypassSecurityTrustHtml(tutorial.description);
      // this.howItWorksIcon = tutorial.photoUrl;
      // let data: IAppVersionDB = await this.resourcesProvider.getAppDetailsFromServer(EAppId.connect);
      // this.appDetails = data;
    } catch (err) {
      this.analytics.dispatchError(err, "content");
      console.error(err);
    }
  }

  ngOnInit() {
    this.updatePageMetadata();
    this.analytics.trackView("content");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.marketplaceContent, null, this.np);
      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;

      let hasParams: boolean = npInfo.hasParams;
      let promiseParams: Promise<boolean> = new Promise((resolve) => {
        if (hasParams) {
          console.log("has params: ", npar);
          let np: INavParams = npar;
          if (np && np.params) {
            this.params = np.params;
            // params already in object form
          }
          this.vs = np.view;
          this.isModal = this.vs ? this.vs.isModal : false;
          this.linkPreview = this.params.standalone ? true : false;
          this.fromMarket = this.params.fromMarket;
          // remove temp params for next reload
          this.removeTempParams();
          resolve(true);
        } else {
          this.storyId = 0;
          this.isModal = false;
          this.route.queryParams.subscribe((params: Params) => {
            if (params) {
              let data: IMarketplaceDetailNavParams = params as any;
              this.params = Object.assign({}, data);
              // params in stringified form to object form
              this.params.storyId = Number.parseInt(this.params.storyId as any);
              this.params.checkout = this.params.checkout as any === "true";
              this.params.nocheckout = this.params.nocheckout as any === "true";
              this.params.qr = this.params.qr as any === "true";
              this.params.var = this.params.var as any === "true";
              this.linkPreview = true;
              console.log("parsed params: ", this.params);
              // remove temp params for next reload
              this.removeTempParams();
            }
            resolve(true);
          });
        }
      });
      promiseParams.then(() => {
        console.log("loading params: ", this.params);
        this.loadedPage = true;
        this.storyId = this.params.storyId;
        this.reload = this.params.reload;
        this.token = this.params.token;
        this.unlockCodeGranted = this.params.unlock;
        this.isCheckout = this.params.checkout;
        this.noCheckout = this.params.nocheckout;
        this.isQRTracking = this.params.qr;
        this.isTracking = this.params.notrack ? false : true;
        this.isVanityTracking = this.params.var;
        // can be opened either as a modal or a root page
        this.plt.ready().then(() => {
          this.backButton.pushOrReplace(() => {
            this.back();
          }, this.vs);
        }).catch((err: Error) => {
          console.error(err);
        });

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
          if (loaded) {
            this.platform = SettingsManagerService.settings.platformFlags;
          }
        }, (err: Error) => {
          console.error(err);
        });

        this.settingsProvider.getSettingsLoaded(false).then((res) => {
          if (res) {
            this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
            this.loadAll();
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  toggleCheckout() {
    this.noCheckout = !this.noCheckout;
  }

  removeTempParams() {
    // remove temp params for next reload
    const [path, query] = this.location.path(false).split('?');
    console.log("pathname", path);
    const qparams = new HttpParams({ fromString: query });
    console.log("qparams: ", qparams);
    this.location.replaceState(path, qparams.delete('qr').toString());
    this.location.replaceState(path, qparams.delete('var').toString());
  }

  async loadAll() {
    try {
      // load story data
      await this.loadStoryWrapper(false);
      await this.loadExtData();
      // this.loadTutorialEmbed();
      this.loadTutorialPDF();
      this.loadTutorialVideoLink();
      if (this.isCheckout) {
        let tutorial: ITutorial = await this.resourcesProvider.getInfoData(ETutorialEntries.checkoutNote, true);
        let params: IUnlockContentCheckoutParams = {
          title: tutorial.title,
          description: tutorial.description,
          descriptionFooter: tutorial.descriptionIos,
          iconDescription: tutorial.photoUrl,
          iconFooter: tutorial.photoUrlIos,
          unlockCode: this.unlockCodeGranted
        };
        let res: number = await this.uiext.showCustomModal(null, UnlockContentCheckoutComponent, {
          view: {
            fullScreen: false,
            transparent: AppConstants.transparentMenus,
            large: true,
            addToStack: true,
            frame: false
          },
          params: params
        });
        console.log(res);
        this.checkUnlockCodes(this.story, this.unlockCodeGranted);
      }

      let isTracking: boolean = this.isTracking;
      if (this.isQRTracking) {
        console.log("is QR tracking");
        isTracking = true;
        this.businessData.trackScanQR(this.storyId, this.token).then(() => {
          console.log("QR code tracked");
        }).catch((err: Error) => {
          console.error(err);
        });
      }
      if (this.isVanityTracking) {
        console.log("is vanity tracking");
        isTracking = true;
        this.businessData.trackScanVar(this.storyId, this.token).then(() => {
          console.log("vanity link tracked");
        }).catch((err: Error) => {
          console.error(err);
        });
      }
      if (isTracking) {
        console.log("is tracking");
        this.businessData.trackView(this.storyId, this.token).then(() => {
          console.log("view tracked");
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    }
  }

  ngOnDestroy() {
    this.clearTimers();
    this.titleService.setTitle("LP Connect");
    this.backButton.checkPop(this.vs);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }


  loadTutorialEmbed() {
    this.marketplaceDataProvider.requestEmbedTutorial("pages/tutorial/leplace_booktes_tutorial.html").then((resp: IGenericResponse) => {
      if (resp && resp.data) {
        this.tutorialEmbed = resp.data.html;
        console.log("tutorial embed: ", this.tutorialEmbed);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    });
  }

  loadTutorialPDF() {
    this.resourcesProvider.getInfoData(ETutorialEntries.activateStoryTutorialEn, true).then((tutorial: ITutorial) => {
      // this.tutorialEmbedPDFLink = this.domSanitizer.bypassSecurityTrustResourceUrl(tutorial.description);
      this.tutorialPreviewLink = tutorial.photoUrl;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    });
  }

  loadTutorialVideoLink() {
    this.resourcesProvider.getInfoData(ETutorialEntries.storylineTutorialVideo, true).then((tutorial: ITutorial) => {
      // this.tutorialEmbedPDFLink = this.domSanitizer.bypassSecurityTrustResourceUrl(tutorial.description);
      this.tutorialVideoUrl = tutorial.description;
      this.tutorialVideoExtUrlMobile = tutorial.photoUrlIos;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    });
  }

  downloadTutorial() {
    this.resourcesProvider.getInfoData(ETutorialEntries.activateStoryTutorialEn, true).then((tutorial: ITutorial) => {
      let tutorialEmbedPDFLink: string = tutorial.description;
      this.fileManager.downloadAsFileViaBrowserLocal("tutorial.pdf", tutorialEmbedPDFLink);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    });
  }


  loadStoryWrapper(unlock: boolean) {
    let promise = new Promise((resolve) => {
      if (unlock && this.unlockInProgress) {
        console.warn("unlock already in progress");
        resolve(null);
        return;
      }
      this.unlockInProgress = true;
      this.loadStoryFromSource(this.storyId).then(() => {
        this.unlockInProgress = false;
        if (this.animate) {
          setTimeout(() => {
            this.showState = 'active';
          }, 100);
        }
        resolve(true);
      }).catch((err: Error) => {
        this.unlockInProgress = false;
        this.analytics.dispatchError(err, "content");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        resolve(null);
      });
    });
    return promise;
  }

  /**
   * load story from the backend or local storage
   * @param storyId 
   */
  loadStoryFromSource(storyId: number) {
    console.log("load story: " + storyId);
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      if (this.token == null) {
        this.showTokenNotFoundPopup();
        resolve(false);
        return;
      }
      this.marketplaceDataProvider.getStory(storyId, this.coords, this.token).then((data: IStoryResponse) => {
        this.loadStoryCore(data.story);
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.loaded = true;
        reject(err);
      });
    });
    return promise;
  }

  showTokenNotFoundPopup() {
    let params: ICustomFiltersContainer = {
      title: "Content access error",
      filters: [
        {
          type: ESettingsType.labelOnly,
          label: "Description",
          description: "<p>Required token not found.</p><p>This might happen if you received the message through an encoded channel (such as a message from a booking platform) or because your browser or app that you used to open the page did not support it.</p><p>Please make sure to open this page in a dedicated web browser (Chrome, Safari, etc.). Copy and paste the original URL into your browser to continue. If that doesn't work, check for the original URL that might be hidden in the message that you received from the booking partner.</p>",
          enabled: true
        },
        {
          type: ESettingsType.inputText,
          label: "Original URL detected",
          value: GeneralCache.initURL,
          enabled: true
        }
      ],
      create: true
    };
    this.uiext.showCustomModal(null, CustomFormViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: false,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: ICustomFiltersContainer) => {
      if (res != null && res.create) {
        this.popupFeatures.copyToClipboard(GeneralCache.initURL);
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  getStandardPhoto() {
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.story + EStandardStoryCode.locked).then((photoUrl: string) => {
      this.photoUrl = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  loadStoryCore(story: IStory) {
    if (story !== null) {

      let levels: boolean[] = [];
      if (!story.islocal) {
        for (let i = 1; i <= 5; i++) {
          if (i <= story.level) {
            levels.push(true);
          } else {
            levels.push(false);
          }
        }
      }

      this.storyExt = {
        story: story,
        storyProgress: null,
        photoUrl: null,
        color: null,
        hide: false,
        show: true,
        levels: levels,
        large: false,
        modeIcon: null,
        modeText: null
      };

      this.storyExt.modeIcon = StoryUtils.getModeIcon(story);
      // this.storyExt.modeText = StoryUtils.getModeText(story);

      // show actual category (might open from global category)
      if (this.storyExt && this.storyExt.story && this.storyExt.story.categoryName) {
        this.categoryName = this.storyExt.story.categoryName.toUpperCase();
      }

      let useDefaultPhoto: boolean = SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value;

      if (this.useDefaultLocationPhotos) {
        // override settings, use only default (challenge) photos here
        useDefaultPhoto = true;
      }

      this.appLocations = this.storyExt.story.locs.map((sl: ILocationContainer) => {
        sl.merged.photoUrlSmall = LocationUtils.selectPlaceDispPhoto(sl, null, {
          hidden: useDefaultPhoto ? true : null
        });

        let appLoc: IAppLocation = {
          loc: sl,
          flag: 0,
          dispDone: false
        };
        return appLoc;
      });

      this.story = this.storyExt.story;
      this.description = this.story.listingDescription != null ? this.story.listingDescription : this.story.description;

      if (this.story.storyPhotos && this.story.storyPhotos.length > 0) {
        this.storyPhotos = this.story.storyPhotos;
      }

      this.potentialXp = this.gameStats.getStoryFinishedWeight(this.story);
      this.levelDisp = "Level " + this.story.level + " " + this.categoryName;

      if (this.potentialXp) {
        this.levelDisp += " (" + this.potentialXp + " XP)";
      }

      this.photoUrl = story.photoUrl;


      let res: ICheckStoryProviders = StoryUtils.checkLoadProvidersWizard(this.story, () => { return this.loadProviders(); });
      this.hasProviders = res.hasProviders;
      this.storyProviders = res.providers;
      this.loadBookingProviders();

      this.checkUnlockCodes(story, this.unlockCodeGranted);
      this.checkDefaultAppStoreURLOverrides();
      this.checkDefaultPriceIAPOverrides();

      this.isBeta = story.devOnly === EStoryReleaseFlag.unlisted;
      this.isDev = story.devOnly === EStoryReleaseFlag.devOnly;
      this.isPremium = this.story.itemIapCode != null;

      this.resetDistFlags();

      if (this.story.city && this.story.city.country) {
        let country: IDBModelCountry = this.story.city.country;
        this.dist.android = country.enableStoreAndroid !== 0;
        this.dist.ios = country.enableStoreIos !== 0;
        if (!this.dist.android && !this.dist.ios) {
          this.dist.mobile = false;
        }
      }

      if (this.isPremium) {
        this.enableCheckout = true;
      }

      let disp = {
        isPremium: this.isPremium,
        enableCheckout: this.enableCheckout,
        releaseOnly: this.isTestFlightOnly
      };

      this.isTestFlightOnly = this.dist.mobile && !this.dist.ios;

      this.updatePageTitle(this.story.name);

      console.log("content distribution: ", disp);
      this.storyLoaded = true;
    }
  }

  resetDistFlags() {
    this.dist = Object.assign({}, this.distInit);
  }

  checkDefaultPriceIAPOverrides() {
    if (!(this.story.itemIap != null && this.story.defaultPrice != null && this.story.currency != null)) {
      return;
    }
    this.alternativePrice = this.checkPriceIAPOverrides(this.story.itemIap, this.story.defaultPrice);
    if (!(this.story.itemIapPromo != null && this.story.promoPrice != null && this.story.currency != null)) {
      return;
    }
    this.alternativePricePromo = this.checkPriceIAPOverrides(this.story.itemIapPromo, this.story.promoPrice);
  }

  checkPriceIAPOverrides(itemIap: IGameItemIAP, overridePrice: string) {
    // let defaultPriceOverride: string = overridePrice + " " + this.story.currency.name;
    let priceInteger: number = Math.floor(Number.parseFloat(overridePrice) * AppConstants.gameConfig.externalPaymentDeduction * 100) / 100; // account for app store fee deduction  
    let defaultPriceStripe: string = "" + priceInteger + " " + this.story.currency.name;
    // itemIap.price = defaultPriceOverride;
    // itemIap.priceIos = defaultPriceOverride;
    return defaultPriceStripe;
  }

  checkDefaultAppStoreURLOverrides() {
    if (!(this.story.city != null && this.story.city.country != null && this.story.city.country.appStoreUrl != null)) {
      return;
    }
    this.appStoreUrl = this.story.city.country.appStoreUrl;
  }


  checkUnlockCodes(story: IStory, redeemCode: string) {
    let unlock: IStoryUnlock[] = story.storyUnlocks;
    // console.log("story unlocks: ", unlock);

    let hasActivationCodes: boolean = false;
    let hasActivationQRCodes: boolean = false;
    let hasRedeemQRCodes: boolean = false;

    let checkUnlockCode = (type: number, context: IUnlockCodeSpec, label: string, isQR: boolean) => {
      let uc: IStoryUnlock = unlock.find(u => u.enabled && u.type === type);
      if (uc != null) {
        hasActivationCodes = true;
        if (isQR) {
          hasActivationQRCodes = true;
        }
        context.name = label;
        context.length = uc.unlockCode.length;
        context.code = uc.unlockCode;
        context.show = true;
        context.isQR = isQR;
      }
    };

    let checkRedeemUnlockCode = (context: IUnlockCodeSpec, label: string, code: string, isQR: boolean) => {
      hasActivationCodes = true;
      if (isQR) {
        hasActivationQRCodes = true;
        hasRedeemQRCodes = true;
      }
      context.name = label;
      context.length = code.length;
      context.code = code;
      context.show = true;
      context.isQR = isQR;
    };

    for (let key of Object.keys(this.activationCodes)) {
      this.activationCodes[key] = {
        name: "",
        input: true,
        length: 0,
        code: null,
        show: false,
        isQR: false
      } as IUnlockCodeSpec;
    }

    checkUnlockCode(EStoryUnlockType.searchOnlyShort, this.activationCodes.searchShort, "Search Code (input)", false);
    checkUnlockCode(EStoryUnlockType.short, this.activationCodes.unlockShort, "Unlock Code (input)", false);
    checkUnlockCode(EStoryUnlockType.searchOnly, this.activationCodes.search, "Search Code (QR)", true);
    checkUnlockCode(EStoryUnlockType.branded, this.activationCodes.unlock, "Unlock Code (QR)", true);
    checkUnlockCode(EStoryUnlockType.partner, this.activationCodes.unlockPartner, "Unlock Code - Partner (QR)", true);

    if (redeemCode != null) {
      if (redeemCode.length === AppConstants.gameConfig.unlockCodeLength) {
        checkRedeemUnlockCode(this.activationCodes.redeemShort, "Unlock Code - Redeem", redeemCode, false);
      } else {
        checkRedeemUnlockCode(this.activationCodes.redeem, "Unlock Code - Redeem (QR)", redeemCode, true);
      }
    }

    if (story.partnerUnlock) {
      this.partnerUnlock = true;
      this.activationCodes.unlock.show = false;
      this.activationCodes.unlockShort.show = false;
    }

    if (hasActivationQRCodes || hasRedeemQRCodes) {
      this.activationCodes.search.show = false;
      this.activationCodes.searchShort.show = false;
    }

    this.activationCodesList = [];
    this.activationCodesQRList = [];

    hasActivationCodes = false;
    hasActivationQRCodes = false;

    for (let key of Object.keys(this.activationCodes)) {
      let ac: IUnlockCodeSpec = this.activationCodes[key];
      if (ac.show && ac.code != null) {
        hasActivationCodes = true;
        if (ac.isQR) {
          hasActivationQRCodes = true;
          this.activationCodesQRList.push(ac);
        } else {
          this.activationCodesList.push(ac);
        }
      }
    }

    this.hasActivationCodes = hasActivationCodes;
    this.hasActivationQRCodes = hasActivationQRCodes;
    this.hasRedeemQRCodes = hasRedeemQRCodes;

    console.log("list: ", this.activationCodesList)
    console.log("list - QR:", this.activationCodesQRList);
    console.log("has activation codes: ", hasActivationCodes, hasActivationQRCodes, hasRedeemQRCodes);

  }

  loadProviders() {
    this.storyProviders = [];
    this.placesData.viewStoryProviders(this.story.id).then((places: ILocationContainer[]) => {
      if (places && places.length) {
        for (let i = 0; i < places.length; i++) {
          this.storyProviders.push(places[i].db);
        }
        this.hasProviders = true;
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  loadBookingProviders() {
    this.storyProducts = this.story.storyProducts;
    setTimeout(() => {
      if (this.storyProducts && this.storyProducts.length > 0) {
        this.hasStoryProducts = true;
      } else {
        this.hasStoryProducts = false;
      }
    }, 1);
  }

  clearTimers() {
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
  }

  /**
   * back to story list
   * @param animate 
   */
  back() {
    if (this.isModal) {
      this.dismissModal();
    } else {
      let navParams: INavParams = {
        view: null,
        params: null
      };
      if (this.fromMarket) {
        this.nps.set(ENavParamsResources.marketplaceBrowse, navParams);
        this.router.navigate([ERouteDef.marketplace], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      } else {
        this.nps.set(ENavParamsResources.home, navParams);
        this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }
  }

  /**
   * return from modal
   * load story flag shows if the map should load the current story
   * @param animate 
   * @param loadStory 
   */
  dismissModal() {
    let params: IMarketplaceBrowseNavParams = {
      storyId: this.storyId,
      reload: false,
      token: this.token
    };
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      reload: {
        name: "Reload",
        code: 2,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
      // return: {
      //   name: "Home",
      //   code: 1,
      //   icon: EAppIconsStandard.home,
      //   enabled: true
      // }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 1:
          this.back();
          break;
        case 2:
          this.reload = true;
          this.loadAll();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  showPopup() {
    let description: string = "";
    if (this.storyExt) {
      description = this.storyExt.story.description;
    }
    this.uiextStandard.showStandardModal(null, EModalTypes.description, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { description: description }
    }).then(() => {

    }).catch((err: Error) => {
      console.error(err);

    });
  }

  handleQRSliderCallback(buttonCode: number, mode: number, _unlockCodeCrt: string): Promise<IQRFrameCallbackData> {
    return new Promise<IQRFrameCallbackData>((resolve) => {
      let res: IQRFrameCallbackData = {
        qrCode: null,
        dismiss: false
      };
      switch (buttonCode) {
        case 1:
          resolve(null);
          break;
        case 2:
          this.requestEmbedQR(mode);
          resolve(res);
          break;
        default:
          resolve(res);
          break;
      }
    });
  }

  hasQRCode(): boolean {
    return this.story.qrUnlock != null && this.story.qrUnlock !== EStoryUnlock.none;
  }

  showQR() {
    console.log("show qr");

    if (!this.story.qrUnlock) {
      this.uiext.showAlertNoAction(Messages.msg.qrCodeNotRegistered.after.msg, Messages.msg.qrCodeNotRegistered.after.sub);
      return;
    }

    let qrSlides: IQRCodeSlide[] = StoryQRUtils.getQRSlides(this.story, (buttonCode: number, mode: number, unlockCodeCrt: string) => this.handleQRSliderCallback(buttonCode, mode, unlockCodeCrt));

    let params: IQRFrameParams = {
      title: StringUtils.trimName(this.story.name, EMessageTrim.storyNameTitle),
      description: "Scan / paste to unlock",
      qs: qrSlides
    };

    this.uiext.showCustomModal(null, QRFrameViewComponent, {
      view: {
        fullScreen: true, // full screen only!
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((res: number) => {
      console.log(res);
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  requestEmbedQR(mode: number) {
    this.businessData.requestEmbedQR(this.storyId, mode).then((res: any) => {
      let embedCode: string = res.html as string;
      this.popupFeatures.copyToClipboard(embedCode);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "content");
    });
  }

  onZoomImage(_zoom: boolean) {
    // this.zoomState = zoom;
  }

  getPlaceDetails(index) {
    console.log(index);
  }

  goToAppStore() {
    Util.openURLAdaptive(this.appStoreUrl);
  }

  goToAppleTestFlight() {
    Util.openURLAdaptive(this.testFlightUrl);
  }

  goToPlayStore() {
    Util.openURLAdaptive(this.playStoreUrl);
  }

  openWebpage() {
    Util.openURLAdaptive(this.website);
  }

  goToWeb() {
    // url += "/#/story-home?storyId=" + this.storyId;
    Util.openURLAdaptive(this.webUrl);
  }

  openLink(url: string) {
    Util.openURLAdaptive(url);
  }

  /**
  * open app FB page
  */
  openFBPage() {
    // Util.openURLAdaptive(this.appDetails.fbLink);
    Util.openURLAdaptive("https://www.facebook.com/leplaceglobal/");
  }

  /**
   * write email to developers
   */
  contact() {
    // let link: string = this.appDetails.contactEmail;
    let link: string = "office@leplace.online";
    if (link) {
      link = "mailto:" + link;
      let subject = "Visitor contact from LP Connect";
      let body = "";
      let uri: string = EmailFormatUils.formatExistingContent(link, subject, body);
      // Util.openURLAdaptive(uri);
      this.fileManager.openURLAsAnchor(uri);
    }
  }

  copyToClipboard(code: string) {
    this.popupFeatures.copyToClipboard(code);
  }

  toggleActivationGuide() {
    this.showActivationGuide = !this.showActivationGuide;
  }

  async checkoutStripe() {
    try {
      if (this.isCheckout) {
        let res: number = await this.uiext.showAlert(Messages.msg.alreadyCheckedOut.before.msg, Messages.msg.alreadyCheckedOut.before.sub, 2, null, true);
        if (res === EAlertButtonCodes.cancel) {
          return;
        }
      }
      await this.uiext.showLoadingV2Queue("Redirecting to checkout..");
      let checkoutUrl: string = await this.paymentStripe.createPaymentCheckoutWizard(null, this.storyId, document.URL);
      await this.uiext.dismissLoadingV2();
      if (!this.isModal) {
        this.fileManager.openURLAsAnchor(checkoutUrl);
      } else {
        Util.openURL(checkoutUrl);
      }
      // Util.openURLAdaptive(checkoutUrl);
    } catch (err) {
      await this.uiext.dismissLoadingV2();
      this.analytics.dispatchError(err, "content");
      this.uiext.showAlertNoAction(Messages.msg.preCheckoutError.after.msg, ErrorMessage.parse(err, Messages.msg.preCheckoutError.after.sub));
    }
  }

  onProviderSlideSelected(event) {
    console.log(event);
  }

  isAppStore() {
    return this.dist.ios && !this.isTestFlightOnly;
  }

  isPlayStore() {
    return this.dist.android;
  }
}

