import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Injectable } from "@angular/core";
import { AnalyticsTemplateService } from './analytics-template';


// event tracking on firebase analytics
@Injectable({
    providedIn: 'root'
})
export class AnalyticsFirebaseXService implements AnalyticsTemplateService {
    constructor(
        public firebaseXAnalytics: FirebaseX
    ) {

    }

    init() {
        let promise = new Promise((resolve) => {
            resolve(true);
        });
        return promise;
    }

    /**
     * set user id from the db
     * @param userId 
     */
    setUserId(userId: number) {
        try {
            this.firebaseXAnalytics.setUserId(userId.toString()).then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        }
        catch (e) {
            console.error(e);
        }
    }

    /**
     * track view (changes)
     * @param title 
     */
    trackView(title: string) {
        console.log("track view: " + title);
        try {
            this.firebaseXAnalytics.setScreenName(title).then((res: any) => {
                console.log(res);
            }).catch((error: any) => {
                console.error(error);
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * send event to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEvent(category: string, action: string, label: string, value: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            try {
                this.firebaseXAnalytics.logEvent(category, {
                    action: action,
                    description: action,
                    label: label,
                    value: value
                }).then((res: any) => {
                    console.log(res);
                    resolve(true);
                }).catch((error: any) => {
                    console.error(error);
                    resolve(false);
                });
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }
}
