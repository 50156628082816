import { Injectable, OnDestroy } from "@angular/core";
import { IQContainer, IQOptions, IGenericMessageQueueEvent, IQElement } from 'src/app/classes/utils/queue';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ResourceManager } from "src/app/classes/general/resource-manager";
import { SleepUtils } from "../utils/sleep-utils";


@Injectable({
    providedIn: 'root'
})
/**
 * the queue handler is triggered on produce
 * and finishes when there are no more items in the queue 
 */
export class GenericQueueService implements OnDestroy {
    addQ: IQContainer[] = [];
    debug: boolean = true;

    constructor(

    ) {
        console.log("generic queue service created");
    }

    checkSize(qid: number): number {
        let q: IQContainer = this.checkQueueExists(qid);
        if (q != null) {
            return q.elements.length;
        }
        return 0;
    }

    resetQueue(qid: number) {
        let q: IQContainer = this.checkQueueExists(qid);
        if (q != null) {
            q.elements = [];
            q.processRunning = false;
        }
    }

    private checkQueueExists(qid: number) {
        for (let i = 0; i < this.addQ.length; i++) {
            if (this.addQ[i].id === qid) {
                return this.addQ[i];
            }
        }
        return null;
    }

    private checkCreateQueue(qid: number, options: IQOptions) {
        let q: IQContainer = this.checkQueueExists(qid);
        if (!q) {
            if (options.delay == null || options.delay === 0) {
                console.warn("zero delay not allowed in processing queue");
                options.delay = 100;
            }
            let newQ: IQContainer = {
                id: qid,
                processRunning: false,
                elements: [],
                options: options
            };
            this.addQ.push(newQ);
            return newQ;
        }
        return q;
    }

    private checkFullQueue(q: IQContainer) {
        if (q.options.size === 0) {
            return false;
        } else {
            return q.elements.length >= q.options.size;
        }
    }

    /**
    * add a new function to the queue
    * start q manager if not already running
    * @param nextfn the function that will be executed
    * @param callback the callback that will be triggered on nextfn complete
    */
    enqueue(nextfn: (data: any) => any,
        callback: (qEvent: IGenericMessageQueueEvent) => any,
        id: string, qid: number, options: IQOptions) {

        if (!options) {
            options = {
                size: 25,
                delay: 100
            };
        }
        let q: IQContainer = this.checkCreateQueue(qid, options);
        if (!this.checkFullQueue(q)) {
            q.elements.push({
                fn: nextfn,
                callback: callback,
                id: id,
                data: null
            });

            if (this.debug) {
                console.log(new Date().toISOString() + " declare " + id + " | qsize[" + qid + "] = " + q.elements.length + " | delay: " + options.delay + " | running: " + q.processRunning);
            }

            // start the q manager
            if (!q.processRunning) {
                this.processQ(q);
            }
        }
    }

    /**
     * add a new function to the queue
     * start q manager if not already running
     * @param nextfn the function that will be executed
     * @param callback the callback that will be triggered on nextfn complete
     */
    enqueueWithData(nextfn: (data: any) => any,
        callback: (qEvent: IGenericMessageQueueEvent) => any,
        id: string, data: any, qid: number, options: IQOptions) {
        let q: IQContainer = this.checkCreateQueue(qid, options);
        if (!this.checkFullQueue(q)) {
            let shouldRun: boolean = q.elements.length === 0;
            q.elements.push({
                fn: nextfn,
                callback: callback,
                id: id,
                data: data
            });

            if (this.debug) {
                console.log(new Date().toISOString() + " declare " + id + " | qsize[" + qid + "] = " + q.elements.length + " | delay: " + options.delay + " | running: " + q.processRunning);
            }

            // start the q manager
            if (!q.processRunning || shouldRun) {
                this.processQ(q);
            }
        }
    }

    /**
     * add a new function to the queue
     * start q manager if not already running
     * @param nextfn the function that will be executed
     * @param callback the callback that will be triggered on nextfn complete
     */
    enqueueWithDataSnapshot(nextfn: (data: any) => any,
        callback: (qEvent: IGenericMessageQueueEvent) => any,
        id: string, data: any, qid: number, options: IQOptions) {
        let dataSnapshot: any = DeepCopy.deepcopy(data);
        this.enqueueWithData(nextfn, callback, id, dataSnapshot, qid, options);
    }


    /**
    * consume ONE element in the queue
    * returns the state of the queue
    * e.g. if it needs more cycles to process or if it's done
    */
    processElement(q: IQContainer) {
        let promise = new Promise(async (resolve) => {
            if (q.elements.length > 0) {
                let qElem: IQElement = q.elements.shift();

                if (this.debug) {
                    console.log(new Date().toISOString() + " call: ", qElem.id);
                }

                if (!qElem.fn) {
                    console.error("fn undefined");
                    await SleepUtils.sleep(1);
                    resolve(true);
                    return;
                }
                let promiseFn = qElem.fn(qElem.data);
                if (!promiseFn) {
                    console.error("promiseFn undefined");
                    await SleepUtils.sleep(1);
                    resolve(true);
                    return;
                }

                // check timeout
                if (q.options.timeout != null) {
                    qElem.timeout = setTimeout(() => {
                        let err: Error = new Error("queue timeout processing");
                        let qEvent: IGenericMessageQueueEvent = {
                            data: err,
                            state: false
                        };
                        if (qElem.callback) {
                            qElem.callback(qEvent);
                        }
                        if (this.debug) {
                            console.log(new Date().toISOString() + " consume timeout " + qElem.id);
                            console.error(err.message);
                        }
                        resolve(true);
                    }, q.options.timeout);
                }

                promiseFn.then(async (data: any) => {
                    let qEvent: IGenericMessageQueueEvent = {
                        data: data,
                        state: true
                    };
                    await SleepUtils.sleep(1);
                    if (qElem.callback) {
                        qElem.callback(qEvent);
                    }
                    ResourceManager.clearTimeout(qElem.timeout);
                    if (this.debug) {
                        console.log(new Date().toISOString() + " consume ok " + qElem.id);
                    }
                    resolve(true);
                }).catch(async (err: Error) => {
                    let qEvent: IGenericMessageQueueEvent = {
                        data: err,
                        state: false
                    };
                    await SleepUtils.sleep(1);
                    if (qElem.callback) {
                        qElem.callback(qEvent);
                    }
                    ResourceManager.clearTimeout(qElem.timeout);
                    if (this.debug) {
                        console.log(new Date().toISOString() + " consume error " + qElem.id);
                        console.error(err.message);
                    }
                    resolve(true);
                });
            } else {
                await SleepUtils.sleep(1);
                if (this.debug) {
                    console.log(new Date().toISOString() + " empty queue");
                }
                resolve(false); // empty queue
            }
        });
        return promise;
    }

    /**
     * manage queue loop for specified queue
     * warning: zero delay breaks queue - somehow promises don't resolve / processRunning is not reset
     */
    processQ(q: IQContainer) {
        q.processRunning = true;
        // console.log("will process");
        this.processElement(q).then((res: boolean) => {
            if (res) {
                if (q.options.delay != null && q.options.delay > 0) {
                    // SleepUtils.sleepRealtime(q.options.delay).then(() => {
                    //     this.processQ(q);
                    // });
                    setTimeout(() => {
                        this.processQ(q);
                    }, q.options.delay);
                } else {
                    this.processQ(q);
                }
            } else {
                // q is empty
                q.processRunning = false;
            }
        }).catch((err: Error) => {
            console.error(err);
            q.processRunning = false;
        });
    }

    /**
     * clear timeouts
     */
    ngOnDestroy() {

    }

}




