<div *ngIf="!loaded && showLoading">
  <progress-loading [small]="true"></progress-loading>
</div>

<div class="img-container" [ngClass]="zoomState?'text-center':'text-center'">
  <ion-button *ngIf="withUpload && (loaded || loading)" class="action-button-xs button-color-alternate info-text-s upload-btn"
    (click)="tapUpload()">
    <icon-wrapper slot="icon-only" [icon]="appIconsStandard.pickPhoto" [custom]="false"></icon-wrapper>
  </ion-button>
  <ion-button *ngIf="withUpload && (!loaded && !loading)" class="action-button-fill button-color-primary" (click)="tapUpload()"
    icon-end>
    <span class="button-font-size-s">upload</span>
    <icon-wrapper slot="start" [icon]="appIconsStandard.pickPhoto" [custom]="false" class="slot-icon-padding">
    </icon-wrapper>
  </ion-button>

  <ion-button *ngIf="loaded" class="as-text element-selector info-text-s text-color-primary zoom-btn"
    (click)="tapZoom()">
    <icon-wrapper slot="icon-only" icon="search"></icon-wrapper>
  </ion-button>

  <div *ngIf="photoUrl" [@showState]="showState">
    <img class="border-radius-img" [src]="photoUrl" [ngClass]="imgClass">
  </div>

</div>

<img *ngIf="photoUrl && !noAnimation && !loading" [src]="photoUrl" (load)="setLoaded()" (error)="handleLoadError()" hidden>