<div *ngIf="fab">

  <ion-button ion-fab [ngClass]="!state ? fabBtnDefaultClass : fabBtnHighClass" [disabled]="disabled"
    (click)="clicked(0)">
    <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon" [huge]="huge"></icon-wrapper>
  </ion-button>

  <!-- <ion-button *ngIf="!state" ion-fab [ngClass]="fabBtnDefaultClass" [disabled]="disabled" (click)="clicked(0)">
    <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
  </ion-button>
  <ion-button *ngIf="state" ion-fab [ngClass]="fabBtnHighClass" [disabled]="disabled" (click)="clicked(0)">
    <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
  </ion-button> -->
</div>
<div *ngIf="!fab">
  <div *ngIf="!iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <span class="button-font-size-s">{{name}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
    <!-- <ion-button *ngIf="!state" [ngClass]="btnDefaultClass" [disabled]="disabled" (click)="clicked(0)">
      <span class="text-color">{{name}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
    <ion-button *ngIf="state" [ngClass]="btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <span class="text-color">{{name}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button> -->
  </div>
  <div *ngIf="iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
    <!-- <ion-button *ngIf="!state" [ngClass]="btnDefaultClass" [disabled]="disabled" (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
    <ion-button *ngIf="state" [ngClass]="btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button> -->
  </div>
</div>