<div *ngIf="loaded && availableItems">
  <ion-list class="list-padding">
    <ion-item *ngFor="let d of data; let i=index" class="list-item list-item-padding transparent-bg">
      <div *ngIf="!customSelect" tappable class="full-w theme-border-bottom" (click)="itemAction(i, d)">
        <dynamic-content [type]="type" [context]="d" [update]="update">
        </dynamic-content>
      </div>
      <div *ngIf="customSelect" tappable class="full-w theme-border-bottom">
        <dynamic-content [type]="type" [context]="d" [update]="update" (action)="itemAction(i, d)">
        </dynamic-content>
      </div>
    </ion-item>
  </ion-list>
</div>

<div *ngIf="loaded && !availableItems" class="padding-s center-text">
  <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span>
</div>