<modal-header-container [vs]="vs">
    <leplace-toolbar [noBackButton]="true" [rightButton]="rightBtnIcon" [rightIcon]="true" [title]="title"
        (rightClick)="return()" [rounded]="vs.fullScreen">
    </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
    [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
    <ion-list class="list-md-noborder" *ngIf="filters">

        <div *ngFor="let filter of filters.filters">
            <ion-item-group *ngIf="filter.type === types.checkbox">
                <ion-item-divider sticky class="list-item-divider">
                    <ion-label class="label-text-s bold text-color-primary">
                        {{filter.label | uppercase}}
                    </ion-label>
                </ion-item-divider>
                <ion-item class="list-item transparent-bg settings-item">
                    <ion-label class="text-color-primary label-text-s">Select All</ion-label>
                    <ion-checkbox [(ngModel)]="filter.selectAll" ngDefaultControl
                        (ionChange)="onSelectAll(filter, $event)" class="custom-checkbox">
                    </ion-checkbox>
                </ion-item>
                <ion-item class="list-item transparent-bg settings-item item-noborder"
                    *ngFor="let set of filter.options" #slidingItem>
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{set.name}}</ion-label>
                    <ion-checkbox [(ngModel)]="set.checked" ngDefaultControl (ionChange)="onChange(filter, set)"
                        class="custom-checkbox">
                    </ion-checkbox>
                </ion-item>
            </ion-item-group>

            <ion-radio-group *ngIf="filter.type === types.radio" [value]="filter.selected"
                (ionChange)="radioSelect(filter, $event)">
                <ion-item-divider sticky class="list-item-divider">
                    <ion-label class="label-text-s bold text-color-primary">
                        {{filter.label | uppercase}}
                    </ion-label>
                </ion-item-divider>
                <ion-item *ngFor="let o of filter.options; let i = index"
                    class="list-item transparent-bg item-noborder settings-item">
                    <ion-label class="ion-text-wrap info-text-m-imp">
                        <div [innerHTML]="o.name" class="text-color-primary"></div>
                    </ion-label>
                    <ion-radio slot="start" [value]="o.code"></ion-radio>
                </ion-item>
            </ion-radio-group>
        </div>

    </ion-list>
</modal-container>