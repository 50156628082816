import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EImgIcons, EAppIcons } from 'src/app/classes/def/app/icons';
import { Util } from 'src/app/classes/general/util';
import { ISponsor } from 'src/app/classes/def/business/sponsor';

@Component({
  selector: 'sponsor-card',
  templateUrl: './sponsor-card.component.html',
  styleUrls: ['./sponsor-card.component.scss'],
})
export class SponsorCardComponent implements OnInit, OnDestroy {
  imgIcons = EImgIcons;
  icons = EAppIcons;

  @Input()
  sponsor: ISponsor;

  constructor() {

  }

  ngOnInit() {
    if (this.sponsor) {
      this.format();
    }
  }

  ngOnDestroy() {

  }

  openURL(url: string) {
    Util.openURLAdaptive(url);
  }

  /**
   * format place display
   */
  format() {
    console.log(this.sponsor);
  }
}
