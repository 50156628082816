import { Subscription, BehaviorSubject } from 'rxjs';
import { IObservableMultiplex } from '../def/mp/subs';

export class ResourceManager {
    /**
     * clear a single subscription, returns null
     * @param obs 
     */
    static clearSub(obs: Subscription) {
        if (obs != null) {
            obs.unsubscribe();
            obs = null;
        }
        return null;
    }

    /**
     * clear sub from array, returns empty array
     * @param subs 
     */
    static clearSubArray(subs: Subscription[]) {
        subs.forEach((element: Subscription) => {
            element = ResourceManager.clearSub(element);
        });
        return [];
    }

    /**
     * clear sub from obj, returns object with null attributes
     * @param subObj 
     */
    static clearSubObj(subObj: any) {
        let dtk: string[] = Object.keys(subObj);
        dtk.forEach((name: string) => {
            subObj[name] = ResourceManager.clearSub(subObj[name]);
        });
        return subObj;
    }


    /**
     * clear a single subscription, returns null
     * @param obs 
     */
    static initBsub() {
        return new BehaviorSubject(null);
    }

    /**
     * clear sub from array, returns empty array
     * @param obs 
     */
    static initBsubArray(obs: BehaviorSubject<any>[]) {
        for (let i = 0; i < obs.length; i++) {
            obs[i] = ResourceManager.initBsub();
        }
        return obs;
    }

    static broadcastBsubObj(subObj: IObservableMultiplex, val: any) {
        let dtk: string[] = Object.keys(subObj);
        dtk.forEach((name: string) => {
            if (subObj[name] != null) {
                subObj[name].next(val);
            }
        });
    }

    /**
     * clear sub from obj, returns object with null attributes
     * @param subObj 
     */
    static initBsubObj(subObj: IObservableMultiplex): any {
        let dtk: string[] = Object.keys(subObj);
        dtk.forEach((name: string) => {
            subObj[name] = ResourceManager.initBsub();
        });
        return subObj;
    }

    /**
     * clears a single timeout, returns null
     * @param timeout 
     */
    static clearTimeout(timeout: any) {
        if (timeout != null) {
            clearTimeout(timeout);
            timeout = null;
        }
        return null;
    }

    /**
     * clear timeouts from array, returns empty array
     * @param timeouts 
     */
    static clearTimeoutArray(timeouts: any[]) {
        timeouts.forEach((element: any) => {
            element = ResourceManager.clearTimeout(element);
        });

        return [];
    }

    /**
     * clear timeouts from obj, returns object with null attributes
     * @param timeoutObj 
     */
    static clearTimeoutObj(timeoutObj: any) {
        let dtk = Object.keys(timeoutObj);
        dtk.forEach((name) => {
            timeoutObj[name] = ResourceManager.clearTimeout(timeoutObj[name]);
        });

        return timeoutObj;
    }


    static clearTriggerableTimeout(timeout: any) {
        if (timeout) {
            timeout.clear();
        }
        timeout = null;
        return null;
    }

    /**
     * clear triggerable timeouts from obj, returns object with null attributes
     * @param timeoutObj 
     */
    static clearTriggerableTimeoutObj(timeoutObj: any) {
        let dtk = Object.keys(timeoutObj);
        dtk.forEach((name) => {
            timeoutObj[name] = ResourceManager.clearTriggerableTimeout(timeoutObj[name]);
        });

        return timeoutObj;
    }


    /**
     * create triggerable timeout
     * @param timeoutHandler 
     * @param delay 
     */
    static createTriggerableTimeout(timeoutHandler: any, delay: number) {
        let timeoutId = setTimeout(timeoutHandler, delay);
        return {
            clear: () => {
                clearTimeout(timeoutId);
            },
            trigger: () => {
                clearTimeout(timeoutId);
                return timeoutHandler();
            }
        };
    }
}
