import { IGameItem, IGameItemOptions } from 'src/app/classes/def/items/game-item';
import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { EventEmitter } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { GameItemOptionsViewComponent } from 'src/app/modals/app/modals/game-item-options/game-item-options.component';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IItemAction } from 'src/app/classes/def/items/action';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'game-item-disp',
  templateUrl: './game-item-disp.component.html',
  styleUrls: ['./game-item-disp.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        transition: "background-color 250ms linear"
      })),
      state('active', style({
        transition: "background-color 250ms linear"
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class GameItemDispComponent implements OnInit, OnDestroy {
  photoLoaded: boolean = false;
  inventoryAmount: string = "";
  showState: string = "inactive";
  state: boolean = false;
  timerSub = null;
  showLabel: boolean = false;
  itemName: string = "";
  isLocked: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  published: boolean = false;

  @Input()
  data: IGameItem;

  @Input()
  interact: boolean;

  @Output()
  action: EventEmitter<IItemAction> = new EventEmitter();

  @Input()
  small: boolean;

  constructor(
    private uiext: UiExtensionService
  ) {

  }

  showDescription() {
    console.log("show desc: ", this.data);
    let item: IGameItem = this.data;
    if (!item) {
      return;
    }
    // if (item.isDraft) {
    //   let gio: IDraftItemCreatorNavParams = {
    //     item: StoryUtils.draftSpecToGameItemReverseAdapter(this.data),
    //     isAttached: false
    //   };
    //   let navParams: INavParams = {
    //     view: {
    //       fullScreen: false,
    //       transparent: false,
    //       large: true,
    //       addToStack: true,
    //       frame: false
    //     },
    //     params: gio
    //   };
    //   this.uiext.showCustomModal(null, DraftTemplateCreatorViewComponent, navParams).then((res: IDraftItemCreatorReturnData) => {
    //     if (res != null) {
    //       this.action.emit({
    //         itemAction: null,
    //         draftAction: res,
    //       });
    //     }
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });
    // } else {
    if ((this.interact || this.interact == null) && this.data.peek) {
      let gio: IGameItemOptions = {
        item: this.data,
        basicView: true
      };
      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: true,
          addToStack: true,
          frame: false
        },
        params: gio
      };
      this.uiext.showCustomModal(null, GameItemOptionsViewComponent, navParams).then((data: IItemAction) => {
        if (data != null) {
          this.action.emit(data);
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    } else {
      this.action.emit({
        code: null,
        item: this.data,
        data: null
      });
    }
  }


  ngOnDestroy() {

  }

  ngOnInit() {
    if (this.data) {
      let item: IGameItem = this.data;
      let itemName: string = item.name;
      if (itemName != null) {
        this.itemName = StringUtils.trimName(itemName, EMessageTrim.itemNameCard);
        this.showLabel = true;
      } else {
        this.itemName = "?";
        this.showLabel = true;
      }
      if (item.amount != null) {
        this.itemName += " (" + item.amount + ")";
      }
      if (item.draftItemLink != null || item.draftCparamLink != null) {
        this.published = true;
      }
      if (!item.isDraft) {
        this.published = true;
      }
    }
  }
}
