import { IActivity, EActivityCodes } from "src/app/classes/def/core/activity";
import { EHiddenMarkerModes } from "src/app/classes/def/core/story";
import { IPlaceMarkerContent } from "src/app/classes/def/map/map-data";
import { IAppLocation, ELocationFlag } from "src/app/classes/def/places/app-location";
import { ILocationContainer, IBackendLocation } from "src/app/classes/def/places/backend-location";
import { Util } from "src/app/classes/general/util";

export interface ILocationInfoText {
    show: boolean;
    headerText: string;
    footerText: string;
    locationDispName: string;
    nameClass: string;
    uncharted: boolean;
}

export enum ECheckHiddenPlaceMode {
    place = 1,
    checkpoint = 2
}

export class LocationDispUtils {

    /**
     * check hidden checkpoint name
     * @param place 
     * @returns 
     */
    static checkHiddenCheckpointName(place: ILocationContainer, inProgress: boolean) {
        if (!(place && place.merged)) {
            return true;
        }
        let activity: IActivity = place.merged.activity;
        if (!activity) {
            return true;
        }
        let hiddenCheckpointName: boolean = false;
        if (LocationDispUtils.checkHiddenFlag(place, ECheckHiddenPlaceMode.checkpoint)) {
            hiddenCheckpointName = !place.merged.done && !inProgress; // hidden unless done or in progress
        }
        return hiddenCheckpointName;
    }

    /**
     * check hidden place for find activity or via flag
     * @param place 
     */
    static checkHiddenPlace(place: ILocationContainer, inProgress: boolean) {
        if (!(place && place.merged)) {
            return true;
        }
        let activity: IActivity = place.merged.activity;
        if (!activity) {
            return true;
        }
        let hiddenPlace: boolean = false;
        if (activity.code === EActivityCodes.find) {
            hiddenPlace = !place.merged.done; // always hidden until completed
        } else {
            if (LocationDispUtils.checkHiddenFlag(place, ECheckHiddenPlaceMode.place)) {
                hiddenPlace = !place.merged.done && !inProgress; // hidden unless done or in progress
            }
        }
        return hiddenPlace;
    }

    static checkHiddenFlag(place: ILocationContainer, mode: number) {
        if (!(place && place.merged)) {
            return true;
        }
        return LocationDispUtils.checkHiddenFlagCore(place.merged, mode);
    }

    static checkHiddenFlagCore(place: IBackendLocation, mode: number) {
        if (place == null) {
            return true;
        }
        let activity: IActivity = place.activity;
        let hiddenFlag: boolean = false;
        switch (mode) {
            case ECheckHiddenPlaceMode.place:
                hiddenFlag = activity.hiddenPlace === 1 || place.hiddenLocationName === 1;
                break;
            case ECheckHiddenPlaceMode.checkpoint:
                hiddenFlag = place.hiddenName === 1;
                break;
        }
        return hiddenFlag;
    }

    static checkHiddenMarkerModeCircle(place: ILocationContainer) {
        if (!(place && place.merged)) {
            return false;
        }
        let hidden: boolean = place.merged.hiddenMarkerMode === EHiddenMarkerModes.circle;
        return hidden;
    }


    static checkHiddenLocationOverrides(apploc: IAppLocation, mkdata: IPlaceMarkerContent, hidden: boolean) {
        if (mkdata && mkdata.data && mkdata.data.loc && mkdata.data.loc.merged && apploc.loc && apploc.loc.merged) {
            let mkdataLoc: IBackendLocation = mkdata.data.loc.merged;
            if (hidden && (mkdataLoc.hiddenLocationName === 1)) {
                if (apploc.loc.merged.tempName == null) {
                    apploc.loc.merged.tempName = mkdataLoc.name;
                }
                // mkdataLoc.name = "UNCHARTED";
                mkdataLoc.name = "HIDDEN";
            } else {
                if (apploc.loc.merged.tempName != null) {
                    // handle locations that were just completed (replace uncharted label with place name)
                    mkdataLoc.name = apploc.loc.merged.tempName;
                }
            }
            mkdata.label = mkdataLoc.name;
            LocationDispUtils.updateLocationMarkerHeading(mkdata, apploc.loc.merged, hidden && (mkdataLoc.hiddenName === 1));
        }
    }

    static updateLocationMarkerHeading(placeMarkerContent: IPlaceMarkerContent, bloc: IBackendLocation, hidden: boolean) {
        let heading: string = "";
        if (bloc.heading) {
            if (!hidden) {
                heading += bloc.heading;
            } else {
                heading += "Checkpoint";
            }

            if (bloc.activity) {
                heading += " (" + bloc.activity.title + ")";
            }
        }
        placeMarkerContent.heading = heading;
    }

    /**
     * checkpoint name (challenge name)
     * ---
     * place type / place name (status)
     * @param bloc 
     * @param hidden 
     */
    static getLocationInfoText(bloc: IBackendLocation, hidden: boolean): ILocationInfoText {
        let res: ILocationInfoText = {
            show: false,
            headerText: "",
            footerText: "",
            locationDispName: "",
            nameClass: "text-color-element",
            uncharted: hidden
        };

        let placeType = Util.formatPlaceType(bloc.type);
        res.footerText = placeType;

        if (bloc.heading) {
            res.headerText = bloc.heading;
        } else {
            res.headerText = "Challenge";
        }
        if (bloc.activity) {
            res.headerText += " (" + bloc.activity.title + ")";
        }

        if (bloc.name) {
            res.locationDispName = bloc.name;
            if (bloc.tempName != null) {
                res.locationDispName = bloc.tempName; // original name (not replaced by UNCHARTED)
            }
            if (hidden) {
                res.locationDispName = LocationDispUtils.checkHiddenFlagCore(bloc, ECheckHiddenPlaceMode.place) ? "HIDDEN" : "UNCHARTED";
                res.nameClass = "text-color-warn-contrast";
            }
            res.footerText = res.locationDispName;
        } else {
            res.locationDispName = placeType.toUpperCase();
            res.footerText = res.locationDispName;
        }

        let flagText: string = null;
        switch (bloc.flag) {
            case ELocationFlag.FIXED:
                flagText = "verified";
                break;
            case ELocationFlag.RANDOM:
                flagText = "dynamic";
                break;
            case ELocationFlag.SAVED:
                flagText = "saved";
                break;
        }

        if (flagText) {
            res.footerText += " (" + flagText + ")";
        }

        return res;
    }

    static getLocationSpanDisp(fixedLocation: boolean, locationId: number, locationName: string) {
        return ((fixedLocation && locationId != null) ? ("LP" + locationId + ": ") : "") + locationName;
    }
}