
import { NgModule } from '@angular/core';
import { AmountInputViewComponent } from './modals/amount-input/amount-input.component';
import { CheckboxFrameViewComponent } from './modals/checkbox-frame/checkbox-frame.component';
import { DescriptionFrameViewComponent } from './modals/description-frame/description-frame.component';
import { OptionsListViewComponent } from './modals/options-list/options-list.component';
import { PhotoFrameViewComponent } from './modals/photo-frame/photo-frame.component';
import { RatingFrameViewComponent } from './modals/rating-frame/rating-frame.component';
import { RewardPopupViewComponent } from './modals/reward-popup/reward-popup.component';
import { TextInputViewComponent } from './modals/text-input/text-input.component';
import { TextInputCoreViewComponent } from './modals/text-input-core/text-input-core.component';
import { AppComponentsModule } from 'src/app/components/app/app-components.module';
import { GenericComponentsModule } from 'src/app/components/generic/generic-components.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListSelectorViewComponent } from './modals/list-selector/list-selector.component';
import { YouTubeViewFrameComponent } from './modals/youtube-view-frame/youtube-view-frame.component';
import { QRFrameViewComponent } from './modals/qr-frame/qr-frame.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { CalendarModule } from 'ion2-calendar';
import { DateSelectorViewComponent } from './modals/date-selector/date-selector.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxStarsModule } from 'ngx-stars';
import { CheckboxGridFrameViewComponent } from './modals/checkbox-grid-frame/checkbox-grid-frame.component';
import { CustomFiltersViewComponent } from './modals/custom-filters/custom-filters.component';
import { RewardPopupToastViewComponent } from './modals/reward-popup-toast/reward-popup-toast.component';
import { CustomFormViewComponent } from './modals/custom-form/custom-form.component';

const components = [
    AmountInputViewComponent,
    CheckboxFrameViewComponent,
    CheckboxGridFrameViewComponent,
    CustomFiltersViewComponent,
    CustomFormViewComponent,
    DateSelectorViewComponent,
    DescriptionFrameViewComponent,
    OptionsListViewComponent,
    PhotoFrameViewComponent,
    RatingFrameViewComponent,
    RewardPopupViewComponent,
    RewardPopupToastViewComponent,
    TextInputViewComponent,
    TextInputCoreViewComponent,
    ListSelectorViewComponent,
    YouTubeViewFrameComponent,
    QRFrameViewComponent
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        AppComponentsModule,
        GenericComponentsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        CalendarModule,
        NgxQRCodeModule,
        RatingModule,
        NgxStarsModule
    ],
    exports: [...components]
})
export class GenericModalsModule { }
