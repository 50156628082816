<div class="flex-card bg-container bg-container-fill item-card item-border-radius" tappable
  [ngClass]="{'bg-no-filter':data.enabled, 'bg-filter-2':!data.enabled}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade-no-animation', url: data ? data.photoUrl:null}"
  (click)="onCheckToggle($event)">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute" *ngIf="data">
      <ion-row class="padding-null">
        <ion-col size="12">
          <div>
            <span class="info-text-s bold text-color-overlay-s">{{data.name}}</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="padding-null">
        <ion-col size="8">

        </ion-col>
        <ion-col size="4">
          <ion-checkbox [ngModel]="data.enabled" ngDefaultControl class="custom-checkbox checkbox-right"
            (click)="onCheck($event)">
          </ion-checkbox>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>