<mat-progress-bar *ngIf="indeterminate" mode="indeterminate" class="mat-progress-bar-loading"></mat-progress-bar>
<ion-row *ngIf="!indeterminate" class="no-padding" [ngClass]="small ? 'leplace-progress-bar-s':classLarge">
  <ion-col size="6" [class]="class">
    <mat-progress-bar mode="determinate" class="mat-progress-bar-loading flip-horizontal"
      [ngClass]="rounded ? 'progress-rounded-right' : ''" [value]="loadingValue">
    </mat-progress-bar>
  </ion-col>
  <ion-col size="6" [class]="class">
    <mat-progress-bar mode="determinate" class="mat-progress-bar-loading"
      [ngClass]="rounded ? 'progress-rounded-right' : ''" [value]="loadingValue"></mat-progress-bar>
  </ion-col>
</ion-row>