import { Injectable, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SettingsManagerService } from "../settings-manager";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { BehaviorSubject, Subscription } from "rxjs";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { BackButtonEventDetail } from '@ionic/core';
import { IViewSpecs } from 'src/app/classes/def/nav-params/general';


@Injectable({
    providedIn: 'root'
})
export class BackButtonService {

    callbackStack: (() => any)[] = [];
    platform: IPlatformFlags = {} as IPlatformFlags;

    keyEventObservable: BehaviorSubject<number>;
    backButtonSub: Subscription;
    keyEventSub: Subscription;

    keyPressEnabled: boolean = true;

    constructor(
        public plt: Platform,
        public settingsProvider: SettingsManagerService,
        public ngZone: NgZone
    ) {
        console.log("back button service created");
        this.keyEventObservable = new BehaviorSubject(null);

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                console.log("platform flags loaded");
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    exitApp() {
        navigator['app'].exitApp();
    }

    registerKeyPress(code: number) {
        if (this.keyPressEnabled) {
            this.keyEventObservable.next(code);
        }
    }

    enableGlobalKeyPress() {
        this.keyPressEnabled = true;
    }

    disableGLobalKeyPress() {
        this.keyPressEnabled = false;
    }

    /**
     * register back button action
     * wrapper for mobile/web
     * @param callback 
     */
    registerBackButtonAction(callback: () => any) {
        if (!this.platform.WEB) {
            if (this.platform.ANDROID) {
                this.backButtonSub = ResourceManager.clearSub(this.backButtonSub);
                this.backButtonSub = this.plt.backButton.subscribe((bed: BackButtonEventDetail) => {
                    console.log("back button action registered: ", bed);

                    // setTimeout(() => {
                    //     callback();
                    // }, 100);

                    this.ngZone.run(() => {
                        if (callback) {
                            callback();
                        } else {
                            console.warn("undefined callback");
                        }
                    });

                }, (err: Error) => {
                    console.error(err);
                });
            } else {
                // for iOS use swipe gesture

            }
        } else {
            ResourceManager.clearSub(this.keyEventSub);
            this.keyEventObservable.next(null);
            // disabled back via keyboard because this app may be available as a web app

            // let state: number = 0;
            this.keyEventSub = this.keyEventObservable.subscribe((key: number) => {
                // if (SettingsManagerService.checkWebDevMode()) {
                //     if (key === 98) {
                //         // b
                //         console.log("back key detected");
                //         if (callback) {
                //             callback();
                //         } else {
                //             console.warn("undefined callback");
                //         }
                //     }
                // }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * run callback
     */
    runCurrentBackButtonAction() {
        console.log("bb run callback");
        let callback: () => any = this.callbackStack.pop();
        if (callback) {
            callback();
        }
    }

    /**
     * push back button action to stack 
     * @param callback 
     */
    push(callback: () => any) {
        // console.log("push: ", callback);
        this.callbackStack.push(callback);
        this.registerBackButtonAction(callback);
    }

    /**
     * dismiss current back button action in the stack and 
     * retrieve and set previous back button action
     */
    pop(): () => any {

        let callback: () => any = null;

        let callbackStackLength: number = this.callbackStack.length;

        if (callbackStackLength > 0) {
            callback = this.callbackStack.pop();
        }

        console.log("callback stack length after pop: " + callbackStackLength);

        if (callback) {
            let popCallback: () => any;
            if (callbackStackLength > 0) {
                popCallback = this.callbackStack[this.callbackStack.length - 1];
            } else {
                popCallback = this.callbackStack[0];
            }
            this.registerBackButtonAction(popCallback);
        } else {
            this.registerBackButtonAction(null);
        }

        return callback;
    }

    reset() {
        this.callbackStack = [];
        this.backButtonSub = ResourceManager.clearSub(this.backButtonSub);
    }


    /**
     * replace the last back button action and set
     * @param callback 
     */
    replace(callback: () => any) {
        console.log("replace callback");
        let callbackStackLength: number = this.callbackStack.length;
        if (callbackStackLength > 0) {
            this.callbackStack.pop();
        }
        this.push(callback);
    }

    /**
     * push back button action to stack 
     * or
     * replace the last back button action and set
     * used for views that can be opened either as root or modals
     * @param callback 
     */
    pushOrReplace(callback: () => any, vs: IViewSpecs) {
        if (vs) {
            if (vs.isModal && !vs.addToStack) {
                this.push(callback);
            } else {
                this.replace(callback);
            }
        } else {
            this.replace(callback);
        }
    }



    checkPop(vs: IViewSpecs) {
        if (vs && vs.isModal && !vs.addToStack) {
            this.pop();
        }
    }


    /**
     * handle swipe event 
     * iOS use as back button function
     * Android nop
     * returns false if swipe should be handled by the caller instead
     * @param e 
     */
    handleSwipeEvent(e) {
        console.log("bb handle swipe event: ", e);
        if (this.platform.IOS) {
            console.log("bb handle ios swipe");
            if (e) {
                switch (e.direction) {
                    case 2:
                        // right to left

                        break;
                    case 4:
                        // left to right
                        // back button iOS
                        this.runCurrentBackButtonAction();
                        break;
                }
            }
            return true;
        } else {
            return false;
        }
    }
}
