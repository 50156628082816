import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { PhotoUploadUtils } from 'src/app/classes/def/media/photo';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { PhotosService } from 'src/app/services/media/photos';

@Component({
  selector: 'upload-img',
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.scss'],
})
export class UploadImgComponent implements OnInit, OnChanges {

  appIconsStandard = EAppIconsStandard;

  @Input()
  withUpload: boolean;

  @Input()
  withUploadBtn: boolean;

  @Input()
  photoUrl: string;

  @Input()
  customClass: string;

  @Input()
  externalUpload: boolean;

  @Output() upload: EventEmitter<string> = new EventEmitter();

  withUploadPhoto: boolean = true;
  withUploadPhotoBtn: boolean = true;

  photoUrlContent: string = "";
  reloadContent: boolean = false;

  constructor(
    public photoProvider: PhotosService,
    public analytics: AnalyticsService,
    public uiext: UiExtensionService
  ) { }

  ngOnInit() {
    if (this.withUpload === false) {
      this.withUploadPhoto = false;
    }
    if (this.withUploadBtn === false) {
      this.withUploadPhotoBtn = false;
    }
    this.loadContent(this.photoUrl);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'photoUrl':
            this.loadContent(this.photoUrl);
            break;
        }
      }
    }
  }

  uploadPhotoFromGallery(_event) {
    if (this.externalUpload) {
      this.upload.emit(null);
      return;
    }
    // returns temp photo url
    this.photoProvider.uploadPhotoFromGalleryWizard((data) => {
      this.photoUrl = data;
      this.loadContent(data);
      this.upload.emit(data);
      return Promise.resolve(null);
    }, false).then(() => {

    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "upload-img");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    });
  }

  loadContent(data: string) {
    let content: string = PhotoUploadUtils.getPhotoURLFromHTML(data);
    this.photoUrlContent = content != null ? content : data;
  }
}
