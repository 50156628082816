import { IKVStat } from '../app/kval';
import { IEditorStoryLocationDetails } from '../core/editor';
import { IBackendLocation, ILocationContainer } from '../places/backend-location';


export interface IClientCountDaily {
    count: number,
    date: string
}

export interface IBusinessDataExt {
    dateRegistered?: string;
    paymentId: string,
    locationId: number,
    type: number,
    /**
     * user types (can be multiple roles for a business), e.g., owner, associate, supervisor
     */
    types?: number[],
    disp: IKVStat[];
    location: IBackendLocation,

    payment: {
        amountToPay: number;
        lastPayDate: string;
        duePayDate: string;
    };
    clients: {
        totalCount: number;
        currentCount: number;
        currentCountDaily: IClientCountDaily[]
    };
    colorClass?: string;
    storyLocationIndex?: number;
    storyLocationIndexActual?: number;
    customTag?: string;
    locked?: boolean;
    published?: boolean;
    editorDraft?: IEditorStoryLocationDetails;
    editorSummary?: IKVStat[];
}

export class BusinessUtils {
    static blocAdapter(bloc: IBackendLocation) {
        let bde: IBusinessDataExt = {
            dateRegistered: null,
            paymentId: null,
            locationId: bloc.id,
            type: null,
            disp: [],
            location: bloc,
            payment: null,
            clients: null,
            colorClass: null
        };
        return bde;
    }
    static blocContainerAdapter(aloc: ILocationContainer) {
        let bde: IBusinessDataExt = {
            dateRegistered: null,
            paymentId: null,
            locationId: aloc.merged.id,
            type: null,
            disp: [],
            location: aloc.merged,
            editorDraft: aloc.editorDraft,
            editorSummary: aloc.editorSummary,
            payment: null,
            clients: null,
            colorClass: null,
            storyLocationIndex: aloc.index,
            storyLocationIndexActual: aloc.actualIndex,
            customTag: aloc.customTag
        };
        return bde;
    }
}

export interface IBusinessPayment {
    amountToPay: number;
    lastPayDate: string;
    duePayDate: string;
}

export interface IBusinessClients {
    totalCount: number;
    currentCount: number;
}

export interface IBusinessEmployees {
    count: number;
}


export enum EViewType {
    BUSINESS = 0,
    WORKPLACE = 1,
    STORYLIST = 2,
    SALESLIST = 3
}

export enum EUserType {
    ANY = -1,
    CLIENT = 0,
    EMPLOYEE = 1,
    OWNER = 2,
    VISITOR = 3,
    ASSOCIATE = 4,
    SUPERVISOR = 5
}

export interface IBusinessFeaturesEnabled {
    qr: boolean;
    sales: boolean;
}


export interface ICheckRegisteredPlaceResponse {
    id: number,
    // registered business
    registeredBusiness: boolean,
    googleId?: string,
    ownerId: number
}

export interface IGetClientCountDaily {
    count: number,
    date: string
}

export interface IGetClientCountDetails {
    count: number,
    chart: IGetClientCountDaily[]
}