<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs" [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
  <div class="padding-s" fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1-border padding-s" fxLayout="column">
      <div [innerHTML]="infoHTML"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative">
      </div>

      <!-- <div>
        <ion-item class="transparent">
          <ion-input [(ngModel)]="refCode" ngDefaultControl type="text" required placeholder="R-Code" class="text-color">
          </ion-input>
        </ion-item>
      </div> -->

    </div>
    <div fxFlex></div>
    <div class="stick-bottom">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-primary-70" (click)="accept()" type="submit" block>
              <span class="button-font-size-m">
                Enter Leplace
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</modal-container>