import { EOS } from '../../classes/def/app/app';
import { GeneralCache } from '../../classes/app/general-cache';


import { LatLng, MyLocation } from "@ionic-native/google-maps/ngx";
import { IPlatformFlags } from "../../classes/def/app/platform";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { Injectable } from "@angular/core";
import { LocationMonitorService } from "./location-monitor";
import { IGeolocationResult } from "../../classes/def/map/map-data";

import { SettingsManagerService } from "../general/settings-manager";
import { EGeolocationModes, IGeolocationOptionsSetup, EGPSTimeout, ELocationTimeoutEvent } from "../../classes/def/map/geolocation";
import { ResourceManager } from "../../classes/general/resource-manager";
import { AnalyticsService } from "../general/apis/analytics";
import { Platform } from "@ionic/angular";
import { GoogleMap } from '@ionic-native/google-maps/ngx';
import { SleepUtils } from '../utils/sleep-utils';
import { UiExtensionService } from '../general/ui/ui-extension';
import { FallbackUtils } from '../utils/fallback-utils';


@Injectable({
    providedIn: 'root'
})
export class LocationManagerService {
    // private static _instance: LocationManagerService;

    locationObs = null;
    mode: number;
    platform: IPlatformFlags = {} as IPlatformFlags;
    map: GoogleMap;
    // LocationMonitorService: LocationMonitorService;
    prevLoc: IGeolocationResult = null;

    // check when first starting up the gps location watch
    locationFixed: boolean = false;

    locationNotFixedTimeoutLimit: number = 5;
    locationNotFixedTimeoutCounter: number = 0;
    locationTimeoutLimit: number = 3;
    locationTimeoutCounter: number = 0;
    locationSlowCounterLimit: number = 10;
    locationSlowCounter: number = 0;
    locationSlowWarningCounter: number = 0;

    singleRequestOptions: IGeolocationOptionsSetup = {
        navigator: {
            enableHighAccuracy: false, // default: false
            timeout: EGPSTimeout.gps, // default: Infinity
            maximumAge: EGPSTimeout.gps // default: 0
            // maximumAge: 10000
        },
        cordova: {
            maximumAge: 60000,
            timeout: EGPSTimeout.gps,
            enableHighAccuracy: false
        },
        background: {
            locationProvider: 1,
            desiredAccuracy: 10, // high 0, medium 10, low 100, passive 1000
            stationaryRadius: 5, // distance moved from stationary to enable full tracking
            distanceFilter: 1, // min distance for update event
            debug: false, //  enable this hear sounds for background-geolocation life-cycle.
            stopOnTerminate: true, // enable this to clear background location settings when the app terminates
            // added
            stopOnStillActivity: false,
            interval: 200,
            saveBatteryOnBackground: true,
            notificationTitle: "Background location",
            notificationText: "enabled"
        },
        gmap: {
            enableHighAccuracy: true
        },
        web: {
            enableHighAccuracy: false, // default: false
            timeout: EGPSTimeout.gps, // default: Infinity
            // maximumAge: 0 // default: 0
            maximumAge: 10000
        }
    };

    watchOptions: IGeolocationOptionsSetup = {
        navigator: {
            enableHighAccuracy: true,
            timeout: EGPSTimeout.gps,
            maximumAge: 60000 // was 0
        },
        cordova: {
            maximumAge: 60000, // was 0
            timeout: EGPSTimeout.gps,
            enableHighAccuracy: true
            // enableHighAccuracy: false
        },
        background: {
            locationProvider: 1,
            desiredAccuracy: 10, // high 0, medium 10, low 100, passive 1000
            stationaryRadius: 5, // distance moved from stationary to enable full tracking
            distanceFilter: 1, // min distance for update event
            debug: false, //  enable this hear sounds for background-geolocation life-cycle.
            stopOnTerminate: true, // enable this to clear background location settings when the app terminates
            // added
            stopOnStillActivity: false,
            interval: 200,
            saveBatteryOnBackground: true,
            notificationTitle: "Background location",
            notificationText: "enabled"
        },
        gmap: {
            enableHighAccuracy: true
        },
        web: {
            enableHighAccuracy: true,
            timeout: EGPSTimeout.gps,
            maximumAge: 10000
        }
    };


    errorFlag = {
        watchPosition: false
    };

    observables = {
        backgroundGeolocation: null,
        myUnifiedGeolocation: null
    };

    watches = {
        nativeGeolocation: null,
        cordovaGeolocation: null
    };

    subscription = {
        cordovaGeolocation: null
    };

    flags = {
        /* pause/resume watch location for bg mode */
        watchLocationMasterEnabled: false, // prevent starting watch on resume before enabled via gmap
        watchLocationEnabled: false,
        promiseEnabled: true,
        backgroundGeolocationInit: false,
        backgroundGeolocationEnabled: false,
        masterLock: true // true: unlocked
    };

    timeouts = {
        location: null,
        watchTimeout: null
    };

    constructor(
        public locationMonitor: LocationMonitorService,
        public geolocation: Geolocation,
        public settings: SettingsManagerService,
        public analytics: AnalyticsService,
        public uiext: UiExtensionService,
        public plt: Platform
    ) {
        console.log("location manager service created");
        // this.LocationMonitorService = LocationMonitorService.getInstance();
        this.mode = EGeolocationModes.native;
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded(SettingsManagerService.settings.platformFlags);
            }
        }, (err: Error) => {
            console.error(err);
        });

        this.plt.ready().then(() => {
            // this.setBackgroundGeolocation(true);
        });
    }

    requestBackgroundGeolocationIOS() {
        let promise = new Promise((resolve) => {
            console.log("requesting background geolocation access");
            if (GeneralCache.os !== EOS.ios) {
                resolve(false);
                return;
            }
            resolve(true);
        });
        return promise;
    }

    setBackgroundGeolocation(enabled: boolean) {
        console.log("bg geo / disabled: ", enabled);
    }

    setGeolocationMode(mode: number) {
        this.mode = mode;
        if (this.platform.MOBILE_LIVERELOAD) {
            switch (mode) {
                case EGeolocationModes.native:
                case EGeolocationModes.cordova:
                case EGeolocationModes.nativePolling:
                case EGeolocationModes.cordovaPolling:
                    this.mode = EGeolocationModes.gmap;
                    break;
            }
        }
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.reset);
        console.log("set geolocation mode: " + this.mode);
    }

    onPlatformLoaded(platform: IPlatformFlags) {
        console.log("location manager set platform: ", platform);
        this.platform = platform;
        this.setGeolocationMode(SettingsManagerService.settings.app.settings.locationMode.value);
    }

    setGmap(map: GoogleMap) {
        this.map = map;
    }

    /**
     * set timeout to reset location
     */
    setLocationWatchdog(force: boolean) {
        // console.log("location watchdog set request");

        // only used while watch location is enabled
        if (!this.flags.watchLocationEnabled && !force) {
            console.warn("location watchdog not available (set)");
            return;
        }

        if (!this.timeouts.watchTimeout) {
            // console.log("location watchdog set");
            this.timeouts.watchTimeout = setTimeout(() => {
                if (this.flags.watchLocationEnabled) {
                    this.fireLocationWatchdog();
                }
            }, EGPSTimeout.watchdog);
        }
    }

    fireLocationWatchdog() {

        if (!this.flags.watchLocationEnabled) {
            console.warn("location watchdog not available (fire)");
            return;
        }

        console.log("location watchdog fired");
        // disable watchdog for background geolocation
        // because it returns only when location changed
        if (this.mode !== EGeolocationModes.background) {
            // wake up location timeout
            this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutWatchdog);
            // stop watch location first

            if (!this.locationFixed) {
                this.locationNotFixedTimeoutCounter += 1;
                if (this.locationNotFixedTimeoutCounter >= this.locationNotFixedTimeoutLimit) {
                    this.locationFixed = true; // no more warnings
                    this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutNotFound);
                }
            }
            this.stopWatchPositionResolve().then(() => {
                // trigger get current location (single request) to kickstart location services
                this.getCurrentLocationCore().then(() => {
                    // resume watch location
                    this.startWatchPosition();
                }).catch((err: Error) => {
                    console.error(err);
                    // resume watch location
                    this.startWatchPosition();
                });
            });
        }
        // slow update is not relevant on location timeout
        this.locationSlowCounter = 0;
        this.locationSlowWarningCounter = 0;
    }

    /**
     * clear location watchdog
     */
    clearLocationWatchdog() {
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.reset);
        // console.log("location watchdog clear request");
        this.timeouts.watchTimeout = ResourceManager.clearTimeout(this.timeouts.watchTimeout);
    }


    /**
     * clear previous watchdog and reset location watchdog
     */
    resetLocationWatchdog() {
        this.clearLocationWatchdog();
        this.setLocationWatchdog(false);
    }

    getCurrentLocationRetryWrapper(enable: boolean, fromCache: boolean, withLoading: boolean): Promise<LatLng> {
        return FallbackUtils.retry(() => {
            return this.getCurrentLocationWrapper(enable, fromCache, withLoading);
        }, 5, 2000, null, null);
    }


    /**
     * this should be used only for initial geolocation
     * or instant location (not for location updates)
     * handles cache, loading modal
     */
    getCurrentLocationWrapper(enable: boolean, fromCache: boolean, withLoading: boolean): Promise<LatLng> {
        return new Promise<LatLng>(async (resolve, reject) => {

            if (!enable) {
                resolve(null);
                return;
            }

            if (fromCache) {
                let location: LatLng = this.locationMonitor.getCachedLocation();
                console.log("location from cache: ", location);
                if (!location) {
                    // continue with location request
                } else {
                    resolve(location);
                    return;
                }
            }

            if (withLoading) {
                await this.uiext.showLoadingV2Queue("Waiting for location..");
            }
            this.getCurrentLocationCore().then(async (res: LatLng) => {
                if (withLoading) {
                    await this.uiext.dismissLoadingV2();
                }
                resolve(res);
            }).catch(async (err: Error) => {
                if (withLoading) {
                    await this.uiext.dismissLoadingV2();
                }
                reject(err);
            });
        })
    }

    /**
     * this should be used only for initial geolocation
     * or instant location (not for location updates)
     */
    getCurrentLocationCore(): Promise<LatLng> {
        console.log("get current location");
        let promise: Promise<LatLng> = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!this.platform.WEB) {
                    let mode: number = this.mode;
                    // if (this.platform.MOBILE_LIVERELOAD) {
                    //     mode = GeolocationModes.none;
                    // }
                    // this.flags.mobileGeolocationMode = this.app.geolocationModes.GEO_GMAP;
                    switch (mode) {
                        case EGeolocationModes.native:
                        case EGeolocationModes.background:
                        case EGeolocationModes.nativePolling:
                            if (navigator.geolocation) {
                                // using default navigator on user phone browser
                                navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
                                    if (location) {
                                        console.log('location found using navigator');
                                        let coordinates: LatLng = new LatLng(location.coords.latitude, location.coords.longitude);

                                        let t1 = new Date(location.timestamp).getTime();
                                        let coords: LatLng = new LatLng(location.coords.latitude, location.coords.longitude);
                                        let loc: IGeolocationResult = {
                                            coords,
                                            timestamp: t1,
                                            elapsed: 0,
                                            accuracy: location.coords.accuracy,
                                            speed: location.coords.speed,
                                            heading: location.coords.heading
                                        };

                                        this.locationSender(loc);
                                        this.locationMonitor.setLocationCache(coordinates);
                                        resolve(coordinates);
                                    }
                                }, (err: GeolocationPositionError) => {
                                    console.error(err);
                                    reject(err);
                                }, this.singleRequestOptions.navigator);
                            }
                            break;
                        case EGeolocationModes.cordova:
                        case EGeolocationModes.cordovaPolling:
                            this.geolocation.getCurrentPosition(this.singleRequestOptions.cordova).then((data: Geoposition) => {
                                // console.log(data);
                                if (data && data.coords) {
                                    console.log('location found using cordova: ', data.coords);
                                    this.locationSenderCordova(data);
                                    this.locationMonitor.setLocationCache(new LatLng(data.coords.latitude, data.coords.longitude));
                                    let coords: LatLng = new LatLng(data.coords.latitude, data.coords.longitude);
                                    resolve(coords);
                                    // resolve(data.coords);
                                } else {
                                    reject(new Error("location not found using cordova"));
                                    return;
                                }
                            }).catch((err: Error) => {
                                console.error(err);
                                reject(err);
                            });
                            break;
                        case EGeolocationModes.gmap:
                            if (this.map) {
                                this.map.getMyLocation(this.singleRequestOptions.gmap)
                                    .then((data: MyLocation) => {
                                        // console.log(data);
                                        let coordinates: LatLng = new LatLng(data.latLng.lat, data.latLng.lng);

                                        let t1 = new Date(data.time).getTime();
                                        let loc: IGeolocationResult = {
                                            coords: coordinates,
                                            timestamp: t1,
                                            elapsed: 0
                                        };

                                        this.locationSender(loc);
                                        this.locationMonitor.setLocationCache(coordinates);
                                        resolve(coordinates);
                                    }).catch((err: Error) => {
                                        console.error(err);
                                        reject(err);
                                    });
                            } else {
                                reject(new Error("map is not defined"));
                            }
                            break;
                        default:
                            reject(new Error("unknown geolocation mode: " + mode));
                            break;
                    }
                } else {
                    // web
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((data: GeolocationPosition) => {
                            if (data) {
                                // console.log(data);
                                let coordinates: LatLng = new LatLng(data.coords.latitude, data.coords.longitude);
                                let t1 = new Date().getTime();
                                let loc: IGeolocationResult = {
                                    coords: coordinates,
                                    timestamp: t1,
                                    elapsed: 0
                                };

                                this.locationSender(loc);
                                this.locationMonitor.setLocationCache(coordinates);
                                resolve(coordinates);
                            } else {
                                reject(new Error("location not found web"));
                                return;
                            }
                        }, (err: GeolocationPositionError) => {
                            // console.error(err);
                            // setTimeout(()=>{
                            //     this.getCurrentLocation();
                            // }, 500);
                            reject(err);
                        }, this.singleRequestOptions.web);
                    } else {
                        reject(new Error("Geolocation is not supported by this browser."));
                    }
                }
            }, 1);
        });
        return promise;
    }

    checkPolling() {
        if (this.flags.watchLocationEnabled) {
            this.timeouts.location = setTimeout(() => {
                this.watchPositionCore();
            }, 500);
        }
    }

    /**
     * triggered when location found and coords have been retrieved
     */
    onLocationFoundWatch() {
        // initial fix done
        // now it should work properly at least until next app restart
        this.locationFixed = true;
        this.locationTimeoutCounter = 0;
    }

    /**
     * triggered when location not found e.g. simple timeout
     */
    onLocationNotFoundWatch() {
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
        this.locationTimeoutCounter += 1;
        if (this.locationTimeoutCounter >= this.locationTimeoutLimit) {
            this.locationTimeoutCounter = 0;
            this.resetLocationWatchdog();
            this.fireLocationWatchdog();
        }
    }

    watchPositionCore() {
        // console.log("watch position core");
        if (!this.platform.WEB) {
            switch (this.mode) {
                case EGeolocationModes.native:
                case EGeolocationModes.nativePolling:
                    if (navigator.geolocation) {
                        // use the navigator from the user phone browser
                        console.log("watch location using navigator");
                        if (this.watches.nativeGeolocation === null) {
                            this.watches.nativeGeolocation = navigator.geolocation.watchPosition((location: GeolocationPosition) => {
                                if (location) {
                                    this.onLocationFoundWatch();
                                    if (this.flags.watchLocationEnabled) {
                                        console.log("location updated using native watch");
                                        let t1 = new Date(location.timestamp).getTime();
                                        let coords: LatLng = new LatLng(location.coords.latitude, location.coords.longitude);
                                        let loc: IGeolocationResult = {
                                            coords,
                                            timestamp: t1,
                                            elapsed: 0,
                                            accuracy: location.coords.accuracy,
                                            speed: location.coords.speed,
                                            heading: location.coords.heading
                                        };
                                        this.resetLocationWatchdog();
                                        this.locationSender(loc);
                                        this.locationMonitor.setLocationCache(coords);
                                    }
                                }
                            }, (err: GeolocationPositionError) => {
                                console.log("watch position error ", err);
                                this.locationMonitor.setLocationFromUnified(null);
                                this.onLocationNotFoundWatch();

                            }, this.watchOptions.navigator);
                            // do not unsubscribe as it can cause errors (we put it into this.observables_other which do not get unsubscribed when exit page)
                        }
                    }
                    break;
                case EGeolocationModes.background:
                    console.log("watch location using background geolocation");

                    console.warn("not available");
                    // if (!this.observables.backgroundGeolocation) {
                    //     this.observables.backgroundGeolocation = this.backgroundGeolocation.configure(this.watchOptions.background)
                    //         .then((location: BackgroundGeolocationResponse) => {
                    //             // console.log(location);
                    //             if (location) {
                    //                 let t1 = new Date(location.time).getTime();
                    //                 let coords: LatLng = new LatLng(location.latitude, location.longitude);
                    //                 let loc: IGeolocationResult = {
                    //                     coords,
                    //                     timestamp: t1,
                    //                     elapsed: 0,
                    //                     accuracy: location.accuracy,
                    //                     speed: location.speed,
                    //                     heading: location.bearing
                    //                 };

                    //                 this.locationSender(loc);
                    //                 this.resetLocationWatchdog();
                    //             }
                    //             // IMPORTANT:  You must execute the finish method here to inform the native plugin that you're finished,
                    //             // and the background-task may be completed.  You must do this regardless if your HTTP request is successful or not.
                    //             // IF YOU DON'T, ios will CRASH YOUR APP for spending too much time in the background.
                    //             // this.backgroundGeolocation.finish().then(() => { }).catch((err: Error) => {
                    //             //     console.error(err);
                    //             // }); // FOR IOS ONLY
                    //         }, (err: Error) => {
                    //             console.error(err);
                    //         });
                    //     this.setBackgroundGeolocation(true);
                    // }
                    break;
                case EGeolocationModes.cordova:
                    console.log("watch location using cordova");
                    if (this.subscription.cordovaGeolocation === null) {
                        this.watches.cordovaGeolocation = this.geolocation.watchPosition(this.watchOptions.cordova);
                        this.subscription.cordovaGeolocation = this.watches.cordovaGeolocation.subscribe((data: Geoposition) => {
                            console.log("location updated using cordova watch");
                            if (data && data.coords) {
                                this.onLocationFoundWatch();
                                this.locationSenderCordova(data);
                                this.locationMonitor.setLocationCache(new LatLng(data.coords.latitude, data.coords.longitude));
                                this.resetLocationWatchdog();
                            }
                        }, (err: Error) => {
                            console.error(err);
                            // retry subscribing to location
                            if (!this.errorFlag.watchPosition) {
                                this.errorFlag.watchPosition = true;
                                this.analytics.dispatchError(err, "location-manager");
                            }

                            this.locationMonitor.setLocationFromUnified(null);
                            this.onLocationNotFoundWatch();
                        });
                    }
                    break;
                case EGeolocationModes.cordovaPolling:
                    this.geolocation.getCurrentPosition(this.watchOptions.cordova).then((data: Geoposition) => {
                        // console.log(data);
                        if (data && data.coords) {
                            console.log('location updated using cordova polling: ', data.coords);
                            this.onLocationFoundWatch();
                            this.locationSenderCordova(data);
                            this.locationMonitor.setLocationCache(new LatLng(data.coords.latitude, data.coords.longitude));
                            this.resetLocationWatchdog();
                        } else {
                            console.log("location not found using cordova");
                        }
                        this.checkPolling();
                    }).catch((err: Error) => {
                        console.error(err);
                        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
                        this.checkPolling();
                    });

                    break;
                case EGeolocationModes.gmap:
                    // console.log("using gmap");
                    if (this.map) {
                        // use map geolocation

                        this.timeouts.location = setTimeout(() => {
                            this.map.getMyLocation(this.watchOptions.gmap).then((location: MyLocation) => {
                                if (!this.flags.promiseEnabled) {
                                    return;
                                }

                                if (location) {
                                    this.onLocationFoundWatch();
                                    // console.log("location manager get location");
                                    if (this.flags.watchLocationEnabled) {
                                        // let t1 = new Date().getTime();
                                        let t1 = new Date(location.time).getTime();
                                        let loc: IGeolocationResult = {
                                            coords: location.latLng,
                                            timestamp: t1,
                                            elapsed: 0
                                        };
                                        this.resetLocationWatchdog();
                                        this.locationSender(loc);
                                        this.locationMonitor.setLocationCache(location.latLng);
                                        this.watchPositionCore();
                                    }
                                }

                            }).catch((err: Error) => {
                                console.error(err);
                                this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
                                this.locationMonitor.setLocationFromUnified(null);
                                this.watchPositionCore();
                            });
                        }, 500);
                    }
                    break;
                default:
                    break;
            }
        } else if (this.platform.WEB) {
            // use browser geolocation

            this.timeouts.location = setTimeout(() => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((data: GeolocationPosition) => {
                        if (data) {
                            this.onLocationFoundWatch();
                            // console.log(data);
                            let location: LatLng = new LatLng(data.coords.latitude, data.coords.longitude);
                            let t1 = new Date().getTime();
                            let loc: IGeolocationResult = {
                                coords: location,
                                timestamp: t1,
                                elapsed: 0
                            };
                            if (this.flags.watchLocationEnabled) {
                                this.resetLocationWatchdog();
                                this.locationSender(loc);
                                this.locationMonitor.setLocationCache(location);
                                this.watchPositionCore();
                            }
                        }
                    }, (err: GeolocationPositionError) => {
                        console.log("watch position error ", err);
                        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
                        this.locationMonitor.setLocationFromUnified(null);
                        this.timeouts.location = setTimeout(() => {
                            if (this.flags.watchLocationEnabled) {
                                this.watchPositionCore();
                            }
                        }, 2000);
                    }, this.watchOptions.web);
                } else {
                    this.locationMonitor.setLocationFromUnified(null);
                }
            }, 500);
        } else {
            this.locationMonitor.setLocationFromUnified(null);
        }
    }



    /**
     * process raw data from cordova geolocation api
     * @param data 
     */
    locationSenderCordova(data: Geoposition) {
        let t1 = new Date(data.timestamp).getTime();
        let coords: LatLng = new LatLng(data.coords.latitude, data.coords.longitude);
        let loc: IGeolocationResult = {
            coords,
            timestamp: t1,
            elapsed: 0,
            accuracy: data.coords.accuracy,
            speed: data.coords.speed,
            heading: data.coords.heading
        };

        this.locationSender(loc);
    }

    setPrevLoc(loc: IGeolocationResult) {
        this.prevLoc = {
            coords: new LatLng(loc.coords.lat, loc.coords.lng),
            elapsed: loc.elapsed,
            timestamp: loc.timestamp
        };
    }

    /**
     * dispatch geolocation result to the location monitor
     * after some basic processing e.g. elapsed time
     */
    locationSender(loc: IGeolocationResult) {
        if (this.prevLoc === null) {
            this.setPrevLoc(loc);
        }

        loc.elapsed = loc.timestamp - this.prevLoc.timestamp;

        this.setPrevLoc(loc);

        if (loc.elapsed >= EGPSTimeout.slowUpdate) {
            this.locationSlowCounter += 1;
            if (this.locationSlowCounter >= this.locationSlowCounterLimit) {
                this.locationSlowCounter = 0;
                this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutSlowUpdate);
                this.locationSlowWarningCounter += 1;

                if (this.locationSlowWarningCounter >= 3) {
                    this.locationSlowWarningCounter = 0;
                    this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutSlowUpdateWarning);
                }
            }
        } else {
            this.locationSlowCounter = 0;
            this.locationSlowWarningCounter = 0;
        }

        // console.log("location sender ", loc);
        this.locationMonitor.setLocationFromUnified(loc);
    }

    /**
     * start position watch
     * returns true if not already enabled
     * returns false if previously enabled
     * set master enabled flag
     */
    startWatchPosition(): boolean {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        console.log("watchPosition");
        this.flags.watchLocationMasterEnabled = true;
        return this.startWatchPositionCore();
    }

    /**
    * start position watch
    * returns true if not already enabled
    * returns false if previously enabled
    */
    startWatchPositionCore(): boolean {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        console.log("start watch position core");

        if (!this.flags.watchLocationEnabled) {
            this.flags.watchLocationEnabled = true;
            console.log("watch position enabled");
            this.watchPositionCore();
            return true;
        }
        return false;
    }

    /**
     * fully enable/disable location manager
     * when disabled, watch position cannot be enabled (e.g. after gmap deinit request)
     * @param lock 
     */
    setMasterLock(lock: boolean) {
        this.flags.masterLock = lock;
    }


    /**
     * resume if master enabled
     */
    resumeWatchPosition() {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        if (this.flags.watchLocationMasterEnabled) {
            this.startWatchPositionCore();
        }
    }

    /**
     * pause if master enabled
     */
    pauseWatchPosition(): Promise<boolean> {
        if (this.flags.watchLocationMasterEnabled) {
            return this.stopWatchPositionResolveCore();
        } else {
            return Promise.resolve(true);
        }
    }


    /**
     * stop watch
     * reset master enabled flag
     */
    stopWatchPositionResolve(): Promise<boolean> {
        console.log("stop watch position");
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.stopWatchPositionResolveCore().then(() => {
                this.flags.watchLocationMasterEnabled = false;
                resolve(true);
            });
        });
        return promise;
    }

    /**
     * stop watch
     */
    stopWatchPositionResolveCore(): Promise<boolean> {
        console.log("stop watch position core");
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            this.flags.watchLocationEnabled = false;

            // there is a problem here!
            if (this.mode === EGeolocationModes.native && navigator && navigator.geolocation && this.watches.nativeGeolocation !== null) {
                navigator.geolocation.clearWatch(this.watches.nativeGeolocation);
                this.watches.nativeGeolocation = null;
            }

            this.subscription.cordovaGeolocation = ResourceManager.clearSub(this.subscription.cordovaGeolocation);

            if (this.observables.backgroundGeolocation !== null) {
                this.setBackgroundGeolocation(false);
                ResourceManager.clearSub(this.observables.backgroundGeolocation);
            }

            this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);

            this.prevLoc = null;
            this.clearLocationWatchdog();
            console.log("stop watch position core complete");

            await SleepUtils.sleep(500);
            resolve(true);
        });
        return promise;
    }

}
