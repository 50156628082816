import { IGameConfig } from "../../app/config";
import { IFeatureDef, IStandardPhoto, IServiceUrlResponse } from "./app";
import { IPlaceProvider } from "../places/provider";
import { IStatDef } from "../user/stats";
import { ILocationType } from "../places/backend-location";
import { IUserFlagDB } from "./settings";
import { INewsfeedInbox } from "../newsfeed/newsfeed";
import { IUserPublicData } from "../user/general";
import { ITreasureSpec } from "../places/leplace";
import { IMapStyle } from "../core/story";
import { IMessageTimelineCategory } from "../newsfeed/message-timeline";
import { AppConstants } from "../../app/constants";
import { IPlaceScanParamsResponse } from '../items/scanner';
import { ICheckReferralCode } from '../../utils/popup-features';


export interface IAppResourcesGeneral extends IGenericResources {
    /**
    * e.g. coin value, default scan energy, etc
    * replaces: /app/get-config
    */
    gameConfig: {
        loaded: boolean,
        content: IGameConfig
    };
    /**
     * e.g. create group, scan place, world editor
     * replaces: /user/check-price-list
     */
    featureList: {
        loaded: boolean,
        content: IFeatureDef[]
    };
    /**
     * e.g. item found, time expired, story locked
     * replaces: /app/get-standard-photos
     */
    standardPhotos: {
        loaded: boolean,
        content: IStandardPhoto[]
    };
    /**
     * e.g. google, here
     * replaces: /app/location-providers
     */
    placeProviders: {
        loaded: boolean,
        content: IPlaceProvider[]
    };
    /**
     * stat codes with names, weights
     * replaces: /app/get-stat-scores
     */
    statScores: {
        loaded: boolean,
        content: IStatDef[]
    };
    /**
     * place type, icon, photo url
     * replaces: /places/get-templates
     */
    placeTemplates: {
        loaded: boolean,
        content: ILocationType[]
    };
    /**
     * replaces: /app/places/scan-params
     */
    placeScanParams: {
        loaded: boolean,
        content: IPlaceScanParamsResponse
    };
    /**
     * external services e.g. mp, media
     * replaces: /app/service-url
     */
    appServices: {
        loaded: boolean,
        content: IServiceUrlResponse
    };
    /**
     * map styles definitions (without actual config)
     * replaces: /app/map-styles
     */
    mapStyles: {
        loaded: boolean,
        content: IMapStyle[]
    };
    /**
     * used for marker specs
     * e.g. explore coins, find target
     * replaces: /app/treasure-specs
     * where: map loading
     * DISABLED
     */
    treasureSpecs: {
        loaded: boolean,
        content: ITreasureSpec[]
    };
    /**
     * used for map filters
     * replaces: /app/treasure-specs-generic 
     * where: map loading
     */
    treasureSpecsGeneric: {
        loaded: boolean,
        content: ITreasureSpec[]
    };
    /**
     * dynamic loaded
     */
    messageTimeline: {
        loaded: boolean,
        content: IMessageTimelineCategory[]
    };
}

export interface IAppResourcesUser extends IGenericResources {
    /**
    * user flags/settings that are synced with the server
    * replaces: /user/get-all-flags
    */
    userFlags: {
        loaded: boolean,
        content: IUserFlagDB[]
    };
    /**
     * check user inbox (newsfeed)
     * replaces: /newsfeed/check-inbox
     */
    newsfeedInbox: {
        loaded: boolean,
        content: INewsfeedInbox
    };
    /**
     * check if user referral code was validated by other users
     * replaces: /misc/referral/check
     */
    referralCheck: {
        loaded: boolean,
        content: ICheckReferralCode
    };
    /**
     * public info about the user
     * replaces: /user/profile-picture/get-url
     * mode: extend
     * where: map loading
     */
    general: {
        loaded: boolean,
        content: IUserPublicData
    };
}

/**
 * container for loading app resources via single request
 */
export interface IAppResources {
    general: IAppResourcesGeneral;
    user: IAppResourcesUser;
}

export interface IGenericResources {
    [key: string]: {
        loaded: boolean,
        content: any
    };
}

export interface IAppResourcesResponse {
    loaded: number;
    failed: number;
    resources: IAppResources;
}


export class CAppResources implements IAppResources {
    general: IAppResourcesGeneral = {
        gameConfig: {
            loaded: false,
            content: AppConstants.gameConfig
        },
        featureList: {
            loaded: false,
            content: []
        },
        standardPhotos: {
            loaded: false,
            content: []
        },
        placeProviders: {
            loaded: false,
            content: []
        },
        statScores: {
            loaded: false,
            content: []
        },
        placeTemplates: {
            loaded: false,
            content: []
        },
        placeScanParams: {
            loaded: false,
            content: {
                // the order is important for prioritisation here
                // as opposed to the treasure scan
                placeAds: [{
                    type: "restaurant",
                    limit: 5
                }, {
                    type: "cafe",
                    limit: 5
                }, {
                    type: "bar",
                    limit: 5
                }, {
                    type: "museum",
                    limit: 2
                }, {
                    type: "park",
                    limit: 2
                }, {
                    type: "store",
                    limit: 2
                }],
                treasures: [{
                    type: "park",
                    limit: 2
                }, {
                    type: "museum",
                    limit: 1
                }, {
                    type: "restaurant",
                    limit: 3
                }, {
                    type: "store",
                    limit: 2
                }, {
                    type: "cafe",
                    limit: 3
                }, {
                    type: "bar",
                    limit: 3
                }, {
                    type: "night_club",
                    limit: 3
                }, {
                    type: "church",
                    limit: 2
                }, {
                    type: "point_of_interest",
                    limit: 2
                }]
            }
        },
        appServices: {
            loaded: false,
            content: {
                object: {
                    mp: {
                        code: 2,
                        /**
                         * the regular url, locally is not filled, should be retrieved from the server
                         */
                        url: "ws://localhost:8202",
                        urlProd: "ws://localhost:8202",
                        /**
                        * the test url that may be used when the user (which is also a tester) logs in as a tester
                        */
                        urlTest: "ws://192.168.1.10:8202",
                        status: 0
                    },
                    mpHttp: {
                        code: 1,
                        /**
                         * the regular url, locally is not filled, should be retrieved from the server
                         */
                        url: "http://localhost:8202",
                        urlProd: "http://localhost:8202",
                        /**
                        * the test url that may be used when the user (which is also a tester) logs in as a tester
                        */
                        urlTest: "http://192.168.1.10:8202",
                        status: 0
                    },
                    // not used at the moment, running the data through api gateway because https, UPDATE: using this because file upload
                    media: {
                        code: 3,
                        /**
                         * the regular url, locally is not filled, should be retrieved from the server
                         */
                        url: "http://webpie.twilightparadox.com:8600",
                        urlProd: "http://webpie.twilightparadox.com:8600",
                        /**
                         * the test url that may be used when the user (which is also a tester) logs in as a tester
                         */
                        urlTest: "http://192.168.1.10:8600",
                        status: 0
                    },
                    bench: {
                        code: 0,
                        /**
                         * the regular url, locally is not filled, should be retrieved from the server
                         */
                        url: "http://webpie.twilightparadox.com:8101",
                        urlProd: "http://webpie.twilightparadox.com:8101",
                        /**
                         * the test url that may be used when the user (which is also a tester) logs in as a tester
                         */
                        urlTest: "http://192.168.1.10:8101",
                        status: 0
                    }
                }, array: []
            }
        },
        mapStyles: {
            loaded: false,
            content: []
        },
        treasureSpecs: {
            loaded: false,
            content: []
        },
        treasureSpecsGeneric: {
            loaded: false,
            content: []
        },
        messageTimeline: {
            loaded: false,
            content: []
        }
    };
    user: IAppResourcesUser = {
        userFlags: {
            loaded: false,
            content: []
        },
        newsfeedInbox: {
            loaded: false,
            content: null
        },
        referralCheck: {
            loaded: false,
            content: null
        },
        general: {
            loaded: false,
            // init fallback (prevent null errors)
            content: {
                id: null,
                name: "",
                alias: "",
                timestamp: null,
                loginTimestamp: null,
                cachedCoins: 0,
                cachedDistance: 0,
                cachedLevel: 0,
                cachedScore: 0,
                photoUrl: null,
                rank: 0,
                countryCode: null,
                settings: [],
                stats: null,
                tutorialSeen: 0
            }
        }
    };

    constructor() {

    }
}
