<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="false" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
  <div [class]="wrapperClass" fxLayout="column">
    <div [class]="innerClass" fxLayout="column">
      <ion-row class="slider-controls">
        <ion-col class="col-with-arrow control-icon" (click)="slidePrev()" no-padding size="1">
          <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
        </ion-col>
        <ion-col size="10" #sliderContainer>
          <ion-slides *ngIf="loaded" [pager]="true" [options]="sliderOptions"
            class="slider-tutorial full-height-modal slide-width-modal" (ionSlideDidChange)="sliderChanged()">
            <ion-slide *ngFor="let slide of slidesData; let i = index" fxLayout="column" class="slide-height">
              <div class="scrollbar-y full-height-modal text-center" fxLayout="column">
                <span class="info-text-m bold text-color-primary padding-top-s">{{slide.heading}}</span>
                <div linkInterceptor [innerHTML]="description"
                  class="info-text-m description-frame text-color-primary padding-top-s padding-bottom-s">
                </div>
                <qr-code *ngIf="slide.qrCode && !slide.plainText" [index]="i" [code]="slide.qrCode" [interact]="true">
                </qr-code>
                <ion-row *ngIf="slide.plainText">
                  <ion-col size="12" class="padding-s">
                    <div *ngIf="!checkUseInputCode(slide.qrCode)"
                      class="paste-code bold description-frame text-color-primary">{{slide.qrCode}}</div>
                    <edit-code *ngIf="checkUseInputCode(slide.qrCode)" [(prop)]="slide.qrCode" placeholder="Auto"
                      [numeric]="false" [dim]="unlockCodeShortLength" [autocaps]="true" [disabled]="true">
                      <inner-content></inner-content>
                    </edit-code>
                    <!-- <div class="paste-code bold description-frame text-color-primary">{{slide.qrCode}}</div> -->
                  </ion-col>
                </ion-row>
                <ion-button *ngIf="slide.plainText"
                  class="as-text element-selector info-text-s text-color-primary zoom-btn"
                  (click)="copyToClipboard(slide.qrCode)">
                  <icon-wrapper slot="icon-only" [icon]="appIconsStandard.clipboard" [custom]="false"></icon-wrapper>
                </ion-button>

                <!-- <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-top-m" *ngIf="!isDesktop">
                  <ion-icon [icon]="appIcons.swipe"></ion-icon>
                </div> -->
                <div *ngIf="slide.withAction">
                  <ion-button class="action-button-fill button-color-primary" (click)="applyCallback(slide, 1)"
                    icon-end>
                    <span class="button-font-size-s">{{slide.actionLabel}}</span>
                    <icon-wrapper slot="start" [icon]="appIconsStandard.refresh" [custom]="false"
                      class="slot-icon-padding">
                    </icon-wrapper>
                  </ion-button>
                </div>
                <div *ngIf="slide.withEmbed">
                  <ion-button class="action-button-fill button-color-primary" (click)="applyCallback(slide, 2)"
                    icon-end>
                    <span class="button-font-size-s">Embed</span>
                    <icon-wrapper slot="start" [icon]="appIconsStandard.embed" [custom]="false"
                      class="slot-icon-padding">
                    </icon-wrapper>
                  </ion-button>
                </div>
              </div>
            </ion-slide>
          </ion-slides>
        </ion-col>
        <ion-col class="col-with-arrow control-icon" (click)="slideNext()" no-padding size="1">
          <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline">
          </icon-wrapper>
        </ion-col>
      </ion-row>
    </div>

    <!-- <div fxFlex>
    </div> -->

    <!-- <div class="stick-bottom" [ngClass]="buttonBar">
      <ion-grid class="full-w no-padding">
        <ion-row>
          <ion-col size="12" class="no-padding">
            <ion-button class="action-button-fill button-color-primary" (click)="ok()">
              <span class="button-font-size-m">
                Ok
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div> -->
  </div>
</modal-container>