import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'youtube-view',
    templateUrl: './youtube-view.component.html',
    styleUrls: ['./youtube-view.component.scss'],
})

export class YouTubeViewComponent implements OnInit, OnDestroy {

    @Input()
    videoId: string;

    @Input()
    videoUrl: string;

    @Input()
    customClass: string;

    @Input()
    noFrame: boolean;

    @Input()
    portrait: boolean;

    viewClass: string = "modal-large-height-inner";

    urlSecurized: SafeResourceUrl = null;

    boxWidth: number;

    constructor(private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
        let params: string = "?controls=1&autoplay=0&loop=1&modestbranding=1&rel=0";
        // this.urlSecurized = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/watch?v=' + this.videoId);
        if (this.videoUrl != null) {
            const videoIdMatch = this.videoUrl.match(/youtube\.com\/embed\/(.+)/);
            if (videoIdMatch) {
                // using embed URL
                const videoId = videoIdMatch[1];
                console.log("Video ID:", videoId);
                if (videoId != null) {
                    params += "&playlist=" + videoId;
                }
            } else {
                // using video URL
                const urlParams = new URLSearchParams(new URL(this.videoUrl).search);
                let videoId: string = urlParams.get("v");
                if (videoId != null) {
                    params += "&playlist=" + videoId;
                }
            }
            this.urlSecurized = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl + params);
        } else {
            // using video ID
            this.urlSecurized = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId + params + "&playlist=" + this.videoId);
            console.log("loaded video url securized for video id: " + this.videoId);
        }
        if (this.customClass) {
            this.viewClass = this.customClass;
        }
        if (!this.noFrame) {
            this.viewClass += " video-frame";
        }

        if (this.portrait) {
            this.setPortraitMode();
        }
    }

    setPortraitMode() {
        const viewportHeight = window.innerHeight * 0.8 - 64;
        this.boxWidth = (viewportHeight * 9) / 16;
    }

    ngOnDestroy() {

    }
}
