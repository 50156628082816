
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../../general/ui/ui-extension';
import { AppConstants } from '../../../../classes/app/constants';
import { IDescriptionFrameParams, EDescriptionViewStyle, DescriptionFrameViewComponent } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { YouTubeService } from 'src/app/services/general/apis/youtube';
import { ISignupTermsNavParams } from 'src/app/classes/def/nav-params/modals';
import { SignupTermsPage } from 'src/app/pages/logins/signup-terms/signup-terms.page';
import { SignupTutorialPage } from 'src/app/pages/logins/signup-tutorial/signup-tutorial.page';

@Injectable({
    providedIn: 'root'
})
export class TutorialsService {
    constructor(
        public uiext: UiExtensionService,
        public ytb: YouTubeService
    ) {
        console.log("tutorials service created");

    }

    
    showSignupTerms(withButtons: boolean) {
        let promise = new Promise((resolve, reject) => {

            let params: ISignupTermsNavParams = {
                withButtons: withButtons
            };

            this.uiext.showCustomModal(null, SignupTermsPage, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then((res) => {
                resolve(res);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    showSignupTutorial() {
        let promise = new Promise((resolve, reject) => {
            this.uiext.showCustomModal(null, SignupTutorialPage, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: null
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    showSignupTermsNoAction(withButtons: boolean) {
        this.showSignupTerms(withButtons).then(() => {

        }).catch(() => {

        });
    }

    showSignupTutorialNoAction() {
        this.showSignupTutorial().then(() => {

        }).catch(() => {

        });
    }
    
    checkVideoCode(params: IDescriptionFrameParams, videoCode: number, videoString: string): IDescriptionFrameParams {
        if ((videoCode != null) || (videoString != null)) {
            params.mode = EDescriptionViewStyle.withCustomButton;
            params.customButton = {
                name: null,
                code: null,
                icon: EAppIcons.film,
                customIcon: true,
                class: null,
                size: null,
                disabled: false,
                enabled: true,
                highlight: null,
                callback: null
            };
            params.customButtonFn = () => {
                if (videoString) {
                    this.ytb.open(videoString);
                } else {
                    if (videoCode) {
                        this.ytb.openWithCode(videoCode);
                    }
                }
            };
        }
        return params;
    }


    showTutorialNoAction(title: string, loaderCode: number, description: string, paramsOverride: IDescriptionFrameParams, large: boolean) {
        this.showTutorialResolve(title, loaderCode, description, paramsOverride, large).then(() => {

        });
    }

    showTutorialLocalResolve(title: string, description: string) {
        let params: IDescriptionFrameParams = {
            title: title,
            description: description,
            mode: EDescriptionViewStyle.withOk,
            photoUrl: null,
            loaderCode: null
        };
        return this.showTutorialResolve(null, null, null, params, false);
    }

    showTutorialResolve(title: string, loaderCode: number, description: string, paramsOverride: IDescriptionFrameParams, large: boolean): Promise<any> {
        let promise: Promise<any> = new Promise((resolve) => {
            let params: IDescriptionFrameParams = {
                title: title,
                description: description,
                mode: EDescriptionViewStyle.plain,
                photoUrl: null,
                loaderCode: loaderCode
            };

            if (paramsOverride) {
                params = paramsOverride;
            }

            this.uiext.showCustomModal(null, DescriptionFrameViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: AppConstants.transparentMenus,
                    large: large,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then((res: any) => {
                resolve(res);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
        return promise;
    }
}
