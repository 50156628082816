
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { HTTPErrorHandlerService } from '../data/error-handler';
import { IAppFlagsGen } from '../../../classes/def/app/storage-flags';
import { IAppFlagsElement } from '../../../classes/def/app/storage-flags';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../../utils/wait-utils';


@Injectable({
    providedIn: 'root'
})
export class StorageOpsService {
    storageCreatedObs: BehaviorSubject<boolean>;
    storageCreated: boolean = false;

    constructor(
        public storage: Storage,
        public errorHandler: HTTPErrorHandlerService
    ) {
        console.log("storage ops service created");
        this.storageCreatedObs = new BehaviorSubject<boolean>(null);
        this.storage.create().then(() => {
            this.storageCreatedObs.next(true);
            this.storageCreated = true;
            console.log("storage initialized");
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    getStorage(): Promise<Storage> {
        return new Promise((resolve) => {
            if (this.storageCreated) {
                resolve(this.storage);
                return;
            }
            WaitUtils.waitFlagResolve(this.storageCreated, this.storageCreatedObs, [true], null).then(() => {
                resolve(this.storage);
            });
        });
    }

    getStorageCreated() {
        return this.storage;
    }

    /**
     * get local data by key
     * @param key 
     */
    getLocalDataKey(key: string) {
        return this.storage.get(key);
    }

    /**
     * get local data by key
     * resolve data or null
     * @param key 
     */
    getLocalDataKeyResolve(key: string): Promise<any> {
        return new Promise((resolve) => {
            this.storage.get(key).then((val) => {
                resolve(val);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
    }


    /**
     * clear user data from local storage
     * useful e.g. when the user logs out
     * @param keys 
     */
    clearStorageKeys(keys: string[]) {
        for (let i = 0; i < keys.length; i++) {
            this.storage.remove(keys[i]).then(() => {
                console.log(keys[i] + " removed");
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * clear all data in the local storage
     */
    clearStorage() {
        let promise = new Promise((resolve, reject) => {
            this.storage.keys().then((keys: string[]) => {
                let promises = [];
                for (let i = 0; i < keys.length; i++) {
                    promises.push(new Promise((resolve) => {
                        this.storage.remove(keys[i]).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(false);
                        });
                    }));
                }
                Promise.all(promises).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;

        // this.storage.clear().then(() => {
        //     console.log("storage cleared");
        // }).catch((err: Error) => {
        //     console.error(err);
        // });
    }





    /**
     * get a single storage flag, or set if not existing
     * resolve only
     * @param flag 
     * @param value 
     */
    checkStorageFlagResolve(flag: IAppFlagsElement, save: boolean): Promise<any> {
        let promise: Promise<any> = new Promise((resolve) => {
            // console.log("check storage flag");
            this.storage.get(flag.flag).then((value: any) => {
                if (value !== null) {
                    resolve(value);
                } else {
                    if (save) {
                        this.setStorageFlagNoAction(flag);
                    }
                    resolve(flag.value);
                }
            }).catch((err: Error) => {
                console.error(err);
                if (save) {
                    this.setStorageFlagNoAction(flag);
                }
                resolve(flag.value);
            });
        });
        return promise;
    }


    saveLocalDataNoAction(data: IAppFlagsElement[]) {
        this.saveLocalDataResolve(data).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }
    /**
    * save user data to local storage
    * @param data 
    */
    saveLocalDataResolve(data: IAppFlagsElement[]) {
        let promise = new Promise((resolve) => {
            let promises = [];
            promises.push(Promise.resolve(true));
            for (let i = 0; i < data.length; i++) {
                promises.push(new Promise((resolve) => {
                    this.storage.set(data[i].flag, data[i].value).then(() => {
                        console.log(data[i].flag + " updated");
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                }));
            }

            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
        return promise;
    }

    /**
     * set storage using flag element container (wrapper)
     * @param flag 
     */
    setStorageFlagNoAction(flag: IAppFlagsElement) {
        this.setStorageFlag(flag).then(() => {
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * set storage using flag element container (wrapper)
     * @param flag 
     */
    setStorageFlag(flag: IAppFlagsElement) {
        return this.storage.set(flag.flag, flag.value);
    }

    /**
    * set storage using flag element container (wrapper)
    * resolve only
    * @param flag 
    */
    setStorageFlagResolve(flag: IAppFlagsElement) {
        return new Promise((resolve) => {
            this.storage.set(flag.flag, flag.value).then((res) => {
                resolve(res);
            }).catch((err) => {
                console.error(err);
                resolve(null);
            });
        });
    }

    /**
     * get multiple storage flags, or set if not existing
     * @param flags 
     */
    loadMultipleStorageFlags(flags: IAppFlagsGen) {
        let promises: Promise<boolean>[] = [];
        let promise = new Promise((resolve, reject) => {
            let flagKeys: string[] = Object.keys(flags);
            for (let i = 0; i < flagKeys.length; i++) {
                promises.push(new Promise((resolve) => {
                    this.checkStorageFlagResolve(flags[flagKeys[i]], true).then((value) => {
                        // console.log("flag: ", value);
                        if (value !== null) {
                            flags[flagKeys[i]].value = value;
                        }
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    })
                }));
            }
            promises.push(Promise.resolve(true));
            Promise.all(promises).then(() => {
                console.log("storage flags: ", flags);
                resolve(flags);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
