<div>
    <ion-grid class="full-w padding-xs">

        <ion-row *ngIf="titleDisp" class="center-text">
            <ion-col>
                <div [innerHTML]="titleDisp" class="info-text-m bold description-frame text-color-primary center-text">
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="descriptionDisp" class="text-left">
            <ion-col>
                <div [innerHTML]="descriptionDisp" class="info-text-m description-frame text-color-primary text-left">
                </div>
            </ion-col>
        </ion-row>

        <!-- <ion-row *ngIf="promo">
            <ion-col size="12">
                <span class="info-text-s center-text">{{getPromoDisp()}}</span>                
            </ion-col>
        </ion-row>   -->

        <ion-row>
            <ion-col size="12">
                <ion-button class="action-button-fill-large button-color-alternate" (click)="tapCheckout()">
                    <!-- <span class="button-font-size-s padding-horizontal-s">{{checkoutDisp}}</span> -->
                    <span class="button-font-size-s padding-horizontal-s">Checkout</span>
                    <div class="flex-col padding-left-s">
                        <span *ngIf="!promo" class="info-text-s bold">{{price}}</span>
                        <span *ngIf="promo" class="info-text-xs text-strikethrough">{{price}}</span>
                        <span *ngIf="promo" class="info-text-s bold">{{promo}}</span>
                    </div>
                    <icon-wrapper slot="start" [icon]="appIcons.secureCheckout" [custom]="true"
                        class="slot-icon-padding">
                    </icon-wrapper>
                </ion-button>

                <!-- <ion-button [disabled]="!placeUrl"
                    class="as-text element-selector info-text-s text-color-primary text-center"
                    (click)="goToReference()">
                    <span class="button-font-size-xs">Visit website</span>
                    <icon-wrapper slot="start" [icon]="appIcons.www" [custom]="true" class="slot-icon-padding">
                    </icon-wrapper>
                </ion-button> -->

            </ion-col>
        </ion-row>

        <div class="text-center margin-bottom-s">
            <img [src]="stripeBadge" height="40" class="padding-top-s">
        </div>

        <ion-row *ngIf="footerDisp" class="text-left">
            <div [innerHTML]="footerDisp" class="info-text-s description-frame text-color-primary"></div>
        </ion-row>

    </ion-grid>
</div>