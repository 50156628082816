import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { TimeUtils } from "../../../classes/general/time";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';

@Injectable({
    providedIn: 'root'
})
export class TimeoutService {
    timeObs = null;
    moveTimer = null;
    enableAuxCounter: boolean = false;

    timeParams: ITimeoutMonitorParams = {
        timeLimit: 0
    };

    timeDataInit: ITimeoutMonitorData = {
        timerValue: 0,
        timerValueTs: 0,
        elapsedValue: 0,
        elapsedValueAux: 0,
        status: ETimeoutStatus.started,
        timerDisp: ""
    };

    timeData: ITimeoutMonitorData;

    constructor(
    ) {
        console.log("timeout service created");
        this.timeObs = new BehaviorSubject(null);
        this.timeData = Object.assign({}, this.timeDataInit);
    }

    private startTimer() {
        if (this.moveTimer) {
            return;
        }

        if (!this.timeParams.timeLimit) {
            return;
        }

        this.timeData.timerValueTs = new Date().getTime();

        this.runTimerAction();
    }

    runTimerAction() {
        this.moveTimer = setTimeout(() => {
            // check actual amount of time elapsed since last call (might not be exactly 1 sec, especially in bg mode on iOS)
            let timeCrt: number = new Date().getTime();
            let elapsedMs: number = timeCrt - this.timeData.timerValueTs;
            let elapsedSecs: number = Math.floor(elapsedMs / 1000);
            // next timestamp minus remainder of elapsed seconds crt
            this.timeData.timerValueTs = timeCrt - (elapsedMs - elapsedSecs * 1000);

            this.timeData.timerValue -= elapsedSecs;
            this.timeData.elapsedValue += elapsedSecs;

            if (this.enableAuxCounter) {
                this.timeData.elapsedValueAux += elapsedSecs;
            }

            if (this.timeData.timerValue < 0) {
                this.timeData.timerValue = 0;
                this.timeData.status = ETimeoutStatus.expired;
            }

            this.timeData.timerDisp = TimeUtils.formatTimeFromSeconds(this.timeData.timerValue, true);
            this.timeObs.next(this.timeData);
            this.runTimerAction();
        }, 1000);
    }

    setAuxCounter(enable: boolean) {
        this.enableAuxCounter = enable;
    }

    private stopTimer() {
        this.moveTimer = ResourceManager.clearTimeout(this.moveTimer);
    }

    getTimeoutData() {
        return this.timeData;
    }

    getWatch() {
        return this.timeObs;
    }

    start(params: ITimeoutMonitorParams) {
        console.log("start timeout monitor: ", params);
        this.timeParams = params;
        this.timeData = Object.assign({}, this.timeDataInit);
        this.timeData.timerValue = this.timeParams.timeLimit;
        this.timeObs.next(null);
        this.startTimer();
    }

    triggerExpired() {
        this.timeData.timerValue = 0;
    }

    stop() {
        console.log("stop timeout monitor");
        this.stopTimer();
    }
}
