import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AppComponentsModule } from '../components/app/app-components.module';
import { AppModalsModule } from '../modals/app/app-modals.module';
import { GenericModalsModule } from '../modals/generic/generic-modals.module';
import { DirectivesModule } from '../directives/directives.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PipesModule } from '../pipes/pipes.module';
import { GenericComponentsModule } from '../components/generic/generic-components.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

const impex = [
  CommonModule,
  FormsModule,
  IonicModule,
  GenericComponentsModule,
  AppComponentsModule,
  AppModalsModule,
  GenericModalsModule,
  DirectivesModule,
  FlexLayoutModule,
  PipesModule,
  NgxQRCodeModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  InfiniteScrollModule,
  NgbModule,
  NgbCarouselModule
];

@NgModule({
  declarations: [],
  imports: [...impex],
  exports: [...impex]
})
export class PagesModule { }
