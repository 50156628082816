import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';

/**
 * encapsulates the content with the specified view params
 * e.g. show/hide frame, transparency
 */
@Component({
  selector: 'modal-header-container',
  templateUrl: './modal-header-container.component.html',
  styleUrls: ['./modal-header-container.component.scss'],
})
export class ModalHeaderContainerComponent implements OnInit, OnDestroy {
  @Input()
  vs: IViewSpecs;
  headerClass: string = "";

  disableTransparency: boolean = true;

  constructor() {

  }

  ngOnInit() {
    if (!this.vs) {
      this.vs = ViewSpecs.getDefault();
    }

    if (this.vs.headerOpacity) {
      this.headerClass = "custom-modal-header-2";
    } else {
      this.headerClass = "toolbar-background-primary";
    }

    // && this.vs.frame
    // if (this.vs.fullScreen && !this.vs.transparent) {
    //   this.headerClass += " rounded-toolbar";
    // }

    this.headerClass += " theme-border-bottom";

    // if(this.vs.fullScreen){
    //   this.headerClass += " overlay-header theme-border-bottom";
    // } else {
    //   this.headerClass += " theme-border-bottom";
    // }

    console.log("modal header vs: ", this.vs);
    console.log("modal header class: ", this.headerClass);
  }

  ngOnDestroy() {

  }
}
