import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { EImgIcons, EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ILeplaceReg, IPlaceAux } from 'src/app/classes/def/places/google';
import { ITreasureLocationExtended } from 'src/app/classes/def/places/leplace';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { PlacesDataService } from 'src/app/services/data/places';
import { Util } from 'src/app/classes/general/util';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { IGetPhotoOptions, ISelectPhotoOptions } from 'src/app/services/location/location-utils-def';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { DomSanitizer } from '@angular/platform-browser';
import { IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'place-card',
  templateUrl: './place-card.component.html',
  styleUrls: ['./place-card.component.scss'],
})
export class PlaceCardComponent implements OnInit, OnDestroy {
  imgIcons = EImgIcons;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  @Input()
  place: ILeplaceReg;

  @Input()
  location: ITreasureLocationExtended;

  @Input()
  nofilter: boolean;

  @Input()
  selectIcon: string;

  @Input()
  selectIconCustom: boolean;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  placeType: string = "PLACE";
  registeredPlace: boolean = false;
  placeId: number = null;
  placeAux: IPlaceAux;
  placeName: string;
  rating: number = 0;

  show: boolean = false;
  initialized: boolean = false;

  /**
   * final condition for showing business features
   */
  enableBusinessFeaturesAction: boolean = false;

  constructor(
    public uiext: UiExtensionService,
    public businessFeaturesProvider: BusinessFeaturesService,
    public placeProvider: PlacesDataService,
    public locationUtilsWizard: LocationUtilsWizard,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.formatPlace();
    if (!this.selectIcon) {
      this.selectIcon = EAppIconsStandard.accept;
      this.selectIconCustom = false;
    }
    this.initialized = true;
  }

  ngOnDestroy() {

  }

  selectPlace() {
    this.select.emit(true);
  }

  openURL(url: string) {
    Util.openURLAdaptive(url);
  }

  /**
   * format place display
   */
  formatPlace() {

    if (this.place && this.place.place) {
      // treasure
      this.placeName = StringUtils.trimName(this.place.place.name, EMessageTrim.placeNameCard);
      this.rating = this.place.place.rating;
      this.placeId = this.place.place.id;

      this.placeAux = {
        mapsURL: Util.generateMapsLink(this.place.place.googleId, this.place.place.name),
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        cacheDisk: true,
        redirect: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.placeAux.photoUrl = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options).then((extracted: string) => {
          this.placeAux.photoUrl = extracted;
        });
      }

      this.placeType = this.place.place.type;
      this.placeType = Util.formatPlaceType(this.placeType);
      if (this.place.registeredBusiness) {
        this.placeType += " (verified)";
      }

      this.registeredPlace = this.place.registeredBusiness;
      if (this.registeredPlace) {
        this.enableBusinessFeaturesAction = true;
      }

      this.show = true;
    } else {
      // story location
      if (this.location && this.location.location) {
        let bloc: IBackendLocation = this.location.location.merged;

        this.placeName = StringUtils.trimName(bloc.name, EMessageTrim.placeNameCard);

        let opts: ISelectPhotoOptions = {
          hidden: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value ? true : null
        };

        LocationUtils.selectPlaceDispPhoto(this.location.location, null, opts);

        this.placeAux = {
          mapsURL: Util.generateMapsLink(bloc.googleId, bloc.name),
          photoUrl: this.location.location.dispPhoto.photoUrl,
          canvasLoaded: false,
          photoUrlCached: null,
          canvasURL: null
        };

        if (!opts.hidden) {
          this.locationUtilsWizard.runThroughCache(bloc.photoUrl).then((extracted: string) => {
            this.placeAux.photoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(extracted) as any;
            // this.placeAux.photoUrl = extracted;
          });
        }

        console.log("place aux: ", this.placeAux);

        this.placeType = bloc.type;
        this.placeType = Util.formatPlaceType(this.placeType);
        this.placeId = bloc.id;
        if (this.location.registered) {
          this.placeType += " (verified)";
        }

        this.registeredPlace = this.location.registered;
        if (this.registeredPlace) {
          this.enableBusinessFeaturesAction = true;
        }
        this.show = true;
      }
    }
  }

  /**
   * view available sales for this place
   */
  viewSales() {
    console.log("view sales: ");
    console.log(this.place);
    console.log(this.location);
    if (this.place && this.place.place) {
      this.businessFeaturesProvider.viewSalesCoreNoAction(this.place.place.googleId, this.place.place.name, null);
    } else {
      if (this.location && this.location.location) {
        let bloc: IBackendLocation = this.location.location.merged;

        this.businessFeaturesProvider.viewSalesCoreNoAction(bloc.googleId, bloc.name, null);
      }
    }
  }
}
