import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
})
export class ExpanderComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  expanded: boolean;

  @Input()
  large: boolean;

  // /**
  //  * In order to do two way binding, the output variable name should be, inputVarName + 'Change'
  //  */
  // @Output() expandedChange: EventEmitter<any> = new EventEmitter();

  // get Value() {
  //     return this.expanded;
  // }
  // set Value(tValue) {
  //     // Check some things about the new value then...
  //     this.expandedChange.emit(tValue);
  // }

  @Output()
  expand: EventEmitter<any> = new EventEmitter();


  constructor() {

  }

  setExpanded(expanded: boolean) {
    this.expanded = expanded;
    this.expand.emit(expanded);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'expanded':
            this.expanded = chg.currentValue;
            break;
        }
      }
    }
  }


  ngOnInit() {

  }

  ngOnDestroy() {

  }
}

