<div *ngIf="loaded">
    <ion-grid class="full-w padding-xs">

        <ion-row *ngIf="titleDisp" class="center-text">
            <ion-col>
                <div [innerHTML]="titleDisp" class="info-text-m bold description-frame text-color-primary center-text">
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="descriptionDisp" class="text-left">
            <ion-col>
                <div [innerHTML]="descriptionDisp" class="info-text-m description-frame text-color-primary text-left">
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="center-items">
            <ion-col size="4">
                <img [src]="imgIcons.googleIap" class="img-xxs" />
            </ion-col>
            <ion-col size="8" class="text-left">
                <div class="ion-float-left flex-row">
                    <span class="info-text-xs">from</span>
                    <div class="flex-col padding-left-s">
                        <span *ngIf="!priceAndroidPromo" class="info-text-s bold">{{priceAndroid}}</span>
                        <span *ngIf="priceAndroidPromo" class="info-text-xs text-strikethrough">{{priceAndroid}}</span>
                        <span *ngIf="priceAndroidPromo" class="info-text-s bold">{{priceAndroidPromo}}</span>
                    </div>
                    <span class="info-text-s text-color-primary padding-left-s">{{priceExtra}}</span>
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="center-items">
            <ion-col size="4">
                <img [src]="imgIcons.appleIap" class="img-xxs" />
            </ion-col>
            <ion-col size="8" class="text-left">
                <div class="ion-float-left flex-row">
                    <span class="info-text-xs">from</span>
                    <div class="flex-col padding-left-s">
                        <span *ngIf="!priceIOSPromo" class="info-text-s bold">{{priceIOS}}</span>
                        <span *ngIf="priceIOSPromo" class="info-text-xs text-strikethrough">{{priceIOS}}</span>
                        <span *ngIf="priceIOSPromo" class="info-text-s bold">{{priceIOSPromo}}</span>
                    </div>
                    <span class="info-text-s text-color-primary padding-left-s">{{priceExtra}}</span>
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="footerDisp" class="text-left">
            <div [innerHTML]="footerDisp" class="info-text-s description-frame text-color-primary"></div>
        </ion-row>

    </ion-grid>
</div>