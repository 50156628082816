<div *ngIf="small" class="flex-card item-card-small bg-container bg-container-fill item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="showDescription()">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12">
          <span class="info-text-xs bold text-color-overlay-s" *ngIf="showLabel">{{itemName}}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<div *ngIf="!small" class="flex-card item-card bg-container bg-container-fill item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="showDescription()">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12">
          <span class="info-text-xs bold text-color-overlay-s" *ngIf="showLabel">{{itemName}}</span>
        </ion-col>
      </ion-row>
      <ion-row class="padding-null" *ngIf="published">
        <ion-col size="2">
        </ion-col>
        <ion-col size="8" class="self-center">
        </ion-col>
        <ion-col size="2">
          <div class="ion-float-right leplace-icon-m icon-color-accent">
            <icon-wrapper [icon]="appIcons.check" [custom]="true"></icon-wrapper>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>