<div *ngIf="data">
  <ion-grid class="full-w">
    <plain-entry-header [name]="data.name" [icon]="data.icon" [withButton]="data.withButton"
      [customIcon]="data.customIcon" [photoUrl]="data.photoUrl" [center]="data.center" (action)="onTapSelect()">
    </plain-entry-header>
    <ion-row>
      <ion-col>
        <div [innerHTML]="data.description" class="info-text-m description-frame text-color-primary"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>