import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IGetCountriesWithDetailsAndNearby } from 'src/app/classes/def/world/world';
import { GeometryUtils } from '../utils/geometry-utils';
import { LatLng } from '@ionic-native/google-maps/ngx';

@Injectable({
    providedIn: 'root'
})
export class WorldDataService {

    cache: IGetCountriesWithDetailsAndNearby;
    // check distance from last scan location to determine if a new scan is required
    prevLat: number = null;
    prevLng: number = null;

    constructor(
        public generic: GenericDataService
    ) {
        console.log("world data service created");
    }

    /**
     * get countries list for local storyline
     * @param lat 
     * @param lng 
     * @param reload 
     */
    getCountriesWithDetailsAndNearby(lat: number, lng: number, reload: boolean): Promise<IGetCountriesWithDetailsAndNearby> {
        let promise: Promise<IGetCountriesWithDetailsAndNearby> = new Promise((resolve, reject) => {
            if (this.cache != null) {
                let resolveCache: boolean = false;
                if (!reload) {
                    if (this.prevLat != null && this.prevLng != null) {
                        // check distance from last request
                        // allow cache if distance is not too large (same city boundary)
                        if (GeometryUtils.getDistanceBetweenEarthCoordinates(new LatLng(lat, lng), new LatLng(this.prevLat, this.prevLng), Number.MAX_VALUE) < 10000) {
                            resolveCache = true;
                        }
                    } else {
                        resolveCache = true;
                    }
                }
                if (resolveCache) {
                    resolve(this.cache);
                    return;
                }
            }

            this.generic.genericPostNoAuthWData("/world/main/get-countries-details-nearby", {
                lat: lat,
                lng: lng,
                includeStoryCount: false,
                storyCategoryCode: null
            }).then((resp: IGetCountriesWithDetailsAndNearby) => {
                this.cache = resp;
                this.prevLat = lat;
                this.prevLng = lng;
                resolve(resp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
