import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InnerContentDirective } from 'src/app/directives/directives/inner-content';

@Component({
  selector: 'edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
})
export class EditInputComponent implements OnInit, AfterContentInit {
  @ContentChild(InnerContentDirective, { read: ElementRef })
  content: ElementRef;
  _propVal: any;

  _prop: any;
  @Input()
  set prop(prop: any) {
    this._prop = prop;
    // setTimeout(() => {
    //   this.propChange.emit(this._prop);
    // }, 1);
  }
  get prop() {
    return this._prop;
  }
  @Output()
  propChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  label: string;

  _disabled: boolean;
  @Input()
  set disabled(disabled: any) {
    this._disabled = disabled;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  placeholder: string;

  @Input()
  type: string;

  @Output()
  inputChange: EventEmitter<any> = new EventEmitter();

  constructor() { }
  ngOnInit(): void {

  }

  onChange() {
    setTimeout(() => {
      this.parseInput();
      // this actually does the trick
      this.propChange.emit(this._propVal);
      setTimeout(() => {
        this.inputChange.emit(this._propVal);
      }, 1);
    }, 1);
  }

  parseInput() {
    let propVal: any;
    switch (this.type) {
      case "number":
        propVal = Number.parseFloat(this._prop);
        if (Number.isNaN(propVal)) {
          propVal = null;
        }
        break;
      default:
        propVal = this._prop;
        break;
    }
    this._propVal = propVal;
  }

  ngAfterContentInit() {
    if (!this.label) {
      if (this.content) {
        this.label = this.content.nativeElement.innerText;
      }
    }
  }

}
