import { Injectable } from '@angular/core';
import { UiExtensionService } from '../general/ui/ui-extension';
import { GenericDataService } from '../general/data/generic';
import { IResizeImageResult, MediaUtilsService } from './media-utils';
import { ResourceManager } from '../../classes/general/resource-manager';
import { Messages } from '../../classes/def/app/messages';
import { StorageOpsService } from '../general/data/storage-ops';
import { IAppFlagsElement, ELocalAppDataKeys } from '../../classes/def/app/storage-flags';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AnalyticsService } from '../general/apis/analytics';
import { EOS } from 'src/app/classes/def/app/app';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { FileManagerService } from './file';
import { SleepUtils } from '../utils/sleep-utils';


/**
 * the high level photo provider
 * manages camera and api interaction
 */
@Injectable({
    providedIn: 'root'
})
export class PhotosService {

    prevInputElement: HTMLInputElement;

    timeout = {
        loading: null
    };

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public generic: GenericDataService,
        public uiext: UiExtensionService,
        public mediaUtils: MediaUtilsService,
        public storageOps: StorageOpsService,
        public analytics: AnalyticsService,
        public settingsProvider: SettingsManagerService,
        public file: FileManagerService
    ) {
        console.log("photos service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    checkShowLoading(message: string, always: boolean): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            if (!message) {
                // default message
                message = "<p>Initializing camera..</p><p>Please wait.. the first time it might take a while</p>";
            }
            let val: boolean = true;
            if (!always) {
                // check flag if loading required once
                await this.storageOps.getLocalDataKeyResolve(ELocalAppDataKeys.cameraInitialized);
            }
            if (!val) {
                // set flag if loading required once
                this.storageOps.setStorageFlagNoAction({
                    flag: ELocalAppDataKeys.cameraInitialized,
                    value: true
                });
            }
            if (!val || always) {
                // show loading if loading required/always
                if (GeneralCache.os === EOS.ios) {
                    await this.showLoading(message, false);
                }
            }
            if (always) {
                // return as loading required
                val = false;
            }
            resolve(!val);
        });
    }

    /**
     * handle all steps for uploading a photo,
     * callback is the actual upload function (may be different types e.g. profile picture)
     */
    uploadPhotoFromGalleryWizard(callback: (data: string) => Promise<any>, crop: boolean): Promise<IPhotoResult> {
        let promise: Promise<IPhotoResult> = new Promise(async (resolve, reject) => {
            let isLoading: boolean = false;
            try {
                let res: IPhotoResult;
                await this.showLoading("Loading..", false);
                isLoading = true;
                if (this.platform.WEB) {
                    res = {
                        data: null,
                        fileURI: null,
                        warn: false,
                        message: null
                    };
                    let orig: string = await this.uploadPhotoViaBrowser();
                    let resImage: IResizeImageResult = await this.mediaUtils.resizeImage(orig, null);
                    let resizedB64: string = resImage.content as string;
                    await this.mediaUtils.checkDataSize(resizedB64);
                    res.data = resizedB64;
                    res.warn = resImage.warn;
                    res.message = "<p>" + resImage.message + "</p>" + resImage.detail;
                } else {
                    reject(new Error("mobile not supported"));
                    return;
                }
                console.log("loaded");
                await this.dismissLoading();
                await this.mediaUtils.checkDataSize(res.data);
                isLoading = false;
                await SleepUtils.sleep(500);
                await this.showLoading("Processing..", false);
                isLoading = true;
                let remoteURI: string = await callback(res.data);
                if (this.platform.WEB) {
                    res.fileURI = remoteURI;
                }
                // this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Image uploaded");
                await this.dismissLoading();
                if (res && res.warn) {
                    await this.uiext.showRewardPopupQueue("Warning", res.message, null, false, null);
                }
                resolve(res);
            } catch (err) {
                if (isLoading) {
                    await this.dismissLoading();
                }
                reject(err);
            }
        });
        return promise;
    }

    uploadPhotoWrapper(photo: string, callback: (data: string) => Promise<any>, resize: boolean): Promise<string> {
        let promise: Promise<string> = new Promise(async (resolve, reject) => {
            try {
                if (resize) {
                    let resImage: IResizeImageResult = await this.mediaUtils.resizeImage(photo, null);
                    photo = resImage.content as string;
                    await this.mediaUtils.checkDataSize(photo);
                }
                await this.showLoading("Uploading..", false);
                await callback(photo);
                // this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Image uploaded");
                await this.dismissLoading();
                resolve(photo);
            } catch (err) {
                await this.dismissLoading();
                reject(err);
            }
        });
        return promise;
    }

    uploadProfilePictureNoAction(imageURI: string) {
        this.uploadProfilePicture(imageURI).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "photos");
        })
    }

    /**
     * upload profile image
     * @param imageURI 
     */
    uploadProfilePicture(imageURI: string) {
        GeneralCache.resourceCache.user.general.content.photoUrl = imageURI;
        let flag: IAppFlagsElement = {
            flag: ELocalAppDataKeys.photoUrl,
            value: imageURI
        };
        this.storageOps.setStorageFlagNoAction(flag);
        return this.generic.genericPostStandard("/photos/upload-profile-photo", {
            photo: imageURI
        });
    }

    uploadPhotoViaBrowser() {
        return new Promise<string>((resolve, reject) => {
            this.file.uploadFileViaBrowser(".png, .jpg", true, null).then((res: string) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    uploadStoryDraftPhoto(draftId: number, imageURI: string) {
        return this.generic.genericPostStandardWData("/editor/content/upload-story-draft-photo", {
            draftId: draftId,
            photo: imageURI,
            passwordHash: GeneralCache.passwordHash
        });
    }

    uploadStoryLocationDraftPhoto(draftId: number, storyLocationIndex: number, oldPhotoUrl: string, imageURI: string) {
        return this.generic.genericPostStandardWData("/editor/content/upload-story-draft-location-photo", {
            draftId: draftId,
            storyLocationIndex: storyLocationIndex,
            photo: imageURI,
            oldPhotoUrl: oldPhotoUrl,
            passwordHash: GeneralCache.passwordHash
        });
    }

    uploadQuestItemSelectPhoto(draftId: number, storyLocationIndex: number, optIndex: number, oldPhotoUrl: string, imageURI: string) {
        return this.generic.genericPostStandardWData("/editor/content/upload-story-draft-quest-item-select-photo", {
            draftId: draftId,
            storyLocationIndex: storyLocationIndex,
            optIndex: optIndex,
            photo: imageURI,
            oldPhotoUrl: oldPhotoUrl,
            passwordHash: GeneralCache.passwordHash
        });
    }

    uploadDescriptionEntryPhoto(imageURI: string) {
        return this.generic.genericPostStandardWData("/editor/content/upload-description-entry-photo", {
            photo: imageURI,
            passwordHash: GeneralCache.passwordHash
        });
    }

    /**
     * remove user photo by url
     * @param photoUrl 
     * @returns 
     */
    removeUserPhoto(photoUrl: string) {
        return this.generic.genericPostStandard("/editor/content/remove-user-photo", {
            photoUrl: photoUrl
        });
    }

    /**
     * upload location profile photo
     * @param locationId 
     * @param imageURI 
     */
    uploadPlacePhoto(locationId: number, imageURI: string) {
        return this.generic.genericPostStandard("/business/locations/upload-profile-photo", {
            locationId: locationId,
            photo: imageURI
        });
    }

    /**
     * upload group photo
     * @param imageURI 
     */
    uploadGroupPhoto(groupId: number, imageURI: string) {
        return this.generic.genericPostStandard("/photos/upload-group-photo", {
            groupId: groupId,
            photo: imageURI
        });
    }

    /**
     * remove profile image
     */
    removeGroupPhoto(groupId: number) {
        return this.generic.genericGetStandard("/photos/remove-group-photo", {
            groupId: groupId
        });
    }

    /**
    * download profile image
    */
    downloadProfilePicture() {
        return this.generic.genericGetStandard("/photos/download-profile-photo", null);
    }

    /**
     * download profile image
     */
    downloadItemPhoto(name: string) {
        return this.generic.genericGetStandard("/photos/download-item-photo", { name: name });
    }


    /**
    * remove profile picture from the device
    * remove reference from local storage
    */
    removeProfilePicture() {
        let promise = new Promise((resolve, reject) => {
            let flag: IAppFlagsElement = {
                flag: ELocalAppDataKeys.photoUrl,
                value: null
            };
            this.storageOps.setStorageFlagNoAction(flag);
            this.generic.genericGetStandard("/photos/remove-profile-photo", null).then(() => {
                GeneralCache.resourceCache.user.general.content.photoUrl = null;
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    setLoadingTimeout() {
        if (!this.timeout.loading) {
            this.timeout.loading = setTimeout(() => {
                this.uiext.dismissLoadingV2();
                this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
            }, 20000);
        }
    }

    resetLoadingTimeout() {
        this.timeout.loading = ResourceManager.clearTimeout(this.timeout.loading);
    }

    /**
     * loading disabled on web because there is no way to detect if upload was cancelled by the user, so the loading would hang
     * @returns 
     */
    isLoadingEnabled() {
        return GeneralCache.os === EOS.ios;
    }

    showLoading(message: string, timeout: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.isLoadingEnabled()) {
                await this.uiext.showLoadingV2Queue(message ? message : "Loading..");
                if (timeout) {
                    this.setLoadingTimeout();
                }
            } else {
                console.warn("show loading disabled in photo service: ", message, timeout);
                resolve(true);
            }
            resolve(true);
        });
    }

    dismissLoading(): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.isLoadingEnabled()) {
                await this.uiext.dismissLoadingV2();
                this.resetLoadingTimeout();
                resolve(true);
            } else {
                console.warn("show loading disabled in photo service");
                resolve(true);
            }
        });
    }
}







