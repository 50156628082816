export interface IMeasurementModesDisp {
    [key: string]: IMeasurementDisp;
}

export interface IMeasurementDisp {
    distance: string;
    speed: string;
}


export const measurementModesDisp: IMeasurementModesDisp = {
    metric: {
        distance: "m",
        speed: "km/h"
    },
    imperial: {
        distance: "mi",
        speed: "mph"
    }
};
