import { Component, OnInit, OnDestroy, Input, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { EventEmitter } from '@angular/core';
import { EAppIconsStandard, EPhotos } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'responsive-img',
  templateUrl: './responsive-img.component.html',
  styleUrls: ['./responsive-img.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class ResponsiveImgComponent implements OnInit, OnDestroy, OnChanges {
  showState: string = "inactive";
  loaded: boolean = false;

  appIconsStandard = EAppIconsStandard;

  customClassCrt: string = "border-radius-img paper-card";

  loading: boolean = false;

  @Input()
  zoomState: boolean;

  @Input()
  photoUrl: string;

  @Input()
  reload: boolean;

  @Input()
  showLoading: boolean;

  @Input()
  customClass: string;

  @Input()
  withUpload: boolean;

  @Input()
  noAnimation: boolean;

  @Output()
  zoom: EventEmitter<boolean> = new EventEmitter();

  @Output()
  upload: EventEmitter<string> = new EventEmitter();

  defaultClass: string = "img-small paper-card";
  imgClass: string = "";


  constructor() {

  }

  ngOnInit() {
    if (this.customClass) {
      this.defaultClass = this.customClass;
    }
    if (this.zoomState == null) {
      this.zoomState = false;
    }
    this.setZoom(this.zoomState);
    if (this.noAnimation) {
      this.setLoaded();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'photoUrl':
            // this.onPhotoUrlChanged();
            break;
        }
      }
    }
  }

  ngOnDestroy() {

  }

  onPhotoUrlChanged() {
    console.log("photo url changed");
    this.showState = "inactive";
    this.loading = true;
    this.loaded = false;
    setTimeout(() => {
      this.loading = false;
    }, 1);
  }

  setLoaded() {
    this.loaded = true;
    this.showState = "active";
    console.log("responsive img loaded");
  }

  setZoom(_zoom: boolean) {
    this.imgClass = this.zoomState ? 'img-full' : this.defaultClass;
  }

  tapZoom() {
    this.zoomState = !this.zoomState;
    this.setZoom(this.zoomState);
    console.log("tap zoom: ", this.zoomState);
    this.zoom.emit(this.zoomState);
  }

  tapUpload() {
    console.log("tap upload");
    this.upload.emit(null);
  }

  handleLoadError() {
    console.warn("image not available. using placeholder");
    this.photoUrl = EPhotos.imageNotAvailable;
  }
}
