import { LatLng } from "@ionic-native/google-maps/ngx";
import { BusinessDataService } from "../data/business";
import { PlacesDataService } from "../data/places";
import { Injectable } from "@angular/core";
import { ILeplaceRegMulti, ILeplaceReg } from "../../classes/def/places/google";
import { IGenericResponse, IGenericResponseDataWrapper } from "../../classes/def/requests/general";
import { IRegisteredPlaceCheck } from "../../classes/def/places/leplace";
import { IPlaceExtContainer } from "../../classes/def/places/container";


@Injectable({
    providedIn: 'root'
})
export class LocationApiHelpersService {
    placesService;

    resultBuffer: IPlaceExtContainer[] = [];
    resultBufferOutIndex: number = 0;

    constructor(
        public businessDataProvider: BusinessDataService,
        public placesDataProvider: PlacesDataService
    ) {
        console.log("location api helpers service created");
    }

    chooseRandomLocation(currentLocation: LatLng, results: IPlaceExtContainer[], standard: number[], excludeLocationIdList: string[], sortRating: boolean, mode: number): Promise<ILeplaceRegMulti> {
        // console.log("choose random location from: ", results);
        // console.log("exclude locations: ", excludeLocationIdList);
        // console.log("local: ", islocal, ", standard: ", standard);
        let promise: Promise<ILeplaceRegMulti> = new Promise((resolve, reject) => {
            let filteredResults: IPlaceExtContainer[] = [];
            if (!results || results.length === 0) {
                reject(new Error("Place not found"));
                return;
            }

            let serverProcessing: boolean = true;

            // initial filtering by photo availability, etc
            // the != is not by mistake as it covers both null and undefined values
            if (standard[0] != null || standard[1] != null) {
                // with standard

                // db story
                if (serverProcessing) {
                    // server processing enabled
                    // only basic filtering on client
                    filteredResults = this.basicFiltering(results, excludeLocationIdList);
                } else {
                    // server processing disabled
                    // advanced filtering on client
                    filteredResults = this.advancedFiltering(results, excludeLocationIdList, standard);
                }

            } else {
                // without standard / random
                filteredResults = this.basicFiltering(results, excludeLocationIdList);
            }

            // advanced filtering processed on the server
            // check server if there are registered locations in the search results
            if (serverProcessing) {
                // do priority evaluation on the server
                this.processSearchResultsOnServer(currentLocation,
                    standard, filteredResults, sortRating).then((sortedResults: ILeplaceRegMulti) => {
                        resolve(sortedResults);
                    }).catch((err: Error) => {
                        console.error(err);
                        // if error occured revert to local processing
                        this.checkServerForRegisteredPlaces(filteredResults).then((registeredPlacesId: IRegisteredPlaceCheck[]) => {
                            resolve(this.processSearchResultsOnClient(filteredResults, registeredPlacesId, false));
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(this.processSearchResultsOnClient(filteredResults, null, false));
                        });
                    });
            } else {
                this.checkServerForRegisteredPlaces(filteredResults).then((registeredPlacesId: IRegisteredPlaceCheck[]) => {
                    resolve(this.processSearchResultsOnClient(filteredResults, registeredPlacesId, false));
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(this.processSearchResultsOnClient(filteredResults, null, false));
                });
            }
        });
        return promise;
    }


    /**
     * exclude locations with no photos
     * @param results 
     * @param excludeLocationIdList 
     */
    basicFiltering(results: IPlaceExtContainer[], excludeLocationIdList: string[]): IPlaceExtContainer[] {
        console.log("basic local filtering");
        console.log("input: ", results);
        let filteredResults: IPlaceExtContainer[] = [];
        for (let i = 0; i < results.length; i++) {
            // filter by locations with photos
            let res: IPlaceExtContainer = results[i];
            if (res.aux) {
                if (results[i].aux.photos && results[i].aux.photos.length > 0) {
                    // check that the location is not already in the given list of previously visited locations
                    if (excludeLocationIdList.indexOf(results[i].googleId) === -1) {
                        filteredResults.push(results[i]);
                    }
                }
            } else {
                // check that the location is not already in the given list of previously visited locations
                if (excludeLocationIdList.indexOf(results[i].googleId) === -1) {
                    filteredResults.push(results[i]);
                }
            }
        }
        console.log("output: ", filteredResults);
        return filteredResults;
    }

    advancedFiltering(results: IPlaceExtContainer[], excludeLocationIdList: string[], standard: number[]): IPlaceExtContainer[] {
        console.log("advanced local filtering");
        // let result = null;
        let targetReviews: number = 10;

        // store index and score of places
        let validResults: any[] = [];

        let validResultsPlaces: IPlaceExtContainer[] = [];
        let filteredResults: IPlaceExtContainer[];
        // let best_valid_result = null;

        for (let i = 0; i < results.length; i++) {
            // filter by locations with photos
            let res: IPlaceExtContainer = results[i];
            if (!res.aux) {
                continue;
            }
            if (res.aux.photos && res.aux.photos.length > 0) {
                // check that the location is not already in the given list of previously visited locations
                if (excludeLocationIdList.indexOf(res.googleId) === -1) {
                    // filter by rating and number of reviews so that the standard is validated
                    console.log("reviews: ", res.reviewCount);
                    let reviewsCap: number = targetReviews;

                    // if no reviews then let it pass
                    // if (res.reviewCount > 0) {
                    reviewsCap = res.reviewCount;
                    if (reviewsCap > targetReviews) {
                        reviewsCap = targetReviews;
                    }
                    // }

                    let score: number = res.rating / 5 * (reviewsCap / targetReviews);
                    if (standard[0] != null && standard[1] != null) {
                        // between
                        if (score >= standard[0] && score <= standard[1]) {
                            validResults.push({
                                index: i,
                                score
                            });
                            validResultsPlaces.push(res);
                        }
                    } else if (standard[0] != null) {
                        // only greater
                        if (score >= standard[0]) {
                            validResults.push({
                                index: i,
                                score
                            });
                            validResultsPlaces.push(res);
                        }
                    } else if (standard[1] != null) {
                        // only less
                        if (score <= standard[1]) {
                            validResults.push({
                                index: i,
                                score
                            });
                            validResultsPlaces.push(res);
                        }
                    }
                }
            }
        }

        filteredResults = validResultsPlaces;
        return filteredResults;
    }

    /**
     * check if fixed location is registered
     * @param result 
     */
    checkRegisteredFixedLocation(result: IPlaceExtContainer): Promise<IRegisteredPlaceCheck> {
        let promise: Promise<IRegisteredPlaceCheck> = new Promise((resolve, reject) => {
            this.checkServerForRegisteredPlaces([result]).then((result: IRegisteredPlaceCheck[]) => {
                if (result && result.length > 0) {
                    // console.log("fixed registered");
                    resolve(result[0]);
                } else {
                    // console.log("fixed not registered");
                    resolve(null);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check if there are registered places in the found locations
     * @param searchResults 
     */
    checkServerForRegisteredPlaces(searchResults: IPlaceExtContainer[]): Promise<IRegisteredPlaceCheck[]> {
        let promise: Promise<IRegisteredPlaceCheck[]> = new Promise((resolve, reject) => {
            let googleIdList = [];
            for (let i = 0; i < searchResults.length; i++) {
                googleIdList.push(searchResults[i].googleId);
            }
            this.businessDataProvider.checkRegisteredMultiple(googleIdList).then((d: IRegisteredPlaceCheck[]) => {
                resolve(d);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    processSearchResultsOnServer(currentLocation: LatLng, standard: number[], searchResults: IPlaceExtContainer[], sortRating: boolean): Promise<ILeplaceRegMulti> {
        let promise: Promise<ILeplaceRegMulti> = new Promise((resolve, reject) => {
            this.placesDataProvider.processScanAds(currentLocation, standard, searchResults, sortRating).then((res: IGenericResponseDataWrapper<ILeplaceReg[]>) => {
                // the server returns the sorted ids for the search results
                // retrieve the full details by ordering the search results based on the sorted ids

                let sortedResults: ILeplaceReg[] = res.data;

                let sortedSearchResults: ILeplaceReg[] = [];

                for (let i = 0; i < sortedResults.length; i++) {
                    let sr: ILeplaceReg = sortedResults[i];
                    for (let j = 0; j < searchResults.length; j++) {
                        if (sortedResults[i].place.googleId === searchResults[j].googleId) {
                            sortedSearchResults.push({
                                place: searchResults[j],
                                registeredBusiness: sr.registeredBusiness,
                                registeredId: sr.registeredId
                            });
                            break;
                        }
                    }
                }
                let r: ILeplaceRegMulti = {
                    selected: sortedSearchResults[0],
                    array: sortedSearchResults
                };
                resolve(r);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * order the places by priority (if registered, distance, etc)
     * @param places 
     * @param registeredPlaces 
     * @param random 
     */
    processSearchResultsOnClient(places: IPlaceExtContainer[], registeredPlaces: IRegisteredPlaceCheck[], random: boolean): ILeplaceRegMulti {
        let indexFirst: number = 0;
        let orderedPlaces: ILeplaceReg[] = [];

        if (random) {
            // really random places
            if (places.length === 0) {
                return null;
            }
            indexFirst = Math.floor(Math.random() * places.length);

            if (indexFirst < 0) {
                indexFirst = 0;
            }

            if (indexFirst > places.length - 1) {
                indexFirst = places.length - 1;
            }

            for (let i = 0; i < places.length; i++) {
                orderedPlaces.push({
                    place: places[i],
                    registeredBusiness: false,
                    registeredId: null
                });
            }

            let r: ILeplaceRegMulti = {
                selected: orderedPlaces[indexFirst],
                array: orderedPlaces
            };
            return r;

        } else {
            // order by priority
            if (places.length === 0) {
                return null;
            }

            indexFirst = 0; // the first is the most recommended from list

            if (registeredPlaces && registeredPlaces.length > 0) {
                // add registered places in order
                for (let i = 0; i < registeredPlaces.length; i++) {
                    let rp: IRegisteredPlaceCheck = registeredPlaces[i];
                    for (let j = 0; j < places.length; j++) {
                        if (places[j].googleId === rp.googleId) {
                            orderedPlaces.push({
                                place: places[j],
                                registeredBusiness: true,
                                registeredId: rp.id
                            });
                            break;
                        }
                    }
                }
            }
            // add the rest based on score (distance, direction)
            // at the moment, just random
            for (let i = 0; i < places.length; i++) {
                let isreg = false;
                if (registeredPlaces && registeredPlaces.length > 0) {
                    for (let j = 0; j < registeredPlaces.length; j++) {
                        if (places[i].googleId === registeredPlaces[j].googleId) {
                            isreg = true;
                            break;
                        }
                    }
                }
                if (!isreg) {
                    orderedPlaces.push({
                        place: places[i],
                        registeredBusiness: false,
                        registeredId: null
                    });
                }
            }

            console.log("ordered places: ", orderedPlaces);

            if (indexFirst < 0) {
                indexFirst = 0;
            }

            if (indexFirst > places.length - 1) {
                indexFirst = places.length - 1;
            }

            let r: ILeplaceRegMulti = {
                selected: orderedPlaces[indexFirst],
                array: orderedPlaces
            };
            return r;
        }
    }

}
