<modal-header-container [vs]="vs">
    <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
        (rightClick)="return(false)" [rounded]="vs.fullScreen">
    </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
    [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

    <div class="scrollbar-y custom-scrollbar scroll-content-modal-2s">
        <ion-list class="list-md-noborder" *ngIf="filters">
            <div *ngFor="let filter of filters.filters">
                <ion-item-group *ngIf="filter.type === types.checkbox">
                    <ion-item-divider sticky class="list-item-divider">
                        <ion-label class="label-text-s bold text-color-primary">
                            {{filter.label}}
                        </ion-label>
                    </ion-item-divider>
                    <ion-item class="list-item transparent-bg settings-item-fit item-noborder"
                        *ngFor="let set of filter.options" #slidingItem>
                        <ion-label class="text-color-primary label-text-s ion-label-input">{{set.name}}</ion-label>
                        <ion-checkbox [(ngModel)]="set.checked" ngDefaultControl (ionChange)="onChange(filter, set)"
                            class="custom-checkbox">
                        </ion-checkbox>
                    </ion-item>
                </ion-item-group>

                <ion-radio-group *ngIf="filter.type === types.radio" [value]="filter.selected"
                    (ionChange)="radioSelect(filter, $event)">
                    <ion-item-divider sticky class="list-item-divider">
                        <ion-label class="label-text-s bold text-color-primary">
                            {{filter.label}}
                        </ion-label>
                    </ion-item-divider>
                    <ion-item *ngFor="let o of filter.options; let i = index"
                        class="list-item transparent-bg item-noborder settings-item-fit">
                        <ion-label class="ion-text-wrap info-text-m-imp">
                            <div [innerHTML]="o.name" class="text-color-primary"></div>
                        </ion-label>
                        <ion-radio slot="start" [value]="o.code"></ion-radio>
                    </ion-item>
                </ion-radio-group>

                <ion-item *ngIf="filter.type === types.checkboxInput"
                    class="list-item transparent-bg settings-item-fit item-noborder">
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                    <ion-checkbox [(ngModel)]="filter.value" ngDefaultControl class="custom-checkbox"
                        (ionChange)="onInputChange(filter)">
                    </ion-checkbox>
                </ion-item>

                <ion-item *ngIf="filter.type === types.input"
                    class="list-item transparent-bg settings-item-fit item-noborder">
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                    <ion-input type="number" [(ngModel)]="filter.value" ngDefaultControl
                        (input)="onInputChange(filter)">
                    </ion-input>
                </ion-item>

                <ion-item *ngIf="filter.type === types.inputText"
                    class="list-item transparent-bg settings-item-fit item-noborder">
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                    <ion-input type="text" [(ngModel)]="filter.value" ngDefaultControl (input)="onInputChange(filter)">
                    </ion-input>
                </ion-item>

                <ion-item *ngIf="filter.type === types.photoUpload" class="list-item transparent-bg item-noborder">
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                    <upload-img [photoUrl]="filter.value" [externalUpload]="true"
                        (upload)="uploadPhotoFromGallery(filter, $event)">
                    </upload-img>
                </ion-item>

                <ion-item *ngIf="filter.type === types.select"
                    class="list-item transparent-bg settings-item-fit item-noborder">
                    <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                    <ion-select class="custom-select info-text-s" ngDefaultControl [(ngModel)]="filter.value"
                        (ionChange)="onInputChange(filter)">
                        <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                        <ion-select-option *ngFor="let o of filter.options; let i = index" [value]="o.code">{{o.name}}
                        </ion-select-option>
                    </ion-select>
                </ion-item>

                <div *ngIf="filter.type === types.customSelect" class="custom-select-enabled" (click)="filter.fn()">
                    <ion-item class="list-item transparent-bg settings-item-fit item-noborder custom-select-enabled">
                        <ion-label class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                        <ion-select class="custom-select custom-select-stretch info-text-s" ngDefaultControl
                            [(ngModel)]="filter.value" [disabled]="true">
                            <ion-label
                                class="text-color-primary label-text-s ion-label-input">{{filter.label}}</ion-label>
                            <ion-select-option *ngFor="let o of filter.options; let i = index"
                                [value]="o.code">{{o.name}}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <!-- <div *ngIf="filter.selected" class="list-item padding-s transparent-bg item-noborder flex-column">
                        <div class="info-text-m" [innerHTML]="filter.selected.name"></div>
                    </div> -->
                </div>

                <!-- <div (click)="openCustomSelector('Map Theme', settings.maps, story, 'map')"
                    class="custom-select-enabled">
                    <edit-options [(prop)]="story.map" [disabled]="true" [options]="settings.maps"
                        placeholder="undefined" (optionChange)="updateSpec()">
                        <inner-content>Map Theme</inner-content>
                    </edit-options>
                </div> -->

                <div *ngIf="filter.type === types.dateSelector"
                    class="list-item padding-s transparent-bg item-noborder flex-column">

                    <ion-row>
                        <ion-col>
                            <div class="info-text-m" [innerHTML]="filter.label"></div>
                        </ion-col>
                        <ion-col>
                            <!-- <ion-button class="action-button-fill button-color-primary" (click)="filter.callback()">
                                <span class="button-font-size-m">
                                    clear
                                </span>
                            </ion-button> -->
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col>
                            <date-input-selector [selector]="filter.options[0].options"></date-input-selector>
                        </ion-col>
                        <ion-col>
                            <date-input-selector [selector]="filter.options[1].options"></date-input-selector>
                        </ion-col>
                    </ion-row>
                    <div class="info-text-s description-frame description-frame-p" [innerHTML]="filter.description">
                    </div>
                </div>
            </div>

            <div *ngFor="let filter of filters.filters">
                <div *ngIf="filter.type === types.labelOnly"
                    class="list-item padding-s transparent-bg item-noborder flex-column">
                    <div class="info-text-m" [innerHTML]="filter.label"></div>
                    <div class="info-text-s description-frame description-frame-p" [innerHTML]="filter.description">
                    </div>
                </div>
            </div>
        </ion-list>
    </div>

    <div fxFlex></div>

    <div class="stick-bottom theme-border-top">
        <ion-grid class="full-w">
            <ion-row>
                <ion-col size="12">
                    <ion-button class="action-button-fill button-color-transparent" [disabled]="!update && !create"
                        (click)="return(true)">
                        <span *ngIf="!create" class="button-font-size-m">Update</span>
                        <span *ngIf="create" class="button-font-size-m">Ok</span>
                        <icon-wrapper slot="start" [icon]="appIconsStandard.check" [custom]="false"
                            class="slot-icon-padding"></icon-wrapper>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>

</modal-container>