
export enum EOrderPlacesBy {
  default = 0,
  name = 1,
  dateRegistered = 2,
  country = 3
};

export enum EOrderStoriesBy {
  default = 0,
  name = 1,
  dateRegistered = 2,
  dateEdited = 3
};

export enum EOrderBySort {
  ascending = 1,
  descending = 2
};

export enum EFilterStoriesBy {
  unlisted = 1,
  listed = 2
};

export interface ICustomFiltersContainer {
  title: string;
  create?: boolean;
  changed?: boolean;
  filters: ICustomFilterElement[];
};

export interface ICustomFilterElement {
  type: number;
  label: string;
  selected?: any;
  value?: number | string | boolean;
  description?: string;
  selectAll?: boolean;
  options?: ICustomFilterOption[];
  enabled?: boolean;
  fn?: () => any;
};

export interface ICustomFilterOption {
  name: string;
  code: number;
  checked?: boolean;
  value?: any;
  options?: any;
};