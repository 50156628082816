import { IGameItemCategoryWithItems } from "../items/game-item";
import { IGameContext } from "../app/app";
import { IGenericActivityStats } from '../core/activity-stats';
import { GeneralCache } from '../../app/general-cache';
import { IKVStat } from '../app/kval';

/**
 * specific implementation in game
 */
export interface IGameStats {
    collectedCoins: IStatDef;
    placesFound: IStatDef;
    placesVisited: IStatDef;
    storiesStarted: IStatDef;
    storiesFinished: IStatDef;
    treasuresFound: IStatDef;
    treasuresOpened: IStatDef;
}

export enum EStatCodes {
    collectedCoins = 1,
    placesFound = 2,
    placesVisited = 3,
    storiesStarted = 4,
    storiesFinished = 5,
    treasuresDiscovered = 6,
    treasuresOpened = 7,
    groupEventsStarted = 8,
    groupEventsClosed = 9,
    itemsPhotographed = 10,
    soundsRecorded = 11,
    challengeCoinsCollected = 20,
    challengeCachedDistance = 21,
    challengeCachedScore = 22,
    challengeCachedTimedelta = 23,
    challengeCachedAverageSpeed = 24,
    /**
     * additional xp that may be gained via custom actions
     */
    xpGained = 25,

    distanceFlown = 32
}


export interface IStatDef extends IKVStat {
    id: number;
    /**
     * specific code
     */
    code: number;
    name: string;
    singleName: string;
    value: number;
    weight: number;
    /**
     * orig: genericCode
     */
    delegateCode: number;
    /**
    * same as delegate code
    */
    genericCode: number;


    /**
     * the key that may be matched to a stats object
     */
    key: string;
    gameContextCode: number;
    scoreEntry: number;
    show: number;

    statsLevelActivities: ISpecificStat[],

    gameContext: IGameContext;
    level: number;
    valueString?: string;
    notify?: number;
    checkTransition?: number;
}

export interface ISpecificStat {
    id: number;
    code: number;
    genericCode: number;
    levelCode: number;
    activityCode: number;
    weight: number;
}

export interface IUserStatCategory {
    level: number;
    categoryCode: number;
    label: string;
    score: number;
    refStart: number;
    refLevelUp: number;
    disp: IStatDef[];
    dispFlag: number;
    maxLevel: number;
}

export interface IUserStatsOverview {
    categories: IUserStatCategory[];
    root: IUserStatCategory;
}

export interface IUserInventory {
    categories: IGameItemCategoryWithItems[];
}


export interface IStatChange {
    id: number;
    userId: number;
    categoryCode: number;
    message: string;
    value: number;
    heading: string;
    type: number;
    seen: number;
    timestamp: string;
}

export enum EStatChangeCode {
    levelup = 0,
    scoreup = 1
}

export interface IUserStatsChangedResult {
    // check if reload is required (e.g. level up, trigger reload treasures to recalculate locked condition)
    reload: boolean;
    statChangedCodes: number[];
}


export class ActivityStatsUtils {
    /**
     * matches stat keys with stat defs in resource cache
     * returns a list with stats in standard format
     * @param stats 
     */
    static getStatList(stats: IGenericActivityStats): IStatDef[] {
        if (!stats) {
            return null;
        }
        let statsDef: IStatDef[] = GeneralCache.resourceCache.general.statScores.content;
        let statList: IStatDef[] = [];
        let keys: string[] = Object.keys(stats);
        keys.forEach((key) => {
            let statDef: IStatDef = statsDef.find(sd => sd.key === key);
            if (statDef != null) {
                let statDef1: IStatDef = Object.assign({}, statDef);
                statDef1.value = stats[key];
                statList.push(statDef1);
            }
        });
        return statList;
    }
}


