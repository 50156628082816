import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'src/app/classes/app/constants';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ISignupTermsNavParams } from 'src/app/classes/def/nav-params/modals';


@Component({
  selector: 'app-signup-terms',
  templateUrl: './signup-terms.page.html',
  styleUrls: ['./signup-terms.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupTermsPage implements OnInit, OnDestroy {
  title: string = AppConstants.viewTitle.signupTerms;
  infoHTML: string = "";
  photoUrlLoaded: string = null;
  loading: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;

  withButtons: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public resources: ResourcesCoreDataService,
    public nps: NavParamsService
  ) {


  }


  loadSignupTerms() {
    this.loading = true;
    this.resources.getSignupTerms().then((response: IGenericResponse) => {
      this.loading = false;
      this.infoHTML = response.data;
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      this.loading = false;
    });
  }


  ngOnInit() {

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.login, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        let np: INavParams = npInfo.params;
        if (np.view) {
          this.vs = np.view;
        }
        if (np.params) {
          let params: ISignupTermsNavParams = np.params;
          this.withButtons = params.withButtons;
        }
        console.log(np);
      }

      this.loadSignupTerms();
    }).catch((err: Error) => {
      console.error(err);
    });

  }


  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  accept() {
    this.dismiss(true);
  }

  cancel() {
    this.dismiss(false);
  }

  ngOnDestroy() {

  }
  clearWatch() {

  }
}
