import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICustomFilterElement, ICustomFilterOption, ICustomFiltersContainer } from 'src/app/classes/def/app/filters';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ESettingsType } from 'src/app/classes/def/app/settings';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ParamHandler } from 'src/app/classes/general/params';

@Component({
  selector: 'modal-custom-filters',
  templateUrl: './custom-filters.component.html',
  styleUrls: ['./custom-filters.component.scss']
})
export class CustomFiltersViewComponent implements OnInit {
  title: string = "";
  rightBtnIcon: string = EAppIconsStandard.close;
  types = ESettingsType;

  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  filters: ICustomFiltersContainer = {
    title: "",
    changed: false,
    filters: []
  };
  filtersOrig: ICustomFilterElement[] = [];

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ICustomFiltersContainer = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.filters = params;
      this.title = params.title;
      if (!this.filters) {
        return;
      }
      this.filtersOrig = DeepCopy.deepcopy(this.filters.filters);
      this.checkAllFilterSelectAll();
    }
  }

  ngOnDestroy() {

  }

  return() {
    this.dismiss();
  }

  checkAllFilterSelectAll() {
    for (let filter of this.filters.filters) {
      this.checkFilterSelectAll(filter);
    }
  }

  checkFilterSelectAll(filter: ICustomFilterElement) {
    switch (filter.type) {
      case ESettingsType.checkbox:
        let allSelected: boolean = true;
        for (let opt of filter.options) {
          if (!opt.checked) {
            allSelected = false;
          }
        }
        if (allSelected) {
          filter.selectAll = true;
        } else {
          filter.selectAll = false;
        }
        break;
      default:
        break;
    }
  }

  checkUpdatesBtnText() {
    let update: boolean = this.checkFiltersChanged();
    if (update) {
      // this.selectAll = this.checkAllSelected();
      this.rightBtnIcon = EAppIconsStandard.check;
    } else {
      this.rightBtnIcon = EAppIconsStandard.close;
    }
    return update;
  }

  checkFiltersChanged() {
    let changed: boolean = false;
    for (let i = 0; i < this.filters.filters.length; i++) {
      let filter: ICustomFilterElement = this.filters.filters[i];
      let filterOrig: ICustomFilterElement = this.filtersOrig[i];
      if (filter.selected !== filterOrig.selected) {
        changed = true;
        break;
      }
      for (let j = 0; j < filter.options.length; j++) {
        if (filter.options[j].checked !== filterOrig.options[j].checked) {
          changed = true;
          break;
        }
      }
      if (changed) {
        break;
      }
    }
    return changed;
  }

  dismiss() {
    if (this.checkFiltersChanged()) {
      this.filters.changed = true;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(this.filters).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  onChange(filter: ICustomFilterElement, set: ICustomFilterOption) {
    console.log("onchange: ", filter, set);
    // this.checkFilterSelectAll(filter);
    this.checkUpdatesBtnText();
  }

  radioSelect(filter: ICustomFilterElement, event: any) {
    console.log("radioselect: ", filter, event);
    if (event && event.detail) {
      let value: number = event.detail.value;
      filter.selected = value;
    }
    this.checkUpdatesBtnText();
  }

  onSelectAll(filter: ICustomFilterElement, event: any) {
    console.log("on select all: ", filter, event);
    if (filter.selectAll) {
      for (let opt of filter.options) {
        opt.checked = true;
      }
    } else {
      for (let opt of filter.options) {
        opt.checked = false;
      }
    }
    this.checkUpdatesBtnText();
  }
}
