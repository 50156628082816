import { Injectable } from '@angular/core';
import { StoryDataHelpers } from './story-helpers';
import { LatLng } from '@ionic-native/google-maps/ngx';
import { IStory, IStoriesPageResponse, IStoryResponse, IStoriesPageRequest } from '../../classes/def/core/story';
import { IGenericResponse } from '../../classes/def/requests/general';
import { GenericDataService } from '../general/data/generic';
import { InventoryDataService } from './inventory';
import { IEventGroupLinkData } from 'src/app/classes/def/events/links';
import { LocationUtilsWizard } from '../location/location-utils-wizard';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceDataService {

    constructor(
        public generic: GenericDataService,
        public inventory: InventoryDataService,
        public locationUtilsWizard: LocationUtilsWizard
    ) {
        console.log("marketplace data service created");
    }


    /**
     * get all stories from the backend that are on the same page with the story having the specified name
     * the stories don't contain details
     * they are added to the local object to be shown in the story list
     */
    async searchStoryByName(coords: LatLng, categoryCode: number, providerId: number, cityId: number, name: string): Promise<IStoriesPageResponse> {
        let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
            let req: IStoriesPageRequest = {
                lat: coords ? coords.lat : null,
                lng: coords ? coords.lng : null,
                categoryCode: categoryCode,
                providerId: providerId,
                cityId: cityId,
                name: name
            };
            this.listStoriesCore(req).then((data: IStoriesPageResponse) => {
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get all stories from the backend
     * the stories don't contain details
     * they are added to the local object to be shown in the story list
     */
    listStories(coords: LatLng, categoryCode: number, providerId: number, cityId: number, releaseFilter: number[], orderBy: number, sort: number, page: number): Promise<IStoriesPageResponse> {
        let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
            let req: IStoriesPageRequest = {
                lat: coords ? coords.lat : null,
                lng: coords ? coords.lng : null,
                categoryCode: categoryCode,
                providerId: providerId,
                cityId: cityId,
                page: page,
                releaseFilter: releaseFilter,
                orderBy: orderBy,
                sort: sort
            };
            this.listStoriesCore(req).then((data: IStoriesPageResponse) => {
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    listStoriesCore(req: IStoriesPageRequest) {
        let promise = new Promise((resolve, reject) => {
            let route: string = "/marketplace/list-stories";
            console.log(req);
            if (req.page == null && req.name != null) {
                route = "/marketplace/search-story-by-name";
            }
            this.generic.genericPostNoAuth(route, req).then((response: IGenericResponse) => {
                if (response.status) {
                    let stories: IStory[] = [];
                    let responseData: IStoriesPageResponse = response.data;
                    for (let i = 0; i < responseData.stories.length; i++) {
                        let story: IStory = responseData.stories[i];
                        story = StoryDataHelpers._formatStoryFromBackend(story);
                        stories.push(story);
                    }
                    console.log(stories);
                    let responseDataFormat: IStoriesPageResponse = {
                        stories: stories,
                        page: responseData.page,
                        pages: responseData.pages
                    };

                    resolve(responseDataFormat);
                } else {
                    reject(new Error(response.message));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get a story from server
     * save it into the local object (buffer)
     * update if existing story with id
     * @param storyId 
     */
    getStory(storyId: number, coords: LatLng, token: string): Promise<IStoryResponse> {
        let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
            let story: IStory;
            let req = {
                storyId: storyId,
                lat: coords ? coords.lat : null,
                lng: coords ? coords.lng : null,
                token: token
            };

            this.generic.genericPostNoAuth("/marketplace/get-story-preview", req).then((response: IGenericResponse) => {
                if (response.status) {
                    let data: IStoryResponse = response.data;
                    if (!data.locked) {
                        story = StoryDataHelpers._formatStoryFromBackend(data.story);
                    }
                    resolve(data);
                } else {
                    reject(new Error(response.message));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    requestEmbedTutorial(path: string) {
        return this.generic.genericGetNoAuth("/marketplace/get-embed-tutorial", {
            path: path
        });
    }
}
