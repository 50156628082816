export enum EMapShapes {
    marker = 0,
    circle = 1
}

export enum EMarkerTypes {
    plain = 2,
    canvasFrame = 1,
    canvasPlain = 0,
    canvasPlainCenter = 100
}

export enum EMarkerScope {
    place = 0,
    auxPlace = 1,
    item = 2,
    auxItem = 3
}

/**
 * lower value is higher priority
 * UPDATE: Higher zIndex value overlays will be drawn on top of lower zIndex value tile layers and overlays.
 */
export enum EMarkerPriority {
    circle = 0,
    directions = 1,
    waypoint = 2,
    coin = 3,
    auxPlace = 4,
    place = 5,

    otherUser = 6,
    user = 7,
    drone = 7,

    treasure = 2,
    story = 3,
    arena = 3,
    challenge = 3,
    otherTreasure = 3
}

export interface ISetThisMarkerOptions {
    zindex: number;
    size: number;
    color?: string;
}
