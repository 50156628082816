import { Injectable } from "@angular/core";
import { IPlaceMarkerContent } from "../../../classes/def/map/map-data";
import { ETreasureMode, ILeplaceTreasure, ILeplaceTreasureDynamic, ILeplaceTreasureMin, ILeplaceWrapper } from "../../../classes/def/places/leplace";
import { MarkerUtilsService } from "../../map/marker-utils-provider";
import { ETreasureType, ITreasureScanResponse } from "../../../classes/def/items/treasures";
import { ItemScannerUtils } from "./item-scanner-utils";
import { MarkerHandlerService } from "../../map/markers";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { EMarkerLayers } from "../../../classes/def/map/marker-layers";
import { LocationUtils } from '../../location/location-utils';
import { ArrayUtils } from '../../utils/array-utils';
import { StoryDataService } from '../../data/story';
import { LatLng } from "@ionic-native/google-maps/ngx";
import { IPlaceScanParamsResponse, IPlaceScanParams } from "src/app/classes/def/items/scanner";
import { IPlaceExtContainer } from "src/app/classes/def/places/container";
import { PlacesDataService } from "../../data/places";
import { LocationApiService } from "../../location/location-api";
import { AppConstants } from "src/app/classes/app/constants";
import { IGenericResponse, IGenericResponseDataWrapper } from "src/app/classes/def/requests/general";
import { DeepCopy } from "src/app/classes/general/deep-copy";
import { IGmapViewport } from "../../map/map-manager";
import { GeometryUtils } from "../../utils/geometry-utils";
import { SleepUtils } from "../../utils/sleep-utils";
import { IActionLayers, IActionLayer } from "src/app/classes/def/core/objects";
import { GeoObjectsService } from "./geo-objects";
import { MarkerUtils } from "../../map/marker-utils";

export interface ITreasureScanResult {
    places: ILeplaceWrapper[],
    treasures: ILeplaceTreasure[]
}

interface IScanSpecs {
    location: LatLng,
    radius: number
}

@Injectable({
    providedIn: 'root'
})
export class ItemScannerService {
    virtualMode: boolean = false;
    unlockScanner: boolean = true;
    enabled: boolean = false;

    editorMode: boolean = true;
    lockedOnly: boolean = false;
    includeNearbyScan: boolean = true;

    editorUnlocked: boolean = true;

    platform: IPlatformFlags = {} as IPlatformFlags;
    prevScanSpecs: IScanSpecs = {
        location: null,
        radius: null
    };
    scanRadius: number = null;

    scanLevels: number[] = [1, 2, 5, 10];
    scanLevelIndex: number = 0;

    /**
     * used to sync new markers (new item scan) with the existing constraints
     * so that unlocked markers are not added to a locked item type set
     */
    unlockedLayers: IActionLayers = {};
    unlockedLayersSnapshot: IActionLayers = {};

    cache: ITreasureScanResult = { treasures: [], places: [] };

    constructor(
        private markerUtilsProvider: MarkerUtilsService,
        private placesData: PlacesDataService,
        private locationApi: LocationApiService,
        private markerHandler: MarkerHandlerService,
        private storyData: StoryDataService,
        private geoObjects: GeoObjectsService
    ) {
        console.log("item scanner service created");
    }


    setPlatform(platform: IPlatformFlags) {
        this.platform = platform;
    }


    setEditorMode(editor: boolean) {
        this.editorMode = editor;

        // if (editor) {
        //     this.clearCache();
        // }
    }

    setEditorUnlocked(unlocked: boolean) {
        this.editorUnlocked = unlocked;
    }

    private checkValidTreasure(tm: ILeplaceTreasure) {
        // console.log("treasure: " + tm.id + " valid: " + tm.valid);
        return tm.valid || (tm.valid == null);
    }

    setCurrentScanSpecs(location: LatLng, scanRadius: number, prevSpecs: boolean) {
        if (this.scanRadius == null) {
            this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown;
        }
        let res: IScanSpecs = {
            location: location,
            radius: scanRadius
        };
        if (prevSpecs && this.prevScanSpecs) {
            if (this.prevScanSpecs.radius != null) {
                res.radius = this.prevScanSpecs.radius;
            }
            if (this.prevScanSpecs.location != null) {
                res.location = this.prevScanSpecs.location;
            }
        } else {
            res.radius = scanRadius;
            res.location = location;
        }
        this.prevScanSpecs = res;
        return res;
    }

    /**
    * treasure scanner for event scope
    */
    treasureScanPrivate(storyId: number, location: LatLng, callback: (item: ILeplaceTreasure) => any, prevSpecs: boolean): Promise<ITreasureScanResult> {
        return new Promise((resolve, reject) => {
            if (!storyId) {
                reject(new Error("story id undefined"));
                return;
            }

            let specs: IScanSpecs = this.setCurrentScanSpecs(location, null, prevSpecs);

            this.storyData.getCustomStoryTreasures(storyId, specs.location, null).then((treasures: ILeplaceTreasure[]) => {
                console.log("scan event world map");
                treasures = this.processPrivateTreasureMarkers(treasures, callback);
                let res: ITreasureScanResult = {
                    treasures: treasures,
                    places: []
                }
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * switch scan radius based on predefined levels relative to default value
     */
    switchScanRadius(init: boolean) {
        if (this.scanRadius == null || init) {
            this.scanLevelIndex = 0;
            this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown / (this.scanLevels[this.scanLevelIndex]);
        } else {
            this.scanLevelIndex += 1;
            if (this.scanLevelIndex >= this.scanLevels.length) {
                this.scanLevelIndex = 0;
            }
            this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown / (this.scanLevels[this.scanLevelIndex]);
        }
    }

    setScanRadiusFromZoomLevel() {

    }

    setScanRadiusFromViewport(vp: IGmapViewport) {
        this.scanLevelIndex = 0;
        if ((vp.radius > 0) && (vp.radius < AppConstants.gameConfig.placeScanRadiusTown)) {
            this.scanRadius = vp.radius;
        } else {
            this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown;
        }
    }

    getScanRadius() {
        return this.scanRadius;
    }

    treasureMultiScanPublic(location: LatLng, callback: (item: ILeplaceTreasure) => any, fullDMS: boolean, multidiv: boolean, prevSpecs: boolean): Promise<ITreasureScanResult> {
        if (!multidiv) {
            return this.treasureScanPublic(location, callback, fullDMS, prevSpecs);
        }
        return new Promise(async (resolve, reject) => {
            let rawPlaces: IPlaceExtContainer[] = [];
            if (fullDMS) {
                rawPlaces = await this.findNearbyPlaces(location);
            }

            if (this.scanRadius == null) {
                this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown;
            }

            let scanRadius: number = this.scanRadius;

            let specs: IScanSpecs = this.setCurrentScanSpecs(location, scanRadius, prevSpecs);
            location = specs.location;
            scanRadius = specs.radius;

            let places: ILeplaceWrapper[] = [];
            let treasures: ILeplaceTreasure[] = [];
            let subdiv: number = 9; // power of 3
            let subindex: number = Math.sqrt(subdiv);
            let scanRadiusPartial: number = scanRadius / subindex;
            let locationdiv: LatLng = location;
            let locationPivots: LatLng[] = [];
            try {
                for (let i = 0; i < subindex; i++) {
                    for (let j = 0; j < subindex; j++) {
                        locationdiv = new LatLng(location.lat, location.lng);
                        locationdiv.lat += (j - Math.floor(subindex / 2)) * (2 * scanRadiusPartial) * GeometryUtils.RAD;
                        locationdiv.lng += (i - Math.floor(subindex / 2)) * (2 * scanRadiusPartial) * GeometryUtils.RAD;
                        locationPivots.push(new LatLng(locationdiv.lat, locationdiv.lng));
                    }
                }

                let pivotMarkerContentArray: IPlaceMarkerContent[] = [];
                let pivotIndex: number = 0;

                await this.markerHandler.disposeLayerResolve(EMarkerLayers.WAYPOINTS);

                for (let pivot of locationPivots) {
                    let res: ITreasureScanResult = await this.treasureScanPublicCore(pivot, rawPlaces, scanRadiusPartial);
                    let treasuresdiv: ILeplaceTreasure[] = res.treasures;
                    let placesdiv: ILeplaceWrapper[] = res.places;
                    await SleepUtils.sleep(100);
                    places = places.concat(placesdiv);
                    treasures = treasures.concat(treasuresdiv);
                    // create debug markers
                    pivotMarkerContentArray.push(MarkerUtils.getWaypointMarkerData(pivot, "" + pivotIndex + "/" + locationPivots.length));
                    pivotIndex++;
                }

                await this.markerHandler.insertMultipleMarkers(pivotMarkerContentArray, true);

                let countAll: number = treasures.length;
                treasures = ArrayUtils.removeDuplicates(treasures, "id");
                let countUnique: number = treasures.length;
                console.log("retrieved " + countAll + " (" + countUnique + " distinct) treasures");

                countAll = places.length;
                places = ArrayUtils.removeDuplicates(places, "id");
                countUnique = places.length;
                console.log("retrieved " + countAll + " (" + countUnique + " distinct) places");

                treasures = this.processTreasureMarkers(treasures, callback);
                let res: ITreasureScanResult = {
                    treasures: treasures,
                    places: places
                };
                this.setCache(res);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    clearCache() {
        this.cache = { treasures: [], places: [] };
    }

    clearSession() {
        this.prevScanSpecs = {
            location: null,
            radius: null
        };
    }

    setCache(res: ITreasureScanResult) {
        this.cache = res;
    }

    getCache(): ITreasureScanResult {
        return this.cache;
    }

    /**
    * treasure scanner for global scope
    * the item scan should request the nearby places
    * and place the corresponding items for each place type
    * in a nearby location
    * a place can have multiple items (crates)
    */
    treasureScanPublic(location: LatLng, callback: (item: ILeplaceTreasure) => any, fullDMS: boolean, prevSpecs: boolean): Promise<ITreasureScanResult> {
        return new Promise(async (resolve, reject) => {
            let rawPlaces: IPlaceExtContainer[] = [];

            if (fullDMS) {
                rawPlaces = await this.findNearbyPlaces(location);
            }

            if (this.scanRadius == null) {
                this.scanRadius = AppConstants.gameConfig.placeScanRadiusTown;
            }

            let scanRadius: number = this.scanRadius;
            let specs: IScanSpecs = this.setCurrentScanSpecs(location, scanRadius, prevSpecs);
            location = specs.location;
            scanRadius = specs.radius;

            this.treasureScanPublicCore(location, rawPlaces, scanRadius).then((res: ITreasureScanResult) => {
                // check already collected item locations on server
                // also register items into user item location table
                res.treasures = this.processTreasureMarkers(res.treasures, callback);
                this.setCache(res);
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }



    /**
    * treasure scanner for global scope
    * the item scan should request the nearby places
    * and place the corresponding items for each place type
    * in a nearby location
    * a place can have multiple items (crates)
    */
    treasureScanPublicCore(location: LatLng, rawPlaces: IPlaceExtContainer[], scanRadius: number): Promise<ITreasureScanResult> {
        return new Promise(async (resolve, reject) => {
            console.log("process google places: ", rawPlaces);
            let result: ITreasureScanResult = {
                places: [],
                treasures: []
            };
            this.processTreasurePlaces(location, rawPlaces, scanRadius).then((leplaceArray: ILeplaceWrapper[]) => {
                console.log("processed google places: ", leplaceArray);
                result.places = leplaceArray;

                let rawItems: ILeplaceTreasure[] = [];
                let ncTotal: number = 0;
                let ncEmpty: number = 0;

                for (let i = 0; i < leplaceArray.length; i++) {
                    // compute item location nearby the specified location
                    // alter original location within a specified radius
                    let place: ILeplaceWrapper = leplaceArray[i];

                    if (place && place.place && place.place.place) {
                        LocationUtils.checkExistingPhotoUrlInit(place.place.place);
                    }

                    if (place && place.id && place.treasures && place.treasures.length > 0) {
                        for (let j = 0; j < place.treasures.length; j++) {
                            let crate: ILeplaceTreasure = DeepCopy.deepcopy(place.treasures[j]);
                            // copy the place details to each treasure to make it easier later
                            // uses more memory though!

                            if (crate) {
                                crate = this.initCrate(crate, place, j);
                                rawItems.push(crate);
                            } else {
                                // console.log("empty crate in: ", place);
                                ncEmpty += 1;
                            }
                            ncTotal += 1;
                        }
                    }
                }

                console.log("total crates: ", ncTotal);
                console.log("empty crates: ", ncEmpty);

                this.checkAvailableTreasuresServer(rawItems).then((treasures: ILeplaceTreasure[]) => {
                    result.treasures = treasures;
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }


    processPrivateTreasureMarkers(mks: ILeplaceTreasure[], callback: (item: ILeplaceTreasure) => any) {
        // format location containers as standard representation
        // moved to server side
        for (let i = 0; i < mks.length; i++) {
            let mk: ILeplaceTreasure = mks[i];
            LocationUtils.formatTreasureLocationDB(mk);
        }
        return this.processTreasureMarkers(mks, callback);
    }

    processTreasureMarkers(mks: ILeplaceTreasure[], callback: (item: ILeplaceTreasure) => any) {
        console.log("valid items: ", mks.filter(tm => this.checkValidTreasure(tm)).length + "/" + mks.length);
        let challengeItems: ILeplaceTreasure[] = mks.filter(item => item.type === ETreasureType.challenge);
        console.log("format params ok (challenges): ",
            challengeItems.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true).length + "/" + challengeItems.length);
        mks = mks.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true);
        for (let i = 0; i < mks.length; i++) {
            this.initCrate(mks[i], null, i);
        }
        for (let i = 0; i < mks.length; i++) {
            mks[i].dynamic.cacheIndex = i;
        }
        this.showTreasureReport(mks);
        this.attachTreasureMarkers(mks, callback);
        return mks;
    }

    attachTreasureMarkers(mks: ILeplaceTreasure[], callback: (item: ILeplaceTreasure) => any) {
        this.attachPlaceMarkers(mks, 1, EMarkerLayers.CRATES, callback);
    }

    /**
     * load marker layers from the server
     * set unlocked by default
     * saves the configuration as default
     */
    setShowLayers(layersDict: IActionLayers) {
        if (layersDict) {
            this.setShowLayersCore(layersDict, true);
        } else {
            this.geoObjects.getShowLayersResolve(false).then((layersDict: IActionLayers) => {
                if (layersDict) {
                    this.setShowLayersCore(layersDict, true);
                }
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }

    /**
    * set layers
    * filter by world map layers only
    * @param layersDict 
    * @param snapshot 
    */
    private setShowLayersCore(layersDict: IActionLayers, snapshot: boolean) {
        this.unlockedLayers = {};
        let keys: string[] = Object.keys(layersDict);
        for (let i = 0; i < keys.length; i++) {
            let layer: IActionLayer = layersDict[keys[i]];
            if (layer.type === ETreasureMode.worldMap) {
                this.unlockedLayers[keys[i]] = Object.assign({}, layer);
            }
        }
        // this.unlockedLayers = Object.assign({}, layersDict);
        console.log("item scanner init world map layers: ", this.unlockedLayers);
        if (snapshot) {
            this.unlockedLayersSnapshot = Object.assign({}, layersDict);
        }
    }

    /**
     * find nearby places ext provider
     */
    findNearbyPlaces(currentLocation: LatLng): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            if (!currentLocation) {
                reject(new Error("location not specified"));
                return;
            }
            this.placesData.getPlaceScanParams().then((params: IPlaceScanParamsResponse) => {
                let typesObj: IPlaceScanParams[] = params.treasures;
                let limitPerType: number[] = typesObj.map(type => type.limit);
                let types: string[] = typesObj.map(type => type.type);
                console.log("scan: ", types, limitPerType);
                this.locationApi.findPlacesNearbySuggestionMultiPassCore(currentLocation, types, limitPerType).then((placeResults: IPlaceExtContainer[]) => {
                    resolve(placeResults);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                // there is no reject on this provider though
                reject(err);
            });
        });
        return promise;
    }

    /**
    * process treasures DMS
    */
    processTreasurePlaces(currentLocation: LatLng, placeResults: IPlaceExtContainer[], radius: number): Promise<ILeplaceWrapper[]> {
        let promise: Promise<ILeplaceWrapper[]> = new Promise((resolve, reject) => {
            this.placesData.processScanTreasuresDMS(currentLocation, [null, null], placeResults, false, radius, this.includeNearbyScan).then((resp: IGenericResponseDataWrapper<ILeplaceWrapper[]>) => {
                let leplacesWT: ILeplaceWrapper[] = resp.data;
                leplacesWT = this.assignOriginalAuxPlaceData(leplacesWT, placeResults);
                this.placesData.processScanTreasuresUserContent(currentLocation, [null, null], false, radius).then((resp: IGenericResponseDataWrapper<ILeplaceWrapper[]>) => {
                    let leplacesUser: ILeplaceWrapper[] = resp.data;
                    leplacesWT = leplacesWT.concat(leplacesUser);
                    resolve(leplacesWT);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * post processing on the server to check user collected crates for the last 24 hours, etc
     * input: original crates (full spec)
     * request (backend I/O) min specs
     * output: processed crates (full spec)
     * extract/merge data on client
     */
    checkAvailableTreasuresServer(treasures: ILeplaceTreasure[]) {
        let promise = new Promise((resolve, reject) => {
            console.log("process user treasures request");
            // remove un-needed data for reducing data exchange with the server
            let treasuresMin: ILeplaceTreasureMin[] = [];
            for (let i = 0; i < treasures.length; i++) {
                let tm: ILeplaceTreasure = DeepCopy.deepcopy(treasures[i]);
                delete tm.source;
                delete tm.place;
                delete tm.location;
                delete tm.activity;
                treasuresMin.push(tm as ILeplaceTreasureMin);
            }

            this.placesData.processUserTreasuresMin(treasuresMin, this.editorMode, this.lockedOnly).then((response: IGenericResponse) => {
                let availableItemsResult: ITreasureScanResponse = response.data;
                let availableCrates: ILeplaceTreasureMin[] = availableItemsResult.crates;
                let mergedCrates: ILeplaceTreasure[] = [];
                // merge data from server with input data
                for (let i = 0; i < treasures.length; i++) {
                    let input: ILeplaceTreasure = treasures[i];
                    if (input.uid != null) {
                        // start from 0 as the results are not always sorted
                        for (let j = 0; j < availableCrates.length; j++) {
                            let processed: ILeplaceTreasureMin = availableCrates[j];
                            if (input.uid === processed.uid) {
                                let merged: ILeplaceTreasure = Object.assign(input, processed);
                                mergedCrates.push(merged);
                                break;
                            }
                        }
                    } else {
                        console.log("merger > input uid null on crate " + (i + 1) + "/" + treasures.length);
                    }
                }
                resolve(mergedCrates);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * re-assign google specific data
     * aux container
     * @param leplaces 
     * @param places 
     */
    assignOriginalAuxPlaceData(leplaces: ILeplaceWrapper[], places: IPlaceExtContainer[]) {
        // overwrite with original google maps place results
        // because some info might be lost in the exchange such as the method to get photo url
        for (let i = 0; i < leplaces.length; i++) {
            for (let j = 0; j < places.length; j++) {
                if (leplaces[i].place.place.googleId === places[j].googleId) {
                    leplaces[i].place.place.aux = places[j].aux;
                    // console.log("replaced: ", places[i].photos);
                    break;
                }
            }
        }
        return leplaces;
    }

    /**
     * re-assign google specific data
     * aux container
     * @param treasures 
     * @param originalTreasures 
     */
    assignOriginalAuxPlaceDataTreasure(treasures: ILeplaceTreasure[], originalTreasures: ILeplaceTreasure[]) {
        // overwrite with original google maps place results
        // because some info might be lost in the exchange such as the method to get photo url
        for (let i = 0; i < treasures.length; i++) {
            for (let j = 0; j < originalTreasures.length; j++) {
                if (treasures[i].place.place.googleId === originalTreasures[j].place.place.googleId) {
                    treasures[i].place.place.aux = originalTreasures[j].place.place.aux;
                    break;
                }
            }
        }
        return treasures;
    }


    /**
     * init crate
     * @param crate 
     * @param place 
     */
    initCrate(crate: ILeplaceTreasure, place: ILeplaceWrapper, index: number) {
        crate.dynamic = {} as ILeplaceTreasureDynamic;
        if (place) {
            crate.place = place.place;
        }
        crate.dynamic.inRange = false;
        crate.dynamic.index = index; // the index is (should be) only for display
        crate.dynamic.showMarker = this.checkShowLayer(crate.type);
        return crate;
    }

    /**
     * check if the layer is unlocked/enabled
     * @param type 
     */
    private checkShowLayer(type: number) {
        if (!this.unlockedLayers) {
            return false;
        }
        let keys: string[] = Object.keys(this.unlockedLayers);
        for (let i = 0; i < keys.length; i++) {
            let layer: IActionLayer = this.unlockedLayers[keys[i]];
            if (layer.code === type) {
                // console.log("layer enabled");
                return layer.enabled;
            }
        }
        return false;
    }

    /**
     * set show all treasure types
     * @param flag 
     */
    private setShowLayersGlobalCore(flag: boolean) {
        let keys: string[] = Object.keys(this.unlockedLayers);
        for (let i = 0; i < keys.length; i++) {
            let key: string = keys[i];
            this.unlockedLayers[key].enabled = flag;
        }
    }

    /**
    * toggles the visibility of all treasure markers
    * e.g. when starting/exiting a challenge on the world map
    */
    setShowLayersGlobal(show: boolean) {
        this.setShowLayersGlobalCore(show);
    }


    /**
     * show treasure scan report for debug purpose
     */
    showTreasureReport(items: ILeplaceTreasure[]) {
        console.log("treasure scanner report: " + items.length);
        let challengesDict: { [name: string]: ILeplaceTreasure[] } = {};

        for (let i = 0; i < items.length; i++) {
            let item: ILeplaceTreasure = items[i];
            if (item.spec) {
                let key: string = item.spec.dispName;
                if (key in challengesDict) {
                    challengesDict[key].push(item);
                } else {
                    challengesDict[key] = [item];
                }
            }
        }

        challengesDict = ArrayUtils.sortDictKeys(challengesDict);
        console.log("group by dispName:");
        console.log(challengesDict);
    }


    /**
     * attach markers to the items
     * @param worldMapMode 
     * @param debug 
     */
    attachPlaceMarkers(tms: ILeplaceTreasure[], viewId: number, layer: string, callback: (item: ILeplaceTreasure) => any) {
        console.log("attach place markers");
        for (let i = 0; i < tms.length; i++) {
            let tm: ILeplaceTreasure = tms[i];
            if (this.editorMode) {
                tm.owned = true;
            }
            if (this.checkValidTreasure(tm)) {
                tm.viewId = viewId;
                this.markerUtilsProvider.attachPlaceMarkerToTreasure(tm, layer, true, false, callback, !this.platform.WEB, this.editorMode && this.editorUnlocked);
            }
        }
        console.log("attach place markers: ", tms);
    }


    /**
     * refresh the crate markers
     * remove all and then add them again
     * the marker content data is defined in the item scanner itself
     * apply density filtering
     */
    refreshWorldMapMarkerLayer(layer: string, placeMarkerContentArray: IPlaceMarkerContent[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {

            if (!placeMarkerContentArray) {
                placeMarkerContentArray = [];
            }

            console.log("refresh world map marker layer");
            let allCrateMarkers: IPlaceMarkerContent[] = [];

            // clear and re-add markers
            allCrateMarkers = placeMarkerContentArray;

            if (allCrateMarkers == null) {
                allCrateMarkers = [];
            }

            console.log("refresh world map layer: ", layer);

            // pass/enable all treasures
            // still apply filtering by visible flag

            allCrateMarkers = allCrateMarkers.filter(cm => {
                let show: boolean = true;
                if (cm) {
                    if (!cm.visible) {
                        show = false;
                    }
                    if (cm.treasure && !this.checkShowLayer(cm.treasure.type)) {
                        show = false;
                    }
                }
                return show;
            });

            this.markerHandler.syncMarkerArray(layer, allCrateMarkers).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * refresh layer
     * resolve only
     * get stats wrapper
     * @param layer 
     * @param placeMarkerContentArray 
     * @param options 
     */
    refreshWorldMapMarkerLayerWrapperResolve(layer: string, placeMarkerContentArray: IPlaceMarkerContent[]): Promise<boolean> {
        return new Promise((resolve) => {
            if (!placeMarkerContentArray) {
                placeMarkerContentArray = [];
            }
            this.refreshWorldMapMarkerLayer(layer, placeMarkerContentArray).then(() => {
                // get stats
                let markerLayer: IPlaceMarkerContent[] = this.markerHandler.getMarkerDataMultiLayer(layer);
                let inputIds: number[] = placeMarkerContentArray.map(m => {
                    if (m && m.treasure) {
                        return m.treasure.id;
                    }
                }).sort((a, b) => {
                    return a - b;
                });
                let outputIds: number[] = markerLayer.map(m => {
                    if (m && m.treasure) {
                        return m.treasure.id;
                    }
                }).sort((a, b) => {
                    return a - b;
                });
                console.log("refresh marker layer: ", layer, ", input: ", inputIds, ", output: ", outputIds);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }
}
