import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EImgIcons, EAppIcons } from 'src/app/classes/def/app/icons';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';

@Component({
  selector: 'iap-card',
  templateUrl: './iap-card.component.html',
  styleUrls: ['./iap-card.component.scss']
})
export class IapCardComponent implements OnInit, OnDestroy {
  imgIcons = EImgIcons;
  appIcons = EAppIcons;

  descriptionDisp: string = "";
  titleDisp: string = "Available in Leplace World";
  footerDisp: string = "<p>Unlock with In-App Purchase on Leplace World</p><p>The exact prices are shown in the app and may vary according to the local currency and applicable taxes in your country</p><p>This option is not available for TestFlight iOS distribution</p>";

  @Input()
  content: IGameItemIAP;

  @Input()
  promo: IGameItemIAP;

  @Input()
  description: string;

  loaded: boolean = false;

  priceAndroid: string = "";
  priceIOS: string = "";
  priceAndroidPromo: string = "";
  priceIOSPromo: string = "";

  priceExtra: string = " + taxes*";

  constructor(
    public locationUtlsWizard: LocationUtilsWizard,
    public sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.formatIap();
  }

  ngOnDestroy() {

  }

  formatIap() {
    if (this.description) {
      this.descriptionDisp = this.description;
    }
    if (this.content) {
      this.priceAndroid = this.content.price;
      this.priceIOS = this.content.priceIos;
    }
    if (this.promo) {
      this.priceAndroidPromo = this.promo.price;
      this.priceIOSPromo = this.promo.priceIos;
    }
    this.loaded = true;
  }
}
