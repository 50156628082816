<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title" (rightClick)="dismiss()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
  <div *ngIf="singleVideo">
    <youtube-view [videoId]="videoId" [videoUrl]="videoUrl"></youtube-view>
  </div>
  <div *ngIf="!singleVideo">
    <ion-list>
      <ion-item-group *ngFor="let v of videoList">
        <ion-item-divider sticky class="list-item-divider">
          <ion-label class="label-text-m text-color-primary">
            {{v.title}}
          </ion-label>
        </ion-item-divider>
        <ion-item class="list-item transparent-bg item-noborder">
          <youtube-view [videoUrl]="v.url" customClass="video-frame-size" class="full-w"></youtube-view>
        </ion-item>
      </ion-item-group>
    </ion-list>
  </div>
</modal-container>