

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';

/**
 * encapsulates the content with the specified view params
 * e.g. show/hide frame, transparency
 */
@Component({
  selector: 'modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  @Input()
  vs: IViewSpecs;

  @Input()
  wrapperClass: string;

  outerClass: string;
  innerClass: string;

  disableTransparency: boolean = false;

  constructor() {

  }

  ngOnInit() {
    if (!this.vs) {
      this.vs = ViewSpecs.getDefault();
    }

    if (this.vs.transparent && !this.disableTransparency) {
      this.outerClass = 'custom-modal-style';
      if (this.vs.frame) {
        this.innerClass = 'view-frame';
      } else {
        // this.innerClass = 'view-frame-minimal padding-s';
        this.innerClass = 'view-frame-minimal';
      }
    } else {

      this.outerClass = 'custom-modal-style ';

      // modal-background-fixed-content-gradient
      if (this.vs.frame) {
        this.innerClass = 'view-frame';
      } else {
        this.innerClass = 'view-frame-minimal';
      }

      if (!this.vs.fullScreen) {
        this.outerClass += "modal-background-gradient";
      }
    }

    if (this.wrapperClass) {
      this.innerClass += " " + this.wrapperClass;
      this.outerClass += " " + this.wrapperClass;
    }
  }

  ngOnDestroy() {

  }
}
