
<ion-content [ngClass]="theme">
  <div class="view-frame">
    <div class="bg-container bg-container-fill-margin-top margin-top-status-bar"
      [photoBg]="{fade: false, fadeClass: 'bg-container-fade', url: backgroundSrc}">
      <div class="content">
        <div>
          <div class="stick-bottom padding-null" *ngIf="isWeb">
            <div class="text-center padding-xs">
              <span class="info-text-s bold text-color">LP Connect Web</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>