<ion-item class="list-item transparent-bg settings-item">
    <ion-label>{{selector.heading}}</ion-label>
    <ion-datetime-button [datetime]="selector.id" class="datetime-button-style"></ion-datetime-button>
    <ion-modal [keepContentsMounted]="true" class="custom-modal-mounted">
        <ng-template>
            <ion-datetime [id]="selector.id" [min]="selector.minValue" [max]="selector.maxValue"
                [(ngModel)]="selector.value" [presentation]="selector.full ? 'date-time' : 'month-year'"
                [showDefaultButtons]="true" (ionChange)="onChange(selector)"
                class="datetime-modal-style"></ion-datetime>
        </ng-template>
    </ion-modal>
</ion-item>