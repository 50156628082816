
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HTTPErrorHandlerService } from '../data/error-handler';
import { AuthCoreService } from './auth-core';
import { ApiDef } from '../../../classes/app/api';
import { IUserAuthDetails, IUserLoginResponse } from '../../../classes/def/user/general';
import { IGenericResponse, ICheckLoginResponse } from '../../../classes/def/requests/general';
import { SocialAuthWrapperService } from './social-auth';
import { ELocalAppDataKeys } from '../../../classes/def/app/storage-flags';
import { AppSettings } from '../../utils/app-settings';
import { GeneralCache } from '../../../classes/app/general-cache';
import { StorageOpsService } from '../data/storage-ops';
import { IFacebookProfileData } from '../../../classes/def/social/profile';
import { IAuthData } from 'src/app/classes/def/app/auth';
import { AppConstants } from 'src/app/classes/app/constants';
import { AuthUtils } from './utils';


export interface IApiContextData {
    appId: number,
    appVersionCode: number,
    clientOS: number,
    testerMode: boolean,
    userId?: number
}

export interface IApiGenericRequest {
    data: any,
    context: IApiContextData
}

export interface IApiFlatGenericRequest extends IApiContextData {
    [key: string]: any
}

export interface ILoginContainer<T> {
    user: T,
    token: string
}

export interface IChangeEmail {
    email: string,
    status: boolean,
    message: string
}


/**
 * define secure requests
 * also define unsecured (no JWT) routes (signup, login)
 */
@Injectable({
    providedIn: 'root'
})
export class AuthRequestService {
    serverUrl: string;

    testAppVersionOutdated: boolean = false;
    testAppVersionOutdatedValue: number = 102020;

    constructor(
        public http: HttpClient,
        public errorHandler: HTTPErrorHandlerService,
        public authCore: AuthCoreService,
        public social: SocialAuthWrapperService,
        public storageOps: StorageOpsService
    ) {
        console.log("auth request service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    /**
     * most low level http get request w/auth
     * @param route 
     * @param params 
     * @param customApiUrl 
     */
    httpGetSecureUserId(route: string, params: any, customApiUrl?: string) {
        let promise = new Promise((resolve, reject) => {
            this.authCore.getUserIdAndToken().then((user: IUserAuthDetails) => {
                if (user.token !== null) {
                    let headers = AuthUtils.getLoginHeaders(user.token);
                    let params1: IApiFlatGenericRequest = params ? params : {};

                    let appId: number = params1.appId;

                    params1 = Object.assign(params1, this.getContextData());
                    if (appId != null) {
                        params1.appId = appId
                    }
                    params1.userId = user.id;
                    headers.params = params1;

                    let serverUrl: string = this.serverUrl;

                    if (customApiUrl) {
                        serverUrl = customApiUrl;
                    }

                    this.http.get(serverUrl + route, headers).subscribe((response: IGenericResponse) => {
                        if (response) {
                            resolve(response);
                        } else {
                            reject(new Error("no data"));
                        }
                    }, (err: HttpErrorResponse) => {
                        reject(new Error(this.errorHandler.handleHttpError(err)));
                    });
                } else {
                    reject(new Error("Token not found"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * most low level http post request w/auth
     * @param route 
     * @param data 
     * @param customApiUrl 
     */
    httpPostSecureUserId(route: string, data: any, customApiUrl?: string) {
        let promise = new Promise((resolve, reject) => {
            this.authCore.getUserIdAndToken().then((user: IUserAuthDetails) => {
                let headers = AuthUtils.getLoginHeaders(user.token);
                if (user.id === null || user.id === undefined) {
                    reject(new Error("Invalid request"));
                    return;
                }

                let wrapData: IApiFlatGenericRequest = this.getContextData();
                wrapData.userId = user.id;

                if (data) {
                    wrapData = Object.assign(wrapData, data);
                }

                let serverUrl: string = this.serverUrl;
                if (customApiUrl) {
                    serverUrl = customApiUrl;
                }

                this.http.post(serverUrl + route, wrapData, headers).subscribe((response: IGenericResponse) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(new Error("no data"));
                    }
                }, (err: HttpErrorResponse) => {
                    console.error(err);
                    if (err && err.status === 401) {
                        // unauthorized / session expired
                        this.authCore.removeLoginData();
                        this.socialLogOut();
                        GeneralCache.appLoaded = false;
                        GeneralCache.sessionExpired = true;
                        reject(new Error("session expired/ please log in again"));
                    } else {
                        reject(new Error(this.errorHandler.handleHttpError(err)));
                    }
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    getAuthHeaders() {
        let promise = new Promise((resolve, reject) => {
            this.authCore.getUserIdAndToken().then((user: IUserAuthDetails) => {
                let headers = AuthUtils.getLoginHeaders(user.token);
                let res: IAuthData = {
                    userId: user.id,
                    headers: headers
                };
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * check if the user has previously logged in on the server
     * looks for user id and token in the local storage and checks token with token on server
     * sets authenticated flag
     * with app version code
     */
    checkLoginServer(): Promise<ICheckLoginResponse> {
        let promise: Promise<ICheckLoginResponse> = new Promise((resolve, reject) => {
            this.httpPostSecureUserId("/user/check-login", {

            }).then((response: IGenericResponse) => {
                // this.authCore.setLoggedInFlag(true);
                let data: ICheckLoginResponse = response.data;
                GeneralCache.passwordHash = data.passwordHash;
                resolve(data);
            }).catch((err: Error) => {
                // this.authCore.setLoggedInFlag(false);
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * login on server with email and password
     * set login flag on local storage to true
     * @param email
     * @param password 
     */
    submitLoginWithEmail(email: string, password: string): Promise<ILoginContainer<IUserLoginResponse>> {
        let promise: Promise<ILoginContainer<IUserLoginResponse>> = new Promise((resolve, reject) => {
            let data = {
                email: email,
                password: password
            };

            this.genericPostNoAuth("/register/login", data).then((response: IGenericResponse) => {
                let promiseLoginData: Promise<boolean>;
                let data: ILoginContainer<IUserLoginResponse> = null;

                if (response) {
                    data = response.data;
                    promiseLoginData = this.authCore.saveLoginDataResolve(data.token, data.user.userId, data.user.username);
                } else {
                    this.authCore.removeLoginData();
                    promiseLoginData = Promise.resolve(true);
                }
                promiseLoginData.then(() => {
                    resolve(data);
                });
            }).catch((err: HttpErrorResponse) => {
                this.authCore.removeLoginData();
                // console.error(err.message);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * verify google auth
     * register user
     */
    submitLoginWithGoogle(token: string, userId: string): Promise<ILoginContainer<IUserLoginResponse>> {
        let promise: Promise<ILoginContainer<IUserLoginResponse>> = new Promise((resolve, reject) => {
            let data = {
                token: token,
                googleId: userId
            };

            this.genericPostNoAuth("/register/login-google", data).then((response: IGenericResponse) => {
                let promiseLoginData: Promise<boolean>;
                let data: ILoginContainer<IUserLoginResponse> = null;
                if (response) {
                    data = response.data;
                    promiseLoginData = this.authCore.saveLoginDataResolve(data.token, data.user.userId, data.user.username);
                } else {
                    this.authCore.removeLoginData();
                    promiseLoginData = Promise.resolve(true);
                }

                promiseLoginData.then(() => {
                    resolve(data);
                });

            }).catch((err: HttpErrorResponse) => {
                this.authCore.removeLoginData();
                reject(err);
            });
        });

        return promise;
    }

    /**
     * verify facebook auth
     * register user
     */
    submitLoginWithFacebook(token: string, _fbData: IFacebookProfileData): Promise<ILoginContainer<IUserLoginResponse>> {
        let promise: Promise<ILoginContainer<IUserLoginResponse>> = new Promise((resolve, reject) => {
            let data = {
                token: token,
                // fbData: fbData
            };

            this.genericPostNoAuth("/register/login-facebook", data).then((response: IGenericResponse) => {
                let promiseLoginData: Promise<boolean>;
                let data: ILoginContainer<IUserLoginResponse> = null;
                if (response) {
                    data = response.data;
                    promiseLoginData = this.authCore.saveLoginDataResolve(data.token, data.user.userId, data.user.username);
                } else {
                    this.authCore.removeLoginData();
                    promiseLoginData = Promise.resolve(true);
                }

                promiseLoginData.then(() => {
                    resolve(data);
                });
            }).catch((err: HttpErrorResponse) => {
                this.authCore.removeLoginData();
                reject(err);
            });
        });
        return promise;
    }


    /**
    * request with no authentication
    * use with caution
    * e.g. only for GDPR popup before signup
    * w/o handle error
    * @param route 
    * @param data 
    */
    genericPostNoAuth(route: string, data: any) {
        let promise = new Promise((resolve, reject) => {

            if (!data) {
                data = {};
            }

            data = Object.assign(data, this.getContextData());

            this.http.post(this.serverUrl + route, data).subscribe((response: IGenericResponse) => {
                resolve(response);
            }, (err: HttpErrorResponse) => {
                reject(new Error(this.errorHandler.handleHttpError(err)));
            });
        });
        return promise;
    }


    /**
     * request with no authentication
     * use with caution
     * e.g. only for GDPR popup before signup
     * w/ handle error
     * @param route 
     * @param data 
     */
    genericGetNoAuth(route: string, data: any) {
        let promise = new Promise((resolve, reject) => {
            let headers = AuthUtils.getHeaders();

            if (!data) {
                data = {};
            }

            data = Object.assign(data, this.getContextData());

            headers.params = data;

            this.http.get(this.serverUrl + route, headers).subscribe((response: IGenericResponse) => {
                resolve(response);
            }, (err: HttpErrorResponse) => {
                reject(new Error(this.errorHandler.handleHttpError(err)));
            });
        });
        return promise;
    }

    /**
     * sign up with email on server
     * @param email 
     * @param password 
     */
    submitSignUpWithEmail(email: string, username: string, password: string) {
        let promise = new Promise((resolve, reject) => {
            let data = {
                email: email,
                username: username,
                password: password
            };

            this.genericPostNoAuth("/register/signup", data).then((response: IGenericResponse) => {
                if (response && response.status) {
                    // this.saveLoginData(response.message, response.data.userId, response.data.username);
                } else {
                    // this.removeLoginData();
                }
                resolve(response);
            }).catch((err: HttpErrorResponse) => {
                console.log("signup failed ", err);
                this.authCore.setAuthentication(false);
                reject(err);
            });
        });
        return promise;
    }

    forgotMyPassword(email: string) {
        let promise = new Promise((resolve, reject) => {
            let data = {
                email: email
            };

            this.genericPostNoAuth("/register/forgot-my-password", data).then((response: IGenericResponse) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: HttpErrorResponse) => {
                reject(err);
            });
        });
        return promise;
    }

    private getAppVersionCode(): number | string {
        let versionCode: number | string = GeneralCache.versionCode;
        if (this.testAppVersionOutdated) {
            versionCode = this.testAppVersionOutdatedValue;
        }
        return versionCode;
    }

    /**
     * get app specific params (internal)
     */
    private getContextData(): IApiContextData {
        let params: IApiContextData = {
            appVersionCode: this.getAppVersionCode() as any,
            appId: AppConstants.appId,
            clientOS: GeneralCache.os,
            testerMode: AppSettings.testerMode
        };
        return params;
    }


    /**
    * log out from server and set login flag in local storage to false 
    * also log out from facebook
    * set app loaded flag to false
    * so that when a user registers again, the init sequence is run
    */
    logout(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.httpGetSecureUserId("/user/logout", {

            }).then((response: IGenericResponse) => {
                console.log(response);
                this.authCore.removeLoginData();
                this.socialLogOut();

                GeneralCache.appLoaded = false;

                resolve(true);
            }).catch((err: Error) => {
                this.authCore.removeLoginData();
                GeneralCache.appLoaded = false;
                reject(err);
            });
        });
        return promise;
    }

    changeEmailServer(email: string) {
        return this.httpPostSecureUserId("/user/change-email", {
            email: email
        });
    }

    changeUsernameServer(username: string) {
        return this.httpPostSecureUserId("/user/change-username", {
            username: username
        });
    }

    /**
     * validate email (non-auth)
     * @param userId 
     * @param token 
     * @returns 
     */
    validateEmailAccount(userId: number, token: string) {
        return this.genericGetNoAuth("/register/validate", {
            userId: userId,
            token: token
        });
    }


    validateAccount(token: string) {
        return this.httpPostSecureUserId("/user/validate", {
            token: token
        });
    }

    changePasswordServer(oldPassword: string, newPassword: string) {
        return this.httpPostSecureUserId("/user/change-password", {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    /**
     * delete account and log out from facebook/google
     * @param password 
     */
    deleteAccountServer(password: string) {
        let promise = new Promise((resolve, reject) => {
            this.httpPostSecureUserId("/user/delete-account", {
                password: password
            }).then((response: IGenericResponse) => {
                this.socialLogOut();
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * logout from all social apps
     * google, fb
     */
    socialLogOut() {
        this.social.googleLogout().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }


    /**
     * sign in with google
     */
    signInWithGoogleService() {
        return this.social.signInWithGoogle();
    }

    /**
     * return current login status
     */
    isLoggedIn() {
        return this.authCore.isLoggedIn();
    }

    /**
     * observe login status
     */
    checkAuthentication() {
        return this.authCore.checkAuthentication();
    }


    setLoggedInFlag(loggedin: boolean) {
        this.authCore.setLoggedInFlag(loggedin);
    }

    checkLoggedInStorage() {
        return this.authCore.checkLoggedInStorage();
    }

    getUsername() {
        return this.authCore.getUsername();
    }

    saveUsername(username: string) {
        this.storageOps.saveLocalDataNoAction(
            [
                {
                    flag: ELocalAppDataKeys.userName,
                    value: username
                }
            ]
        );
    }

    removeLoginData() {
        this.authCore.removeLoginData();
    }
}
