
export enum EScripts {
    segmentDisplay = "segmentDisplay",
    googleSignin = "googleSignin"
}

export interface IScriptStore {
    name: string;
    src: string;
    type: string;
    loaded?: boolean;
}

export interface IScriptStoreObj {
    [key: string]: IScriptStore;
}

export const ScriptStore: IScriptStore[] = [  
    { name: 'segmentDisplay', src: 'assets/js/SegmentDisplay/segment-display.js', type: 'js' },
    { name: 'googleSignin', src: 'https://accounts.google.com/gsi/client', type: 'js' }
];

