<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" rightButton="close" [rightIcon]="true" [title]="title" (rightClick)="accept()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs" [ngClass]="!vs.fullScreen? (!vs.large?'modal-small-height':'modal-large-height'):'full-height'">
  <div class="padding-s" fxLayout="column">
    <div class="scrollbar-y" [ngClass]="withButtons ? 'scroll-content-modal-2-border' : ''" fxLayout="column">
      <div [innerHTML]="infoHTML"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative">
      </div>
    </div>
    <div fxFlex></div>
    <div class="stick-bottom" *ngIf="withButtons">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12" class="padding-vertical-none">
            <ion-button class="action-button-fill button-color-primary-70" (click)="accept()" type="submit" block>
              <span class="button-font-size-m">
                Accept
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="text-center padding-vertical-none">
            <ion-button class="as-text element-selector button-font-size-m text-color-primary" (click)="cancel()">
              Decline
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>