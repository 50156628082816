
import { Injectable } from '@angular/core';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { LocalDataService } from '../../data/local';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { Messages } from 'src/app/classes/def/app/messages';
import { AuthRequestService } from '../../general/auth-request/auth-request';


@Injectable({
    providedIn: 'root'
})
export class AccountManagerService {
    constructor(
        public uiext: UiExtensionService,
        public authService: AuthRequestService,
        public localData: LocalDataService
    ) {
        console.log("account manager service created");
    }

    /**
     * ask for logout
     * clear all flags
     */
    logoutPromptNoAction() {
        this.logoutPrompt().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * ask for logout
     * clear all flags
     * resolve only
     */
    logoutPrompt(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.uiext.showAlert(Messages.msg.logOut.before.msg, Messages.msg.logOut.before.sub, 2, null).then((res: number) => {
                if (res === EAlertButtonCodes.ok) {
                    this.authService.logout().then(() => {
                        this.localData.clearAppDataFlags();
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    logout() {
        return new Promise<boolean>((resolve) => {
            this.authService.logout().then(() => {
                this.localData.clearAppDataFlags();
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

}