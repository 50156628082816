<div *ngIf="sponsor">
  <ion-grid class="full-w padding-xs">
    <ion-row class="center-items">
      <ion-col size="4">
        <img [src]="sponsor.photoUrl" alt="Avatar" class="img-avatar-header-large">
      </ion-col>
      <ion-col size="8">
        <ion-row>
          <ion-col size="12">
            <span class="info-text-m bold text-color-primary">{{sponsor.name}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <span class="info-text-m text-color-primary">{{sponsor.description}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <!-- <ion-button class="action-button-xs button-color-primary-70" [disabled]="!sponsor.website"
              (click)="openURL(sponsor.website)">
              <icon-wrapper [icon]="icons.world" [custom]="true"></icon-wrapper>
            </ion-button> -->
            <ion-button [disabled]="!sponsor.website"
              class="as-text element-selector info-text-s text-color-primary text-center" (click)="openURL(sponsor.website)">
              <icon-wrapper slot="icon-only" [icon]="icons.www" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div class="content" fxLayout="column" *ngIf="!sponsor">
  <span class="info-text-m margin-top-s text-color-element">
    Sponsor unavailable
  </span>
</div>